import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Branch } from 'src/app/interface/branch';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Constants } from '../../common/Constants';
import * as $ from 'jquery';
import * as moment from 'moment';
import { OrderItem } from 'src/app/models/order-items';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { OrderService } from 'src/app/services/order/order.service';
import { Input } from '@angular/core';
import { SaleAndBottleProfitLoss } from 'src/app/models/order/SaleAndBottleProfitLoss';
import { SaleAndProfitAndLossReportToDisplay } from 'src/app/models/order/SaleAndProfitAndLossReportToDisplay';
 
@Component({
  selector: 'app-report-extra',
  templateUrl: './report-extra.component.html',
  styleUrls: ['./report-extra.component.scss']
})
export class ReportExtraComponent implements OnInit {

  totalRevenue: number;
  totalRevenuWithProfit: number;
  totalLossWithProfit: number;
  totalLossWithoutProfit: number;
  currencyDefault: string;
  branchCurrencyForLowStockProduct: string;
  totalNumberOfOrders_SaleReport: number;
  totalProfit: number;
  totalLossOnDefectedBottles: number;
  totalExpenses: number;

  mostPopularDrink: string;
  numberOfBottleOfTheMostPopularDrink: number;

  tableHasDataSaleProfitDetailReport: boolean;
  tableHasDataSaleReport: boolean;
  tableHasOrderItemData: boolean;

  @ViewChild('successModal', { static: true }) successModal: TemplateRef<any>;
  @ViewChild('failureModal', { static: true }) failureModal: TemplateRef<any>;

  closeResult: string;
  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  showingProfitDetail = false;

  displayedColumnsSaleReport = [
    'Branch',
    'Product',
    'QuantitySold',
    'TotalRevenue',
    'ProfitOnRevenue',
  ];

  dataSourceSaleAndProfitReport: MatTableDataSource<SaleAndBottleProfitLoss>;

  @ViewChild(MatPaginator) paginatorSaleReport: MatPaginator;
  @ViewChild(MatSort) sortSaleReport: MatSort;

  @ViewChild(MatPaginator) paginatorSaleProfitDetailReport: MatPaginator;
  @ViewChild(MatSort) sortSaleProfitDetailReport: MatSort;

  filterFormSaleReport: FormGroup;

  selectedBranchName: string;

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  saleReports: SaleAndBottleProfitLoss[];
  productDTOs: ProductDTO[];

  branches: Branch[];

  startDateSaleReport: string;
  endDateSaleReport: string;

  startDate: string;
  endDate: string;

  @Input() id: string;

  pageSaleProfitDetails
  pageSaleReport1 = 1;

  orderItems: OrderItem[];

  selectedOrderID_PastOrders: number;
  selectedOrderTotalAmount_PastOrders: number;
  selectedOrderCurrency_PastOrders: string;
  saletoDisplay: SaleAndProfitAndLossReportToDisplay;

  workingCurrency: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private formBuilder: FormBuilder) {
    this.saleReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.spin = true;

    this.createForm();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    this.getWorkingCurrency(this.authenticationService.currentUserValue.branchID);

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.loadSaleReportBranches();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadSaleAndProfitReport(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadSaleAndProfitReport(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterFormSaleReport.controls['dtSaleReportFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormSaleReport.controls['dtSaleReportTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  createForm(): void {
    this.filterFormSaleReport = this.formBuilder.group({
      dtSaleReportFrom: new FormControl(null, Validators.required),
      dtSaleReportTo: new FormControl(null, Validators.required),
      selectBranch: ['']
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getWorkingCurrency(branchID: number) {
    this.branchService.getOneBranchByBranchId(branchID,
      this.authenticationService.currentUserValue.companyID).subscribe(res => {

        this.workingCurrency = res.branch_currency;

        this.spin = false;
      }, error => {
        this.spin = false;
      });
  }

  searchSaleReport(branchIdSaleReport) {
    this.spin = true;

    var date = new Date();

    if (this.filterFormSaleReport.controls['dtSaleReportFrom'].value !== null) {
      this.startDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadCompanySaleAndProfitReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  loadSaleReportBranches(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.filterFormSaleReport.controls.selectBranch.enable();
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.filterFormSaleReport.controls.selectBranch.disable();
        this.filterFormSaleReport.controls.selectBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  filterSaleReport(branchIdSaleReport): void {
    this.startDateSaleReport = moment(this.filterFormSaleReport.controls.dtSaleReportFrom.value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateSaleReport = moment(this.filterFormSaleReport.controls.dtSaleReportTo.value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadCompanySaleAndProfitReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  resetSummaryInfo(): void {
    this.totalRevenue = 0;
    this.totalRevenuWithProfit = 0;
    this.totalLossWithProfit = 0;
    this.totalExpenses = 0;
    this.totalLossWithoutProfit = 0;
    this.totalProfit = 0;
    this.totalLossOnDefectedBottles = 0;
    // this.saleReportToBeDisplayed = [];
  }

  loadCompanySaleAndProfitReport(branchId: number, fromDate: string, toDate: string) {
    this.spin = true;

    this.resetSummaryInfo();

    this.orderService.getSaleAndProfitReportForProductWithNoDailyProfit(this.authenticationService.currentUserValue.companyID,
      branchId, fromDate, toDate).subscribe(res => {

        this.saleReports = res;

        if (this.saleReports.length > 0) {
          this.tableHasDataSaleReport = true;

          this.currencyDefault = 'FC'; //this.saleReports[0].currency_one; 
          this.saleReports.forEach(a => this.totalRevenuWithProfit += (a.quantity_sold * a.selling_cost));
          this.saleReports.forEach(a => this.totalRevenue += (a.quantity_sold * a.buying_cost));
          this.totalProfit = Number(this.totalRevenuWithProfit) - Number(this.totalRevenue);
          // this.saleReports.forEach(a => this.totalProfit += (a.quantity_sold * a.profit_on_product));

          this.dataSourceSaleAndProfitReport = new MatTableDataSource(this.saleReports);
          this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
          this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;

        } else {
          this.tableHasDataSaleReport = false;

          this.resetSummaryInfo();
        }

        console.log(this.saleReports);

        this.spin = false;

      }, error => {
        this.tableHasDataSaleReport = false;

        this.resetSummaryInfo();

        this.dataSourceSaleAndProfitReport = new MatTableDataSource([]);
        this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
        this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;
        this.spin = false;
      });
  }

  loadSaleAndProfitReport(branchIdSaleReport) {
    var date = new Date();

    if (this.filterFormSaleReport.controls['dtSaleReportFrom'].value !== null) {
      this.startDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();

    }

    this.loadCompanySaleAndProfitReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  loadProfitByBottle(branchID: number) {
    this.showingProfitDetail = true;
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
