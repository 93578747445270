<!-- Printing div -->
<div style="display: none;" id="print-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card px-2">
          <div class="card-body">

            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-6 pl-0">
                <h6 class="mt-5 mb-2"><b>{{printCompanyName}} - {{printEstablishment}}</b></h6>
                <p>{{printCompanyAddress}},
                  <br>{{printCompanyCity}},
                  <br>{{printCompanyCountry}}.
                </p>
              </div>
              <div class="col-lg-6 pl-0 text-right">
                <p class="mb-0 mt-5">Date d'impression : {{printDate}}</p>
              </div>
            </div>
          </div>

          <div class="container-fluid justify-content-center w-100 table-print mt-3">
            <div class="tn-table-container">
              <table class="table">
                  <tr class="text-secondary">
                    <th class="text-center">Article</th>
                    <th class="text-center">Prix de vente</th>
                    <th class="text-center">Prix d'achat</th>
                    <th class="text-center">Quantité actuelle</th>
                  </tr>
                <tbody>
                  <tr *ngFor="let item of productDTOs" class="text-right">
                    <td class="text-center"> {{item.name}} {{item.type}}</td>
                    <td class="text-center">FC {{item.selling_price}}</td>
                    <td class="text-center">{{branchCurrency}} {{item.cost}}</td>
                    <td class="text-center" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                      <div *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity > item.pack_number_of_piece)">
                        {{(item.quantity % item.pack_number_of_piece) !== 0 ? (item.quantity /
                        item.pack_number_of_piece).toString()
                        .substring(0, (item.quantity / item.pack_number_of_piece).toString().indexOf('.')) :
                        (item.quantity / item.pack_number_of_piece) }}
                        casiers de {{item.drink_pack_name}} et {{item.quantity % item.pack_number_of_piece}}
                        bouteilles
                      </div>
                      <div
                        *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity === item.pack_number_of_piece)">
                        1 casier de {{item.drink_pack_name}}
                      </div>
                      <div *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity < item.pack_number_of_piece)">
                        0 casier et {{item.quantity}} bouteilles
                      </div>
                      <div *ngIf="item.pack_number_of_piece === 1">
                        {{item.quantity}} bouteilles
                      </div>
                    </td>
                    <td class="text-center" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                      {{item.quantity}} shot(s)
                    </td>
                    <td class="text-center" *ngIf="item.category_name === 'Nourriture'">
                      {{item.quantity}} plat(s)
                    </td>
                    <td class="text-center"
                      *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                      {{item.quantity}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" id="prod" data-toggle="tab" href="#product" role="tab" aria-controls="product"
              aria-selected="true" (click)="showProduct()">Liste des articles</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="cat" data-toggle="tab" href="#category" role="tab" aria-controls="category"
              aria-selected="false" (click)="showCategory()">Gérer les
              catégories</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="product" role="tabpanel" aria-labelledby="prod">
            <div class="product" *ngIf="product">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
                  <form [formGroup]="productSearchForm">
                    <div class="form-group mt-4">
                      <small class="form-text text-muted">Rechercher un article</small>
                      <div class="input-group mb-3">
                        <input type="search" class="form-control" formControlName="searchProducName"
                          placeholder="Rechercher..." aria-label="Search..." aria-describedby="basic-addon2">
                        <div *ngIf="productSearchForm.controls['searchProducName'].invalid &&
                            (productSearchForm.controls['searchProducName'].dirty ||
                            productSearchForm.controls['searchProducName'].touched)" class="alert alert-light pt-0">
                          <div *ngIf="productSearchForm.controls['searchProducName'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                        <div class="input-group-append">
                          <button class="btn btn-outline-primary" (click)="searchProduct(selectedBranch.value)"
                            type="button"><i class="fas fa-search"></i></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
                  <div class="row">
                    <div class="form-group mt-0 col-md-4 col-sm-6 col-12">
                      <!-- <small class="form-text text-muted mt-md-5 mt-3"></small>
                      <button printTitle="{{printCompanyName}}_Inventaire" [useExistingCss]="true" printSectionId="print-section"
                        ngxPrint type="button" class="btn btn-primary">
                        <i class="fas fa-print"></i> &nbsp;Imprimer
                      </button> -->
                    </div>
                    <div class="form-group mt-0 col-md-8 col-sm-6 col-12">
                      <small class="form-text text-muted mt-md-5 mt-3"></small>
                      <button *ngIf="!editStock" type="button" class="btn btn-primary" (click)="openCreateNewProductModal()" [disabled]="editStock">
                        <i class="fas fa-plus-square"></i> &nbsp;Ajouter un article
                      </button>
                      <button *ngIf="!editStock" type="button" class="btn btn-primary btn-sm m-1" (click)="hideActionColumn()" [disabled]="editStock">
                        Augmenter le stock
                      </button>

                      <button *ngIf="editStock" type="button" class="btn btn-danger m-1" (click)="showActionColumn()">
                        Annuler
                      </button>
                      <button *ngIf="editStock" type="button" class="btn btn-primary m-1" (click)="saveStockvalueToUpdate()">
                        Sauvegarder
                      </button>
                    </div>

                  </div>
                </div>
                <!-- <div class=" col-lg-3 col-md-3 col-sm-3 col-12 add-button-box text-right">
                <div class="form-group mt-4">
                  <label class="mt-5"></label>
                  <button type="button" class="btn btn-primary" data-toggle="modal"
                    data-target="#createNewProduct">
                    <i class="fas fa-plus-square"></i> &nbsp;Mettre à jour le stock
                  </button>
                </div>
              </div> -->
                <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-12">

              </div> -->
              </div>

              <div class="row mt-1 mb-3">
                <div
                  class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">
                  <div  [ngClass]="(!editStock)? 'card mb-3 d-lg-none' : 'd-none'"
                    *ngFor="let item of productDTOs | paginate: { itemsPerPage: 10, currentPage: page }">
                    <div class="card-body">
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Nom de l'article
                        </div>
                        <div class="col-6">
                          {{item.name}} {{item.type}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Prix ​​de vente
                        </div>
                        <div class="col-6">
                          {{branchCurrency}} {{item.selling_price}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Quantité
                        </div>
                        <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                          <div *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity > item.pack_number_of_piece)">
                            {{(item.quantity % item.pack_number_of_piece) !== 0 ? (item.quantity /
                            item.pack_number_of_piece).toString()
                            .substring(0, (item.quantity / item.pack_number_of_piece).toString().indexOf('.')) :
                            (item.quantity / item.pack_number_of_piece) }}
                            casiers de {{item.drink_pack_name}} et {{item.quantity % item.pack_number_of_piece}}
                            bouteilles
                          </div>
                          <div
                            *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity === item.pack_number_of_piece)">
                            1 casier de {{item.drink_pack_name}}
                          </div>
                          <div *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity < item.pack_number_of_piece)">
                            0 casier et {{item.quantity}} bouteilles
                          </div>
                          <div *ngIf="item.pack_number_of_piece === 1">
                            {{item.quantity}} bouteilles
                          </div>
                        </div>
                        <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                          {{item.quantity}} shot(s)
                        </div>
                        <div class="col-6" *ngIf="item.category_name === 'Nourriture'">
                          {{item.quantity}} plat(s)
                        </div>
                        <div class="col-6"
                          *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                          {{item.quantity}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Fournisseur
                        </div>
                        <div class="col-6">
                          {{item.supplier_name}}
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-12 text-center">
                          <button class="btn btn-primary tn-bg-green-color" data-toggle="modal"
                            data-target="#updateQuantity" (click)="updateStockQuantity(item)"><i
                              class="fas fa-sort-numeric-up"></i></button> &nbsp;
                          <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"
                            (click)="view(item)"><i class="fas fa-eye"></i></button> &nbsp;
                          <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                            (click)="edit(item)"><i class="bi bi-pen"></i></button> &nbsp;
                          <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                            (click)="delete(item)"><i class="bi bi-trash"></i></button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <pagination-controls *ngIf="tableHasData === true"
                  [ngClass]="(!editStock)? 'card mb-3 d-lg-none' : 'd-none'"
                    (pageChange)="onMobileTableChangePage($event)" previousLabel="Précédent" nextLabel="Suivant">
                  </pagination-controls>



                  <mat-table [dataSource]="dataSourceProduct"
                  [ngClass]="(!editStock) ? 'd-none d-lg-block' : 'd-block'"
                  matSort>
                    <ng-container matColumnDef="Nom de l'article">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nom de l'article
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{row.name}} {{row.type}}
                      </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Prix ​​d'achat">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                      Prix ​​d'achat
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{branchCurrency}} {{row.cost}}
                    </mat-cell>
                  </ng-container> -->
                    <ng-container matColumnDef="Prix ​​de vente">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Prix ​​de vente
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{branchCurrency}} {{row.selling_price}}
                      </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Quantité">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                      Quantité
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{row.quantity}}
                    </mat-cell>
                  </ng-container> -->
                    <ng-container matColumnDef="Pack">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Quantité
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name !== 'Shot')">
                          <div *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity > row.pack_number_of_piece)">
                            {{(row.quantity % row.pack_number_of_piece) !== 0 ? (row.quantity /
                            row.pack_number_of_piece).toString()
                            .substring(0, (row.quantity / row.pack_number_of_piece).toString().indexOf('.')) :
                            (row.quantity / row.pack_number_of_piece) }}
                            casiers de {{row.drink_pack_name}} et {{row.quantity % row.pack_number_of_piece}}
                            bouteilles
                          </div>
                          <div *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity === row.pack_number_of_piece)">
                            1 casier
                            de {{row.drink_pack_name}}
                          </div>
                          <div *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity < row.pack_number_of_piece)"> 0
                            casier et
                            {{row.quantity}} bouteilles
                          </div>
                          <div *ngIf="row.pack_number_of_piece === 1">
                            {{row.quantity}} bouteilles
                          </div>
                        </div>
                        <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name === 'Shot')">
                          {{row.quantity}} shot(s)
                        </div>
                        <div *ngIf="row.category_name === 'Nourriture'">
                          {{row.quantity}} plat(s)
                        </div>
                        <div *ngIf="(row.category_name !== 'Nourriture') && (row.category_name !== 'Boisson')">
                          {{row.quantity}}
                        </div>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Fournisseur">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Fournisseur
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{row.supplier_name}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Actions" *ngIf="!editStock">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Actions
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <button class="btn btn-primary tn-bg-green-color" data-toggle="modal"
                          data-target="#updateQuantity" (click)="updateStockQuantity(row)"><i
                            class="fas fa-sort-numeric-up"></i></button> &nbsp;
                        <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"
                          (click)="view(row)"><i class="fas fa-eye"></i></button> &nbsp;
                        <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                          (click)="edit(row)"><i class="bi bi-pen"></i></button> &nbsp;
                        <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                          (click)="delete(row)"><i class="bi bi-trash"></i></button>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Valeur a ajouter" *ngIf="editStock">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Valeur à ajouter
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <div class="mt-2 mb-2 stock-cell">
                          <input class="form-control" type="number" [value]="getItemUpdateValue(row.product_id)" (input)="updatItemValue(row.product_id)"
                          [id]="'product'+row.product_id" [name]="'product'+row.product_id">
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsProduct">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsProduct">
                    </mat-row>
                  </mat-table>
                  <div class="mt-3" *ngIf="tableHasData === false">Aucun article trouvé
                  </div>
                  <mat-paginator (page)="onMatTableChangePage($event)"
                  [ngClass]="(!editStock) ? 'd-none d-lg-block' : 'd-block'"
                  [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </div>

                <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1 ">
                  <form [formGroup]="selectForm">
                    <div class="form-group">
                      <small class="form-text text-muted">Sélectionnez un établissement</small>
                      <select #selectedBranch class="form-control" (change)="getBranchCategories($event)"
                        formControlName="selectBranch">
                        <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                          {{branch.branch_name}}
                        </option>
                      </select>
                    </div>

                    <!-- <div class="row"> -->
                    <div class="form-group">
                      <small class="form-text text-muted">Sélectionnez une catégorie</small>
                      <select class="form-control" *ngIf="sideNavCategories !== undefined"
                        formControlName="categorySelect" (change)="getSubCategories($event)">
                        <option disabled selected>Sélectionnez une catégorie</option>
                        <option *ngFor="let category of sideNavCategories" value={{category.category_id}}>
                          {{category.name}}</option>
                      </select>
                      <input *ngIf="sideNavCategories === undefined" type="emptyCategory" class="form-control" disabled
                        placeholder="Aucune catégorie trouvée.">

                      <small class="form-text text-muted mt-2">Sélectionnez une sous-catégorie</small>
                      <select *ngIf="(sideNavSubCategories !== undefined)  && (sideNavSubCategories !== null)"
                        class="form-control" formControlName="subCategorySelect"
                        (change)="getProductBySubCategory($event, selectedBranch.value)">
                        <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                        <option *ngFor="let subcategory of sideNavSubCategories" value={{subcategory.subcategory_id}}>
                          {{subcategory.sub_name}}
                        </option>
                      </select>
                      <input *ngIf="(sideNavSubCategories === undefined) || (sideNavSubCategories === null)"
                        type="emptySubCategory" class="form-control" disabled
                        placeholder="Aucune sous-catégorie trouvée.">
                    </div>
                    <!-- </div> -->

                    <h6 class="mt-5" *ngIf="!editStock">Imprimer sur PC</h6>
                    <div class="form-group" *ngIf="!editStock">
                      <div class="row my-3">
                        <div class="col-md-12">
                          <button printTitle="{{printCompanyName}}_Inventaire" [useExistingCss]="true" printSectionId="print-section"
                          ngxPrint type="button" class="btn btn-primary w-100">
                          <i class="fas fa-print"></i> &nbsp;Imprimer
                        </button>
                        </div>
                      </div>
                    </div>

                    <h6 *ngIf="!editStock">Imprimer sur imprimante portable</h6>
                    <div class="form-group" *ngIf="!editStock">
                      <div class="row my-3">
                        <div class="col-md-12">

                          <button type="button" class="btn btn-primary w-100" (click)="requestUsb()">
                            <i class="fas fa-print"></i> &nbsp; Selectionnez une imprimante
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="!editStock">
                      <div class="row my-3">
                        <div class="col-md-12">
                          <button (click)="getPrintData(selectedBranch.value)" class="btn btn-primary w-100"
                            [disabled]="status === false"> <i class="fas fa-print"></i> &nbsp; Imprimer
                            maintenant!</button>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="category" role="tabpanel" aria-labelledby="cat">
            <app-category *ngIf="category"></app-category>
          </div>
        </div>

        <!-- Add new product -->
        <ng-template #createNewProduct let-modal>

          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Ajouter un nouveau produit</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="productForm">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <small class="form-text text-muted">Nom de l'article</small>
                      <input type="text" class="form-control" formControlName="productName" placeholder="Nom du produit"
                        required>
                      <div *ngIf="productForm.controls['productName'].invalid &&
                                        (productForm.controls['productName'].dirty ||
                                        productForm.controls['productName'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['productName'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <small class="form-text text-muted">Type d'article</small>
                      <input type="text" class="form-control" formControlName="type" placeholder="Type de produit">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <small class="form-text text-muted">Barcode</small>
                      <input type="text" class="form-control" formControlName="barcode" placeholder="Barcode">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Choisir un établissement</small>
                      <select class="form-control" (change)="selectFanchise($event.target.value)" formControlName="franchise"
                        required>
                        <option value="" disabled selected>Sélectionnez un établissement</option>
                        <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                          {{branch.branch_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['franchise'].invalid &&
                                        (productForm.controls['franchise'].dirty ||
                                        productForm.controls['franchise'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['franchise'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group" *ngIf="addFormCategoryEnable">
                      <small class="form-text text-muted">Catégorie</small>
                      <select class="form-control" formControlName="category_id"
                        (change)="addFormSubCategoriesChange($event)" required>
                        <option value="" selected>Sélectionnez une catégorie</option>
                        <option *ngFor="let category of addFormCategories" value={{category.category_id}}>
                          {{category.name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['category_id'].invalid &&
                                        (productForm.controls['category_id'].dirty ||
                                        productForm.controls['category_id'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['category_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group" *ngIf="addFormSubCategoryEnable">
                      <small class="form-text text-muted">Sous-catégorie</small>
                      <select class="form-control" formControlName="subcategory_id" required>
                        <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                        <option *ngFor="let subcategory of addFormSubCategories" value={{subcategory.subcategory_id}}>
                          {{subcategory.sub_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['subcategory_id'].invalid &&
                                        (productForm.controls['subcategory_id'].dirty ||
                                        productForm.controls['subcategory_id'].touched)"
                        class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['subcategory_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6" *ngIf="!isCategoryFood">
                    <div class="form-group">
                      <small class="form-text text-muted">Prix d'un casier</small>
                      <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                        </div>

                      <input type="number" class="form-control" formControlName="pack_price"
                        placeholder="Prix d'un casier" required>
                      <div *ngIf="productForm.controls['pack_price'].invalid &&
                                        (productForm.controls['pack_price'].dirty ||
                                        productForm.controls['pack_price'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['pack_price'].errors.required">
                          Ne devrait pas être vide
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>

                 

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Prix de vente en FC</small>
                      <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                        </div>
                        <input type="number" class="form-control" formControlName="selling_price"
                            placeholder="Prix de vente" required>
                          <div *ngIf="productForm.controls['selling_price'].invalid &&
                                        (productForm.controls['selling_price'].dirty ||
                                        productForm.controls['selling_price'].touched)" class="pt-0 alert alert-light">
                            <div *ngIf="productForm.controls['selling_price'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Bénéfice sur l'article en FC</small>
                      <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                        </div>
                          <input type="number" class="form-control" formControlName="profit_on_product"
                            placeholder="Bénéfice" required>
                             <div *ngIf="productForm.controls['profit_on_product'].invalid &&
                                        (productForm.controls['profit_on_product'].dirty ||
                                        productForm.controls['profit_on_product'].touched)"
                               class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['profit_on_product'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Prix de vente en USD</small>
                      <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <small class="input-group-text" id="inputGroup-sizing-sm">USD</small>
                        </div>
                        <input type="number" class="form-control" formControlName="selling_price_usd"
                            placeholder="Prix de vente en USD" required>
                          <div *ngIf="productForm.controls['selling_price_usd'].invalid &&
                                        (productForm.controls['selling_price_usd'].dirty ||
                                        productForm.controls['selling_price_usd'].touched)" class="pt-0 alert alert-light">
                            <div *ngIf="productForm.controls['selling_price_usd'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">

                </div>


                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Prix de vente VIP en FC (0 si vous n'avez pas de section VIP)</small>
                      <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                        </div>
                        <input type="number" class="form-control" formControlName="vip_selling_price"
                            placeholder="Prix de vente">
                        <div *ngIf="productForm.controls['vip_selling_price'].invalid &&
                                        (productForm.controls['vip_selling_price'].dirty ||
                                        productForm.controls['vip_selling_price'].touched)" class="pt-0 alert alert-light">
                          <div *ngIf="productForm.controls['vip_selling_price'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Prix de vente VIP en USD (0 si vous n'avez pas de section VIP)</small>
                      <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <small class="input-group-text" id="inputGroup-sizing-sm">USD</small>
                        </div>
                        <input type="number" class="form-control" formControlName="vip_selling_price_usd"
                            placeholder="Prix de vente en USD">
                        <div *ngIf="productForm.controls['vip_selling_price_usd'].invalid &&
                                        (productForm.controls['vip_selling_price_usd'].dirty ||
                                        productForm.controls['vip_selling_price_usd'].touched)" class="pt-0 alert alert-light">
                          <div *ngIf="productForm.controls['vip_selling_price_usd'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">VIP Bénéfice (0 si vous n'avez pas de section VIP)</small>
                      <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                          <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                        </div>
                        <input type="number" class="form-control" formControlName="vip_profit_on_product"
                            placeholder="VIP Bénéfice">
                        <div *ngIf="productForm.controls['vip_profit_on_product'].invalid &&
                                        (productForm.controls['vip_profit_on_product'].dirty ||
                                        productForm.controls['vip_profit_on_product'].touched)"
                               class="pt-0 alert alert-light">
                          <div *ngIf="productForm.controls['vip_profit_on_product'].errors.required">
                              Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                </div>



                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Quantité actuelle</small>
                      <input type="number" class="form-control" formControlName="quantity" placeholder="Quantité"
                        required>
                      <div *ngIf="productForm.controls['quantity'].invalid &&
                                        (productForm.controls['quantity'].dirty ||
                                        productForm.controls['quantity'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['quantity'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                      <div class="form-group">
                        <small class="form-text text-muted">Type de casier</small>
                        <select class="form-control" formControlName="drinkPack" required>
                          <option value="" disabled selected>Selectionnez un casier</option>
                          <option *ngFor="let drinkP of drinkPacks" value={{drinkP.drink_pack_id}}>
                            {{drinkP.drink_pack_name}}</option>
                        </select>
                        <div *ngIf="productForm.controls['drinkPack'].invalid &&
                                          (productForm.controls['drinkPack'].dirty ||
                                          productForm.controls['drinkPack'].touched)" class="pt-0 alert alert-light">
                          <div *ngIf="productForm.controls['drinkPack'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Stock de départ (Nbre de bouteilles)</small>
                      <input type="number" class="form-control" formControlName="stock_default"
                        placeholder="Stock de départ" required>
                      <div *ngIf="productForm.controls['stock_default'].invalid &&
                                        (productForm.controls['stock_default'].dirty ||
                                        productForm.controls['stock_default'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['stock_default'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Alerte de stock</small>
                      <input type="number" class="form-control" formControlName="stock_alert"
                        placeholder="Alerte de stock" required>
                      <div *ngIf="productForm.controls['stock_alert'].invalid &&
                                        (productForm.controls['stock_alert'].dirty ||
                                        productForm.controls['stock_alert'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['stock_alert'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Sélectionnez un fournisseur</small>
                      <select class="form-control" formControlName="supplier_id" required>
                        <option value="" disabled selected>Sélectionnez un fournisseur</option>
                        <option *ngFor="let supplier of suppliers" value={{supplier.supplier_id}}>
                          {{supplier.supplier_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['supplier_id'].invalid &&
                                        (productForm.controls['supplier_id'].dirty ||
                                        productForm.controls['supplier_id'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['supplier_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
              </button>
              <button type="button" [disabled]="!productForm.valid" class="btn btn-primary btn-lg"
                (click)="saveNewProduct()">Sauvegarder
              </button>
            </div>
          </div>

        </ng-template>

        <!-- edit product modal -->
        <ng-template #editProduct let-modal>
          <div class="modal-header">
            <h5 class="modal-title">Modification de l'article </h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="editproductForm">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nom de l'article
                    </small>
                    <input type="text" class="form-control" formControlName="productName" placeholder="Nom du produit"
                      required>
                    <div *ngIf="editproductForm.controls['productName'].invalid &&
                                        (editproductForm.controls['productName'].dirty ||
                                        editproductForm.controls['productName'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['productName'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Type d'article
                    </small>
                    <input type="text" class="form-control" formControlName="type" placeholder="Type">
                    <!-- <div *ngIf="editproductForm.controls['type'].invalid &&
                                        (editproductForm.controls['type'].dirty ||
                                        editproductForm.controls['type'].touched)" class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['type'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Barcode
                    </small>
                    <input type="text" class="form-control" formControlName="barcode" placeholder="Barcode">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Prix de vente
                    </small>
                    <input type="number" class="form-control" formControlName="selling_price"
                      placeholder="Coût de vente" required>
                    <div *ngIf="editproductForm.controls['selling_price'].invalid &&
                                        (editproductForm.controls['selling_price'].dirty ||
                                        editproductForm.controls['selling_price'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['selling_price'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Prix de vente en USD
                    </small>
                    <input type="number" class="form-control" formControlName="selling_price_usd"
                      placeholder="Coût de vente" required>
                    <div *ngIf="editproductForm.controls['selling_price_usd'].invalid &&
                                        (editproductForm.controls['selling_price_usd'].dirty ||
                                        editproductForm.controls['selling_price_usd'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['selling_price_usd'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Bénéfice sur l'article
                    </small>
                    <input type="number" class="form-control" formControlName="profit_on_product" placeholder="Coût"
                      required>
                    <div *ngIf="editproductForm.controls['profit_on_product'].invalid &&
                                        (editproductForm.controls['profit_on_product'].dirty ||
                                        editproductForm.controls['profit_on_product'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['profit_on_product'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Prix d'un casier</small>
                    <input type="number" class="form-control" formControlName="pack_price"
                      placeholder="Prix d'un casier" required>
                    <div *ngIf="editproductForm.controls['pack_price'].invalid &&
                                      (editproductForm.controls['pack_price'].dirty ||
                                      editproductForm.controls['pack_price'].touched)" class="pt-0 alert alert-light">
                      <div *ngIf="editproductForm.controls['pack_price'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Prix de vente VIP en FC (0 si vous n'avez pas de section VIP)</small>
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                      </div>
                      <input type="number" class="form-control" formControlName="vip_selling_price"
                          placeholder="Prix de vente" required>
                      <div *ngIf="editproductForm.controls['vip_selling_price'].invalid &&
                                      (editproductForm.controls['vip_selling_price'].dirty ||
                                      editproductForm.controls['vip_selling_price'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="editproductForm.controls['vip_selling_price'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Prix de vente VIP en USD (0 si vous n'avez pas de section VIP)</small>
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <small class="input-group-text" id="inputGroup-sizing-sm">USD</small>
                      </div>
                      <input type="number" class="form-control" formControlName="vip_selling_price_usd"
                          placeholder="Prix de vente" required>
                      <div *ngIf="editproductForm.controls['vip_selling_price_usd'].invalid &&
                                      (editproductForm.controls['vip_selling_price_usd'].dirty ||
                                      editproductForm.controls['vip_selling_price_usd'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="editproductForm.controls['vip_selling_price_usd'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">VIP Bénéfice (0 si vous n'avez pas de section VIP)</small>
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                      </div>
                      <input type="number" class="form-control" formControlName="vip_profit_on_product"
                          placeholder="VIP Bénéfice" required>
                      <div *ngIf="editproductForm.controls['vip_profit_on_product'].invalid &&
                                      (editproductForm.controls['vip_profit_on_product'].dirty ||
                                      editproductForm.controls['vip_profit_on_product'].touched)"
                             class="pt-0 alert alert-light">
                        <div *ngIf="editproductForm.controls['vip_profit_on_product'].errors.required">
                            Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Quantité actuelle
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="true" formControlName="quantity"
                      placeholder="Quantité" required>
                    <div *ngIf="editproductForm.controls['quantity'].invalid &&
                                        (editproductForm.controls['quantity'].dirty ||
                                        editproductForm.controls['quantity'].touched)" class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <div class="form-group">
                      <small class="form-text text-muted">Casier de boisson</small>
                      <select class="form-control" formControlName="drinkPack" required>
                        <option value="" disabled selected>Selectionnez un casier</option>
                        <option *ngFor="let drinkP of drinkPacks" value={{drinkP.drink_pack_id}}>
                          {{drinkP.drink_pack_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['drinkPack'].invalid &&
                                        (productForm.controls['drinkPack'].dirty ||
                                        productForm.controls['drinkPack'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['drinkPack'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Stock de départ (Nbre de bouteilles)</small>
                    <input type="number" class="form-control" formControlName="stock_default"
                      placeholder="Stock de départ" required>
                    <div *ngIf="editproductForm.controls['stock_default'].invalid &&
                                        (editproductForm.controls['stock_default'].dirty ||
                                        editproductForm.controls['stock_default'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['stock_default'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Alerte de stock
                    </small>
                    <input type="number" class="form-control" formControlName="stock_alert"
                      placeholder="Alerte de stock" required>
                    <div *ngIf="editproductForm.controls['stock_alert'].invalid &&
                                        (editproductForm.controls['stock_alert'].dirty ||
                                        editproductForm.controls['stock_alert'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['stock_alert'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Sélectionnez un établissement
                    </small>
                    <select class="form-control" (change)="selectFanchise($event)" [attr.disabled]="true"
                      formControlName="franchise">
                      <option value="" disabled selected>Sélectionnez un établissement</option>
                      <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                        {{branch.branch_name}}</option>
                    </select>
                    <!-- <input type="text" class="form-control" formControlName="franchise" placeholder="Sélectionnez un établissement"
                          required> -->
                    <!-- <div *ngIf="editproductForm.controls['franchise'].invalid &&
                                        (editproductForm.controls['franchise'].dirty ||
                                        editproductForm.controls['franchise'].touched)" class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['franchise'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Sous-catégorie
                    </small>
                    <select class="form-control" formControlName="subcategory_id" required>
                      <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                      <option *ngFor="let subCategory of viewProductSubcategories" value={{subCategory.subcategory_id}}>
                        {{subCategory.sub_name}}</option>
                    </select>
                    <!-- <input type="text" class="form-control" formControlName="subcategory_id"
                          placeholder="Sous-catégorie" required> -->
                    <div *ngIf="editproductForm.controls['subcategory_id'].invalid &&
                                        (editproductForm.controls['subcategory_id'].dirty ||
                                        editproductForm.controls['subcategory_id'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['subcategory_id'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Fournisseur
                    </small>
                    <select class="form-control" formControlName="supplier_id" required>
                      <option value="" disabled selected>Sélectionnez un fournisseur</option>
                      <option *ngFor="let supplier of suppliers" value={{supplier.supplier_id}}>
                        {{supplier.supplier_name}}</option>
                    </select>
                    <!-- <input type="text" class="form-control" formControlName="supplier_id" placeholder="Fournisseur"
                        required> -->
                    <div *ngIf="editproductForm.controls['supplier_id'].invalid &&
                                        (editproductForm.controls['supplier_id'].dirty ||
                                        editproductForm.controls['supplier_id'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="editproductForm.controls['supplier_id'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
            <button type="button" [disabled]="!editproductForm.valid" class="btn btn-primary btn-lg"
              (click)='editProductName()'>Sauvegarder
            </button>
          </div>
        </ng-template>


        <!-- edit quantity stock product modal -->
        <ng-template #updateQuantity let-modal>
          <div class="modal-header">
            <h5 class="modal-title" *ngIf="!shouldShowReductionDiv">Mise à jour du stock </h5>
            <h5 class="modal-title" *ngIf="shouldShowReductionDiv">Réduction du stock</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="tn-failure-color font-italic" *ngIf="shouldShowReductionDiv">N'utilisez cette option qu'en cas d'extrême nécessité</p>

            <div class="row mb-2" *ngIf="!shouldShowReductionDiv">
              <div class="col-6">
                <a class="tn-failure-color font-weight-bold" *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator'
                || roleLoggedIn == 'Manager Full'" (click)="showReductionStock()">Cliquez ici pour une réduction du stock</a>
              </div>
            </div>


            <form [formGroup]="updateQuantityForm" *ngIf="!shouldShowReductionDiv">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nom de l'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="productName"
                      placeholder="Nom du produit" required>
                    <div *ngIf="updateQuantityForm.controls['productName'].invalid &&
                                        (updateQuantityForm.controls['productName'].dirty ||
                                        updateQuantityForm.controls['productName'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="updateQuantityForm.controls['productName'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="true === false">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Type d'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="type"
                      placeholder="Type">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Quantité actuelle
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="true" formControlName="current_quantity"
                      placeholder="Quantité" required>
                    <div *ngIf="updateQuantityForm.controls['current_quantity'].invalid &&
                                        (updateQuantityForm.controls['current_quantity'].dirty ||
                                        updateQuantityForm.controls['current_quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="updateQuantityForm.controls['current_quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="shouldShowUpdateQtyFormDetails">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Fournit par:
                      </small>
                      <select class="form-control" formControlName="provider_type" required>
                        <option value="" disabled selected>Sélectionnez</option>
                        <option value="warehouse">Depôt</option>
                        <option value="supplier">Fournisseur</option>
                      </select>
                      <div *ngIf="updateQuantityForm.controls['provider_type'].invalid &&
                                        (updateQuantityForm.controls['provider_type'].dirty ||
                                        updateQuantityForm.controls['provider_type'].touched)"
                        class="alert alert-light pt-0">
                        <div *ngIf="updateQuantityForm.controls['provider_type'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="updateQuantityForm.controls['provider_type'].valid">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Fournisseur / Depôt
                      </small>
                      <!-- <select class="form-control" formControlName="supplier_id" [attr.disabled]="editproductForm.controls['provier_type']==='warehouse'" required> -->
                      <select class="form-control" value="" formControlName="supplier_id" required>
                        <option value="" disabled selected>Sélectionnez</option>
                        <option *ngFor="let provider of providers" value={{provider.id}}>
                          {{provider.name}}</option>
                      </select>
                      <!-- <input type="text" class="form-control" formControlName="supplier_id" placeholder="Fournisseur"
                          required> -->
                      <div *ngIf="updateQuantityForm.controls['supplier_id'].invalid &&
                                          (updateQuantityForm.controls['supplier_id'].dirty ||
                                          updateQuantityForm.controls['supplier_id'].touched)"
                        class="alert alert-light pt-0">
                        <div *ngIf="updateQuantityForm.controls['supplier_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <!-- <div class="col-6">
                  <div class="form-group text-right mt-4">
                          <a *ngIf="!shouldShowUpdateQtyFormDetails" class="tn-blue-color font-weight-bold" (click)="showUpdateQtyFormDetails()">Cliquez ici pour ajouter plus de details</a>
                          <a *ngIf="shouldShowUpdateQtyFormDetails" class="tn-blue-color font-weight-bold" (click)="hideUpdateQtyFormDetails()">Cacher les details</a>
                  </div>
                </div> -->
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Combien d'unités souhaitez-vous ajouter?
                    </small>
                    <input type="number" class="form-control" formControlName="quantity" placeholder="Quantité"
                      required>
                    <div *ngIf="updateQuantityForm.controls['quantity'].invalid &&
                                        (updateQuantityForm.controls['quantity'].dirty ||
                                        updateQuantityForm.controls['quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="updateQuantityForm.controls['quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <form [formGroup]="negativeUpdateQuantityForm" *ngIf="shouldShowReductionDiv">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nom de l'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="productName"
                      placeholder="Nom du produit" required>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Type d'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="type"
                      placeholder="Type">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Quantité actuelle
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="true" formControlName="current_quantity"
                      placeholder="Quantité" required>
                    <div *ngIf="negativeUpdateQuantityForm.controls['current_quantity'].invalid &&
                                        (negativeUpdateQuantityForm.controls['current_quantity'].dirty ||
                                        negativeUpdateQuantityForm.controls['current_quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="negativeUpdateQuantityForm.controls['current_quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Combien d'unités souhaitez-vous rétrancher?
                    </small>
                    <input type="text" class="form-control" formControlName="quantity" placeholder="Quantité" max="0" value="-1"
                      required>
                    <div *ngIf="negativeUpdateQuantityForm.controls['quantity'].invalid &&
                                        (negativeUpdateQuantityForm.controls['quantity'].dirty ||
                                        negativeUpdateQuantityForm.controls['quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="negativeUpdateQuantityForm.controls['quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Pourquoi souhaitez vous faire cette opération?
                    </small>
                    <input type="text" class="form-control" formControlName="comment" placeholder="Votre commentaire"
                      required>
                    <div *ngIf="negativeUpdateQuantityForm.controls['comment'].invalid &&
                                        (negativeUpdateQuantityForm.controls['comment'].dirty ||
                                        negativeUpdateQuantityForm.controls['comment'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="negativeUpdateQuantityForm.controls['comment'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </form>
          </div>
          <div class="modal-footer">
            <button type="button" *ngIf="!shouldShowReductionDiv" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
            <button type="button" *ngIf="shouldShowReductionDiv" class="btn btn-danger btn-lg" (click)="cancelQuantityReduction()">Annuler</button>
            <button type="button" *ngIf="!shouldShowReductionDiv" class="btn btn-primary btn-lg"
              (click)='openConfirmUpdateStock()'>Sauvegarder
            </button>
            <button type="button" *ngIf="shouldShowReductionDiv" [disabled]="!negativeUpdateQuantityForm.valid" class="btn btn-primary btn-lg"
              (click)='openConfirmUpdateStock()'>Sauvegarder
            </button>
          </div>
        </ng-template>

        <!-- VIEW PRODUCT MODAL -->
        <ng-template #viewProduct let-modal>
          <div class="modal-header">
            <h5 class="modal-title">Détails de l'article</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="viewEditproductForm">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nom de l'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="productName" placeholder="Nom du produit" required>
                    <div *ngIf="viewEditproductForm.controls['productName'].invalid &&
                                            (viewEditproductForm.controls['productName'].dirty ||
                                            viewEditproductForm.controls['productName'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['productName'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Type d'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="type" placeholder="Type">
                    <!-- <div *ngIf="viewEditproductForm.controls['type'].invalid &&
                                            (viewEditproductForm.controls['type'].dirty ||
                                            viewEditproductForm.controls['type'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['type'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Barcode
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="barcode" placeholder="Barcode">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Bénéfice sur l'article
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="profit_on_product" placeholder="Coût" required>
                    <div *ngIf="viewEditproductForm.controls['profit_on_product'].invalid &&
                                            (viewEditproductForm.controls['profit_on_product'].dirty ||
                                            viewEditproductForm.controls['profit_on_product'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['profit_on_product'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Prix de vente en FC
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="selling_price" placeholder="Coût de vente" required>
                    <div *ngIf="viewEditproductForm.controls['selling_price'].invalid &&
                                            (viewEditproductForm.controls['selling_price'].dirty ||
                                            viewEditproductForm.controls['selling_price'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['selling_price'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Prix de vente en USD
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="selling_price_usd" placeholder="Coût de vente" required>
                    <div *ngIf="viewEditproductForm.controls['selling_price_usd'].invalid &&
                                            (viewEditproductForm.controls['selling_price_usd'].dirty ||
                                            viewEditproductForm.controls['selling_price_usd'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['selling_price_usd'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Prix d'un casier</small>
                    <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="pack_price" placeholder="Prix d'un casier" required>
                    <div *ngIf="viewEditproductForm.controls['pack_price'].invalid &&
                                      (viewEditproductForm.controls['pack_price'].dirty ||
                                      viewEditproductForm.controls['pack_price'].touched)"
                      class="pt-0 alert alert-light">
                      <div *ngIf="viewEditproductForm.controls['pack_price'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Prix de vente VIP en FC (0 si vous n'avez pas de section VIP)</small>
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                      </div>
                      <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                       formControlName="vip_selling_price" placeholder="Prix de vente" required>
                      <div *ngIf="viewEditproductForm.controls['vip_selling_price'].invalid &&
                                      (viewEditproductForm.controls['vip_selling_price'].dirty ||
                                      viewEditproductForm.controls['vip_selling_price'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="viewEditproductForm.controls['vip_selling_price'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Prix de vente VIP en USD (0 si vous n'avez pas de section VIP)</small>
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <small class="input-group-text" id="inputGroup-sizing-sm">USD</small>
                      </div>
                      <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                       formControlName="vip_selling_price_usd" placeholder="Prix de vente" required>
                      <div *ngIf="viewEditproductForm.controls['vip_selling_price_usd'].invalid &&
                                      (viewEditproductForm.controls['vip_selling_price_usd'].dirty ||
                                      viewEditproductForm.controls['vip_selling_price_usd'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="viewEditproductForm.controls['vip_selling_price_usd'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">VIP Bénéfice en FC (0 si vous n'avez pas de section VIP)</small>
                    <div class="input-group input-group-sm mb-3">
                      <div class="input-group-prepend">
                        <small class="input-group-text" id="inputGroup-sizing-sm">{{branchCurrency}}</small>
                      </div>
                      <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="vip_profit_on_product" placeholder="VIP Bénéfice" required>
                      <div *ngIf="viewEditproductForm.controls['vip_profit_on_product'].invalid &&
                                      (viewEditproductForm.controls['vip_profit_on_product'].dirty ||
                                      viewEditproductForm.controls['vip_profit_on_product'].touched)"
                             class="pt-0 alert alert-light">
                        <div *ngIf="viewEditproductForm.controls['vip_profit_on_product'].errors.required">
                            Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Quantité actuelle
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="true" formControlName="quantity"
                      placeholder="Quantité" required>
                    <div *ngIf="viewEditproductForm.controls['quantity'].invalid &&
                                            (viewEditproductForm.controls['quantity'].dirty ||
                                            viewEditproductForm.controls['quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <div class="form-group">
                      <small class="form-text text-muted">Type de casier</small>
                      <select class="form-control" formControlName="drinkPack"
                        [attr.disabled]="disableEditField == true ? true : null" required>
                        <option value="" disabled selected>Selectionnez un casier</option>
                        <option *ngFor="let drinkP of drinkPacks" value={{drinkP.drink_pack_id}}>
                          {{drinkP.drink_pack_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['drinkPack'].invalid &&
                                        (productForm.controls['drinkPack'].dirty ||
                                        productForm.controls['drinkPack'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['drinkPack'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Stock de départ (Nbre de bouteilles)</small>
                    <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="stock_default" placeholder="Stock de départ" required>
                    <div *ngIf="viewEditproductForm.controls['stock_default'].invalid && (viewEditproductForm.controls['stock_default'].dirty
                     || viewEditproductForm.controls['stock_default'].touched)" class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['stock_default'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Alerte de stock
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="stock_alert" placeholder="Alerte de stock" required>
                    <div *ngIf="viewEditproductForm.controls['stock_alert'].invalid && (viewEditproductForm.controls['stock_alert'].dirty
                    || viewEditproductForm.controls['stock_alert'].touched)" class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['stock_alert'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Sélectionnez un établissement
                    </small>
                    <select class="form-control" (change)="selectFanchise($event)" [attr.disabled]="true"
                      formControlName="viewProductBranchIdSelect" required>
                      <option value="" disabled selected>Sélectionnez un établissement</option>
                      <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                        {{branch.branch_name}}</option>
                    </select>
                    <input type="text" class="form-control" *ngIf="0 !== 0" [attr.disabled]="true"
                      formControlName="franchise" placeholder="Sélectionnez un établissement">
                    <!-- <div
                      *ngIf="viewEditproductForm.controls['viewProductBranchIdSelect'].invalid &&
                                                                (viewEditproductForm.controls['viewProductBranchIdSelect'].dirty ||
                                                                viewEditproductForm.controls['viewProductBranchIdSelect'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['viewProductBranchIdSelect'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Sous-catégorie
                    </small>
                    <select class="form-control" *ngIf="viewProductFormOnLoad === false"
                      [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="viewProductSubcategoryIdSelect" required>
                      <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                      <option *ngFor="let subCategory of viewProductSubcategories" value={{subCategory.subcategory_id}}>
                        {{subCategory.sub_name}}</option>
                    </select>
                    <input type="text" class="form-control" *ngIf="viewProductFormOnLoad === true"
                      [attr.disabled]="disableEditField == true ? true : null" formControlName="subcategory_id"
                      placeholder="Sous-catégorie" required>
                    <div *ngIf="viewEditproductForm.controls['viewProductSubcategoryIdSelect'].invalid &&
                                            (viewEditproductForm.controls['viewProductSubcategoryIdSelect'].dirty ||
                                            viewEditproductForm.controls['viewProductSubcategoryIdSelect'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['viewProductSubcategoryIdSelect'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Fournisseur
                    </small>
                    <select class="form-control" *ngIf="viewProductFormOnLoad === false"
                      [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="viewProductSupplierIdSelect" required>
                      <option value="" disabled selected>Sélectionnez un fournisseur</option>
                      <option *ngFor="let supplier of suppliers" value={{supplier.supplier_id}}>
                        {{supplier.supplier_name}}</option>
                    </select>
                    <input type="text" class="form-control" *ngIf="viewProductFormOnLoad === true"
                      [attr.disabled]="disableEditField == true ? true : null" formControlName="supplier_id"
                      placeholder="Fournisseur">
                    <!-- <div *ngIf="viewEditproductForm.controls['viewProductSupplierIdSelect'].invalid &&
                                            (viewEditproductForm.controls['viewProductSupplierIdSelect'].dirty ||
                                            viewEditproductForm.controls['viewProductSupplierIdSelect'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['viewProductSupplierIdSelect'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
            </button>
            <button type="button" [disabled]="!viewEditproductForm.valid" class="btn btn-primary btn-lg"
              *ngIf="!disableEditField" (click)='saveEditProductNameFromViewProductDetail()'>Sauvegarder
            </button>
            <button type="button" class="btn btn-primary btn-lg" *ngIf="disableEditField" (click)='enableEdit()'>Éditer
            </button>
          </div>
        </ng-template>

        <!----add quantity confirmation modal -->
        <ng-template #ConfirmUpdateProductStock let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form [formGroup]="deleteProductForm">
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir ajouter {{quantityAdded}} bouteille(s) à cet article </label>
                <h5>{{tempProductName}}</h5>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Non
            </button>
            <button type="button" class="btn btn-primary btn-lg" (click)='SaveNewStockQuantity()'>Oui
            </button>
          </div>
        </ng-template>

         <!----remove quantity confirmation modal -->
         <ng-template #ConfirmDeductionProductStock let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form [formGroup]="deleteProductForm">
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir rétrancher {{quantityAdded}} bouteille(s) à cet article </label>
                <h5>{{tempProductName}}</h5>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Non
            </button>
            <button type="button" class="btn btn-primary btn-lg" (click)='SaveNewStockQuantity()'>Oui
            </button>
          </div>
        </ng-template>

        <!----delete product modal -->
        <ng-template #deleteProduct let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation de suppression</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form [formGroup]="deleteProductForm">
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir supprimer cet article ?</label>
                <h5>{{tempProductName}}</h5>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
            </button>
            <button type="button" class="btn btn-primary btn-lg" (click)='yes()'>Confirmer
            </button>
          </div>
        </ng-template>

        <!----success product modal -->
        <ng-template #successSaveProduct let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{successModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>


        <!----failure product modal -->
        <ng-template #failureSaveProduct let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{failureModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>
