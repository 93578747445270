<div style="display: none;" id="print-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card px-2">
          <div class="card-body">

            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-3 pl-0">
                <h6 class="mt-5 mb-2"><b>{{printCompanyName}} - {{printEstablishment}}</b></h6>
                <p>{{printCompanyAddress}},
                  <br>{{printCompanyCity}},
                  <br>{{printCompanyCountry}}.
                </p>
              </div>
            </div>

            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-3 pl-0">
                <p class="mb-0 mt-5">Date d'impression : {{printDate}}</p>
              </div>
            </div>
            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-3 pl-0">
                <p class="mt-5"><b>Bénéfice du {{printDateFrom}} au {{printDateTo}}</b></p>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="card-body">
                  <h6 class="tn-blue-color mb-0 font-15px">Revenue total</h6>
                  <p class="card-text tn-blue-color"> 
                    {{workingCurrency}}
                    <!-- {{currencyDefault}} -->
                     {{grandTotalRevenue | number}}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="card-body">
                  <h6 class="tn-red-color mb-0 font-15px">Autre dépense éffectué</h6>
                  <p class="card-text tn-red-color"> 
                    <!-- {{currencyDefault}}  -->
                    {{workingCurrency}}
                    {{totalExpenses | number}}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="card-body">
                  <h6 class="tn-green-color mb-0 font-15px">Bénéfice total</h6>
                  <p class="card-text tn-green-color"> 
                    {{currencyDefault}} 
                    <!-- {{workingCurrency}} -->
                    {{totalProfitForEverything | number}}</p>
                </div>
              </div>
            </div>

            <hr />

            <div class="container-fluid d-flex justify-content-between">
              <div class="row mt-4">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <h6 class="mb-3">Boisson</h6>
                  <div class="row mb-3">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Revenu</h6>
                      <p class="card-text">
                         <!-- {{currencyDefault}}  -->
                         {{workingCurrency}}
                         {{totalDrinkRevenuWithProfit | number}}</p>
                    </div>

                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <h6 class="mb-0 font-15px">Revenu sur prix achat</h6>
                      <p class="card-text"> 
                        <!-- {{currencyDefault}}  -->
                        {{workingCurrency}}
                        {{totalDrinkRevenueWithoutProfit | number}}</p>
                    </div>
                    <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Bouteille offerte</h6>
                      <p class="card-text"> {{currencyDefault}} {{totalLossOnDefectedBottles}}</p>
                    </div> -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Perte sur bouteille défectueuse</h6>
                      <p class="card-text"> 
                        <!-- {{currencyDefault}}  -->
                        {{workingCurrency}}
                        {{totalLossOnDefectedBottles}}</p>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Bénéfice sur boisson</h6>
                      <p class="card-text"> 
                        {{currencyDefault}} 
                        <!-- {{workingCurrency}} -->
                        {{totalDrinkProfit}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <h6 class="mb-3">Nourriture</h6>
                  <div class="row mb-3">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Revenu</h6>
                      <p class="card-text"> 
                        <!-- {{currencyDefault}}  -->
                        {{workingCurrency}}
                        {{totalFoodRevenue}}</p>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Revenu sur prix achat</h6>
                      <p class="card-text"> 
                        <!-- {{currencyDefault}}  -->
                        {{workingCurrency}}
                        {{totalFoodRevenueOnBuyingPrice}}</p>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Bénéfice sur nourriture</h6>
                      <p class="card-text"> 
                        {{currencyDefault}} 
                        <!-- {{workingCurrency}} -->
                        {{totalFoodProfit}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <h6 class="mb-3">Produits Maison</h6>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Revenu</h6>
                      <p class="card-text"> 
                        <!-- {{currencyDefault}}  -->
                        {{workingCurrency}}
                        {{totalOtherItemRevenue}}</p>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Revenu sur prix achat</h6>
                      <p class="card-text"> 
                        <!-- {{currencyDefault}}  -->
                        {{workingCurrency}}
                        {{totalOtherItemRevenueOnBuyingPrice}}</p>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-0 font-15px">Bénéfice sur tous autre article</h6>
                      <p class="card-text"> 
                        {{currencyDefault}}
                        <!-- {{workingCurrency}} -->
                        {{totalOtherItemProfit}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <div class="container-fluid d-flex justify-content-center w-100">
            <div class="rere">
              <table class="table">
                <thead>
                  <tr class="bg-dark text-white">
                    <th class="text-center">Article</th>
                    <th class="text-center">Quantité sortie</th>
                    <th class="text-center">Revenu total</th>
                    <th class="text-center">Perte</th>
                    <th class="text-center">Bénéfices</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of saleReportToBeDisplayed" class="text-right">
                    <td class="text-center">{{item.product_name}}</td>
                    <td class="text-center">
                      <div class="col-12" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                        {{item.quantity_sold}} bouteille(s)
                      </div>
                      <div class="col-12" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                        {{item.quantity_sold}} shot(s)
                      </div>
                      <div class="col-12" *ngIf="item.category_name === 'Nourriture'">
                        {{item.quantity_sold}} plat(s)
                      </div>
                      <div class="col-12"
                        *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                        {{item.quantity_sold}}
                      </div>
                    </td>
                    <td class="text-center">FC {{item.quantity_sold * item.selling_cost}}</td>
                    <td class="text-center">{{item.quantity_loss}} bouteille(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_loss * item.profit_on_product)}}</td>
                    <td class="text-center">
                      {{currencyDefault}} 
                      <!-- {{workingCurrency}} -->
                      {{(item.quantity_sold * item.profit_on_product) -
                      (item.quantity_loss * item.profit_on_product)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>







<div class="d-flex" id="wrapper">
  <div id="page-content-wrapper">

    <div class="container-fluid">
      <div class="panel mt-5">

        <div class="row mt-4">
          <div class="col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="card tn-bg-white-color">
              <div class="card-body">
                <h6 class="tn-blue-color mb-0 font-15px">Revenue total</h6>
                <p class="card-text tn-blue-color"> 
                  <!-- {{currencyDefault}}  -->
                  {{workingCurrency}}
                  {{grandTotalRevenue | number}}</p>
                <h6 class="tn-red-color mb-0 font-15px">Autre dépense éffectué</h6>
                <p class="card-text tn-red-color"> 
                  <!-- {{currencyDefault}}  -->
                  {{workingCurrency}}
                  {{totalExpenses | number}}</p>
                <h6 class="tn-green-color mb-0 font-15px">Bénéfice total</h6>
                <p class="card-text tn-green-color"> 
                  {{currencyDefault}} 
                  <!-- {{workingCurrency}} -->
                  {{totalProfitForEverything | number}}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-12">
            <!-- <div class="card tn-bg-failure-color">
              <div class="card-body">
                <h6 class="tn-red-color">Dépense éffectué</h6>
                <p class="card-text tn-red-color"> {{currencyDefault}} {{totalExpenses}}</p>
                <h6 class="tn-green-color">Bénéfice total</h6>
                <p class="card-text tn-green-color"> {{currencyDefault}} {{totalProfitForEverything}}</p>
              </div>
            </div> -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-12">

          </div>
        </div>

        <p class="mt-3">
          <button class="btn btn-primary bg-transparent tn-blue-color" type="button" data-toggle="collapse"
            data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            Clicker ici pour plus de details
          </button>
        </p>
        <div class="collapse" id="collapseExample">
          <div class="row mt-4">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <h6>Boisson</h6>
              <div class="row mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-0">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-blue-color height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Revenu total</h6>
                          <p class="card-text"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalDrinkRevenuWithProfit | number}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-blue-color height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Revenu sur prix achat</h6>
                          <p class="card-text"> 
                            {{currencyDefault}} 
                            <!-- {{workingCurrency}} -->
                            {{totalDrinkRevenueWithoutProfit | number}}</p>
                          <h6 class="tn-red-color mb-0 font-15px">Achat boissons</h6>
                          <p class="card-text tn-red-color"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalExpenseLinkedToDrinkRevenue | number}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-0">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-failure-color height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Perte sur bouteille défectueuse</h6>
                          <p class="card-text"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalLossOnDefectedBottles | number}}</p>
                          <h6 class="mb-0 font-15px">Bouteille offerte</h6>
                          <p class="card-text"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalLossOnGiftBottles | number}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-green-color2 height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Bénéfice total sur boisson</h6>
                          <p class="card-text"> 
                            {{currencyDefault}} 
                            <!-- {{workingCurrency}} -->
                            {{totalDrinkProfit | number}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-4">
              <h6>Nourriture</h6>
              <div class="row mb-3">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-0">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-blue-color height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Revenu total</h6>
                          <p class="card-text"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalFoodRevenue | number}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-blue-color height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Revenu sur prix achat</h6>
                          <p class="card-text"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalFoodRevenueOnBuyingPrice | number}}</p>
                          <h6 class="tn-red-color mb-0 font-15px">Achat Nourriture</h6>
                          <p class="card-text tn-red-color"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalExpenseLinkedToFoodRevenue | number}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mt-1">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-green-color2 height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Bénéfice sur nourriture</h6>
                          <p class="card-text"> 
                            {{currencyDefault}} 
                            <!-- {{workingCurrency}} -->
                            {{totalFoodProfit | number}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-xl-0 mt-lg-4 mt-md-4 mt-sm-0 mt-4">
              <h6>Produits Maison</h6>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-0">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-blue-color height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Revenu total</h6>
                          <p class="card-text"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalOtherItemRevenue | number}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-blue-color height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Revenu sur prix achat</h6>
                          <p class="card-text"> 
                            <!-- {{currencyDefault}}  -->
                            {{workingCurrency}}
                            {{totalOtherItemRevenueOnBuyingPrice}}</p>
                          <h6 class="tn-red-color mb-0 font-15px">Achat Produit maison</h6>
                          <p class="card-text tn-red-color"> 
                            {{workingCurrency}}
                            <!-- {{currencyDefault}} -->
                            {{totalExpenseLinkedToHouseProductRevenue}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="row">
                    <div class="col-md-12 col-12 mt-3 mt-sm-0 mt-md-0 mt-xl-0">
                      <div class="card tn-bg-green-color2 height-160px">
                        <div class="card-body">
                          <h6 class="mb-0 font-15px">Bénéfice sur tous autre article</h6>
                          <p class="card-text"> 
                            {{currencyDefault}} 
                            <!-- {{workingCurrency}} -->
                            {{totalOtherItemProfit}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form [formGroup]="filterFormSaleReport">
          <div class="row mt-4 mb-3">
            <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

              <div class="card mb-3 d-md-none"
                *ngFor="let item of saleReportToBeDisplayed | paginate: { itemsPerPage: 10, currentPage: pageSaleReport1 }">
                <div class="card-body">
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Établissement
                    </div>
                    <div class="col-6">
                      {{item.branch_name}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Article
                    </div>
                    <div class="col-6">
                      {{item.product_name}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Quantité sortie
                    </div>
                    <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                      {{item.quantity_sold}} bouteille(s)
                    </div>
                    <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                      {{item.quantity_sold}} shot(s)
                    </div>
                    <div class="col-6" *ngIf="item.category_name === 'Nourriture'">
                      {{item.quantity_sold}} plat(s)
                    </div>
                    <div class="col-6"
                      *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                      {{item.quantity_sold}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Revenu total
                    </div>
                    <div class="col-6">
                      FC {{item.quantity_sold * item.selling_cost}}
                    </div>
                  </div>
                  <div class="row mb-2 tn-red-color">
                    <div class="col-6 card-table-header">
                      Commande staff 
                      ({{workingCurrency}})
                      <!-- ({{currencyDefault}}) -->
                    </div>
                    <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                      {{item.quantity_gifted}} btlle(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_gifted * item.profit_on_product) | number}}
                    </div>
                    <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                      {{item.quantity_gifted}} shot(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_gifted * item.profit_on_product) | number}}
                    </div>
                    <div class="col-6" *ngIf="item.category_name === 'Nourriture'">
                      {{item.quantity_gifted}} plat(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_gifted * item.profit_on_product) | number}}
                    </div>
                    <div class="col-6"
                      *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                      {{item.quantity_gifted}} - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_gifted * item.profit_on_product) | number}}
                    </div> 
                  </div>
                  <div class="row mb-2 tn-red-color">
                    <div class="col-6 card-table-header">
                      Perte
                    </div>
                    <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                      {{item.quantity_loss}} btlle(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_loss * item.profit_on_product) | number}}
                    </div>
                    <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                      {{item.quantity_loss}} shot(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_loss * item.profit_on_product) | number}}
                    </div>
                    <div class="col-6" *ngIf="item.category_name === 'Nourriture'">
                      {{item.quantity_loss}} plat(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_loss * item.profit_on_product) | number}}
                    </div>
                    <div class="col-6"
                      *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                      {{item.quantity_loss}} - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(item.quantity_loss * item.profit_on_product) | number}}
                    </div>
                  </div>
                  <div class="row mb-2 tn-green-color">
                    <div class="col-6 card-table-header">
                      Bénéfices
                    </div>
                    <div class="col-6">
                      {{currencyDefault}}
                      <!-- {{workingCurrency}} -->
                      {{(item.quantity_sold * item.profit_on_product) -
                      (item.quantity_loss * item.profit_on_product) - (item.quantity_gifted * item.profit_on_product) | number}}
                    </div>
                  </div>
                </div>
              </div>
              <pagination-controls *ngIf="tableHasDataSaleReport === true" class="mb-3 text-center d-md-none"
                (pageChange)="onMobileTableChangePage($event)" previousLabel="Précédent" nextLabel="Suivant">
              </pagination-controls>


              <mat-table [dataSource]="dataSourceSaleAndProfitReport" class="d-none d-md-block" matSort>

                <ng-container matColumnDef="Product">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Article
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.product_name}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="QuantitySold">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Quantité sortie
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name !== 'Shot')">
                      {{row.quantity_sold}} bouteille(s)
                    </div>
                    <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name === 'Shot')">
                      {{row.quantity_sold}} shot(s)
                    </div>
                    <div *ngIf="row.category_name === 'Nourriture'">
                      {{row.quantity_sold}} plat(s)
                    </div>
                    <div *ngIf="(row.category_name !== 'Nourriture') && (row.category_name !== 'Boisson')">
                      {{row.quantity_sold}}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="TotalRevenue">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Revenu total 
                    ({{workingCurrency}})
                    <!-- ({{currencyDefault}}) -->
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <!-- {{currencyDefault}} -->
                    {{workingCurrency}}
                    {{row.quantity_sold * row.selling_cost | number}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="LossOnRevenueBecauseOfGift" class="tn-red-color">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Commande offerte 
                    ({{workingCurrency}})
                    <!-- ({{currencyDefault}}) -->
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name !== 'Shot')" class="tn-red-color">
                      {{row.quantity_gifted}} btlle(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                    {{(row.quantity_gifted * row.profit_on_product) | number}}
                    </div>
                    <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name === 'Shot')" class="tn-red-color">
                      {{row.quantity_gifted}} shot(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                    {{(row.quantity_gifted * row.profit_on_product) | number}}
                    </div>
                    <div *ngIf="row.category_name === 'Nourriture'" class="tn-red-color">
                      {{row.quantity_gifted}} plat(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                    {{(row.quantity_gifted * row.profit_on_product) | number}}
                    </div>
                    <div *ngIf="(row.category_name !== 'Nourriture') && (row.category_name !== 'Boisson')" class="tn-red-color">
                      {{row.quantity_gifted}} - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                    {{(row.quantity_gifted * row.profit_on_product) | number}}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="LossOnRevenue" class="tn-red-color">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Perte 
                    ({{workingCurrency}})
                    <!-- ({{currencyDefault}}) -->
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name !== 'Shot')" class="tn-red-color">
                      {{row.quantity_loss}} btlle(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                    {{(row.quantity_loss * row.profit_on_product) | number}}
                    </div>
                    <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name === 'Shot')" class="tn-red-color">
                      {{row.quantity_loss}} shot(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                    {{(row.quantity_loss * row.profit_on_product) | number}}
                    </div>
                    <div *ngIf="row.category_name === 'Nourriture'" class="tn-red-color">
                      {{row.quantity_loss}} plat(s) - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                      {{(row.quantity_loss * row.profit_on_product) | number}}
                    </div>
                    <div *ngIf="(row.category_name !== 'Nourriture') && (row.category_name !== 'Boisson')" class="tn-red-color">
                      {{row.quantity_loss}} - 
                      {{workingCurrency}}
                      <!-- {{currencyDefault}} -->
                    {{(row.quantity_loss * row.profit_on_product) | number}}
                    </div>
                    
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ProfitOnRevenue">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Bénéfices 
                    <!-- ({{workingCurrency}}) -->
                    ({{currencyDefault}})
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div class="tn-green-color">
                      {{currencyDefault}} 
                      <!-- {{workingCurrency}} -->
                      {{(row.quantity_sold * row.profit_on_product)
                        - (row.quantity_loss * row.profit_on_product) - (row.quantity_gifted * row.profit_on_product) | number}}
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsSaleReport">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsSaleReport">
                </mat-row>
              </mat-table>
              <div class="mt-3" *ngIf="tableHasDataSaleReport === false">Aucune vente trouvé
              </div>
              <mat-paginator class="d-none d-md-block mb-4" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>

            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <small class="form-text text-muted">Sélectionnez un établissement</small>
                    <select #selectedBranch class="form-control" (change)="loadSaleAndProfitReport($event.target.value)"
                      formControlName="selectBranch">
                      <option value="0" selected>Tous les établissement</option>
                      <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                        {{branch.branch_name}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <small class="form-text text-muted">Datant de</small>
                  <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtSaleReportFrom"
                    [owlDateTime]="dtSaleReportFrom" name="dtSaleReportFrom" formControlName="dtSaleReportFrom">
                  <owl-date-time #dtSaleReportFrom></owl-date-time>
                </div>

                <div class="col-12">
                  <small class="form-text text-muted">à</small>
                  <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtSaleReportTo"
                    [owlDateTime]="dtSaleReportTo" name="dtSaleReportTo" formControlName="dtSaleReportTo">
                  <owl-date-time #dtSaleReportTo></owl-date-time>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-md-12">
                  <button type="button" class="btn btn-primary w-100" (click)="filterSaleReport(selectedBranch.value)">
                    <i class="fas fa-filter"></i> &nbsp;Filtre
                  </button>
                </div>
              </div>
              <!-- <div class="row my-3">
                <div class="col-md-12">
                  <button  [useExistingCss]="true"
                    printSectionId="print-section" ngxPrint type="button" class="btn btn-primary w-100">
                    <i class="fas fa-print"></i> &nbsp;Imprimer
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </form>

        <!----success product modal -->
        <ng-template #successModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{successModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>


        <!----failure product modal -->
        <ng-template #failureModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{failureModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>