import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Branch } from '../interface/branch';
import { Category } from '../interface/category';
import { Login } from '../interface/login';
import { SaveSubCategory } from '../interface/save-sub-category';
import { SubCategory } from '../interface/sub-category';
import { UpdateSubCategory } from '../interface/update-sub-category';
import { FlowService } from './flow.service';

@Injectable({
  providedIn: 'root'
})

export class PagadoService {
  
  private readonly apiURL = environment.apiBase;

  loggedIn: boolean;
  loginModel = {
    username: '',
    password: ''
  };
  body = {

  };


  constructor(private http: HttpClient, private flowService: FlowService) {
    this.loggedIn = false;
  }

  login(username, password): Observable<Login> {
    this.loginModel.username = username;
    this.loginModel.password = password;
    this.loggedIn = true;
    return this.http.post<Login>(this.apiURL + '/login', this.loginModel);
  }


  getSubCategories(): Observable<SubCategory> {
    return this.http.post<SubCategory>(this.apiURL + '/subcategory/getall?intCategoryID=1', null);
  }
  saveSubCategories(body): Observable<any> {
    return this.http.post<any>(this.apiURL + '/subcategory/save', body);
  }
  updateSubCategory(body: UpdateSubCategory): Observable<SaveSubCategory> {
    return this.http.post<SaveSubCategory>(this.apiURL + '/subcategory/update?subcategoryName=' + body.sub_name
    + '&' + 'subcategoryId=' + body.subcategory_id, this.body);
  }

}
