import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Company} from 'src/app/models/company/company';
import {Supplier} from 'src/app/interface/supplier';
import {SupplierService} from 'src/app/services/supplier.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import { Constants } from '../../common/Constants';
import * as $ from 'jquery';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  supplierSearchForm: FormGroup;
  editSupplierForm: FormGroup;
  deleteSupplierForm: FormGroup;
  addSupplierForm: FormGroup;
  suppliers: Supplier [];
  spin: boolean;
  showTable: boolean;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  // companies: Compapanies;
  tempSupplier: Supplier;
  tempSupplierName: any;
  companies: Company;


  dataSourceSupplier: MatTableDataSource<Supplier>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsSupplier = [
    'Name',
    'Cellphone',
    'SupplierCode',
    'Address',
    'Actions'
  ];

  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;
  @ViewChild('failureModal', {static: true}) failureModal: TemplateRef<any>;
  @ViewChild('editSupplier', {static: true}) editSupplierModal: TemplateRef<any>;
  @ViewChild('deleteSupplier', {static: true}) deleteSupplierModal: TemplateRef<any>;
  @ViewChild('addSupplier', {static: true}) addSupplierModal: TemplateRef<any>;

  @ViewChild('selectedBranch') selectedBranch: ElementRef<any>;

  closeResult: string;

  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  tableHasData: boolean;

  page = 1;

  constructor(private router: Router, private supplierService: SupplierService, private fb: FormBuilder,
              private authenticationService: AuthenticationService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.createForm();
    // this.tableSettings();
    this.getSuppliers();
    this.supplierSearchForm.controls['searchSupplierName'].valueChanges.subscribe(val=>{
      if(val ===''){
        this.searchSupplier();
      }
    });
    // this.tempSupplier = this.suppliers[0];
  }

  createForm(): void {
    this.supplierSearchForm = this.fb.group({
      searchSupplierName: ['']
    });

    this.editSupplierForm = this.fb.group({
      company_id: [''],
      supplier_name: [''],
      supplier_address: [''],
      supplier_cell: [''],
      supplier_code: [''],
      loyalty_percent: ['']
    });

    this.addSupplierForm = this.fb.group({
      company_id: [''],
      supplier_name: [''],
      supplier_address: [''],
      supplier_cell: [''],
      supplier_code: [''],
      loyalty_percent: ['']
    });


    this.deleteSupplierForm = this.fb.group({
      company_id: [''],
      supplier_name: ['']
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  searchSupplier(): void {

    this.spin = true;

    const name = this.supplierSearchForm.controls.searchSupplierName.value;
    this.supplierService.searchSupplier(this.authenticationService.currentUserValue.companyID, name).subscribe(res => {
      this.suppliers = res;

      if (this.suppliers.length > 0) {
        this.tableHasData = true;
        this.dataSourceSupplier = new MatTableDataSource(this.suppliers);
        this.dataSourceSupplier.paginator = this.paginator;
        this.dataSourceSupplier.sort = this.sort;
      } else {
        this.tableHasData = false;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceSupplier = new MatTableDataSource([]);
      this.dataSourceSupplier.paginator = this.paginator;
      this.dataSourceSupplier.sort = this.sort;
      this.spin = false;

    });
  }
  
  getSuppliers(): void {

    this.spin = true;

    this.supplierService.getSupplier(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.suppliers = res;


      // collection: any[] = someArrayOfThings;

      if (this.suppliers.length > 0) {
        this.tableHasData = true;
        this.dataSourceSupplier = new MatTableDataSource(this.suppliers);
        this.dataSourceSupplier.paginator = this.paginator;
        this.dataSourceSupplier.sort = this.sort;
      } else {
        this.tableHasData = false;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceSupplier = new MatTableDataSource([]);
      this.dataSourceSupplier.paginator = this.paginator;
      this.dataSourceSupplier.sort = this.sort;
      this.spin = false;

    });
  }
  viewSupplierHistory(supplierID){
    this.router.navigate([Constants.TRANSACTIONS_ROUTE,supplierID]); 
  }
  addSuppleir(): void {
    this.spin = true;
    let supplier: Supplier;
    supplier = {
      supplier_id: 0,
      supplier_name: this.addSupplierForm.controls.supplier_name.value,
      supplier_address: this.addSupplierForm.controls.supplier_address.value,
      supplier_cell: this.addSupplierForm.controls.supplier_cell.value,
      supplier_code: this.addSupplierForm.controls.supplier_code.value,
      loyalty_percent: Number(this.addSupplierForm.controls.loyalty_percent.value),
      company_id: this.authenticationService.currentUserValue.companyID,
      status: 0,
      date_created: '',
      last_modified: ''
    };
    this.supplierService.addSupplier(supplier).subscribe(res => {
      if (res) {
        this.modalService.dismissAll();
        this.addSupplierForm.reset();
        this.showSuccessModal('Le fournisseur a été enregistré avec succès');

        this.getSuppliers();
      } else {
        this.showFailureModal('L\'ajout du fournisseur a échoué, réessayez plus tard');
      }
      this.spin = false;
      // document.getElementById('addSupplier').click();

    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Ce fournisseur éxiste déjà');
      } else {
        this.showFailureModal('L\'ajout du fournisseur a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  editSupplier1(supplier: Supplier): void {
    this.open(this.editSupplierModal);
    this.getCompany(supplier.company_id);
    this.tempSupplier = supplier;

    this.editSupplierForm.controls.company_id.setValue(supplier.company_id);
    this.editSupplierForm.controls.supplier_name.setValue(supplier.supplier_name);
    this.editSupplierForm.controls.supplier_address.setValue(supplier.supplier_address);
    this.editSupplierForm.controls.supplier_cell.setValue(supplier.supplier_cell);
    this.editSupplierForm.controls.supplier_code.setValue(supplier.supplier_code);
    this.editSupplierForm.controls.loyalty_percent.setValue(supplier.loyalty_percent);
  }

  confirmEdidtSupplier(): void {
    this.spin = true;
    let editSupplier: Supplier;
    editSupplier = {
      supplier_id: this.tempSupplier.supplier_id,
      supplier_name: this.editSupplierForm.controls.supplier_name.value,
      supplier_address: this.editSupplierForm.controls.supplier_address.value,
      supplier_cell: this.editSupplierForm.controls.supplier_cell.value,
      supplier_code: this.editSupplierForm.controls.supplier_code.value,
      loyalty_percent: Number(this.editSupplierForm.controls.loyalty_percent.value),
      company_id: this.tempSupplier.company_id,
      status: this.tempSupplier.status,
      date_created: this.tempSupplier.date_created,
      last_modified: this.tempSupplier.last_modified
    };

    this.supplierService.editSupplier(editSupplier).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('Ce fournisseur a été modifié avec succès');
        this.getSuppliers();
      } else {
        this.showFailureModal('Ce fournisseur n\'a pas pu être mis à jour');
      }
      this.spin = false;

      // document.getElementById('editSupplier').click();

    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Ce fournisseur éxiste déjà');
      } else {
        this.showFailureModal('Ce fournisseur n\'a pas pu être mis à jour');
      }
      this.spin = false;
    });
  }

  deleteSupplier1(supplier: Supplier): void {
    this.open(this.deleteSupplierModal);
    this.tempSupplier = supplier;
    this.tempSupplierName = this.tempSupplier.supplier_name;
  }

  confirmDelete(): void {
    this.spin = true;
    this.supplierService.deleteSupplier(this.tempSupplier).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('Ce fournisseur a été supprimé avec succès');
        this.getSuppliers();
      } else {
        this.showFailureModal('Ce fournisseur n\'a pas pu être supprimé');
      }
      this.spin = false;
      // document.getElementById('deleteSupplier').click();
    }, error => {
      this.showFailureModal('Ce fournisseur n\'a pas pu être supprimé');
      this.spin = false;
    });
  }

  addSupplierButton(): void {
    this.open(this.addSupplierModal);
    // this.getCompanies();
  }

  getCompany(id): void {
    this.supplierService.getCompany(id).subscribe(res => {
      this.companies = res;
    }, error => {
      this.spin = false;
    });
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
