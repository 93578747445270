<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" id="cat">Sub Categories</a>
          </li>
        </ul>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
            <form [formGroup]="subCategorySearch">
              <div class="form-group mt-4">
                <small class="form-text text-muted">Rechercher une sous-catégorie</small>
                <div class="input-group mb-3">
                  <input type="search" class="form-control" formControlName="searchSubCategoryName"
                    placeholder="Rechercher..." aria-label="Search..." aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button class="btn btn-outline-primary" (click)="searchSubCategory()" type="button"><i
                        class="fas fa-search"></i></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
            <div class="form-group mt-4">
              <small class="form-text text-muted mt-5"></small>
              <button type="button" class="btn btn-primary" (click)="addSub(selectCategories2.value)">
                <i class="fas fa-plus-square"></i> &nbsp;Ajouter une sous-catégorie
              </button>
            </div>
          </div>
          <!-- <div class="col-lg-3">

          </div> -->
        </div>

        <div class="row mt-1 mb-3">
          <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

            <div class="card mb-3 d-md-none"
              *ngFor="let item of subCategories | paginate: { itemsPerPage: 10, currentPage: page }">
              <div class="card-body">
                <div class="row mb-2">
                  <div class="col-6 card-table-header">
                    Nom de la sous-catégorie
                  </div>
                  <div class="col-6">
                    {{item.sub_name}}
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12 text-center">
                    <button class="btn btn-primary" (click)="edit(item)"><i class="bi bi-pen"></i></button>&nbsp;
                    <button class="btn btn-danger" (click)="tempDelete(item)"><i class="bi bi-trash"></i></button>&nbsp;
                  </div>
                </div>
              </div>
            </div>
            <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-md-none"
              (pageChange)="page = $event" previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>


            <mat-table [dataSource]="dataSourceSubCategory" class="d-none d-md-block" matSort>
              <ng-container matColumnDef="SubCategoryName">
                <mat-header-cell class="col-9" *matHeaderCellDef mat-sort-header>
                  Nom de la sous-catégorie
                </mat-header-cell>
                <mat-cell class="col-9" *matCellDef="let row">
                  {{row.sub_name}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Actions">
                <mat-header-cell class="col-3" *matHeaderCellDef mat-sort-header>
                  Actions
                </mat-header-cell>
                <mat-cell class="col-3" *matCellDef="let row">
                  <!--                  <button class="btn btn-primary" (click)="goToProducts(row)"><i class="fas fa-eye"></i></button>&nbsp;-->
                  <button class="btn btn-primary" (click)="edit(row)"><i class="bi bi-pen"></i></button>&nbsp;
                  <button class="btn btn-danger" (click)="tempDelete(row)"><i class="bi bi-trash"></i></button>&nbsp;
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumnsSubCategory">
              </mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsSubCategory">
              </mat-row>
            </mat-table>
            <div class="mt-3" *ngIf="tableHasData === false">Aucune sous-catégorie n'a été trouvée
            </div>
            <mat-paginator class="d-none d-md-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
            <form [formGroup]="selectCategoryForm">
              <div class="form-group">
                <small class="form-text text-muted">Sélectionnez une catégorie</small>
                <select #selectCategories2 class="form-control" formControlName="selectCategories"
                  (change)="getBranchCategories($event)">
                  <option value="" disabled selected>Sélectionnez une catégorie</option>
                  <option *ngFor="let category of categories" value={{category.category_id}}>{{category.name}}</option>
                </select>
              </div>
            </form>
          </div>
        </div>

        <!-- Add sub Category Modal -->
        <ng-template #addSubCategory let-modal>
          <div class="modal-header">
            <h5 class="modal-title text-center">Ajouter une sous-catégorie</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="addSubCategoryForm">
              <div class="form-group">
                <small class="form-text text-muted">
                  Catégorie
                </small>
                <select class="form-control" [attr.disabled]="true" formControlName="addSubCategoryFormCategoryID"
                  required>
                  <option value="" disabled selected>Catégorie</option>
                  <option *ngFor="let category of categories" value={{category.category_id}}>
                    {{category.name}}</option>
                </select>
              </div>
              <div class="form-group">
                <small class="form-text text-muted">Nom de la sous-catégorie</small>
                <input type="text" class="form-control" formControlName="addSubCategoryName"
                  placeholder="sous-catégorie 1" required>
                <div *ngIf="addSubCategoryForm.controls['addSubCategoryName'].invalid &&
                            (addSubCategoryForm.controls['addSubCategoryName'].dirty ||
                            addSubCategoryForm.controls['addSubCategoryName'].touched)" class="alert alert-light">
                  <div *ngIf="addSubCategoryForm.controls['addSubCategoryName'].errors.required">
                    Sub Category Name required
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
            <button type="button" class="btn btn-primary" (click)='saveNewSubCategory()'>Sauvegarder</button>
          </div>
        </ng-template>

        <!-- Edit Sub Category Modal -->
        <ng-template #editSubCategory let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Modification de la sous-catégorie</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="subCategoryForm">
              <div class="form-group">
                <small class="form-text text-muted">
                  Catégorie
                </small>
                <select class="form-control" [attr.disabled]="true" formControlName="editSubCategoryFormCategoryID"
                  required>
                  <option value="" disabled selected>Catégorie</option>
                  <option *ngFor="let category of categories" value={{category.category_id}}>
                    {{category.name}}</option>
                </select>
              </div>
              <div class="form-group">
                <small class="form-text text-muted">Nom de la sous-catégorie</small>
                <input type="text" class="form-control" formControlName="subCategoryName" placeholder="sous-catégorie 1"
                  required>
                <div *ngIf="subCategoryForm.controls['subCategoryName'].invalid &&
                            (subCategoryForm.controls['subCategoryName'].dirty || 
                            subCategoryForm.controls['subCategoryName'].touched)" class="alert alert-light">
                  <div *ngIf="subCategoryForm.controls['subCategoryName'].errors.required">
                    Ne devrait pas être vide
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
            <button type="button" class="btn btn-primary" (click)='saveNewSubCategoryName()'>Sauvergarder</button>
          </div>
        </ng-template>


        <!--Delete Modal -->
        <ng-template #deleteSubCategory let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title" id="exampleModalLabel">Confirmation de suppression</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form [formGroup]="categoryFormDelete">
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir supprimer cette sous-catégorie ?</label>
                <h5>{{subCategoryNameToDelete}}</h5>
                <label class="mt-3">de la catégorie <h6>{{categoryOfTheSubcategoryToDelete}}</h6> </label>

              </div>
            </form>
          </div>
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
            <button type="button" class="btn btn-primary" (click)='deleteSubCategory1()'>Confirmer</button>
          </div>
        </ng-template>

        <!----success product modal -->
        <ng-template #successModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{successModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>


        <!----failure product modal -->
        <ng-template #failureModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{failureModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>

<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>