import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Branch } from '../interface/branch';
import { DeleteProduct } from '../interface/deleteProduct';
import { Product } from '../interface/products';
import { SubCategory } from '../interface/sub-category';
import { Supplier } from '../interface/supplier';
import { UpdateProduct } from '../interface/updateProduct';
import { StockUpdateReport } from '../models/order/StockUpdateReport';
import { ProductDTO } from '../models/product/ProductDTO';
import { StockUpdate } from '../models/StockUpdate';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {
  }

  fistLoadProducts(companyID: number, branchID: number): Observable<ProductDTO[]> {
    return this.http.post<ProductDTO[]>(this.apiURL + '/product/getallProductsByBranchAndCompany?intBranchID=' +
      branchID + '&intCompanyID=' + companyID, null);
  }

  getOneranch(companyID: number, branchID: number): Observable<Branch> {
    return this.http.post<Branch>(this.apiURL + '/branch/getone?intBranchID=' +
      branchID + '&intCompanyID=' + companyID, null);
  }

  getProduct(companyID: number, branchID: number, subcategoryId: number): Observable<ProductDTO[]> {
    return this.http
      .post<ProductDTO[]>(this.apiURL + '/product/getall?intBranchID=' + branchID + '&intSubcategoryID=' +
      subcategoryId + '&intCompanyID=' + companyID, null);
  }

  getProductBySubCategory(subCategory: SubCategory, companyID: number, branchID: number): Observable<Product> {
    return this.http.post<Product>(this.apiURL + '/product/getall?intBranchID=' +
      branchID + '&intSubcategoryID=' + subCategory.subcategory_id + '&intCompanyID=' + companyID, null);
  }

  getProductByCategory(categoryID: number, companyID: number, branchID: number): Observable<ProductDTO[]> {
    return this.http.post<ProductDTO[]>(this.apiURL + '/product/getallProductsByBranchAndCompanyAndCategory?b=' +
      branchID + '&cat=' + categoryID + '&c=' + companyID, null);
  }

  getProductBySubCategoryById(subcategoryID: number, companyID: number, branchID: number): Observable<ProductDTO[]> {
    return this.http.post<ProductDTO[]>(this.apiURL + '/product/getall?intBranchID=' +
      branchID + '&intSubcategoryID=' + subcategoryID + '&intCompanyID=' + companyID, null);
  }

  searchProduct(companyId: number, branchId: number, name: string): Observable<ProductDTO[]> {
    if ((name !== null) && (name !== '') && (name !== undefined)) {
      return this.http.post<ProductDTO[]>(this.apiURL + '/product/searchproductnamebybranch?companyID='
      + companyId + '&branchID=' + branchId + '&productName=' + name, null);
    } else {
      return this.http.post<ProductDTO[]>(this.apiURL + '/product/getallProductsByBranchAndCompany?intBranchID=' +
      branchId + '&intCompanyID=' + companyId, null);
    }



  }

  updateProduct(productBody): Observable<UpdateProduct> {
    return this.http.post<UpdateProduct>(this.apiURL + '/product/update', productBody);
  }

  updateStockQuantity(employeeID: number, productID: number, newEnteredQuantity: number,
    newTotalQuantity: number, branchID: number, subcategoryID: number, supplierID: number, companyID: number,
    productPackNumber:number, packPrice:number, comment: string): Observable<UpdateProduct> {
    return this.http.post<UpdateProduct>(this.apiURL + '/product/updateQuantity?employeeID=' + employeeID + '&productID=' + productID
      + '&newEnteredQuantity=' + newEnteredQuantity + '&newTotalQuantity=' + newTotalQuantity + '&branchID='
      + branchID + '&subcategoryID=' + subcategoryID + '&supplierID=' + supplierID + '&companyID=' + companyID +
      '&productPackNumber='+productPackNumber+'&packPrice=' + packPrice.toString().concat('&comment=').concat(comment), null);
  }

  updateItemsQuantity(stockUpdate:StockUpdate[]){
    return this.http.post<boolean>(this.apiURL + "/product/updateAllQuantity", stockUpdate);
  }

  saveProduct(productBody, employeeID: number): Observable<Product> {
    return this.http.post<Product>(this.apiURL + '/product/save?employeeID=' + employeeID, productBody);
  }

  deleteProduct(body: ProductDTO): Observable<DeleteProduct> {
    const productID = body.product_id;
    const subcategoryID = body.subcategory_id;
    const branchID = body.branch_id;
    return this.http.post<DeleteProduct>(this.apiURL + '/product/delete?productID='
      + productID + '&subcategoryID=' + subcategoryID + '&branchID=' + branchID, body);
  }

  getSupplier(companyID: number, branchID: number): Observable<Supplier[]> {
    return this.http.post<Supplier[]>(this.apiURL + '/supplier/getall?companyID='
      + companyID, null);
  }

  getOneSupplier(supplierID: number, companyID: number, branchID: number): Observable<Supplier> {
    return this.http.post<Supplier>(this.apiURL + '/supplier/getone?intSupplierID=' +
      supplierID + '&intCompanyID=' + companyID, null);
  }

  public getDeductedStockUpdateReport(companyID: number, branchID: number, dateFrom: string, dateTo: string): Observable<StockUpdateReport[]> {
    return this.http.post<StockUpdateReport[]>(environment.apiBase
      .concat('/product/getDeductionStockUpdateReportIncoming/').concat(branchID.toString())
      .concat('/').concat(companyID.toString())
      .concat('?dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }
}

