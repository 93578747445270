<div class="body">
  <div class="container">
    <div class="row">
      <div class="offset-md-3 col-md-6 grid-margin stretch-card login-card">
        <div class="card">
          <div class="card-body">

            <div class="brand-logo">
              <img src="assets/images/PagadoLogo.png" alt="logo">
            </div>
            <h6>Bonjour! Commençons</h6>
            <h6 class="font-weight-light mb-lg-5 mb-md-5 mb-sm-3 mb-3">Connectez-vous pour continuer.</h6>

            <div>
              <div class="form-group">
                <p class="color-fail">{{ isOnlineMsg }}</p>
              </div>
            </div>

            <form class="pt-3" class="forms-sample" [formGroup]="loginForm">
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" formControlName="username"
                       placeholder="Nom d'utilisateur">
                <ng-container *ngFor="let validation of validationMessage.username">
                  <div class="color-fail"
                       *ngIf="loginForm.get('username').hasError(validation.type) && (loginForm.get('username').dirty || loginForm.get('username').touched)">
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
              <div class="form-group">
                <input type="password" class="form-control form-control-lg" formControlName="password"
                       placeholder="Mot de passe">
                <ng-container *ngFor="let validation of validationMessage.password">
                  <div class="color-fail" *ngIf="loginForm.get('password').hasError(validation.type) &&
                                  (loginForm.get('password').dirty || loginForm.get('password').touched)">
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
              <div class="mt-3">
                <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" (click)="login()">
                  S'IDENTIFIER
                </div>
              </div>
              <div class="auth-link mt-3">
                <a class="auth-link text-black" (click)="goToForgotPassword()">Mot de passe oublié?</a>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Chargement...</span>
    </div>
  </div>
</div>
