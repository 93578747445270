import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Branch } from 'src/app/interface/branch';
import { DeleteProduct } from 'src/app/interface/deleteProduct';
import { Product } from 'src/app/interface/products';
import { SubCategory } from 'src/app/interface/sub-category';
import { Supplier } from 'src/app/interface/supplier';
import { UpdateProduct } from 'src/app/interface/updateProduct';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { TransactionHistory } from 'src/app/models/warehouse/TransactionHistory';
import { WarehouseProduct } from 'src/app/models/warehouse/WarehouseProduct';
import { WarehouseProductSale } from 'src/app/models/warehouse/WarehouseProductSale';
import { WarehouseTransactionHistory } from 'src/app/models/warehouse/WarehouseTransactionHistory';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseProductService {

  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {
  }

  loadWarehouseProducts(warehouseId: number): Observable<ProductDTO[]> {
    return this.http.post<ProductDTO[]>(this.apiURL + '/warehouse/product/getallWarehouseProductsByWarehouse?w=' + warehouseId, null);
  }

  getOneranch(companyID: number, branchID: number): Observable<Branch> {
    return this.http.post<Branch>(this.apiURL + '/branch/getone?intBranchID=' +
      branchID + '&intCompanyID=' + companyID, null);
  }

  getProduct(companyID: number, branchID: number, subcategoryId: number): Observable<ProductDTO[]> {
    return this.http
      .post<ProductDTO[]>(this.apiURL + '/product/getall?intBranchID=' + branchID + '&intSubcategoryID=' +
      subcategoryId + '&intCompanyID=' + companyID, null);
  }

  getProductBySubCategory(subCategory: SubCategory, companyID: number, branchID: number): Observable<Product> {
    return this.http.post<Product>(this.apiURL + '/product/getall?intBranchID=' +
      branchID + '&intSubcategoryID=' + subCategory.subcategory_id + '&intCompanyID=' + companyID, null);
  }

  getProductBySubCategoryById(subcategoryID: number, companyID: number, branchID: number): Observable<ProductDTO[]> {
    return this.http.post<ProductDTO[]>(this.apiURL + '/product/getall?intBranchID=' +
      branchID + '&intSubcategoryID=' + subcategoryID + '&intCompanyID=' + companyID, null);
  }

  searchProduct(warehouseId: number, name: string): Observable<ProductDTO[]> {
    if ((name !== null) && (name !== '') && (name !== undefined)) {
      return this.http.post<ProductDTO[]>(this.apiURL + '/warehouse/product/search?w='
      + warehouseId + '&p=' + name, null);
    } else {
      return this.loadWarehouseProducts(warehouseId);
    }
  }

  updateWarehouseProduct(productBody): Observable<UpdateProduct> {
    return this.http.post<UpdateProduct>(this.apiURL + '/warehouse/product/update', productBody);
  }

  updateWarehouseStockQuantity(employeeID: number, productID: number, newEnteredQuantity: number,
    newTotalQuantity: number, warehouseId: number, companyID: number , supplierID: number , priceOfPack:number, productPackNumber: number, comment: string ): Observable<UpdateProduct> {
    return this.http.post<UpdateProduct>(this.apiURL + '/warehouse/product/updateWarehouseQuantity?e=' + employeeID + '&p=' + productID
      + '&newEnteredQuantity=' + newEnteredQuantity + '&newTotalQuantity=' + newTotalQuantity + '&w=' + warehouseId + '&c=' + companyID 
      + '&s=' + supplierID + '&pp=' + priceOfPack + '&ppn=' + productPackNumber.toString().concat('&comment=').concat(comment), null);
  }

  saveWarehouseProduct(warehouseProductBody, employeeID: number): Observable<WarehouseProduct> {
    return this.http.post<WarehouseProduct>(this.apiURL + '/warehouse/product/save?employeeID=' + employeeID, warehouseProductBody);
  }

  saveWarehouseProductSale(warehouseProductSale: WarehouseProductSale, oldQuantity: number): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL
      .concat('/warehouse/sale/save?oq=').concat(oldQuantity.toString()), warehouseProductSale);
  }

  public getTransactionSaleHistoryReport(companyID: number, warehouseID: number, dateFrom: string, dateTo: string): Observable<TransactionHistory[]> {
    return this.http.post<TransactionHistory[]>(environment.apiBase
      .concat('/warehouse/sale/getWarehouseTransactionSaleHistoryReport?w=').concat(warehouseID.toString())
      .concat('&c=').concat(companyID.toString())
      .concat('&dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }

  public getWarehouseTransactionHistoryReport(companyID: number, warehouseID: number, dateFrom: string, dateTo: string): Observable<WarehouseTransactionHistory[]> {
    return this.http.post<WarehouseTransactionHistory[]>(environment.apiBase
      .concat('/warehouse/sale/getWarehouseTransactionHistoryReport?w=').concat(warehouseID.toString())
      .concat('&c=').concat(companyID.toString())
      .concat('&dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }
  
  deleteProduct(body): Observable<DeleteProduct> {
    const productID = body.product_id;
    const branchID = body.branch_id;
    return this.http.post<DeleteProduct>(this.apiURL + '/warehouse/product/delete?p=' + productID + '&w=' + branchID, body);
  }

  getSupplier(companyID: number, branchID: number): Observable<Supplier[]> {
    return this.http.post<Supplier[]>(this.apiURL + '/supplier/getall?companyID='+ companyID, null);
  }

  getOneSupplier(supplierID: number, companyID: number, branchID: number): Observable<Supplier> {
    return this.http.post<Supplier>(this.apiURL + '/supplier/getone?intSupplierID=' +
      supplierID + '&intCompanyID=' + companyID, null);
  }
}
