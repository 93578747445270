<div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">

        <div class="container-fluid">
            <div class="panel mt-5">

                <div class="row mt-4">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div class="card tn-bg-white-color">
                            <div class="card-body">
                                <h6 class="mb-0 font-15px">Revenu total</h6>
                                <p class="card-text"> {{currencyDefault}} {{totalDrinkRevenuWithProfit | number}}</p>
                                <h6 class="mb-0 font-15px">Revenu sur prix achat</h6>
                                <p class="card-text"> {{currencyDefault}}
                                    {{totalDrinkRevenueWithoutProfit | number}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <!-- <div class="mt-4">
                            <small class="form-text text-muted mb-5"></small>
                            <button type="button" class="btn btn-primary mt-5" (click)="requestUsb()">
                                <i class="fas fa-print"></i> &nbsp; Selectionnez une imprimante
                            </button>
                        </div> -->
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <!-- <button (click)="getPrintData(selectedBranch.value)" class="btn btn-primary mt-5" [disabled]="status === false"> Imprimer maintenant!</button> -->
                    </div>
                </div>

                <form [formGroup]="filterFormSaleReport">
                    <div class="row mt-4 mb-3">
                        <div
                            class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                            <div class="card mb-3 d-md-none"
                                *ngFor="let item of saleReportToBeDisplayed | paginate: { itemsPerPage: 10, currentPage: pageSaleReport1 }">
                                <div class="card-body">
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Article
                                        </div>
                                        <div class="col-6">
                                            {{item.product_name}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Quantité vendue
                                        </div>
                                        <div class="col-6"
                                            *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                                            {{item.quantity_sold}} bouteille(s)
                                        </div>
                                        <div class="col-6"
                                            *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                                            {{item.quantity_sold}} shot(s)
                                        </div>
                                        <div class="col-6" *ngIf="item.category_name === 'Nourriture'">
                                            {{item.quantity_sold}} plat(s)
                                        </div>
                                        <div class="col-6"
                                            *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                                            {{item.quantity_sold}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Revenu total
                                        </div>
                                        <div class="col-6">
                                            FC {{(item.quantity_sold * item.selling_cost) | number}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <pagination-controls *ngIf="tableHasDataSaleReport === true"
                                class="mb-3 text-center d-md-none" (pageChange)="pageSaleReport1 = $event"
                                previousLabel="Précédent" nextLabel="Suivant">
                            </pagination-controls>


                            <mat-table [dataSource]="dataSourceSaleAndProfitReport" class="d-none d-md-block" matSort>

                                <ng-container matColumnDef="Product">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Article
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.product_name}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="QuantitySold">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Quantité vendue
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name !== 'Shot')">
                                            {{row.quantity_sold}} bouteille(s)
                                          </div>
                                          <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name === 'Shot')">
                                            {{row.quantity_sold}} shot(s)
                                          </div>
                                          <div *ngIf="row.category_name === 'Nourriture'">
                                            {{row.quantity_sold}} plat(s)
                                          </div>
                                          <div *ngIf="(row.category_name !== 'Nourriture') && (row.category_name !== 'Boisson')">
                                            {{row.quantity_sold}}
                                          </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="TotalRevenue">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Revenu total ({{currencyDefault}})
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{currencyDefault}}
                                        {{(row.quantity_sold * row.selling_cost) | number}}
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumnsSaleReport">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsSaleReport">
                                </mat-row>
                            </mat-table>
                            <div class="mt-3" *ngIf="tableHasDataSaleReport === false">Aucune vente trouvé
                            </div>
                            <mat-paginator class="d-none d-md-block mb-4" [pageSize]="10"
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>

                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <small class="form-text text-muted">Sélectionnez un établissement</small>
                                        <select #selectedBranch class="form-control"
                                            (change)="loadSaleAndProfitReport($event.target.value)"
                                            formControlName="selectBranch">
                                            <option value="0" selected>Tous les établissement</option>
                                            <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                                                {{branch.branch_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <small class="form-text text-muted">Datant de</small>
                                    <input class="form-control" [readonly]="true"
                                        [owlDateTimeTrigger]="dtSaleReportFrom" [owlDateTime]="dtSaleReportFrom"
                                        name="dtSaleReportFrom" formControlName="dtSaleReportFrom">
                                    <owl-date-time #dtSaleReportFrom></owl-date-time>
                                </div>

                                <div class="col-12">
                                    <small class="form-text text-muted">à</small>
                                    <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtSaleReportTo"
                                        [owlDateTime]="dtSaleReportTo" name="dtSaleReportTo"
                                        formControlName="dtSaleReportTo">
                                    <owl-date-time #dtSaleReportTo></owl-date-time>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-primary w-100"
                                        (click)="filterSaleReport(selectedBranch.value)">
                                        <i class="fas fa-filter"></i> &nbsp;Filtre
                                    </button>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-primary w-100" (click)="requestUsb()">
                                        <i class="fas fa-print"></i> &nbsp; Selectionnez une imprimante
                                    </button>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-md-12">
                                    <button (click)="getPrintData(selectedBranch.value)" class="btn btn-primary w-100"
                                        [disabled]="status === false"> <i class="fas fa-print"></i> &nbsp; Imprimer
                                        maintenant!</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <!----success product modal -->
                <ng-template #successModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{successModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>


                <!----failure product modal -->
                <ng-template #failureModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{failureModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>