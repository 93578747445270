import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { WarehouseCategory } from 'src/app/models/WarehouseCategory/WarehouseCategory';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseCategoryService {

  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {
  }

  getWarehouseCategories(companyId: number): Observable<WarehouseCategory []> {
    return this.http.post<WarehouseCategory []>(this.apiURL + '/warehouse/category/getall?c=' + companyId, null);
  }

  saveWarehouseCategory(warehouseCategory): Observable<WarehouseCategory> {
    return this.http.post<WarehouseCategory>(this.apiURL + '/warehouse/category/save', warehouseCategory);
  }

  deleteWarehouseCategory(warehouseCategory: WarehouseCategory): Observable<WarehouseCategory> {
    return this.http.post<WarehouseCategory>(this.apiURL + '/warehouse/category/delete?c=' + warehouseCategory.warehouse_category_id, null);
  }
}
