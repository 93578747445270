import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WarehouseCategory } from 'src/app/models/WarehouseCategory/WarehouseCategory';
import { WarehouseSubcategory } from 'src/app/models/WarehouseSubcategory/WarehouseCategory';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseSubcategoryService {
  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) { }

  getAllSubcategoriesByCategory(warehouseCategoryId: number): Observable<WarehouseSubcategory []> {
    return this.http.post<WarehouseSubcategory []>(this.apiURL + '/warehouse/subcategory/getall?c=' + warehouseCategoryId, null);
  }

  getAllSubcategoriesByCompany(companyId: number): Observable<WarehouseSubcategory []> {
    return this.http.post<WarehouseSubcategory []>(this.apiURL + '/warehouse/subcategory/getallbycompany?c=' + companyId, null);
  }

  getOneWarehouseSubcategoryById(subcategory_id, categoryId): Observable<WarehouseSubcategory []> {
    return this.http.post<WarehouseSubcategory []>(this.apiURL + '/warehouse/subcategory/getone?s=' + subcategory_id 
    + '&c=' + categoryId, null);
  } 
}
