import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import * as $ from 'jquery';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { WarehouseService } from 'src/app/services/warehouse/warehouse.service';
import { Warehouse } from 'src/app/models/warehouse/Warehouse';
import { WarehouseProductService } from 'src/app/services/warehouseProduct/warehouse-product.service';
import { TransactionHistory } from 'src/app/models/warehouse/TransactionHistory';
import { WarehouseTransactionHistory } from 'src/app/models/warehouse/WarehouseTransactionHistory';

@Component({
  selector: 'app-warehouse-transaction-history',
  templateUrl: './warehouse-transaction-history.component.html',
  styleUrls: ['./warehouse-transaction-history.component.scss']
})
export class WarehouseTransactionHistoryComponent implements OnInit {

  searchForm: FormGroup;
  filterFormWarehouseTransactionHistory: FormGroup;

  dataSourceWarehouseTransaction: MatTableDataSource<WarehouseTransactionHistory>;

  @ViewChild('paginatorWarehouseTransactionReport') paginatorWarehouseTransactionReport: MatPaginator;
  @ViewChild('sortWarehouseTransactionReport') sortWarehouseTransactionReport: MatSort;

  currencyDefault: string;
  tableHasDataReport: boolean;

  displayedColumnsWarehouseTransaction = [
    'DateTime',
    'Item',
    'Action',
    'SoldTo',
    'OldQuantity',
    // 'QuantitySpent',
    'QuantityLeft'
  ];

  // "warehouse_product_log_id": 126,
  // "employee_id": 2,
  // "warehouse_product_log_product_id": 1,
  // "old_quantity": 204,
  // "quantity_added": 0,
  // "quantity_sold": 36,
  // "warehouse_id": 1,
  // "quantity": 168,
  // "date_placed": "2021-08-28 16:16:58",
  // "branch_id_sold_to": 5,
  // "company_id": 2,
  // "warehouse_product_name": "Simba Grand",
  // "branch_name_sold_to": "Maniema 2 (Boite)",
  // "status": 1,
  // "drink_pack_number": 12,
  // "drink_pack_name": "12 Pack"



  wareHouseList: Warehouse[];
  warehouse: Warehouse;
  warehouseIdToBeUsed: number;
  warehouseCurrency: string;
  userLoggedIn: UserLoggedIn;
  spin: boolean;

  warehouseProductTransactionReports: WarehouseTransactionHistory[];

  startDateWarehouseTransactionHistory: string;
  endDateWarehouseTransactionHistory: string;

  @Input() id: string;

  pageTransactionHistoryReport = 1;

  closeResult: string;

  @ViewChild('viewOrderItem', { static: true }) viewOrderItem: TemplateRef<any>;

  isWaiterDropdownEnabled: boolean;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private warehouseProductService: WarehouseProductService, private branchService: BranchService, private employeeService: EmployeeService,
    private formBuilder: FormBuilder, private warehouseService: WarehouseService) {

    this.warehouseProductTransactionReports = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_WAREHOUSE)) {
      this.spin = true;

      this.getWarehouses();

      var date = new Date();

      this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

    } else {
      this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
    }
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  createForms() {

    this.filterFormWarehouseTransactionHistory = this.formBuilder.group({
      dtTransactionHistoryFrom: new FormControl(null, Validators.required),
      dtTransactionHistoryTo: new FormControl(null, Validators.required),
      selectWarehouse: new FormControl(null),
    });
  }

  getWarehouses(): void {
    this.warehouseService.getWarehousesByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.wareHouseList = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.warehouseIdToBeUsed = this.wareHouseList[0].warehouse_id;
        this.warehouseCurrency = this.wareHouseList[0].warehouse_currency;

      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_WAREHOUSE)) {
        this.wareHouseList = this.wareHouseList.filter(wr => wr.employee_manager_id === this.authenticationService.currentUserValue.employeeID);

        if (this.wareHouseList.length > 0) {
          this.warehouseIdToBeUsed = this.wareHouseList[0].warehouse_id;
          this.warehouseCurrency = this.wareHouseList[0].warehouse_currency;
        } else {
          this.warehouseIdToBeUsed = 0;
          this.warehouseCurrency = '';
        }

      }

      this.filterFormWarehouseTransactionHistory.controls.selectWarehouse.setValue(this.warehouseIdToBeUsed);

      this.loadWarehouseSaleTransaction(this.warehouseIdToBeUsed);
      this.spin = false;

    }, error => {
      this.spin = false;
    });
  }

  searchReportPastOrders(branchIdPastOrders) {
    this.spin = true;

    var date = new Date();

    if (this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value !== null) {
      this.startDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateWarehouseTransactionHistory = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateWarehouseTransactionHistory = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadReportOfWarehouseTransaction(branchIdPastOrders, this.startDateWarehouseTransactionHistory, this.endDateWarehouseTransactionHistory);
  }

  loadReportOfWarehouseTransaction(warehouseId: number, fromDatePastOrders: string, toDatePastOrders: string) {
    this.spin = true;

    this.warehouseProductService.getWarehouseTransactionHistoryReport(this.authenticationService.currentUserValue.companyID,
      warehouseId, fromDatePastOrders, toDatePastOrders).subscribe(res => {
        this.warehouseProductTransactionReports = res;

        if (this.warehouseProductTransactionReports.length > 0) {
          this.tableHasDataReport = true;
          this.dataSourceWarehouseTransaction = new MatTableDataSource(this.warehouseProductTransactionReports);
          this.dataSourceWarehouseTransaction.paginator = this.paginatorWarehouseTransactionReport;
          this.dataSourceWarehouseTransaction.sort = this.sortWarehouseTransactionReport;

        } else {
          this.tableHasDataReport = false;
        }
        this.spin = false;

      }, error => {
        this.tableHasDataReport = false;
        this.dataSourceWarehouseTransaction = new MatTableDataSource([]);
        this.dataSourceWarehouseTransaction.paginator = this.paginatorWarehouseTransactionReport;
        this.dataSourceWarehouseTransaction.sort = this.sortWarehouseTransactionReport;
        this.spin = false;
      });
  }

  filterWarehouseTransactionHistory(branchIdPastOrders) {
    this.startDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadReportOfWarehouseTransaction(branchIdPastOrders, this.startDateWarehouseTransactionHistory, this.endDateWarehouseTransactionHistory);
  }

  loadWarehouseSaleTransaction(warehouseId) {

    var date = new Date();

    if (this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value !== null) {
      this.startDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateWarehouseTransactionHistory = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateWarehouseTransactionHistory = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();

    }

    this.loadReportOfWarehouseTransaction(warehouseId, this.startDateWarehouseTransactionHistory, this.endDateWarehouseTransactionHistory);
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
