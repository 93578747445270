import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoriesService } from './categories.service';
import { OrderService } from './order/order.service';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {
  currentUser;
  categories;
  month;
  constructor(
    private httpClient:HttpClient,
    private categoriService:CategoriesService,
    private orderService:OrderService
  ) {
   }

  private prepareDataForDashBoard(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.categoriService.getCategories(this.currentUser['companyID'], this.currentUser['branchID']).subscribe({
      next: (categories) => {
        this.categories = categories;
      }
    });
  }
  async GetPieData(dateFrom, dateTo){
    this.prepareDataForDashBoard()
    dateFrom = moment(dateFrom).format('YYYY-MM-DD HH:mm:ss').toString();
    dateTo = moment(dateTo).format('YYYY-MM-DD HH:mm:ss').toString()

    let dataToReturn = []

    await this.orderService.getSaleAndProfitReport(this.currentUser['companyID'], this.currentUser['branchID'], dateFrom, dateTo).toPromise().then(res=>{
      this.categories.forEach(category=>{
        let categoryObject:any = {
          label: category.name,
          items:[],
        }
        res.saleAndProfitReportList.forEach(element => {
          if(element.category_id === category.category_id){
            res.summaryItems.forEach(sum => {
              if(element.category_name === sum.categoryName){
                categoryObject = {...categoryObject,total_revenue: sum.totalRevenu, id:sum.totalRevenu }
              }
            });
            categoryObject = {...categoryObject, name:element.category_name}
            categoryObject.items.push({
              name:element.product_name,
              sold:element.quantity_sold,
              value:element.quantity_sold * element.profit_on_product,
            });
          }
        });
        dataToReturn.push(categoryObject);
      })
    }).catch((e=>{
      console.log("An errror occured->", e);
      dataToReturn = [];
    }))
    return dataToReturn;
  }

}
