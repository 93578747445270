<div class="d-flex" id="wrapper">
    <app-side-navigation class="side-bar"></app-side-navigation>
    <div id="page-content-wrapper">
        <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
            <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
        </nav>

        <div class="container-fluid">
            <div class="panel mt-5">

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="cancelledOrders-tab" data-toggle="tab" href="#cancelledOrders"
                            role="tab" aria-controls="cancelledOrders" aria-selected="true">Commandes annulées</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="debts-orders-tab" data-toggle="tab" href="#debtsOrders"
                            role="tab" aria-controls="debtsOrders" aria-selected="true">Commandes avec dettes</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="reductionProducts-tab" data-toggle="tab" href="#reductionProducts"
                            role="tab" aria-controls="reductionProducts" aria-selected="true">Stock diminué Manuellement</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="cancelledOrders" role="tabpanel"
                        aria-labelledby="cancelledOrders-tab">

                        <div class="row mt-4">
                        </div>


                        <form [formGroup]="filterFormCancelledOrders">
                            <div class="row mt-1">
                                <div
                                    class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                                    <div class="card mb-3 d-md-none"
                                        *ngFor="let item of cancelledOrderReports | paginate: { itemsPerPage: 10, currentPage: pageCancelledOrderReport, id: 'orderReport' }">
                                        <div class="card-body">
                                            <div class="row mb-2 text-center">
                                                <div class="col-md-12">
                                                    <!-- <div class="mr-2" *ngIf="item.discount !== 0"> -->
                                                    <a class="mr-2 tn-black-color" *ngIf="item.discount !== 0"> <i
                                                            class="fas fa-angle-double-down"></i></a>
                                                    <!-- </div>
                                        <div *ngIf="item.flag === 1"> -->
                                                    <a *ngIf="item.flag === 1" class="tn-red-color"> <i
                                                            class="fas fa-flag"></i></a>
                                                    <!-- </div>&nbsp; -->
                                                </div>

                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-6 card-table-header">
                                                    OrderID
                                                </div>
                                                <div class="col-6">
                                                    {{item.order_id}}
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-6 card-table-header">
                                                    Date et heure
                                                </div>
                                                <div class="col-6">
                                                    {{item.order_start_time}}
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-6 card-table-header">
                                                    Serveur(se)
                                                </div>
                                                <div class="col-6">
                                                    {{item.employee_fullname}}
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-6 card-table-header">
                                                    Montant payé
                                                </div>
                                                <div class="col-6">
                                                    {{item.currency_one}}
                                                    {{item.total_amount_brought_in}}
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 text-center">
                                                    <button class="btn btn-primary"
                                                        (click)="loadOrderItemsByOrderID(item.order_id,
                                            item.discount, item.total_amount_brought_in, item.currency_one, item.comment)"><i
                                                            class="fas fa-eye"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <pagination-controls *ngIf="tableHasDataCancelledOrderReport === true"
                                        class="mb-3 text-center d-md-none"
                                        (pageChange)="onMobileTableChangePage($event)" id="orderReport"
                                        previousLabel="Précédent" nextLabel="Suivant">
                                    </pagination-controls>


                                    <mat-table [dataSource]="dataSource" class="d-none d-md-block" matSort>
                                        <ng-container matColumnDef="OrderID">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                No de commande
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row">
                                                <div class="mr-2" *ngIf="row.discount !== 0">
                                                    <a data-toggle="modal" data-target="#deleteProduct"> <i
                                                            class="fas fa-angle-double-down"></i></a>
                                                </div>&nbsp;
                                                <div class="mr-2" *ngIf="row.flag === 1">
                                                    <a data-toggle="modal" class="tn-red-color"
                                                        data-target="#deleteProduct"> <i class="fas fa-flag"></i></a>
                                                </div>&nbsp;
                                                <div>
                                                    {{row.order_id}}
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="Date & Time">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Date et heure
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row">
                                                {{row.order_start_time}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="Waiter">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Serveur(se)
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row">
                                                {{row.employee_fullname}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="Value">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Montant payé
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row">
                                                {{row.currency_one}}
                                                {{row.total_amount_brought_in}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="Action">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Action
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row">
                                                <!-- <div class="mr-2" *ngIf="row.flag === 1">
                                        <button class="btn btn-danger" data-toggle="modal"
                                            data-target="#deleteProduct">
                                            <i class="fas fa-flag"></i></button>
                                    </div>&nbsp; -->
                                                <div>
                                                    <button
                                                        (click)="loadOrderItemsByOrderID(row.order_id, row.discount, row.total_amount_brought_in, row.currency_one, row.comment)"
                                                        class="btn btn-primary">
                                                        <i class="fas fa-eye"></i></button>
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="displayedColumnsPastOrderReport">
                                        </mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedColumnsPastOrderReport">
                                        </mat-row>
                                    </mat-table>
                                    <div class="mt-3" *ngIf="tableHasDataCancelledOrderReport === false">Aucune vente
                                        trouvé
                                    </div>
                                    <mat-paginator #paginatorOrderReport class="d-none d-md-block mb-4" [pageSize]="10"
                                        [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                                </div>

                                <div
                                    class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <select #selectedBranch class="form-control" formControlName="dpBranch"
                                                    (change)="loadOrders($event.target.value)">
                                                    <option value="0">
                                                        Tous les établissement
                                                    </option>
                                                    <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                                                        {{branch.branch_name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <small class="form-text text-muted">Datant de</small>
                                            <input class="form-control" [readonly]="true"
                                                [owlDateTimeTrigger]="dtCancelledOrdersFrom"
                                                [owlDateTime]="dtCancelledOrdersFrom" name="dtCancelledOrdersFrom"
                                                formControlName="dtCancelledOrdersFrom">
                                            <owl-date-time #dtCancelledOrdersFrom></owl-date-time>
                                        </div>

                                        <div class="col-12">
                                            <small class="form-text text-muted">à</small>
                                            <input class="form-control" [readonly]="true"
                                                [owlDateTimeTrigger]="dtCancelledOrdersTo"
                                                [owlDateTime]="dtCancelledOrdersTo" name="dtCancelledOrdersTo"
                                                formControlName="dtCancelledOrdersTo">
                                            <owl-date-time #dtCancelledOrdersTo></owl-date-time>

                                        </div>
                                    </div>
                                    <div class="row my-3">
                                        <div class="col-md-12">
                                            <button type="button" class="btn btn-primary w-100"
                                                (click)="filterPastOrders(selectedBranch.value)">
                                                <i class="fas fa-filter"></i> &nbsp;Filtre
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <ng-template #viewOrderItem let-modal>
                            <div class="modal-header text-center">
                                <h5 class="modal-title">Commande #{{selectedOrderID_PastOrders}}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    (click)="modal.dismiss()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body text-center">
                                <div class="row mb-1"
                                    *ngFor="let item of orderItems | paginate: { itemsPerPage: 5, currentPage: viewOrderItemPage, id: 'viewOrderItemPage' }">
                                    <div class="col-6">
                                        {{item.quantity}} x
                                        {{item.product_name}}
                                    </div>
                                    <div class="col-6">
                                        {{currencyDefault}}
                                        {{item.cost_to_pay}}
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                        Total
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                        {{currencyDefault}}
                                        {{selectedOrderTotalAmount_PastOrders}}
                                    </div>
                                </div>
                                <div class="row tn-failure-color">
                                    <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                        Réduction
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                        - {{currencyDefault}} {{selectedOrderDiscount_PastOrders}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                        Total à payer
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                        {{currencyDefault}} {{selectedOrderAmountToBePaid_PastOrders}}
                                    </div>
                                </div>
                                <pagination-controls *ngIf="tableHasDataCancelledOrderReport === true"
                                    class="mb-3 text-center" (pageChange)="viewOrderItemPage = $event"
                                    id="viewOrderItemPage" previousLabel="Précédent" nextLabel="Suivant">
                                </pagination-controls>

                                <hr>

                                <div class="row tn-blue-color">
                                    <div class="col-lg-12 col-md-12 col-12 font-weight-500">
                                        <h6 class="text-left text-underline margin-left-10px">Comment:</h6>
                                        <p class="text-left font-weight-normal margin-left-10px">
                                            {{selectedOrderComment_PastOrders}}</p>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer text-center">
                                <button type="button" class="btn btn-primary" (click)="modal.close()">Fermer</button>
                            </div>
                        </ng-template>

                        <!----success product modal -->
                        <ng-template #successModal let-modal>
                            <div class="modal-header text-center border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    (click)="modal.dismiss()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body py-0 text-center">
                                <div class="row">
                                    <div class="col-12">
                                        <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                                    </div>
                                </div>
                                <label class="mt-3"> {{successModalMessage}} </label>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary btn-lg"
                                    (click)="modal.close()">Fermer</button>
                            </div>
                        </ng-template>


                        <!----failure product modal -->
                        <ng-template #failureModal let-modal>
                            <div class="modal-header text-center border-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    (click)="modal.dismiss()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body py-0 text-center">
                                <div class="row">
                                    <div class="col-12">
                                        <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                                    </div>
                                </div>
                                <label class="mt-3"> {{failureModalMessage}} </label>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary btn-lg"
                                    (click)="modal.close()">Fermer</button>
                            </div>
                        </ng-template>

                    </div>
                    <div class="tab-pane fade" id="debtsOrders" role="tabpanel"
                    aria-labelledby="debts-orders-tab">
                    <app-report-debts-order></app-report-debts-order>
                    </div>
                    <div class="tab-pane fade" id="reductionProducts" role="tabpanel"
                        aria-labelledby="reductionProducts-tab">
                        <app-report-deducted-stock></app-report-deducted-stock>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>