<div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">

        <div class="container-fluid">
            <div class="panel mt-5">

                <div class="row mt-4">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">Revenu total</h6>
                          <p class="card-text"> {{currencyDefault}} {{totalRevenue}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                <div class="row mt-4">
                    <div
                        class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                        <div class="card mb-3 d-md-none"
                            *ngFor="let item of warehouseProductTransactionReports | paginate: { itemsPerPage: 10, currentPage: pageTransactionHistoryReport, id: 'orderReport' }">
                            <div class="card-body">
                                
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Vendu a
                                    </div>
                                    <div class="col-6">
                                        {{item.branch_name_sold_to}}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Vendu par
                                    </div>
                                    <div class="col-6">
                                        {{item.employee_name}}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Date et heure
                                    </div>
                                    <div class="col-6">
                                        {{item.date_sale_placed}}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Article
                                    </div>
                                    <div class="col-6">
                                        {{item.currency_one}}
                                        {{item.warehouse_product_name}}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Quantité
                                    </div>
                                    <div class="col-6">
                                        <div *ngIf="(item.pack_number_of_piece !== 1)">
                                            <p *ngIf="item.quantity > item.pack_number_of_piece">
                                                {{(item.quantity % item.pack_number_of_piece) !== 0 ? (item.quantity /
                                                item.pack_number_of_piece).toString()
                                                .substring(0, (item.quantity / item.pack_number_of_piece).toString().indexOf('.')) :
                                                (item.quantity / item.pack_number_of_piece) }}
                                                casiers de {{item.drink_pack_name}}
                                            </p>
                                            <p *ngIf="item.quantity === item.pack_number_of_piece"> 1 casier
                                                de {{item.drink_pack_name}}</p>
                                            <p *ngIf="item.quantity < item.pack_number_of_piece"> 0 casier et
                                                    {{item.quantity}} bouteilles </p>
                                        </div>
                                        <div *ngIf="(item.pack_number_of_piece === 1)">
                                            <p> {{item.quantity}} bouteilles </p>
                                        </div>    
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Montant payé
                                    </div>
                                    <div class="col-6">
                                        {{warehouseCurrency}}
                                        {{item.total_amount}}
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <pagination-controls *ngIf="tableHasDataReport === true" class="mb-3 text-center d-md-none"
                            (pageChange)="pageTransactionHistoryReport = $event" id="orderReport" previousLabel="Précédent"
                            nextLabel="Suivant">
                        </pagination-controls>

                        <mat-table [dataSource]="dataSourceWarehouseTransaction" class="d-none d-md-block" matSort>
                           
                            <ng-container matColumnDef="BranchTo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Vendu a l'Établissement
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{row.branch_name_sold_to}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="SoldBy">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Vendu par
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{row.employee_name}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="DateTime">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Date et heure
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{row.date_sale_placed}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Item">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Article
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{row.warehouse_product_name}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Quantity">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Quantité
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <div *ngIf="(row.pack_number_of_piece !== 1)">
                                        <p *ngIf="row.quantity > row.pack_number_of_piece">
                                            {{(row.quantity % row.pack_number_of_piece) !== 0 ? (row.quantity /
                                                row.pack_number_of_piece).toString()
                                            .substring(0, (row.quantity / row.pack_number_of_piece).toString().indexOf('.')) :
                                            (row.quantity / row.pack_number_of_piece) }}
                                            casiers de {{row.drink_pack_name}}
                                          </p>
                                          <p *ngIf="row.quantity === row.pack_number_of_piece"> 1 casier
                                            de {{row.drink_pack_name}}</p>
                                          <p *ngIf="row.quantity < row.pack_number_of_piece"> 0 casier et
                                            {{row.quantity}} bouteilles </p>
                                    </div>
                                    <div *ngIf="(row.pack_number_of_piece === 1)">
                                        <p> {{row.quantity}} bouteilles </p>
                                    </div> 
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Amount paid">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Montant payé
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{warehouseCurrency}}
                                    {{row.total_amount}}
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumnsWarehouseTransaction">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsWarehouseTransaction">
                            </mat-row>
                        </mat-table>
                        <div class="mt-3" *ngIf="tableHasDataReport === false">Aucune vente trouvé
                        </div>
                        <mat-paginator #paginatorWarehouseTransactionReport class="d-none d-md-block mb-4" [pageSize]="10"
                            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                    </div>

                    <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                        <form [formGroup]="filterFormWarehouseTransactionHistory">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <select #selectedWarehouse class="form-control"
                                            formControlName="selectWarehouse"
                                            (change)="loadWarehouseSaleTransaction($event.target.value)">
                                            <option value="0">
                                                Tous les dépot
                                            </option>
                                            <option *ngFor="let warehouse of wareHouseList"
                                                value={{warehouse.warehouse_id}}>
                                                {{warehouse.warehouse_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="row">
                                <div class="col-12">
                                    <small class="form-text text-muted">Datant de</small>
                                    <input class="form-control" [readonly]="true"
                                        [owlDateTimeTrigger]="dtTransactionHistoryFrom" [owlDateTime]="dtTransactionHistoryFrom"
                                        name="dtTransactionHistoryFrom" formControlName="dtTransactionHistoryFrom">
                                    <owl-date-time #dtTransactionHistoryFrom></owl-date-time>
                                </div>

                                <div class="col-12">
                                    <small class="form-text text-muted">à</small>
                                    <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtTransactionHistoryTo"
                                        [owlDateTime]="dtTransactionHistoryTo" name="dtTransactionHistoryTo"
                                        formControlName="dtTransactionHistoryTo">
                                    <owl-date-time #dtTransactionHistoryTo></owl-date-time>

                                </div>
                            </div>
                        </form>
                        <div class="row my-3">
                            <div class="col-md-12">
                                <button type="button" class="btn btn-primary w-100"
                                    (click)="filterWarehouseTransactionHistory(selectedWarehouse.value)">
                                    <i class="fas fa-filter"></i> &nbsp;Filtre
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!----success product modal -->
                <ng-template #successModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{successModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>


                <!----failure product modal -->
                <ng-template #failureModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{failureModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>