export class SaleAndProfitAndLossReportToDisplay {
    date_sale_placed: string;
    product_name: string;
    branch_id: number;
    category_id: number;
    branch_name: string;
    quantity_sold: number;
    buying_cost: number;
    selling_cost: number;
    profit_on_product: number;
    company_id: string;
    quantity_loss: number;
    quantity_gifted: number;
    category_name:string;
    subcategory_name:string;
}