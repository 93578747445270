import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from 'src/app/common/Constants';
import { DataManagerService } from '../data-management/data-manager.service';
import { EmployeePerformanceReport } from 'src/app/models/order/EmployeePerformanceReport';
import { SaleReport } from 'src/app/models/order/SaleReport';
import { PastOrderReport } from 'src/app/models/order/PastOrderReport';
import { StockUpdateReport } from 'src/app/models/order/StockUpdateReport';
import { StockUpdateOutgoingReport } from 'src/app/models/order/StockUpdateOutgoingReport';
import { OrderItem } from 'src/app/models/order-items';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { SaleAndProfitAndLossReport } from 'src/app/models/order/SaleAndProfitAndLossReport';
import { SaleAndBottleProfitLoss } from 'src/app/models/order/SaleAndBottleProfitLoss';
import { FixDebtDTO } from 'src/app/models/order/FixDebtDTO.model';
import { DebtsOrderDTO } from 'src/app/models/order/DebtsOrderDTO.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  
  private behaviourSubject = new BehaviorSubject(false);
  private readonly apiUrl = environment.apiBase;

  isItOnBookingPage = this.behaviourSubject.asObservable();

  constructor(private dataManServ: DataManagerService, private http: HttpClient) {
  }

  public getEmployeePerformanceReport(companyID: number, branchID: number, dateFrom: string, dateTo: string): Observable<EmployeePerformanceReport[]> {
    return this.http.post<EmployeePerformanceReport[]>(environment.apiBase
      .concat('/order/getEmployeePerformanceReport?branchID=').concat(branchID.toString())
      .concat('&companyID=').concat(companyID.toString())
      .concat('&dateFrom=').concat(dateFrom)
      .concat('&dateTo=').concat(dateTo), null);
  }

  public getSalesReport(companyID: number, branchID: number, dateFrom: string, dateTo: string): Observable<SaleReport[]> {
    return this.http.post<SaleReport[]>(environment.apiBase
      .concat('/order/getSaleReport?branchID=').concat(branchID.toString())
      .concat('&companyID=').concat(companyID.toString())
      .concat('&dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }

  public getPastOrdersReport(companyID: number, employeeID:number, branchID: number, dateFrom: string, dateTo: string): Observable<PastOrderReport[]> {
    return this.http.post<PastOrderReport[]>(environment.apiBase
      .concat('/order/getPastOrderReport?b=').concat(branchID.toString())
      .concat('&c=').concat(companyID.toString())
      .concat('&e=').concat(employeeID.toString())
      .concat('&dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }

  getCancelledOrdersReport(companyID: number, employeeID: number, branchID: number, dateFrom: string, dateTo: string): Observable<PastOrderReport[]> {
    return this.http.post<PastOrderReport[]>(environment.apiBase
      .concat('/order/getCancelledOrders?b=').concat(branchID.toString())
      .concat('&c=').concat(companyID.toString())
      .concat('&df=').concat(dateFrom)
      .concat('&dt=').concat(dateTo), null);
  }

  getDebtsOrdersReport(companyID: number, employeeID: number, branchID: number, dateFrom: string, dateTo: string): Observable<DebtsOrderDTO[]> {
    return this.http.post<DebtsOrderDTO[]>(environment.apiBase
      .concat('/order/getDebtsOrders/').concat(branchID.toString())
      .concat('/').concat(companyID.toString())
      .concat('/').concat(dateFrom)
      .concat('/').concat(dateTo), null);
  }

  fixDebtsOrders(fixDebtsOrders: FixDebtDTO): Observable<boolean> {
    return this.http.post<boolean>(environment.apiBase.concat('/order/fixDebtsOrders'), fixDebtsOrders);
  }

  public getStockUpdateEntryReport(companyID: number, branchID: number, dateFrom: string, dateTo: string): Observable<StockUpdateReport[]> {
    return this.http.post<StockUpdateReport[]>(environment.apiBase
      .concat('/product/getStockUpdateReportIncoming?branchID=').concat(branchID.toString())
      .concat('&companyID=').concat(companyID.toString())
      .concat('&dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }

  public getStockUpdateOutgoingReport(companyID: number, branchID: number, dateFrom: string, dateTo: string): Observable<StockUpdateOutgoingReport[]> {
    return this.http.post<StockUpdateOutgoingReport[]>(environment.apiBase
      .concat('/order/getStockUpdateOutgoingReport?branchID=').concat(branchID.toString())
      .concat('&companyID=').concat(companyID.toString())
      .concat('&dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }

  public getSaleAndProfitReport(companyID: number, branchID: number, dateFrom: string, dateTo: string): Observable<SaleAndProfitAndLossReport> {
    return this.http.post<SaleAndProfitAndLossReport>(environment.apiBase
      .concat('/order/getSaleAndProfitReport?branchID=').concat(branchID.toString())
      .concat('&companyID=').concat(companyID.toString())
      .concat('&dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }

  public getSaleAndProfitReportForProductWithNoDailyProfit(companyID: number, branchID: number, dateFrom: string, dateTo: string): Observable<SaleAndBottleProfitLoss[]> {
    return this.http.post<SaleAndBottleProfitLoss[]>(environment.apiBase
      .concat('/order/getSaleAndProfitReportForProductWithNoDailyProfit?b=').concat(branchID.toString())
      .concat('&c=').concat(companyID.toString())
      .concat('&df=').concat(dateFrom)
      .concat('&dt=').concat(dateTo), null);
  }

  public getAllOrderItem(orderID: number): Observable<OrderItem[]> {
    return this.http.post<OrderItem[]>(environment.apiBase.concat('/orderitem/getAllOrderItemByOrderID?orderID=').concat(orderID.toString()), null);
  }

  public getAllLowStockProduct(branchID: number, companyID): Observable<ProductDTO[]> {
    return this.http.post<ProductDTO[]>(environment.apiBase
      .concat('/product/getLowStockProducts?branchID=').concat(branchID.toString())
      .concat('&c=').concat(companyID.toString()), null);
  }

  public getGiftOrdersReport(companyID: number, employeeID:number, branchID: number, dateFrom: string, dateTo: string): Observable<PastOrderReport[]> {
    return this.http.post<PastOrderReport[]>(environment.apiBase
      .concat('/order/getGiftedOrders?b=').concat(branchID.toString())
      .concat('&c=').concat(companyID.toString())
      .concat('&e=').concat(employeeID.toString())
      .concat('&dateTimeFrom=').concat(dateFrom)
      .concat('&dateTimeTo=').concat(dateTo), null);
  }

  public searchOrder(companyID:number, branchID: number, order_id: string): Observable<PastOrderReport[]> {
      return this.http.post<PastOrderReport[]>(environment.apiBase
        .concat('/order/search?b=').concat(branchID.toString())
        .concat('&c=').concat(companyID.toString())
        .concat('&o=').concat(order_id.toString()), null);
  
    } 

    public searchGiftOrder(companyID:number, branchID: number, order_id: string): Observable<PastOrderReport[]> {
      return this.http.post<PastOrderReport[]>(environment.apiBase
        .concat('/order/searchGiftedOrders?b=').concat(branchID.toString())
        .concat('&c=').concat(companyID.toString())
        .concat('&o=').concat(order_id.toString()), null);
  
    }
  
  // public getPastOrdersReportPerEmployee(companyID: number, employeeID: any, branchID: number, 
  //   startDatePastOrders: string, endDatePastOrders: string): Observable<PastOrderReport[]> {
  //   return this.http.post<PastOrderReport[]>(environment.apiBase
  //     .concat('/order/getPastOrderReport?branchID=').concat(branchID.toString())
  //     .concat('&companyID=').concat(companyID.toString())
  //     .concat('&dateTimeFrom=').concat(dateFrom)
  //     .concat('&dateTimeTo=').concat(dateTo), null);
  // }
  

}
