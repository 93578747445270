<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">

        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" id="prod">Fournisseurs</a>
          </li>
        </ul>
        <div class="tab-content">

          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
              <form [formGroup]="supplierSearchForm">
                <div class="form-group mt-4">
                  <small class="form-text text-muted">Rechercher un fournisseur</small>
                  <div class="input-group mb-3">
                    <input type="search" class="form-control"
                           formControlName="searchSupplierName" placeholder="Rechercher..."
                           aria-label="Search..." aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" (click)="searchSupplier()"
                              type="button"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
              <form>
                <div class="form-group mt-4">
                  <small class="form-text text-muted mt-5"></small>
                  <button type="button" class="btn btn-primary" (click)="addSupplierButton()">
                    <i class="fas fa-plus-square"></i> Ajouter un nouveau fournisseur
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="row mt-1 mb-3">
            <div class="col-lg-9 col-md-12 col-sm-12 col-12 table-responsive">

              <div class="card mb-3 d-lg-none" *ngFor="let item of suppliers | paginate: { itemsPerPage: 10, currentPage: page }">
                <div class="card-body">
                 <div class="row mb-2">
                   <div class="col-6 card-table-header">
                     Nom du fournisseur
                   </div>
                   <div class="col-6">
                     {{item.supplier_name}}
                   </div>
                 </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Numéro de téléphone
                    </div>
                    <div class="col-6">
                      {{item.supplier_cell}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Code de la part du fournisseur
                    </div>
                    <div class="col-6">
                      {{item.supplier_code}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Adresse
                    </div>
                    <div class="col-6">
                      {{item.supplier_address}}
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12 text-center">
                      <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                              (click)="editSupplier1(item)"><i class="bi bi-pen"></i></button> &nbsp;
                      <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                              (click)="deleteSupplier1(item)"><i class="bi bi-trash"></i></button> &nbsp;
                      <button class="btn btn-primary" (click)="viewSupplierHistory(item.supplier_id)"><i class="bi bi-eye"></i></button>        
                    </div>
                  </div>
                </div>
              </div>
              <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-lg-none" (pageChange)="page = $event" previousLabel="Précédent"
                                   nextLabel="Suivant"></pagination-controls>


              <mat-table [dataSource]="dataSourceSupplier" matSort class="d-none d-lg-block">
                <ng-container matColumnDef="Name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Nom du fournisseur
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.supplier_name}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Cellphone">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Numéro de téléphone
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.supplier_cell}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SupplierCode">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Code de la part fournisseur
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.supplier_code}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Address">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Adresse
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.supplier_address}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Actions">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Actions
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <!--                    <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"-->
                    <!--                            (click)="view(row)"><i class="fas fa-eye"></i></button> &nbsp;-->
                    <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                            (click)="editSupplier1(row)"><i class="bi bi-pen"></i></button> &nbsp;

                    <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                            (click)="deleteSupplier1(row)"><i class="bi bi-trash"></i></button> &nbsp;

                    <button class="btn btn-primary" data-toggle="modal" data-target="#viewSupplier"
                            (click)="viewSupplierHistory(row.supplier_id, row.company_id)"><i class="bi bi-eye"></i></button>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsSupplier">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsSupplier">
                </mat-row>
              </mat-table>
              <div class="mt-3" *ngIf="tableHasData === false">Aucun fournisseur trouvé
              </div>
              <mat-paginator class="d-none d-lg-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 col-12">

            </div>
          </div>


          <!-- CREATE SUPPLIER MODAL -->
          <ng-template #addSupplier let-modal>
            <div class="modal-header">
              <h5 class="modal-title">Ajouter un nouveau fournisseur</h5>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="addSupplierForm">
                <div class="form-group">
                  <small class="form-text text-muted">Nom du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_name"
                         placeholder="Nom" required>
                  <div *ngIf="addSupplierForm.controls['supplier_name'].invalid &&
                                                        (addSupplierForm.controls['supplier_name'].dirty ||
                                                        addSupplierForm.controls['supplier_name'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="addSupplierForm.controls['supplier_name'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Numéro de téléphone du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_cell"
                         placeholder="Numéro de téléphone" required>
                  <div *ngIf="addSupplierForm.controls['supplier_cell'].invalid &&
                                                        (addSupplierForm.controls['supplier_cell'].dirty ||
                                                        addSupplierForm.controls['supplier_cell'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="addSupplierForm.controls['supplier_cell'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Code de la part du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_code"
                         placeholder="Code" required>
                  <div *ngIf="addSupplierForm.controls['supplier_code'].invalid &&
                                                        (addSupplierForm.controls['supplier_code'].dirty ||
                                                        addSupplierForm.controls['supplier_code'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="addSupplierForm.controls['supplier_code'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Adresse du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_address"
                         placeholder="Address" required>
                  <div *ngIf="addSupplierForm.controls['supplier_address'].invalid &&
                                                        (addSupplierForm.controls['supplier_address'].dirty ||
                                                        addSupplierForm.controls['supplier_address'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="addSupplierForm.controls['supplier_address'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Réduction du fournisseur</small>
                  <input type="text" class="form-control" formControlName="loyalty_percent"
                         placeholder="discount %" required>
                  <div *ngIf="addSupplierForm.controls['loyalty_percent'].invalid &&
                                                        (addSupplierForm.controls['loyalty_percent'].dirty ||
                                                        addSupplierForm.controls['loyalty_percent'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="addSupplierForm.controls['loyalty_percent'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer text-center">
              <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
              </button>
              <button type="button" [disabled]="!addSupplierForm.valid" class="btn btn-primary btn-lg"
                      (click)='addSuppleir()'>Sauvegarder
              </button>
            </div>
          </ng-template>


          <!-- EDIT SUPPLIER MODAL -->
          <ng-template #editSupplier let-modal>
            <div class="modal-header">
              <h5 class="modal-title">Modification des détails du fournisseur</h5>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="editSupplierForm">
                <div class="form-group">
                  <small class="form-text text-muted">Nom du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_name"
                         placeholder="Nom" required>
                  <div *ngIf="editSupplierForm.controls['supplier_name'].invalid &&
                                                            (editSupplierForm.controls['supplier_name'].dirty ||
                                                            editSupplierForm.controls['supplier_name'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['supplier_name'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Numéro de téléphone du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_cell"
                         placeholder="Numéro de téléphone" required>
                  <div *ngIf="editSupplierForm.controls['supplier_cell'].invalid &&
                                                            (editSupplierForm.controls['supplier_cell'].dirty ||
                                                            editSupplierForm.controls['supplier_cell'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['supplier_cell'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Code de la part du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_code"
                         placeholder="Code" required>
                  <div *ngIf="editSupplierForm.controls['supplier_code'].invalid &&
                                                            (editSupplierForm.controls['supplier_code'].dirty ||
                                                            editSupplierForm.controls['supplier_code'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['supplier_code'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Adresse du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_address"
                         placeholder="Addresse" required>
                  <div *ngIf="editSupplierForm.controls['supplier_address'].invalid &&
                                                            (editSupplierForm.controls['supplier_address'].dirty ||
                                                            editSupplierForm.controls['supplier_address'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['supplier_address'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Réduction du fournisseur</small>
                  <input type="text" class="form-control" formControlName="loyalty_percent"
                         placeholder="discount %" required>
                  <div *ngIf="editSupplierForm.controls['loyalty_percent'].invalid &&
                                                        (addSupplierForm.controls['loyalty_percent'].dirty ||
                                                        addSupplierForm.controls['loyalty_percent'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['loyalty_percent'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer text-center">
              <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
              </button>
              <button type="button" [disabled]="!editSupplierForm.valid" class="btn btn-primary btn-lg"
                      (click)='confirmEdidtSupplier()'>Sauvegarder
              </button>
            </div>
          </ng-template>

          <!----delete product modal -->
          <ng-template #deleteSupplier let-modal>
            <div class="modal-header text-center">
              <h5 class="modal-title">Confirmation de suppression</h5>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir supprimer ce fournisseur ?</label>
                <h5>{{tempSupplierName}}</h5>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
              </button>
              <button type="button" class="btn btn-primary btn-lg"
                      (click)='confirmDelete()'>Confirmer
              </button>
            </div>
          </ng-template>

          <!----success product modal -->
          <ng-template #successModal let-modal>
            <div class="modal-header text-center border-0">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-0 text-center">
              <div class="row">
                <div class="col-12">
                  <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                </div>
              </div>
              <label class="mt-3"> {{successModalMessage}} </label>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
            </div>
          </ng-template>


          <!----failure product modal -->
          <ng-template #failureModal let-modal>
            <div class="modal-header text-center border-0">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-0 text-center">
              <div class="row">
                <div class="col-12">
                  <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                </div>
              </div>
              <label class="mt-3"> {{failureModalMessage}} </label>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>
