import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Table } from 'src/app/interface/tables';
import { TablesService } from 'src/app/services/tables.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Constants } from '../../common/Constants';
import { Category } from '../../interface/category';
import { CategoriesService } from '../../services/categories.service';
import { Branch } from '../../interface/branch';
import { BranchService } from 'src/app/services/branch.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {
  searchTableName: FormGroup;
  tables: Table[];
  spin: boolean;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  tableForm: FormGroup;
  // tableFormDelete: FormGroup;
  tableToDelete: any;
  tempTable: Table;
  viewTableForm: FormGroup;
  editTableForm: FormGroup;
  disableEditField: boolean;
  showTable: boolean;
  selectBranchForm: FormGroup;
  isAdminManager: boolean = false;

  dataSourceTable: MatTableDataSource<Table>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsTable = [
    'Name',
    'Actions'
  ];

  @ViewChild('successModal', { static: true }) successModal: TemplateRef<any>;
  @ViewChild('failureModal', { static: true }) failureModal: TemplateRef<any>;
  @ViewChild('editTable', { static: true }) editTableModal: TemplateRef<any>;
  @ViewChild('deleteTable', { static: true }) deleteTableModal: TemplateRef<any>;
  @ViewChild('createNewTable', { static: true }) createNewTableModal: TemplateRef<any>;

  // @ViewChild('selectedBranch') selectedBranch: ElementRef<any>;

  closeResult: string;

  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  tableHasData: boolean;
  categories: Category[];
  branchList: Branch[];

  branchOfTheTableToDelete: string;

  page = 1;
  isAdmin = true;


  constructor(private router: Router, private tablesService: TablesService, private fb: FormBuilder,
    private authenticationService: AuthenticationService, private modalService: NgbModal,
    private categoriesService: CategoriesService, private branchService: BranchService) {
  }

  ngOnInit(): void {
    this.spin = true;

    if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
    (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

      this.isAdmin = true;

  } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
    (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL) ||
    (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_STOCK)) {
      this.isAdmin = false;
  }


    this.createForm();
    this.getFranchise();
    this.disableEditField = true;
    this.spin = false;
    this.searchTableName.controls['table_name'].valueChanges.subscribe(val=>{
      if(val === ''){
        this.searchTable(this.branchIdToBeUsed);
      }
    });
  }

  createForm(): void {
    this.searchTableName = this.fb.group({
      branch_id: [''],
      table_name: ['']
    });
    this.tableForm = this.fb.group({
      table_name: [''],
      table_id: [''],
      addSubCategoryFormBranchID: ['']
    });
    this.viewTableForm = this.fb.group({
      table_name: [''],
      table_id: ['']
    });
    this.editTableForm = this.fb.group({
      table_name: [''],
      table_id: [''],
      editTableFormBranchID: ['']
    });
    this.selectBranchForm = this.fb.group({
      selectBranch: ['']
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getFranchise(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchList = res;


      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.branchIdToBeUsed = this.branchList[0].branch_id;
        this.selectBranchForm.controls.selectBranch.enable();
        this.isAdminManager = true;
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

        this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        this.selectBranchForm.controls.selectBranch.disable();
        this.isAdminManager = false;
      } else {
        this.isAdminManager = false;
      }
      this.selectBranchForm.controls.selectBranch.setValue(this.branchIdToBeUsed);
      this.getTables(this.branchIdToBeUsed);

    }, error => {
      this.spin = false;
    });
  }

  getBranchCategories(event): void {
    const branchId = event.target.value;
    this.branchIdToBeUsed = branchId;
    this.getTables(branchId);
  }

  searchTable(branchId): void {
    this.spin = true;
    this.tables = null;

    const name = this.searchTableName.controls.table_name.value;

    if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
      (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
      this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
    } else {
      this.branchIdToBeUsed = branchId;
    }

    this.tablesService.searchTable(this.branchIdToBeUsed, name, this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.tables = res;

      if (this.tables.length > 0) {
        this.tableHasData = true;
        this.dataSourceTable = new MatTableDataSource(this.tables);
        this.dataSourceTable.paginator = this.paginator;
        this.dataSourceTable.sort = this.sort;
      } else {
        this.tableHasData = false;
      }
      this.spin = false;
    }, err => {
      this.tableHasData = false;
      this.dataSourceTable = new MatTableDataSource([]);
      this.dataSourceTable.paginator = this.paginator;
      this.dataSourceTable.sort = this.sort;
      this.spin = false;

    });
  }

  addTableButton(branchId): void {

    this.tableForm.controls.addSubCategoryFormBranchID.setValue(branchId);
    this.open(this.createNewTableModal);
  }

  saveNewTable(): void {
    this.spin = true;
    let newTable: Table;
    newTable = {
      branch_id: Number(this.tableForm.controls.addSubCategoryFormBranchID.value),
      table_id: 0,
      table_name: this.tableForm.controls.table_name.value,
      status: 1,
      date_created: '',
      last_modified: '',
    };
    this.tablesService.saveTable(newTable).subscribe(res => {

      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Votre table a été enregistré avec succès');

        if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
          (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
          this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        }

        this.tableForm.reset();
        this.getTables(this.branchIdToBeUsed);
      } else {
        this.spin = false;
        this.showFailureModal('L\'ajout de la table a échoué, réessayez plus tard');
      }
    }, error => {

      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cette table existe déjà');
      } else {

        this.showFailureModal('L\'ajout de la table a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  viewTable(table): void {
    this.tempTable = table;
    this.viewTableForm.controls.table_name.setValue(this.tempTable.table_name);
  }

  editTable1(table): void {

    this.editTableForm.controls.editTableFormBranchID.setValue(table.branch_id);

    this.tempTable = table;
    this.editTableForm.controls.table_name.setValue(this.tempTable.table_name);

    this.open(this.editTableModal);
  }

  editTableName(): void {
    this.spin = true;
    let table = this.tables[0];
    table = {
      table_name: this.editTableForm.controls.table_name.value,
      table_id: this.tempTable.table_id,
      branch_id: this.tempTable.branch_id,
      status: 0,
      date_created: '',
      last_modified: ''
    };
    this.tablesService.updateTable(table).subscribe(res => {

      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Cette table a été modifié avec succès');

        this.getTables(table.branch_id);
      } else {
        this.spin = false;
        this.showFailureModal('La modification de la table a échoué, réessayez plus tard');
      }
    }, error => {

      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cette table existe déjà');
      } else {

        this.showFailureModal('La modification de la table a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  tempDelete(table): void {
    this.tempTable = table;
    this.tableToDelete = this.tempTable.table_name;

    this.branchOfTheTableToDelete = this.branchList.find(br => br.branch_id === table.branch_id).branch_name;

    this.open(this.deleteTableModal);
  }

  deleteTable1(): void {
    this.spin = true;
    this.tablesService.deleteTable(this.tempTable.table_id).subscribe(res => {

      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Cette table a été supprimé avec succès');

        this.getTables(this.tempTable.branch_id);
      } else {
        this.spin = false;
        this.showFailureModal('La suppression de la table a échoué, réessayez plus tard');
      }
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('La suppression de la table a échoué, réessayez plus tard');
      } else {
        this.showFailureModal('La suppression de la table a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  showCompany(): void {
    this.router.navigate(['/company']);
  }

  showBranch(): void {
    // this.router.navigate(['/branches']);
  }

  getTables(branchId): void {
    this.spin = true;

    this.tablesService.getAllTables(branchId).subscribe(res => {
      this.tables = res;

      if (this.tables.length > 0) {
        this.tableHasData = true;
        this.dataSourceTable = new MatTableDataSource(this.tables);
        this.dataSourceTable.paginator = this.paginator;
        this.dataSourceTable.sort = this.sort;
      } else {
        this.tableHasData = false;
      }
      this.spin = false;
    }, err => {
      this.tableHasData = false;
      this.dataSourceTable = new MatTableDataSource([]);
      this.dataSourceTable.paginator = this.paginator;
      this.dataSourceTable.sort = this.sort;
      this.spin = false;

    });
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event) {
    this.page = event;
    window.scroll(0,0);
  }
}
