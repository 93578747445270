export class Constants {
  static ADMIN_DASHBOARD_ROUTE = 'dashboard';
  static LOGIN_ROUTE = 'login';
  static MANAGE_PRODUCT_ROUTE = 'products';
  static EMPLOYEE_ROUTE = 'employees';
  static SUPPLIER_ROUTE = 'suppliers';
  static COMPANY_ROUTE = 'company';
  static ROUTE_BRANCH = 'branches';
  static ROUTE_TABLE = 'tables';
  static ROUTE_PROFILE = 'view-profile';
  static ROUTE_REPORT = 'report';
  static ROUTE_EXPENSE = 'expense';
  static ROUTE_FIRST_LOGIN = 'first-login';
  static ROUTE_FORGOT_PASSWORD = 'forgot-password';
  static WAREHOUSE_ROUTE = 'warehouse';
  static TRANSACTIONS_ROUTE = 'supplier-transactions';
  static OTHER_ROUTE_REPORT = 'report-with-issues';
  static ROUTE_DEBT_ORDER_REPORT = 'report-debts-orders';


  static ROLE_USER = '[USER]';
  
  // static ROLE_ADMIN_MANAGER = '';
  static ROLE_ADMIN_MASTER = 'Master Admin';
  static ROLE_ADMINISTRATOR = 'Administrator';
  static ROLE_DEPUTY_ADMINISTRATOR = 'Deputy Administrator';
  static ROLE_MANAGER_FULL = 'Manager Full';
  static ROLE_MANAGER_NORMAL = 'Manager Normal';
  static ROLE_MANAGER_WAREHOUSE = 'Manager Warehouse';
  static ROLE_MANAGER_STOCK = 'Manager Stock';
  static ROLE_BAR_PERSON = 'Bar Person';
  static ROLE_WAITER = 'Waiter';
  
  static CATEGORY_DRINK = 'Boisson';
  static CATEGORY_FOOD = 'Nourriture';
  static CATEGORY_OTHER_ITEM = 'Maison';

  static NUMBER_OF_PRODUCT_PER_MENU_PAGE = 8;

  static DEFAULT_CUSTOMER_ACCOUNT = 1;
  static DEFAULT_CUSTOMER = 1;
  
  
  static BRANCH_TYPE_RESTAURANT_BAR = 1;
  static BRANCH_TYPE_SHOP = 2;
  static BRANCH_TYPE_TRANSPORT = 1;

}
