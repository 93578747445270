<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" id="prod">Liste des employés</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
              <form [formGroup]="searchEmployeeName">
                <div class="form-group mt-4">
                  <small class="form-text text-muted">Rechercher un employé</small>
                  <div class="input-group mb-3">
                    <input type="search" class="form-control" formControlName="employee_surname"
                      placeholder="Rechercher..." aria-label="Rechercher..." aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" (click)="searchEmployee(selectedBranch.value)"
                        type="button"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
              <div class="form-group mt-4">
                <small class="form-text text-muted mt-5"></small>
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createNewCEmployee"
                  (click)="addEmployee()"><i class="fas fa-plus-square"></i> &nbsp; Ajouter un Employe
                </button>
              </div>
            </div> 
          </div>


          <div class="row mt-1 mb-3">
            <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">
             
              <div class="card mb-3 d-md-none"
                *ngFor="let item of employees | paginate: { itemsPerPage: 10, currentPage: page }">
                <div class="card-body">
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Prénom & Postnom
                    </div>
                    <div class="col-6">
                      {{item.employee_firstname}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Nom
                    </div>
                    <div class="col-6">
                      {{item.employee_surname}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Numéro
                    </div>
                    <div class="col-6">
                      {{item.cell_number}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Position
                    </div>
                    <div class="col-6">
                        {{item.role_name === 'Waiter' ? 'Serveur' : 
                        item.role_name === 'Administrator' ? 'Propriétaire' : 
                        item.role_name === 'Deputy Administrator' ? 'Manager Général' : 
                        item.role_name === 'Manager Normal' ? 'Gérant Normal' : 
                        item.role_name === 'Manager Warehouse' ? 'Gérant Dépot' :
                        item.role_name === 'Manager Full' ? 'Gérant Full (Normal + Dépot)' : 
                        item.role_name === 'Bar Person' ? 'Barman' : 'Autre'}}


                      <!-- {{item.role_name === 'Waiter' ? 'Serveur' : item.role_name === 'Manager' ? 'Gerant' : 'Manager'}} -->
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12 text-center">
                      <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"
                        (click)="viewEmployee(item)"><i class="fas fa-eye"></i></button> &nbsp;
                      <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                        (click)="tempDelete(item)"><i class="bi bi-trash"></i></button>

                    </div>
                  </div>
                </div>
              </div>
              <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-md-none"
                (pageChange)="onMobileTableChangePage($event)" previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>


              <mat-table [dataSource]="dataSourceEmployee" class="d-none d-md-block" matSort>
                <ng-container matColumnDef="Firstname">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Prenom & Postnom
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.employee_firstname}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Surname">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Nom
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.employee_surname}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cellNumber">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Numéro
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.cell_number}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Position">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Position
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.role_name === 'Waiter' ? 'Serveur' : 
                    row.role_name === 'Administrator' ? 'Propriétaire' : 
                    row.role_name === 'Deputy Administrator' ? 'Manager Général' : 
                    row.role_name === 'Manager Normal' ? 'Gérant Normal' : 
                    row.role_name === 'Manager Warehouse' ? 'Gérant Dépot' :
                    row.role_name === 'Manager Full' ? 'Gérant Full (Normal + Dépot)' : 
                    row.role_name === 'Bar Person' ? 'Barman' : 'Autre'}}
                    <!-- {{row.role_name === 'Waiter' ? 'Serveur' : row.role_name === 'Manager' ? 'Gerant' : 'Manager'}} -->
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Actions">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Actions
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"
                      (click)="viewEmployee(row)"><i class="fas fa-eye"></i></button> &nbsp;
                    <!--                    <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"-->
                    <!--                            (click)="editEmployee(row)"><i class="bi bi-pen"></i></button> &nbsp;-->
                    <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                      (click)="tempDelete(row)"><i class="bi bi-trash"></i></button>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsEmployee">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsEmployee">
                </mat-row>
              </mat-table>
              <div class="mt-3" *ngIf="tableHasData === false">Aucun employé trouvé
              </div>
              <mat-paginator class="d-none d-md-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>

            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
              <form [formGroup]="formSelectBranch">
                <div class="form-group">
                  <small class="form-text text-muted">Sélectionnez une établissement</small>
                  <select #selectedBranch class="form-control" (change)="getBranchEmployees($event)" formControlName="selectBranch">
                    <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                      {{branch.branch_name}}
                    </option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!--Delete Modal -->
        <ng-template #deleteEmployee let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation de suppression</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form [formGroup]="employeeFormDelete">
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir supprimer cet employé?</label>
                <h5>{{employeeToDelete}}</h5>
              </div>
            </form>
          </div>
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
            <button type="button" class="btn btn-primary" (click)="deleteEmployee1()">Confirmer
            </button>
          </div>
        </ng-template>

        <!----success product modal -->
        <ng-template #successModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{successModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>


        <!----failure product modal -->
        <ng-template #failureModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{failureModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>