import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Branch } from 'src/app/interface/branch';
import { DeleteProduct } from 'src/app/interface/deleteProduct';
import { Supplier } from 'src/app/interface/supplier';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ProductDTO } from '../../models/product/ProductDTO';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/common/Constants';
import { DrinkPackService } from 'src/app/services/drink-pack/drink-pack.service';
import { DrinkPack } from 'src/app/models/drinkpack/drinkpack';
import * as $ from 'jquery';
import { Warehouse } from 'src/app/models/warehouse/Warehouse';
import { WarehouseService } from 'src/app/services/warehouse/warehouse.service';
import { WarehouseProductService } from 'src/app/services/warehouseProduct/warehouse-product.service';
import { WarehouseProduct } from 'src/app/models/warehouse/WarehouseProduct';
import { WarehouseCategoryService } from 'src/app/services/warehouseCategory/warehouse-category.service';
import { WarehouseCategory } from 'src/app/models/WarehouseCategory/WarehouseCategory';
import { WarehouseSubcategory } from 'src/app/models/WarehouseSubcategory/WarehouseCategory';
import { WarehouseSubcategoryService } from 'src/app/services/warehouseSubcategory/warehouse-subcategory.service';
import { SupplierService } from 'src/app/services/supplier.service';
import { BranchService } from 'src/app/services/branch.service';
import { WarehouseProductSale } from 'src/app/models/warehouse/WarehouseProductSale';
import * as moment from 'moment';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

  products: WarehouseProduct;
  productDTO: ProductDTO;
  productDTOs: ProductDTO[];
  tempProduct: ProductDTO;
  tempWarehouseProductSale: ProductDTO;
  deleteProduct: DeleteProduct;
  subs: any[];
  num = 1;
  sideNavCategories: WarehouseCategory[];
  sideNavSubCategories: WarehouseSubcategory[];
  viewProductSubcategories: WarehouseSubcategory[];
  spin: boolean;
  branch: Branch;
  branchList: Branch[];
  wareHouseList: Warehouse[];
  warehouse: Warehouse;
  warehouseFound: Warehouse[];
  searchProducts: WarehouseProduct;
  product: boolean;
  category: boolean;
  tempProductName: string;
  tempBranchName: string;
  showTable: boolean;
  deleteWarehouseForm: FormGroup;
  deleteProductForm: FormGroup;
  productForm: FormGroup;
  viewEditproductForm: FormGroup;
  viewEditWarehouseForm: FormGroup;
  productSearchForm: FormGroup;
  editWarehouseForm: FormGroup;
  updateQuantityForm: FormGroup;
  negativeUpdateQuantityForm: FormGroup;
  warehouseProductSaleForm: FormGroup;
  selectForm: FormGroup;
  disableEditField: boolean;
  suppliers: Supplier[];
  supplier: Supplier;
  branchCurrency: string;
  addFormSubCategoryEnable = false;
  addFormWarehouseCategories: WarehouseCategory[];
  addFormWarehouseSubcategories: WarehouseSubcategory[];
  addFormBranches: Branch;
  successModalMessage: string;
  failureModalMessage: string;

  dataSourceProduct: MatTableDataSource<ProductDTO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsProduct = [
    'Nom de l\'article',
    'Prix ​​de vente',
    'Pack',
    'Fournisseur',
    'Actions',
  ];

  @ViewChild('successSaveProduct', { static: true }) successSaveProduct: TemplateRef<any>;
  @ViewChild('failureSaveProduct', { static: true }) failureSaveProduct: TemplateRef<any>;
  @ViewChild('viewProduct', { static: true }) viewProductModal: TemplateRef<any>;
  @ViewChild('deleteProduct', { static: true }) deleteProductModal: TemplateRef<any>;
  @ViewChild('saleWarehouseProduct', { static: true }) saleWarehouseProductModal: TemplateRef<any>;
  @ViewChild('createNewProduct', { static: true }) createNewProductModal: TemplateRef<any>;
  @ViewChild('updateQuantity', { static: true }) updateQuantityModal: TemplateRef<any>;
  @ViewChild('warehouseProductSale1', { static: true }) warehouseProductSaleModal: TemplateRef<any>;
  @ViewChild('ConfirmUpdateProductStock', { static: true }) ConfirmUpdateProductStockModal: TemplateRef<any>;
  @ViewChild('ConfirmWarehouseProductSale', { static: true }) ConfirmWarehouseProductSaleModal: TemplateRef<any>;
  @ViewChild('selectedWarehouse') selectedWarehouse: ElementRef<any>;
  @ViewChild('ConfirmDeductionProductStock', { static: true }) ConfirmDeductionProductStock: TemplateRef<any>;


  closeResult: string;

  viewProductFormOnLoad = true;
  viewProductFormProductID: number;
  deleteProductFormProductID: number;
  warehouseIdToBeUsed: number;

  tableHasData: boolean;

  drinkPacks: DrinkPack[];

  page = 1;
  quantityAdded: number;
  quantityToBeSoldToBranches: number;
  packType: number;
  priceToPay: number = 0;
  shouldShowWarehouseSetup: boolean = false;

  shouldShowReductionDiv: boolean = false;
  roleLoggedIn: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private warehouseService: WarehouseService,
    private warehouseProductService: WarehouseProductService, private fb: FormBuilder, private warehouseCategoryService: WarehouseCategoryService,
    private warehouseSubcategoryService: WarehouseSubcategoryService, private supplierService: SupplierService,
    private modalService: NgbModal, private drinkPackService: DrinkPackService, private branchService: BranchService) {
  }

  ngOnInit(): void {

    this.spin = true;
    this.product = true;
    this.category = false;
    this.createForm();

    if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
      (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
      this.shouldShowWarehouseSetup = true;
    } else {
      this.shouldShowWarehouseSetup = false;
    }

    this.getWarehouses();
    this.getCategoriesToAddProductForm();
    this.getAllDrinkPacks();
    this.getSupplier();
    this.getFranchise();
    this.productSearchForm.controls['searchProducName'].valueChanges.subscribe(val=>{
      if(val ===''){
        this.searchProduct(this.warehouseIdToBeUsed);
      }
    });    
    this.disableEditField = true;

    this.roleLoggedIn = this.authenticationService.currentUserValue.role;

    this.selectForm.reset();
  }

  createForm(): void {

    this.productForm = this.fb.group({
      productName: [''],
      warehouse: [''],
      barcode: [''],
      type: [''],
      cost: [],
      selling_price: [],
      subcategory_id: [''],
      supplier_id: [''],
      quantity: [''],
      category_id: [''],
      drinkPack: ['']
    });

    this.viewEditproductForm = this.fb.group({
      productName: [''],
      warehouse: [''],
      barcode: [''],
      type: [''],
      cost: [],
      selling_price: [],
      subcategory_id: [''],
      supplier_id: [''],
      quantity: [''],
      viewProductSubcategoryIdSelect: [''],
      viewProductWarehouseIdSelect: [''],
      viewProductSupplierIdSelect: [''],
      drinkPack: ['']
    });
    this.deleteProductForm = this.fb.group({
      productName: ['']
    });

    this.productSearchForm = this.fb.group({
      searchProducName: ['']
    });

    this.selectForm = this.fb.group({
      selectWarehouse: ['']
    });

    this.updateQuantityForm = this.fb.group({
      productName: [''],
      type: [''],
      currentQuantityInWarehouse: [''],
      quantity: [''],
      supplier_id:['']
    });

    this.warehouseProductSaleForm = this.fb.group({
      warehouse: [''],
      productName: [''],
      type: [''],
      currentQuantityInWarehouse: [''],
      franchise: [''],
      quantitySold: ['']
    });

    this.negativeUpdateQuantityForm = this.fb.group({
      productName: [''],
      type: [''],
      current_quantity: [''],
      quantity: [''],
      comment: ['']
    });
  }

  showReductionStock(): void {
    this.shouldShowReductionDiv = true;
  }

  changerFournisseur(): void {
    this.updateQuantityForm.controls.supplier_id.enable();
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getFranchise(): void {
    this.selectForm.reset();
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchList = res;
    }, error => {
      this.spin = false;
    });
  }

  getWarehouses(): void {
    this.selectForm.reset();
    this.warehouseService.getWarehousesByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.wareHouseList = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.warehouseIdToBeUsed = this.wareHouseList[0].warehouse_id;
        this.branchCurrency = this.wareHouseList[0].warehouse_currency;
        this.selectForm.controls.selectWarehouse.enable();
        this.productForm.controls.warehouse.enable();

      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_WAREHOUSE)) {

        this.wareHouseList = this.wareHouseList.filter(wr => wr.employee_manager_id === this.authenticationService.currentUserValue.employeeID);

        if (this.wareHouseList.length > 0) {
          this.warehouseIdToBeUsed = this.wareHouseList[0].warehouse_id;
          this.branchCurrency = this.wareHouseList[0].warehouse_currency;
        } else {
          this.warehouseIdToBeUsed = 0;
          this.branchCurrency = '';
        }
      } else {
        this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
      }

      this.selectForm.controls.selectWarehouse.setValue(this.warehouseIdToBeUsed);
      this.loadWarehouseProducts(this.warehouseIdToBeUsed);

    }, error => {
      this.spin = false;
    });
  }

  getAllDrinkPacks(): void {
    this.selectForm.reset();
    this.drinkPackService.getAllDrinkPacks().subscribe(res => {
      this.drinkPacks = res;
    }, error => {
      this.spin = false;
    });
  }

  getCategories(companyId: number): void {
    this.warehouseCategoryService.getWarehouseCategories(companyId).subscribe(res => {
      this.sideNavCategories = res;
      this.addFormWarehouseCategories = res;

    }, err => {

      if (err.status === 404) {

      } else {

      }
      this.showTable = false;
      this.spin = false;
    });
  }

  loadWarehouseProducts(warehouseId: number): void {
    this.spin = true;

    this.warehouseProductService.loadWarehouseProducts(warehouseId).subscribe(res => {
      this.productDTOs = res;

      if (this.productDTOs.length > 0) {
        this.tableHasData = true;
        this.dataSourceProduct = new MatTableDataSource(this.productDTOs);
        this.dataSourceProduct.paginator = this.paginator;
        this.dataSourceProduct.sort = this.sort;
      } else {
        this.tableHasData = false;
        this.productDTOs = [];
        this.dataSourceProduct = new MatTableDataSource([]);
        this.dataSourceProduct.paginator = this.paginator;
        this.dataSourceProduct.sort = this.sort;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.productDTOs = [];
      this.dataSourceProduct = new MatTableDataSource([]);
      this.dataSourceProduct.paginator = this.paginator;
      this.dataSourceProduct.sort = this.sort;
      this.spin = false;

    });
  }

  getSupplier(): void {
    this.supplierService.getSupplier(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.suppliers = res;
    }, error => {
      this.spin = false;
    });
  }

  getWarehouseProductByWarehouse(event): void {
    const warehouseId = event.target.value;
    this.loadWarehouseProducts(warehouseId);
  }

  getAllSubcategoriesByCompanyId(warehouseSubcategoryId): void {
    this.warehouseSubcategoryService.getAllSubcategoriesByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.viewProductSubcategories = res;

      const subcategoryName = this.viewProductSubcategories.
        find(sub => sub.warehouse_subcategory_id === warehouseSubcategoryId).warehouse_subcategory_name;
      this.viewEditproductForm.controls.subcategory_id.setValue(subcategoryName);
      this.viewEditproductForm.controls.viewProductSubcategoryIdSelect.setValue(warehouseSubcategoryId);
    }, error => {
      this.spin = false;
    });
  }

  enableEdit(): void {
    this.viewProductFormOnLoad = false;
    this.disableEditField = false;
  }

  save(): void {
    this.num += 1;
    this.subs.push(this.num);
  }

  openCreateNewProductModal(): void {
    this.productForm.reset();
    this.open(this.createNewProductModal);

    this.productForm.controls.warehouse.setValue(this.warehouseIdToBeUsed);
  }

  updateStockQuantity(product): void {
    this.updateQuantityForm.reset();

    this.updateQuantityForm.controls.supplier_id.disable();
    this.updateQuantityForm.controls.supplier_id.patchValue(product.supplier_id);

    this.open(this.updateQuantityModal);
    let totalNumberOfBottleInDrinkPack = this.drinkPacks.find(x => x.drink_pack_id === Number(product.pack_id)).drink_pack_number;

    this.packType = totalNumberOfBottleInDrinkPack;

    this.updateQuantityForm.controls.productName.setValue(product.name);
    this.updateQuantityForm.controls.type.setValue(product.type);
    this.updateQuantityForm.controls.quantity.setValue(0);
    this.updateQuantityForm.controls.currentQuantityInWarehouse.setValue(product.quantity / totalNumberOfBottleInDrinkPack);

    this.negativeUpdateQuantityForm.controls.productName.setValue(product.name);
    this.negativeUpdateQuantityForm.controls.type.setValue(product.type);
    this.negativeUpdateQuantityForm.controls.quantity.setValue(0);
    this.negativeUpdateQuantityForm.controls.current_quantity.setValue(product.quantity / totalNumberOfBottleInDrinkPack);

    this.tempProduct = product;
  }

  openConfirmWarehouseStockSale(): void {
    if (this.warehouseProductSaleForm.controls.quantitySold.value <= this.warehouseProductSaleForm.controls.currentQuantityInWarehouse.value) {
      this.quantityToBeSoldToBranches = this.warehouseProductSaleForm.controls.quantitySold.value;

      let branch = this.branchList.find(x => x.branch_id === Number(this.warehouseProductSaleForm.controls.franchise.value));

      this.tempBranchName = branch.branch_name;
      this.open(this.ConfirmWarehouseProductSaleModal);
    } else {
      this.showFailureModal('La quantité vendue ne peut pas être supérieure à la quantité actuelle');
    }
  }

  saleWarehouseProduct(product): void {
    this.warehouseProductSaleForm.reset();

    this.open(this.warehouseProductSaleModal);

    let totalNumberOfBottleInDrinkPack = this.drinkPacks.find(x => x.drink_pack_id === Number(product.pack_id)).drink_pack_number;
    let warehouseName = this.wareHouseList.find(x => x.warehouse_id === Number(this.selectForm.controls.selectWarehouse.value)).warehouse_name;

    this.packType = totalNumberOfBottleInDrinkPack;

    this.warehouseProductSaleForm.controls.productName.setValue(product.name);
    this.warehouseProductSaleForm.controls.type.setValue(product.type);
    this.warehouseProductSaleForm.controls.warehouse.setValue(warehouseName)
    this.warehouseProductSaleForm.controls.currentQuantityInWarehouse.setValue(product.quantity / totalNumberOfBottleInDrinkPack);
    this.priceToPay = 0;

    this.tempWarehouseProductSale = product;
  }

  openConfirmUpdateStock(): void {
   
    if (this.shouldShowReductionDiv){
      this.quantityAdded = this.negativeUpdateQuantityForm.controls.quantity.value;
      this.open(this.ConfirmDeductionProductStock);
    } else{
      this.quantityAdded = this.updateQuantityForm.controls.quantity.value;
      this.open(this.ConfirmUpdateProductStockModal);
    }
  }

  SaveNewStockQuantity(): void {
    if (this.shouldShowReductionDiv){
      this.saveStockQuantityDeduction();
    } else {
      if (Number(this.updateQuantityForm.controls.quantity.value) > 0) {
        this.spin = true;

        this.warehouseIdToBeUsed = this.tempProduct.branch_id;

        let newEnteredQuantity = this.updateQuantityForm.controls.quantity.value * this.packType;
        let newTotalQuantity = this.tempProduct.quantity + newEnteredQuantity;
        let supplierId = this.updateQuantityForm.controls.supplier_id.value;

        this.warehouseProductService.updateWarehouseStockQuantity(this.authenticationService.currentUserValue.employeeID, this.tempProduct.product_id,
          newEnteredQuantity, newTotalQuantity, this.warehouseIdToBeUsed,
          this.authenticationService.currentUserValue.companyID, supplierId, this.tempProduct.cost,this.tempProduct.pack_number_of_piece, '').subscribe(res => {

            if (res) {
              this.modalService.dismissAll();
              this.loadWarehouseProducts(this.warehouseIdToBeUsed);
              this.showSuccessModal('Le stock de cet article a été mis à jour avec succès');
            } else {
              this.showFailureModal('Le stock de cet article n\'a pas pu être mis à jour');
            }

            this.spin = false;
          }, error => {
            this.showFailureModal('Le stock de cet article n\'a pas pu être mis à jour');
            this.spin = false;
          });
      } else {
        this.showFailureModal('Le stock de cet article doit être supérieur à zéro');
      }
    }
  }


  saveStockQuantityDeduction(): void {

    if (Number(this.negativeUpdateQuantityForm.controls.quantity.value) < 0) {
      this.spin = true;

      this.warehouseIdToBeUsed = this.tempProduct.branch_id;

        let newEnteredQuantity = this.negativeUpdateQuantityForm.controls.quantity.value * this.packType;
        let newTotalQuantity = this.tempProduct.quantity + newEnteredQuantity;
        let supplierId = this.tempProduct.supplier_id;

      this.warehouseProductService.updateWarehouseStockQuantity(this.authenticationService.currentUserValue.employeeID, this.tempProduct.product_id,
      newEnteredQuantity, newTotalQuantity, this.warehouseIdToBeUsed,
      this.authenticationService.currentUserValue.companyID, supplierId, this.tempProduct.cost,this.tempProduct.pack_number_of_piece,
      this.negativeUpdateQuantityForm.controls.comment.value).subscribe(res => {
  
          if (res) {
            this.modalService.dismissAll();
            this.loadWarehouseProducts(this.warehouseIdToBeUsed);
            this.shouldShowReductionDiv = false;
            this.negativeUpdateQuantityForm.controls.quantity.reset();
            this.negativeUpdateQuantityForm.controls.comment.reset();

            this.showSuccessModal('Le stock de cet article a été mis à jour avec succès');
          } else {
            // this.ConfirmDeductionProductStock.
            this.showFailureModal('Le stock de cet article n\'a pas pu être mis à jour');
          }

          this.spin = false;
        }, error => {
          this.showFailureModal('Le stock de cet article n\'a pas pu être mis à jour');
          this.spin = false;
        });
    } else {
      this.showFailureModal('La quantite rétranché doit etre un montant négatif');
    }
  }

  cancelQuantityReduction(): void{
    this.negativeUpdateQuantityForm.controls.quantity.reset();
    this.shouldShowReductionDiv = false;
  }

  SaveWarehouseSale(): void {

    if (Number(this.warehouseProductSaleForm.controls.quantitySold.value) > 0) {
      this.spin = true;

      let warehouseProductToSale: WarehouseProductSale;

      warehouseProductToSale = {
        warehouse_product_sale_id: 0,
        employee_id: this.authenticationService.currentUserValue.employeeID,
        warehouse_product_id: this.tempWarehouseProductSale.product_id,
        branch_id_sold_to: Number(this.warehouseProductSaleForm.controls.franchise.value),
        total_amount: Number(this.warehouseProductSaleForm.controls.quantitySold.value) * this.tempWarehouseProductSale.selling_price,
        date_sale_placed: moment().format('YYYY-MM-DD HH:mm:ss').toString(),
        quantity: Number(this.warehouseProductSaleForm.controls.quantitySold.value) * this.tempWarehouseProductSale.pack_number_of_piece,
        warehouse_id: Number(this.selectForm.controls.selectWarehouse.value),
        company_id: this.authenticationService.currentUserValue.companyID,
        status: 1
      };

      this.warehouseProductService
        .saveWarehouseProductSale(warehouseProductToSale, (this.warehouseProductSaleForm.controls.currentQuantityInWarehouse.value * this.tempWarehouseProductSale.pack_number_of_piece))
        .subscribe(res => {
          if (res) {
            this.modalService.dismissAll();
            this.showSuccessModal('La vente de cet article a réussi avec succès');
          } else {
            this.showFailureModal('La vente de cet article n\'a pas pu être éffectué');
          }
          this.loadWarehouseProducts(Number(this.selectForm.controls.selectWarehouse.value));
        }, error => {
          this.showFailureModal('La vente de cet article n\'a pas pu être éffectué');
          this.spin = false;
        });
    } else {
      this.showFailureModal('La quantité à vendre doit être supérieur à zéro');
    }
  }

  calculatePrice() {
    let qty = Number(this.warehouseProductSaleForm.controls.quantitySold.value)
    if ((qty !== 0) || (qty !== undefined)) {
      this.priceToPay = qty * this.tempWarehouseProductSale.selling_price;
    }
    else {
      this.priceToPay = 0;
    }
  }

  view(product): void {
    this.disableEditField = true;
    this.open(this.viewProductModal);

    this.viewProductFormProductID = product.product_id;
    this.productDTO = product;

    this.viewProductFormOnLoad = true;

    let totalNumberOfBottleInDrinkPack = this.drinkPacks.find(x => x.drink_pack_id === Number(product.pack_id)).drink_pack_number;

    this.getAllSubcategoriesByCompanyId(product.subcategory_id);

    this.viewEditproductForm.controls.productName.setValue(product.name);
    this.viewEditproductForm.controls.barcode.setValue(product.barcode);
    this.viewEditproductForm.controls.type.setValue(product.type);
    this.viewEditproductForm.controls.cost.setValue(product.cost);
    this.viewEditproductForm.controls.selling_price.setValue(product.selling_price);
    this.viewEditproductForm.controls.quantity.setValue(product.quantity / totalNumberOfBottleInDrinkPack);

    this.viewEditproductForm.controls.drinkPack.setValue(product.pack_id);
    this.viewEditproductForm.controls.viewProductWarehouseIdSelect.setValue(product.branch_id);

    const viewProductSupplierName = this.suppliers.find(supplier1 => supplier1.supplier_id === product.supplier_id).supplier_name;

    this.viewEditproductForm.controls.supplier_id.setValue(viewProductSupplierName);
    this.viewEditproductForm.controls.viewProductSupplierIdSelect.patchValue(product.supplier_id);
  }

  saveEditProductNameFromViewProductDetail(): void {
    this.spin = true;

    let totalNumberOfBottleInDrinkPack = this.drinkPacks.find(x => x.drink_pack_id === Number(this.productDTO.pack_id)).drink_pack_number;

    let warehouseProduct;

    warehouseProduct = {
      warehouse_product_id: this.viewProductFormProductID,
      warehouse_barcode: this.viewEditproductForm.controls.barcode.value,
      warehouse_product_name: this.viewEditproductForm.controls.productName.value,
      warehouse_product_type: this.viewEditproductForm.controls.type.value,
      warehouse_product_buying_cost: Number(this.viewEditproductForm.controls.cost.value),
      warehouse_product_selling_cost: Number(this.viewEditproductForm.controls.selling_price.value),
      warehouse_product_pic_id: '0',
      warehouse_product_subcategory_id: Number(this.viewEditproductForm.controls.viewProductSubcategoryIdSelect.value),
      warehouse_product_supplier_id: Number(this.viewEditproductForm.controls.viewProductSupplierIdSelect.value),
      warehouse_product_quantity: Number(this.viewEditproductForm.controls.quantity.value) * totalNumberOfBottleInDrinkPack,
      warehouse_product_pack_id: Number(this.viewEditproductForm.controls.drinkPack.value),
      warehouse_product_stock_alert: 0,
      company_id: Number(this.authenticationService.currentUserValue.companyID),
      warehouse_id: Number(this.viewEditproductForm.controls.viewProductWarehouseIdSelect.value),
      status: 1
    };

    this.warehouseProductService.updateWarehouseProduct(warehouseProduct).subscribe(res => {
      if (res) {
        this.modalService.dismissAll();
        this.loadWarehouseProducts(warehouseProduct.warehouse_id);
        this.showSuccessModal('Cet article a été modifié avec succès');
      } else {
        this.showFailureModal('Cet article n\'a pas pu être mis à jour');
      }
      this.spin = false;
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cet article éxiste déjà');
      } else {
        this.showFailureModal('Cet article n\'a pas pu être mis à jour');
      }
      this.spin = false;
    });
  }

  saveNewProductToWarehouse(): void {
    this.spin = true;

    let totalNumberOfBottleInDrinkPack = this.drinkPacks.find(x => x.drink_pack_id === Number(this.productForm.controls.drinkPack.value)).drink_pack_number;

    let warehouseProduct;

    warehouseProduct = {
      warehouse_product_id: 0,
      warehouse_barcode: this.productForm.controls.barcode.value,
      warehouse_product_name: this.productForm.controls.productName.value,
      warehouse_product_type: this.productForm.controls.type.value,
      warehouse_product_buying_cost: Number(this.productForm.controls.cost.value),
      warehouse_product_selling_cost: Number(this.productForm.controls.selling_price.value),
      warehouse_product_pic_id: '0',
      warehouse_product_subcategory_id: Number(this.productForm.controls.subcategory_id.value),
      warehouse_product_supplier_id: Number(this.productForm.controls.supplier_id.value),
      warehouse_product_quantity: Number(this.productForm.controls.quantity.value) * totalNumberOfBottleInDrinkPack,
      warehouse_product_pack_id: Number(this.productForm.controls.drinkPack.value),
      warehouse_product_stock_alert: 0,
      company_id: Number(this.authenticationService.currentUserValue.companyID),
      warehouse_id: Number(this.productForm.controls.warehouse.value),
      status: 1
    };

    this.warehouseProductService.saveWarehouseProduct(warehouseProduct,
      this.authenticationService.currentUserValue.employeeID).subscribe(res => {

        if (res) {
          this.modalService.dismissAll();
          this.showSuccessModal('Votre article a été enregistré avec succès');
          this.spin = false;

          this.productForm.reset();

          this.loadWarehouseProducts(this.selectForm.controls.selectWarehouse.value);
        } else {
          this.showFailureModal('L\'ajout de l\'article a échoué, réessayez plus tard');
        }
      }, error => {
        if (error.status === 409) {
          this.modalService.dismissAll();
          this.showFailureModal('Cet article éxiste déjà');
        } else {
          this.showFailureModal('L\'ajout de cet article a échoué, réessayez plus tard');
        }
        this.spin = false;
      });
  }

  delete(warehouseProduct): void {

    this.deleteProductFormProductID = warehouseProduct.product_id;

    this.open(this.deleteProductModal);

    this.tempProduct = warehouseProduct;
    this.tempProductName = this.tempProduct.name;
    this.deleteProductForm.controls.productName.setValue(this.tempProduct.name);
  }

  yes(): void {
    this.spin = true;

    this.tempProduct.name = this.deleteProductForm.controls.productName.value;

    this.warehouseProductService.deleteProduct(this.tempProduct).subscribe(res => {
      if (res) {
        this.modalService.dismissAll();
        this.loadWarehouseProducts(this.warehouseIdToBeUsed);
        this.showSuccessModal('Votre article a été supprimé avec succès');
      } else {
        this.showFailureModal('Votre article n\'a pas pu être supprimé');
      }

    }, error => {
      this.showFailureModal('Votre article n\'a pas pu être supprimé');
      this.spin = false;
    });
  }

  showProduct(): void {
    this.product = true;
    this.category = false;

   }

  onMatTableChangePage(event: any) {
    window.scroll(0, 0);
  }

  onMobileTableChangePage(event: any) {
    this.page = event;
    window.scroll(0, 0);
  }

  addFormWarehouseSubcategoriesChange(event): void {
    const id = event.target.value;
    this.addFormSubCategoryEnable = true;

    this.warehouseSubcategoryService.getAllSubcategoriesByCategory(id).subscribe(res => {
      this.addFormWarehouseSubcategories = res;
    }, error => {
      this.spin = false;
    }
    );
  }

  getCategoriesToAddProductForm(): void {
    this.warehouseCategoryService.getWarehouseCategories(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.addFormWarehouseCategories = res;
    });
  }

  searchProduct(warehouseId): void {
    this.spin = true;

    const name = this.productSearchForm.controls.searchProducName.value;
    this.warehouseProductService.searchProduct(warehouseId, name).subscribe(res => {
      this.productDTOs = res;

      if (this.productDTOs.length > 0) {
        this.tableHasData = true;
        this.dataSourceProduct = new MatTableDataSource(this.productDTOs);
        this.dataSourceProduct.paginator = this.paginator;
        this.dataSourceProduct.sort = this.sort;
      } else {
        this.tableHasData = false;
        this.dataSourceProduct = new MatTableDataSource([]);
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceProduct = new MatTableDataSource([]);
      this.spin = false;

    });
  }

  validate(): void {

    const forms = document.getElementsByClassName('needs-validation');

    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  showSuccessModal(message: string): void {
    this.open(this.successSaveProduct);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureSaveProduct);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
