import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/components/login/login.component';
import { BranchesComponent } from './components/branches/branches.component';
import { CategoryMenuComponent } from './components/category-menu/category-menu.component';
import { CategoryComponent } from './components/category/category.component';
import { CompanyComponent } from './components/company/company.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EmployeeChangePasswordComponent } from './components/employee-change-password/employee-change-password.component';
import { EmployeeLandingComponent } from './components/employee-landing/employee-landing.component';
import { EmployeesComponent } from './components/employees/employees.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ProductsComponent } from './components/products/products.component';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { AuthGuardService } from './services/authentication/auth-guard.service';
import { ReportComponent } from './components/report/report.component';
import { TablesComponent } from './components/tables/tables.component';
import { TestComponent } from './components/test/test.component';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';
import { FirstLoginComponent } from './components/first-login/first-login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ExpenseComponent } from './components/expense/expense.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { SupplierHistoryComponent } from './components/supplier-history/supplier-history.component';
import { ReportWithIssuesComponent } from './components/report-with-issues/report-with-issues.component';
import { BranchMenuComponent } from './components/branch-menu/branch-menu.component';
import { QrCodeGenerateComponent } from './components/qr-code-generate/qr-code-generate.component';
import { ReportDebtsOrderComponent } from './components/report-debts-order/report-debts-order.component';

const routes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuardService] },
  { path: 'branches', component: BranchesComponent, canActivate: [AuthGuardService] },
  { path: 'company', component: CompanyComponent, canActivate: [AuthGuardService] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'category', component: CategoryComponent, canActivate: [AuthGuardService] },
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuardService] },
  { path: 'addemployees/:mode', component: EmployeesComponent, canActivate: [AuthGuardService] },
  { path: 'suppliers', component: SuppliersComponent, canActivate: [AuthGuardService] },
  { path: 'employees', component: EmployeeLandingComponent, canActivate: [AuthGuardService] },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuardService] },
  { path: 'report-with-issues', component: ReportWithIssuesComponent, canActivate: [AuthGuardService] },
  { path: 'report-debts-orders', component: ReportDebtsOrderComponent, canActivate: [AuthGuardService] },
  { path: 'expense', component: ExpenseComponent, canActivate: [AuthGuardService] },
  { path: 'warehouse', component: WarehouseComponent, canActivate: [AuthGuardService] },
  { path: 'supplier-transactions/:id', component: SupplierHistoryComponent, canActivate: [AuthGuardService] },
  { path: 'view-profile', component: ViewProfileComponent, canActivate: [AuthGuardService] },
  { path: 'first-login', component: FirstLoginComponent, canActivate: [AuthGuardService] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'employee-change-password', component: EmployeeChangePasswordComponent, canActivate: [AuthGuardService] },
  { path: 'tables', component: TablesComponent, canActivate: [AuthGuardService] },
  { path: 'menu/:branchId', component: BranchMenuComponent },
  { path: 'qr-code-generate', component: QrCodeGenerateComponent },
  { path: 'test', component: TestComponent },
  {
    path: 'inmenu', component: CategoryMenuComponent,
    children: [
      {
        path: ':name', component: CategoryMenuComponent, canActivate: [AuthGuardService]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
