<div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">

        <div class="container-fluid">
            <div class="panel mt-5">

                <div class="row mt-4">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">Majorité des commandes faite</h6>
                          <p class="card-text">{{waiterNameWithMostSale}}</p>
                          <a href="#" class="card-link">{{waiterNameWithMostSaleAmount}} ventes</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">Montant le plus élevé entré</h6>
                          <p class="card-text">{{waiterNameWithMostRevenue}}</p>
                          <!-- <a href="#" class="card-link">{{currencyDefault}} {{waiterNameWithMostRevenueAmount | number}}</a> -->
                          <a href="#" class="card-link">{{workingCurrency}} {{waiterNameWithMostRevenueAmount | number}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">Nombre le plus bas des commandes faites</h6>
                          <p class="card-text">{{waiterNameWithLeastSale}}</p>
                          <a href="#" class="card-link">{{waiterNameWithLeastSaleAmount}} ventes</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">Montant le plus bas entré</h6>
                          <p class="card-text">{{waiterNameWithLeastRevenue}}</p>
                          <a href="#" class="card-link">{{workingCurrency}} {{waiterNameWithLeastRevenueAmount | number}}</a>
                          <!-- <a href="#" class="card-link">{{currencyDefault}} {{waiterNameWithLeastRevenueAmount | number}}</a> -->
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="searchForm" class="mt-5">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="input-group mb-3">
                              <input type="search" class="form-control" formControlName="searchEmployee"
                                placeholder="Chercher..." aria-label="Search..." aria-describedby="basic-addon2">
                              <div class="input-group-append">
                                <button class="btn btn-outline-primary"
                                  (click)="searchEmployeePerformanceReport(selectedBranch.value)" type="button"><i
                                    class="fas fa-search"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
      
                  <form [formGroup]="filterForm">
                    <div class="row mt-4">
                      <div
                        class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">
      
                        <div class="card mb-3 d-md-none"
                          *ngFor="let item of employeePerformanceReports | paginate: { itemsPerPage: 10, currentPage: employeePerformancePage, id: 'employeePerformance' }">
                          <div *ngIf="tableHasDataEmployeePerformance === true" class="card-body">
                            <div class="row mb-2">
                              <div class="col-6 card-table-header">
                                Employé
                              </div>
                              <div class="col-6">
                                {{item.employee_firstname}}
                                {{item.employee_surname}}
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-6 card-table-header">
                                Établissement
                              </div>
                              <div class="col-6">
                                {{item.branch_name}}
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-6 card-table-header">
                                Montant total entré
                              </div>
                              <div class="col-6">
                                <!-- {{item.currency_one}} -->
                                {{workingCurrency}}
                                {{item.total_amount_brought_in | number}}
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-6 card-table-header">
                                Quantité de vente
                              </div>
                              <div class="col-6">
                                {{item.numberOfSales}}
                              </div>
                            </div>
      
                          </div>
                        </div>
                        <pagination-controls *ngIf="tableHasDataEmployeePerformance === true"
                          class="mb-3 text-center d-md-none" (pageChange)="onMobileTableChangePage($event)" id="employeePerformance" previousLabel="Précédent"
                          nextLabel="Suivant"></pagination-controls>
      
      
      
                        <mat-table [dataSource]="dataSourceEmployeePerformance" class="d-none d-md-block" matSort>
                          <ng-container matColumnDef="Name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                              Employé
                            </mat-header-cell>
                            <mat-cell *matCellDef="let report">
                              {{report.employee_firstname}}
                              {{report.employee_surname}}
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="Branch">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                              Établissement
                            </mat-header-cell>
                            <mat-cell *matCellDef="let report">
                              {{report.branch_name}}
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="Total Amount Brought In">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                              Montant total entré
                            </mat-header-cell>
                            <mat-cell *matCellDef="let report">
                              {{workingCurrency}}
                              <!-- {{report.currency_one}} -->
                              {{report.total_amount_brought_in | number}}
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="Number of Sale">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                              Quantité de vente
                            </mat-header-cell>
                            <mat-cell *matCellDef="let report">
                              {{report.numberOfSales}}
                            </mat-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="displayedColumns">
                          </mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedColumns">
                          </mat-row>
                        </mat-table>
                        <div class="mt-3" *ngIf="tableHasDataEmployeePerformance === false">Aucune performance trouvé
                        </div>
                        <mat-paginator #paginatorEmployeePerformanceReport class="d-none d-md-block mb-4" [pageSize]="10"
                          [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      
                      </div>
                      <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="form-group">
                              <select #selectedBranch class="form-control"
                                (change)="loadEmployeePerformance($event.target.value)" formControlName="selectBranch">
                                <option value="0">
                                  Tous les établissement
                                </option>
                                <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                                  {{branch.branch_name}}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <small class="form-text text-muted">Datant de</small>
                            <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtEmployeePerformanceFrom"
                              [owlDateTime]="dtEmployeePerformanceFrom" name="dtEmployeePerformanceFrom"
                              formControlName="dtEmployeePerformanceFrom">
                            <owl-date-time #dtEmployeePerformanceFrom></owl-date-time>
      
                          </div>
      
                          <div class="col-12">
                            <small class="form-text text-muted">à</small>
                            <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtEmployeePerformanceTo"
                              [owlDateTime]="dtEmployeePerformanceTo" name="dtEmployeePerformanceTo"
                              formControlName="dtEmployeePerformanceTo">
                            <owl-date-time #dtEmployeePerformanceTo></owl-date-time>
      
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-md-12">
                            <button type="button" class="btn btn-primary w-100"
                              (click)="filterEmployeePerformance(selectedBranch.value)">
                              <i class="fas fa-filter"></i> &nbsp;Filtre
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                <!----success product modal -->
                <ng-template #successModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{successModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>


                <!----failure product modal -->
                <ng-template #failureModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{failureModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>