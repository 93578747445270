<div class="d-flex" id="wrapper">
    <app-side-navigation class="side-bar"></app-side-navigation>
    <div id="page-content-wrapper">
        <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
            <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
        </nav>

        <div class="container-fluid">
            <div class="panel mt-5">

                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" id="cat">Dépenses</a>
                    </li>
                </ul>

                <div class="tab-content">
                    <div class="row mt-4">
                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">Dépense total</h6>
                                    <p class="card-text"> {{currencyDefault}} {{totalExpense}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
                            <form [formGroup]="searchExpenseName">
                                <div class="form-group mt-4">
                                    <small class="form-text text-muted">Rechercher une dépenses</small>
                                    <div class="input-group mb-3">
                                        <input type="search" class="form-control" formControlName="expense_description"
                                            placeholder="Rechercher..." aria-label="Search..."
                                            aria-describedby="basic-addon2">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-primary"
                                                (click)="searchExpense(selectBranch.value)" type="button">
                                                <i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
                            <div class="form-group mt-4">
                                <small class="form-text text-muted mt-5"></small>
                                <button type="button" class="btn btn-primary"
                                    (click)="addExpenseButton(selectBranch.value)"><i class="fas fa-plus-square"></i>
                                    Ajouter une nouvelle dépenses
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-1 mb-3">
                        <div
                            class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                            <div class="card mb-3 d-md-none"
                                *ngFor="let item of expenses | paginate: { itemsPerPage: 10, currentPage: page }">
                                <div class="card-body">
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Montant dépensé (FC)
                                        </div>
                                        <div class="col-6">
                                            {{item.amount}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Description de la dépense
                                        </div>
                                        <div class="col-6">
                                            {{item.expense_description}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Date de la dépense
                                        </div>
                                        <div class="col-6">
                                            {{item.expense_date | date:'dd-MM-yyyy'}}
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-danger" data-toggle="modal"
                                                data-target="#deleteExpense" (click)="tempDelete(item)"><i
                                                    class="bi bi-trash"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-md-none"
                                (pageChange)="onMobileTableChangePage($event)" previousLabel="Précédent" nextLabel="Suivant">
                            </pagination-controls>


                            <mat-table [dataSource]="dataSourceExpense" class="d-none d-md-block" matSort>
                                <ng-container matColumnDef="ExpenseAmount">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Montant dépensé (FC)
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.amount}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ExpenseDescription">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Description de la dépense
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.expense_description}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ExpenseDate">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Date de la dépense
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.expense_date | date:'dd-MM-yyyy'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Actions">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Actions
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <!--                    <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"-->
                                        <!--                            (click)="view(row)"><i class="fas fa-eye"></i></button> &nbsp;-->
                                        <!-- <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                                            (click)="editTable1(row)"><i class="bi bi-pen"></i></button> &nbsp; -->
                                        <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                                            (click)="tempDelete(row)"><i class="bi bi-trash"></i></button>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumnsTable">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsTable">
                                </mat-row>
                            </mat-table>
                            <div class="mt-3" *ngIf="tableHasData === false">Aucune dépense trouvé
                            </div>
                            <mat-paginator class="d-none d-md-block" [pageSize]="10"
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>

                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">

                            <form [formGroup]="selectBranchForm">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <small class="form-text text-muted">Sélectionnez une établissement</small>
                                            <select #selectBranch class="form-control"
                                                (change)="getBranchCategories($event)" formControlName="selectBranch">
                                                <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                                                    {{branch.branch_name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <small class="form-text text-muted">Sélectionnez le type de dépense</small>
                                            <select #selectExpense class="form-control" (change)="getExpensesByExpenseType($event)"
                                             formControlName="expense_type">
                                                <option value="0">
                                                    Toute les dépenses
                                                </option>
                                                <option *ngFor="let expenseType of expenseTypeList"
                                                    value={{expenseType.expense_type_id}}>
                                                    {{expenseType.expense_type_name}}</option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <small class="form-text text-muted">Datant de</small>
                                        <input class="form-control" [readonly]="true"
                                            [owlDateTimeTrigger]="dtExpenseFrom" [owlDateTime]="dtExpenseFrom"
                                            name="dtExpenseFrom" formControlName="dtExpenseFrom">
                                        <owl-date-time #dtExpenseFrom></owl-date-time>
                                    </div>

                                    <div class="col-12">
                                        <small class="form-text text-muted">à</small>
                                        <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtExpenseTo"
                                            [owlDateTime]="dtExpenseTo" name="dtExpenseTo"
                                            formControlName="dtExpenseTo">
                                        <owl-date-time #dtExpenseTo></owl-date-time>
                                    </div>
                                </div>
                            </form>
                            <div class="row my-3">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-primary w-100"
                                        (click)="filterExpense(selectBranch.value)">
                                        <i class="fas fa-filter"></i> &nbsp;Filtre
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!---Add Modal-->
                    <ng-template #createNewExpense let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title">Ajouter une nouvelle dépense</h5>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="addExpenseForm">
                                <div class="form-group">
                                    <small class="form-text text-muted">
                                        Établissement
                                    </small>
                                    <select class="form-control" [attr.disabled]="true"
                                        formControlName="addSubCategoryFormBranchID" required>
                                        <option value="" disabled selected>Établissement</option>
                                        <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                                            {{branch.branch_name}}</option>
                                    </select>
                                </div>
                                <!-- <div class="form-group">
                                        <small class="form-text text-muted">Sélectionnez le type de dépense</small>
                                        <select #selectExpense class="form-control" (change)="getExpenseType($event)"
                                            formControlName="expense_type">
                                            <option *ngFor="let expenseType of expenseTypeList"
                                                value={{expenseType.expense_type_id}}>
                                                {{expenseType.expense_type_name}}</option>
                                        </select>
                                    </div> -->
                                <div class="form-group">
                                    <small class="form-text text-muted">Sélectionnez le type de dépense</small>
                                    <select #selectExpense class="form-control" formControlName="expense_type" required>
                                        <option value="" disabled selected>Selectionnez le type de dépense</option>
                                        <option *ngFor="let expenseType of expenseTypeList"
                                            value={{expenseType.expense_type_id}}>
                                            {{expenseType.expense_type_name}}</option>
                                    </select>
                                    <div *ngIf="addExpenseForm.controls['expense_type'].invalid &&
                                    (addExpenseForm.controls['expense_type'].dirty ||
                                    addExpenseForm.controls['expense_type'].touched)" class="alert alert-light pt-0">
                                        <div *ngIf="addExpenseForm.controls['expense_type'].errors.required">
                                            Ne devrait pas être vide
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <small class="form-text text-muted">Description de la dépense</small>
                                    <input type="text" class="form-control" formControlName="expense_description"
                                        placeholder="Description de la dépense" required>
                                    <div *ngIf="addExpenseForm.controls['expense_description'].invalid &&
                                                                      (addExpenseForm.controls['expense_description'].dirty ||
                                                                      addExpenseForm.controls['expense_description'].touched)"
                                        class="alert alert-light pt-0">
                                        <div *ngIf="addExpenseForm.controls['expense_description'].errors.required">
                                            Ne devrait pas être vide
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <small class="form-text text-muted">Montant de la dépense (FC)</small>
                                    <input type="number" class="form-control" formControlName="amount"
                                        placeholder="Montant de la dépense" required>
                                    <div *ngIf="addExpenseForm.controls['amount'].invalid &&
                                                                      (addExpenseForm.controls['amount'].dirty ||
                                                                      addExpenseForm.controls['amount'].touched)"
                                        class="alert alert-light pt-0">
                                        <div *ngIf="addExpenseForm.controls['amount'].errors.required">
                                            Ne devrait pas être vide
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <small class="form-text text-muted">Date de la dépense</small>
                                    <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="expense_date"
                                        [owlDateTime]="expense_date" name="expense_date" formControlName="expense_date"
                                        required>
                                    <owl-date-time [pickerType]="'calendar'" #expense_date></owl-date-time>
                                    <div *ngIf="addExpenseForm.controls['expense_date'].invalid &&
                                                                      (addExpenseForm.controls['expense_date'].dirty ||
                                                                      addExpenseForm.controls['expense_date'].touched)"
                                        class="alert alert-light pt-0">
                                        <div *ngIf="addExpenseForm.controls['expense_date'].errors.required">
                                            Ne devrait pas être vide
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
                            <button type="button" class="btn btn-primary btn-lg" [disabled]="!addExpenseForm.valid"
                                (click)="saveNewExpense()">Sauvegarder</button>
                        </div>
                    </ng-template>

                    <!--Edit modal-->
                    <!-- <ng-template #editTable let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title">Modification des détails de la table</h5>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="editTableForm">
                                <div class="form-group">
                                    <small class="form-text text-muted">
                                        Établissement
                                    </small>
                                    <select class="form-control" [attr.disabled]="true"
                                        formControlName="editTableFormBranchID" required>
                                        <option value="" disabled selected>Établissement</option>
                                        <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                                            {{branch.branch_name}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <small class="form-text text-muted">Nom de la table</small>
                                    <input type="text" class="form-control" formControlName="table_name">
                                    <div *ngIf="editTableForm.controls['table_name'].invalid &&
                                                                      (editTableForm.controls['table_name'].dirty ||
                                                                      editTableForm.controls['table_name'].touched)"
                                        class="alert alert-light pt-0">
                                        <div *ngIf="editTableForm.controls['table_name'].errors.required">
                                            Ne devrait pas être vide
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
                            <button type="button" class="btn btn-primary btn-lg"
                                (click)='editTableName()'>Sauvegarder</button>
                        </div>
                    </ng-template> -->

                    <!--Delete Modal -->
                    <ng-template #deleteExpense let-modal>
                        <div class="modal-header text-center">
                            <h5 class="modal-title">Confirmation de suppression</h5>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <div class="form-group">
                                <label>Êtes-vous sûr de vouloir supprimer cette dépense ?</label>
                                <h5>{{expenseToDelete}}</h5>
                                <label class="mt-3">de l'établissement <h6>{{branchOfTheExpenseToDelete}}</h6> </label>
                            </div>
                        </div>
                        <div class="modal-footer text-center">
                            <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
                            <button type="button" class="btn btn-primary" (click)="deleteExpense1()">Confirmer</button>
                        </div>
                    </ng-template>

                    <!----success modal -->
                    <ng-template #successModal let-modal>
                        <div class="modal-header text-center border-0">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                (click)="modal.dismiss()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body py-0 text-center">
                            <div class="row">
                                <div class="col-12">
                                    <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                                </div>
                            </div>
                            <label class="mt-3"> {{successModalMessage}} </label>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                        </div>
                    </ng-template>


                    <!----failure product modal -->
                    <ng-template #failureModal let-modal>
                        <div class="modal-header text-center border-0">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                (click)="modal.dismiss()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body py-0 text-center">
                            <div class="row">
                                <div class="col-12">
                                    <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                                </div>
                            </div>
                            <label class="mt-3"> {{failureModalMessage}} </label>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>