import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {Role} from '../interface/roles';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  
  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {
  }

  getRoles(): Observable<Role []> {
    return this.http.post<Role []>(this.apiURL + '/role/getall', null);
  }
}
