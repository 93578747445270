<!-- <div class="d-flex pagado-menu" id="wrapper">
  <div class="container-fluid">
    <div class="row menu-logo text-center">
      <div class="col-md-12">
        <img id="logo" src="assets/branch/prestigeLogo.png" width="350px" alt="logo">
      </div>
    </div>
    <div class="row menu-title">
      <div class="col-md-12 text-center">
        <h1 class="pagado-menu-title">Menu</h1>
      </div>
    </div>
    <div class="row mt-4">
      <div *ngFor="let subcategory of subcategoriesDrinks" class="col-md-6 mb-5 text-center">
        <div class="row menu-subcategory mb-3">
          <div class="offset-md-4 col-md-4">
            <h4>{{subcategory.sub_name}}</h4>
          </div>
        </div>
        <div *ngFor="let product of subcategory.products" class="row">
          <div class="offset-md-1 col-md-5 col-sm-6 col-6 text-left">
            <p>{{product.name}}</p>
          </div>
          <div class="offset-md-2 col-md-4 col-sm-6 col-6 text-xl-center text-lg-center text-md-center text-sm-right text-right">
            <p>{{product.selling_price}} FC / {{product.selling_price / 2000}} USD</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->









<div class="d-flex pagado-menu" id="wrapper">
  <div class="container-fluid">
    <!-- <div class="row menu-logo text-center">
      <div class="col-md-12">
        <img id="logo" src="assets/branch/prestigeLogo.png" width="350px" alt="logo">
      </div>
    </div> -->
    <!-- <img src="assets/branch/prestigeBackgroundMenu.jpg" alt="Snow" style="width:100%;"> -->

    <!-- <div class="row menu-title">
      <div class="col-md-12 text-center">
        <h1 class="pagado-menu-title">Menu</h1>
      </div>
    </div> -->
    <div class="row mt-4">
      <!-- DRINK EMENU -->
      <div *ngFor="let subcategory of subcategoriesDrinksToDisplay" class="col-xl-12 col-lg-12 col-md-12 mb-5 text-center">
        <!-- <img src="assets/branch/prestige_background2.jpg" alt="Snow" style="width:100%;"> -->
        <img src="{{companySetting.company_menu_background_url !== null ? companySetting.company_menu_background_url : 'assets/branch/29_default_background_menu2.jpeg'}}"  alt="BackgroundImage" style="width:100%;">
        
        <div class="menu-detail pagado-emenu-mt">
          <div class="row">
            <div class="col-xl-11 col-lg-12 col-md-12" *ngIf="companySetting.show_company_name === 1">
              <h1 id="companyNameMenu" class="company-name-menu company-name-menu-dynamic">{{printCompanyName}}</h1>
            </div>
            <div class="col-xl-11 col-lg-12 col-md-12" *ngIf="companySetting.show_branch_name === 1">
              <h4 class="branch-name-menu branch-name-menu-dynamic">{{printBranchName}}</h4>
            </div>
          </div>
          
          <div class="row menu-subcategory mb-3">
            <div class="offset-xl-3 col-xl-4 offset-lg-4 col-lg-4 offset-md-4 col-md-4">
              <h1 id="subcategoryNameMenu" class="subcategory-name-menu subcategory-name-menu-dynamic">{{subcategory.sub_name}}</h1>
            </div>
          </div>
          <div *ngFor="let product of subcategory.products" class="row">
            <div class="offset-xl-2 col-xl-4 offset-lg-2 col-lg-4 offset-md-2 col-md-4 col-sm-6 offset-3 col-3 text-xl-left text-lg-left text-md-left text-left">
              <p class="product-name-menu product-name-menu-dynamic">{{product.name}}</p>
            </div>
            <div *ngIf="companySetting.branch_menu_special_currency === null" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-xl-center text-lg-center text-md-center text-sm-right text-left">
              <p class="product-name-menu product-name-menu-dynamic">{{product.selling_price}} FC / {{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} USD</p>
            </div>
            <div *ngIf="companySetting.branch_menu_special_currency !== null" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-xl-center text-lg-center text-md-center text-sm-right text-left">
              <p class="product-name-menu product-name-menu-dynamic" *ngIf="(product.selling_price / companySetting.daily_rate) < 30">{{product.selling_price}} FC / {{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} USD</p>
              <p class="product-name-menu product-name-menu-dynamic" *ngIf="(product.selling_price / companySetting.daily_rate) >= 30">{{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} {{companySetting.branch_menu_special_currency}}</p>
            </div>
          </div>
        </div>

        <hr>
      </div>

      <!-- OTHER PRODUCT EMENU -->
      <div *ngFor="let subcategory of subcategoriesOtherToDisplay" class="col-xl-12 col-lg-12 col-md-12 mb-5 text-center">
        <img src="{{companySetting.company_menu_background_url !== null ? companySetting.company_menu_background_url : 'assets/branch/29_default_background_menu2.jpeg'}}"  alt="BackgroundImage" style="width:100%;">
        
        <div class="menu-detail pagado-emenu-mt">
          <div class="row">
            <div class="col-xl-11 col-lg-12 col-md-12" *ngIf="companySetting.show_company_name === 1">
              <h1 id="companyNameMenu" class="company-name-menu company-name-menu-dynamic">{{printCompanyName}}</h1>
            </div>
            <div class="col-xl-11 col-lg-12 col-md-12" *ngIf="companySetting.show_branch_name === 1">
              <h4 class="branch-name-menu branch-name-menu-dynamic">{{printBranchName}}</h4>
            </div>
          </div>
          
          <div class="row menu-subcategory mb-3">
            <div class="offset-xl-3 col-xl-4 offset-lg-4 col-lg-4 offset-md-4 col-md-4">
              <h1 id="subcategoryNameMenu" class="subcategory-name-menu subcategory-name-menu-dynamic">{{subcategory.sub_name}}</h1>
            </div>
          </div>
          <div *ngFor="let product of subcategory.products" class="row">
            <div class="offset-xl-2 col-xl-4 offset-lg-2 col-lg-4 offset-md-2 col-md-4 col-sm-6 offset-3 col-3 text-xl-left text-lg-left text-md-left text-left">
              <p class="product-name-menu product-name-menu-dynamic">{{product.name}}</p>
            </div>
            <div *ngIf="companySetting.branch_menu_special_currency === null" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-xl-center text-lg-center text-md-center text-sm-right text-left">
              <p class="product-name-menu product-name-menu-dynamic">{{product.selling_price}} FC / {{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} USD</p>
            </div>
            <div *ngIf="companySetting.branch_menu_special_currency !== null" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-xl-center text-lg-center text-md-center text-sm-right text-left">
              <p class="product-name-menu product-name-menu-dynamic" *ngIf="(product.selling_price / companySetting.daily_rate) < 30">{{product.selling_price}} FC / {{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} USD</p>
              <p class="product-name-menu product-name-menu-dynamic" *ngIf="(product.selling_price / companySetting.daily_rate) >= 30">{{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} {{companySetting.branch_menu_special_currency}}</p>
            </div>
          </div>
        </div>

        <hr>
      </div>

      <!-- FOOD EMENU -->
      <div *ngFor="let subcategory of subcategoriesFoodToDisplay" class="col-xl-12 col-lg-12 col-md-12 mb-5 text-center">
        <img src="{{companySetting.company_menu_background_url !== null ? companySetting.company_menu_background_url : 'assets/branch/29_default_background_menu2.jpeg'}}"  alt="BackgroundImage" style="width:100%;">
        
        <div class="menu-detail pagado-emenu-mt">
          <div class="row">
            <div class="col-xl-11 col-lg-12 col-md-12" *ngIf="companySetting.show_company_name === 1">
              <h1 id="companyNameMenu" class="company-name-menu company-name-menu-dynamic">{{printCompanyName}}</h1>
            </div>
            <div class="col-xl-11 col-lg-12 col-md-12" *ngIf="companySetting.show_branch_name === 1">
              <h4 class="branch-name-menu branch-name-menu-dynamic">{{printBranchName}}</h4>
            </div>
          </div>
          
          <div class="row menu-subcategory mb-3">
            <div class="offset-xl-3 col-xl-4 offset-lg-4 col-lg-4 offset-md-4 col-md-4">
              <h1 id="subcategoryNameMenu" class="subcategory-name-menu subcategory-name-menu-dynamic">{{subcategory.sub_name}}</h1>
            </div>
          </div>
          <div *ngFor="let product of subcategory.products" class="row">
            <div class="offset-xl-2 col-xl-4 offset-lg-2 col-lg-4 offset-md-2 col-md-4 col-sm-6 offset-3 col-3 text-xl-left text-lg-left text-md-left text-left">
              <p class="product-name-menu product-name-menu-dynamic">{{product.name}}</p>
            </div>
            <div *ngIf="companySetting.branch_menu_special_currency === null" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-xl-center text-lg-center text-md-center text-sm-right text-left">
              <p class="product-name-menu product-name-menu-dynamic">{{product.selling_price}} FC / {{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} USD</p>
            </div>
            <div *ngIf="companySetting.branch_menu_special_currency !== null" class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-xl-center text-lg-center text-md-center text-sm-right text-left">
              <p class="product-name-menu product-name-menu-dynamic" *ngIf="(product.selling_price / companySetting.daily_rate) < 30">{{product.selling_price}} FC / {{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} USD</p>
              <p class="product-name-menu product-name-menu-dynamic" *ngIf="(product.selling_price / companySetting.daily_rate) >= 30">{{product.selling_price / companySetting.daily_rate | number : '1.2-2'}} {{companySetting.branch_menu_special_currency}}</p>
            </div>
          </div>
        </div>

        <hr>
      </div>
    </div>
  </div>
</div>


<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>