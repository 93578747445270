import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import { PastOrderReport } from 'src/app/models/order/PastOrderReport';
import * as $ from 'jquery';
import { OrderItem } from 'src/app/models/order-items';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { Employee } from 'src/app/interface/createEmployee';
import { EmployeeService } from 'src/app/services/employee.service';
import { FixDebtDTO } from 'src/app/models/order/FixDebtDTO.model';
import { DebtsOrderDTO } from 'src/app/models/order/DebtsOrderDTO.model';


@Component({
  selector: 'app-report-debts-order',
  templateUrl: './report-debts-order.component.html',
  styleUrls: ['./report-debts-order.component.scss']
})
export class ReportDebtsOrderComponent implements OnInit {

  filterFormDebtsdOrders: FormGroup;
  debtsFixForm: FormGroup;

  dataSource: MatTableDataSource<DebtsOrderDTO>;

  @ViewChild('paginatorDebtOrderReport') paginatorDebtOrderReport: MatPaginator;
  @ViewChild('sortCancelledDebtReport') sortCancelledDebtReport: MatSort;

  tableHasDataDebtOrderReport: boolean;

  displayedColumnsDebtOrderReport = [
    'OrderID',
    'Date & Time',
    'Waiter',
    'TotalAmount',
    'AmountPayed',
    'Remainder',
    'Customer',
    'Action'
  ];

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  debtsOrderReports: DebtsOrderDTO[]; 

  branches: Branch[];
  employeeWaiters: Employee[];

  startDateDebtsOrderReport: string;
  endDateDebtOrderReport: string;

  @Input() id: string;

  pageDebtsOrderReport = 1;

  orderItems: OrderItem[];
  closeResult: string;

  selectedOrderIDToFix: number;
  remainderToBePaid: number;
  remainderToBePaidWithCurrency: string;
  selectedCustomerOrderIDToFix: number;
  orderRate: number;
  selectedAmountAlreadyPaidForOrderToFix: number;
  selectedAmountAlreadyPaidFCForOrderToFix: number;
  selectedAmountAlreadyPaidUSDForOrderToFix: number;
  selectedBranchIDForOrderToFix: number;
  
  @ViewChild('fixingOrderDebt', { static: true }) fixingOrderDebt: TemplateRef<any>;
  @ViewChild('failureModal', { static: true }) failureModal: TemplateRef<any>;
  @ViewChild('successModal', { static: true }) successModal: TemplateRef<any>;

  successModalMessage: string;
  failureModalMessage: string;

  isWaiterDropdownEnabled: boolean;
  currencyDefault: string;
  fixDebtDTO: FixDebtDTO;

  isMoreDetailEnabled: boolean = false;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private employeeService: EmployeeService,
    private formBuilder: FormBuilder) {

    this.debtsOrderReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.spin = true;

      this.loadBranches();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadDebtsOrders(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadDebtsOrders(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterFormDebtsdOrders.controls['dtDebtOrdersFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormDebtsdOrders.controls['dtDebtOrdersTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    } else {
      this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
    }
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  createForms() {

    this.filterFormDebtsdOrders = this.formBuilder.group({
      dtDebtOrdersFrom: new FormControl(null, Validators.required),
      dtDebtOrdersTo: new FormControl(null, Validators.required),
      dpBranch: new FormControl(null),
    });

    this.debtsFixForm = this.formBuilder.group({
      amountFC: new FormControl(0, Validators.required),
      amountUSD: new FormControl(0, Validators.required),
    });
  }

  showMoreDetail(){
    this.isMoreDetailEnabled = true;
  }

  hideMoreDetail(){
    this.isMoreDetailEnabled = false;
  }

  loadBranches() {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.filterFormDebtsdOrders.controls.dpBranch.enable();
        this.filterFormDebtsdOrders.controls.dpBranch.setValue(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

        this.filterFormDebtsdOrders.controls.dpBranch.disable();
        this.filterFormDebtsdOrders.controls.dpBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  loadAllWaitersInBranch(branchId: number) {
    this.spin = true;
    this.employeeService.getAllWaiters(this.authenticationService.currentUserValue.companyID, branchId).subscribe(res => {
      this.employeeWaiters = res;
      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  openConfirmationModal(orderID: number, remainder: number, customerID: number, orderRate: number, amountAlreadyPaid: number,
    amountAlreadyPaidFC: number, amountAlreadyPaidUSD: number, branchID: number) {
    this.selectedOrderIDToFix = orderID;
    this.selectedCustomerOrderIDToFix = customerID;
    this.selectedAmountAlreadyPaidForOrderToFix = amountAlreadyPaid;
    this.selectedAmountAlreadyPaidFCForOrderToFix = amountAlreadyPaidFC;
    this.selectedAmountAlreadyPaidUSDForOrderToFix = amountAlreadyPaidUSD;
    this.selectedBranchIDForOrderToFix = branchID;
    this.orderRate = orderRate;
   
    this.remainderToBePaid = remainder * (-1);
    this.remainderToBePaidWithCurrency = this.remainderToBePaid + "FC";
    this.open(this.fixingOrderDebt);
  }

  fixingPayment(isMoreDetailShowing: boolean) {
    this.spin = true;
    
    this.fixDebtDTO = new FixDebtDTO();

    this.fixDebtDTO.customer_id = this.selectedCustomerOrderIDToFix;
      this.fixDebtDTO.customer_account_id = Constants.DEFAULT_CUSTOMER_ACCOUNT;
      this.fixDebtDTO.order_id = this.selectedOrderIDToFix;
      this.fixDebtDTO.branch_id = this.selectedBranchIDForOrderToFix;
      this.fixDebtDTO.company_id = this.authenticationService.currentUserValue.companyID;

    if (isMoreDetailShowing){
      let amountPaidInFC: number = (this.debtsFixForm.controls['amountUSD'].value * this.orderRate) + this.debtsFixForm.controls['amountFC'].value;

      this.fixDebtDTO.amount_paid_by_customer = amountPaidInFC + this.selectedAmountAlreadyPaidForOrderToFix;
      this.fixDebtDTO.amount_paid_by_customer_currency_one = this.debtsFixForm.controls['amountFC'].value + this.selectedAmountAlreadyPaidFCForOrderToFix
      this.fixDebtDTO.amount_paid_by_customer_currency_two = this.debtsFixForm.controls['amountUSD'].value + this.selectedAmountAlreadyPaidUSDForOrderToFix;
      this.fixDebtDTO.change_returned_to_customer = Number(this.remainderToBePaid) - amountPaidInFC;
      
    } else{
      
      this.fixDebtDTO.amount_paid_by_customer = Number(this.remainderToBePaid) + this.selectedAmountAlreadyPaidForOrderToFix;
      this.fixDebtDTO.amount_paid_by_customer_currency_one = Number(this.remainderToBePaid) + this.selectedAmountAlreadyPaidFCForOrderToFix
      this.fixDebtDTO.amount_paid_by_customer_currency_two = 0;
      this.fixDebtDTO.change_returned_to_customer = 0;
    }
    

    

    this.orderService.fixDebtsOrders(this.fixDebtDTO).subscribe(res => {
      this.spin = false;

      if (res) {
        this.debtsFixForm.reset();
        this.modalService.dismissAll();
        this.loadDebtsOrders(this.fixDebtDTO.branch_id);
        this.showSuccessModal('Cette commande a été regularisé avec succès');
      } else {
        this.showFailureModal('Cette commande n\'a pas pu etre regularisé');
      }

    }, error => {
      this.spin = false;
    });
  }

  loadAllDebtsOrders(branchId: number, fromDate: string, toDate: string) {
    this.spin = true;

    this.orderService.getDebtsOrdersReport(this.authenticationService.currentUserValue.companyID,
      0, branchId, fromDate, toDate).subscribe(res => {
        this.debtsOrderReports = res;

        if (this.debtsOrderReports.length > 0) {
          this.tableHasDataDebtOrderReport = true;
          this.dataSource = new MatTableDataSource(this.debtsOrderReports);
          this.dataSource.paginator = this.paginatorDebtOrderReport;
          this.dataSource.sort = this.sortCancelledDebtReport;

          this.currencyDefault = this.debtsOrderReports[0].currency_one;
        } else {
          this.tableHasDataDebtOrderReport = false;
        }
        this.spin = false;

      }, error => {
        this.tableHasDataDebtOrderReport = false;
        this.dataSource = new MatTableDataSource([]);
        this.dataSource.paginator = this.paginatorDebtOrderReport;
        this.dataSource.sort = this.sortCancelledDebtReport;
        this.spin = false;
      });

  }

  filterPastOrders(branchIdPastOrders) {
    this.startDateDebtsOrderReport = moment(this.filterFormDebtsdOrders.controls['dtDebtOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateDebtOrderReport = moment(this.filterFormDebtsdOrders.controls['dtDebtOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    // let employeeID_ = this.filterFormDebtsdOrders.controls.dpWaiter.value;

    // if ((employeeID_ !== 0) && (employeeID_ !== null)) {
    //   this.loadAllDebtsOrders(employeeID_);
    // } else {
    this.loadAllDebtsOrders(branchIdPastOrders, this.startDateDebtsOrderReport, this.endDateDebtOrderReport);
    // }
  }

  loadDebtsOrders(branchIdPastOrders) {

    var date = new Date();

    if (this.filterFormDebtsdOrders.controls['dtDebtOrdersFrom'].value !== null) {
      this.startDateDebtsOrderReport = moment(this.filterFormDebtsdOrders.controls['dtDebtOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateDebtOrderReport = moment(this.filterFormDebtsdOrders.controls['dtDebtOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateDebtsOrderReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateDebtOrderReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadAllDebtsOrders(branchIdPastOrders, this.startDateDebtsOrderReport, this.endDateDebtOrderReport);
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event: any) {
    this.pageDebtsOrderReport = event;
    window.scroll(0, 0);
  }
}
