import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Branch } from 'src/app/interface/branch';
import {Company} from 'src/app/models/company/company';
import { BranchService } from 'src/app/services/branch.service';
import { CompanyService } from 'src/app/services/company.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  spin: boolean;
  searchCompanyName: FormGroup;
  companies: Company;
  branches: Branch;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  companyForm: FormGroup;
  viewCompanyForm: FormGroup;
  editCompanyForm: FormGroup;
  tempCompany: Company;
  companyToDelete: any;
  companyFormDelete: FormGroup;
  disableEditField: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private branchService: BranchService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.spin = true;
    this.createForm();

    this.getCompanies();
  }
  createForm(): void {
    this.searchCompanyName = this.fb.group({
      company_name: [''],
      company_id: [''],
      branch_currency: [''],
    });
    this.companyForm = this.fb.group({
      company_name: [''],
      company_registration: [''],
      company_tax_number: [''],
      company_phone_number: [''],
      company_email: [''],
      company_address: [''],
      branch_currency: [''],
    });
    this.viewCompanyForm = this.fb.group({
      company_name: [''],
      company_registration: [''],
      company_tax_number: [''],
      company_phone_number: [''],
      company_email: [''],
      company_address: [''],
      branch_currency: [''],
    });
    this.editCompanyForm = this.fb.group({
      company_name: [''],
      company_registration: [''],
      company_tax_number: [''],
      company_phone_number: [''],
      company_email: [''],
      company_address: [''],
      branch_currency: [''],
    });
  }
  tableSettings(): void {
    this.dtOptions = {
      language: {
        paginate: {
          next: `<i class="fas fa-arrow-right"></i>`,
          previous: `<i class="fas fa-arrow-left"></i>`,
          last: '>>',
          first: '<<'
        }
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      ordering: false,
      searching: false,
      lengthChange: false
    };
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  searchCompany(): void {
    this.spin = true;
    this.companies = null;
    let table = $('#example').DataTable();
    table.destroy();

    const name = this.searchCompanyName.controls.company_name.value;
    this.companyService.searchCompany(name).subscribe(res => {
      this.companies = res;
      $.getJSON(null, () => {
        table.destroy();
        $('#example').empty(); // empty in case the columns change
        table = $('#example').DataTable({
          data: this.companies as unknown as Branch[]
        });
      });
      $('#example').DataTable({
        language: {
          paginate: {
            next: `<i class="fas fa-arrow-right"></i>`,
            previous: `<i class="fas fa-arrow-left"></i>`,
            last: '>>',
            first: '<<'
          }
        },
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        ordering: false,
        searching: false,
        lengthChange: false
      });
      this.dtTrigger.next();
      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  getCompanies(): void {
    this.spin = true;
    let table = $('#example').DataTable();
    table.destroy();

    this.companyService.getCompany().subscribe(res => {
      this.companies = res;
      $.getJSON(null, () => {
        table.destroy();
        $('#example').empty(); // empty in case the columns change
        table = $('#example').DataTable({
          data: this.companies as unknown as Company[]
        });
      });

      $('#example').DataTable({
        language: {
          paginate: {
            next: `<i class="fas fa-arrow-right"></i>`,
            previous: `<i class="fas fa-arrow-left"></i>`,
            last: '>>',
            first: '<<'
          }
        },
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        ordering: false,
        searching: false,
        lengthChange: false
      });
      this.dtTrigger.next();
      this.spin = false;
    }, error => {
      this.spin = false;
    });

  }

  saveNewCompany(): void {
    let newCompany: Company;
    newCompany = {
      company_id: 0,
      company_name: this.companyForm.controls.company_name.value,
      company_registration: this.companyForm.controls.company_registration.value,
      company_tax_number: this.companyForm.controls.company_tax_number.value,
      company_phone_number: this.companyForm.controls.company_phone_number.value,
      company_email: this.companyForm.controls.company_email.value,
      company_address: this.companyForm.controls.company_address.value,
      // branch_currency: this.companyForm.controls.branch_currency.value,
      company_address_suburb: '',
      company_address_postal_code: '',
      company_address_city: '',
      company_address_province: '',
      company_address_country: '',
      is_company_details_saved: 0,
      twentynine_status: 0,
      date_created: '',
      last_modified: '',
    };
    this.spin = true;
    this.companyService.saveCompany(newCompany).subscribe(res => {

      this.getCompanies();
      // document.getElementById('createNewCompany').click();
      // this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  viewCompany(company): void {
    this.tempCompany = company;

    this.viewCompanyForm.controls.company_name.setValue(this.tempCompany.company_name);
    this.viewCompanyForm.controls.company_registration.setValue(this.tempCompany.company_registration);
    this.viewCompanyForm.controls.company_tax_number.setValue(this.tempCompany.company_tax_number);
    this.viewCompanyForm.controls.company_phone_number.setValue(this.tempCompany.company_phone_number);
    this.viewCompanyForm.controls.company_email.setValue(this.tempCompany.company_email);
    this.viewCompanyForm.controls.company_address.setValue(this.tempCompany.company_address);
    // this.viewCompanyForm.controls.branch_currency.setValue(this.tempCompany.branch_currency);
  }

  viewCompanyName(): void {
    let company = this.companies;
    company = {
      company_name: this.tempCompany.company_name,
      company_registration: this.tempCompany.company_registration,
      company_tax_number: this.tempCompany.company_tax_number,
      company_phone_number: this.tempCompany.company_phone_number,
      company_email: this.tempCompany.company_email,
      company_address: this.tempCompany.company_address,
      // branch_currency: this.tempCompany.branch_currency,
      company_id: 0,
      company_address_suburb: '',
      company_address_postal_code: '',
      company_address_city: '',
      company_address_province: '',
      company_address_country: '',
      is_company_details_saved: 0,
      twentynine_status: 0,
      date_created: '',
      last_modified: '',
    };
    this.companyService.getCompany().subscribe(res => {
      this.getCompanies();
      // document.getElementById('viewCompany').click();
    }, error => {
      this.spin = false;
    });
  }

  editCompany(company): void {
    this.tempCompany = company;

    this.editCompanyForm.controls.company_name.setValue(this.tempCompany.company_name);
    this.editCompanyForm.controls.company_registration.setValue(this.tempCompany.company_registration);
    this.editCompanyForm.controls.company_tax_number.setValue(this.tempCompany.company_tax_number);
    this.editCompanyForm.controls.company_phone_number.setValue(this.tempCompany.company_phone_number);
    this.editCompanyForm.controls.company_email.setValue(this.tempCompany.company_email);
    this.editCompanyForm.controls.company_address.setValue(this.tempCompany.company_address);
  }

  editCompanyName(): void {
    let company = this.companies;
    company = {
      company_name: this.editCompanyForm.controls.company_name.value,
      company_registration: this.editCompanyForm.controls.company_registration.value,
      company_tax_number: this.editCompanyForm.controls.company_tax_number.value,
      company_phone_number: this.editCompanyForm.controls.company_phone_number.value,
      company_email: this.editCompanyForm.controls.company_email.value,
      company_address: this.editCompanyForm.controls.company_address.value,
      company_id: this.tempCompany.company_id,
      company_address_suburb: 'add',
      company_address_postal_code: '',
      company_address_city: '',
      company_address_province: '',
      company_address_country: '',
      is_company_details_saved: 0,
      twentynine_status: 0,
      date_created: '',
      last_modified: '',
    };

    this.companyService.updateCompany(company).subscribe(res => {
      this.getCompanies();
      // document.getElementById('editCompany').click();
      // document.getElementById('viewProduct').click();
    }, error => {
      this.spin = false;
    });
  }
  tempDelete(company): void {
    this.tempCompany = company;
    this.companyToDelete = this.tempCompany.company_name;
  }

  deleteCompany(): void {
    this.companyService.deleteCompany(this.tempCompany.company_id).subscribe(res => {
      this.getCompanies();
      document.getElementById('deleteCompany').click();
    }, error => {
      this.spin = false;
    });
  }

  showBranch(): void {
    this.router.navigate(['/branches']);
  }
  showTables(): void {
    this.router.navigate(['/tables']);
  }
}
