import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Subscription} from 'rxjs';
import { Constants } from 'src/app/common/Constants';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  currentUserSubscription: Subscription;
  currentUser: UserLoggedIn;

  constructor(private router: Router,
              private authService: AuthenticationService) {
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
    if (this.authService.isUserLoggedIn()) {

      if (route.data.roles && route.data.roles.indexOf(this.currentUser.role) === -1) {
        //this.authService.logOut(this.currentUser.staffID.toString());
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem('access-token');
        this.router.navigate([Constants.LOGIN_ROUTE]);
        
        return false;
      }
      return true;
    }

    this.router.navigate([Constants.LOGIN_ROUTE]);
    return false;
  }
}
