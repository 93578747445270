import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Constants} from 'src/app/common/Constants';
import {AuthenticationService} from 'src/app/services/authentication/authentication.service';
import {FirstTimeUser} from '../../models/user/first-time-user';
import {EmployeeQuestionAnswer} from '../../models/user/employee-question-answer';
import {RecoveryQuestion} from '../../models/user/recovery-question';
import {EmployeeService} from '../../services/employee.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.scss']
})
export class FirstLoginComponent implements OnInit {
  firstLoginChangePasswordForm: FormGroup;
  isFirstPartCompleted: boolean;
  spin: boolean;

  firstTimeUser: FirstTimeUser;
  employeeQuestionOne: EmployeeQuestionAnswer;
  employeeQuestionTwo: EmployeeQuestionAnswer;
  employeeQuestions: Array<EmployeeQuestionAnswer>;

  recoveryQuestions: RecoveryQuestion [];

  isOnlineMsg;

  @ViewChild('successSaveProduct', {static: true}) successSaveProduct: TemplateRef<any>;
  @ViewChild('failureSaveProduct', {static: true}) failureSaveProduct: TemplateRef<any>;

  closeResult: string;
  successModalMessage: string;
  failureModalMessage: string;

  validationMessage = {
    username: [{type: 'required', message: 'Ne devrait pas être vide'}],
    password: [{type: 'required', message: 'Ne devrait pas être vide'}],
    password2: [{type: 'required', message: 'Ne devrait pas être vide'}],
    questionOne: [{type: 'required', message: 'Ne devrait pas être vide'}],
    answerOne: [{type: 'required', message: 'Ne devrait pas être vide'}],
    questionTwo: [{type: 'required', message: 'Ne devrait pas être vide'}],
    answerTwo: [{type: 'required', message: 'Ne devrait pas être vide'}]
  };

  constructor(private router: Router, private employeeService: EmployeeService, private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.createForm();
    this.getAllQuestions();
    this.firstLoginChangePasswordForm.controls.username.setValue(this.authenticationService.currentUserValue.staffID);
  }

  createForm(): void {
    this.firstLoginChangePasswordForm = this.formBuilder.group({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      password2: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      questionOne: new FormControl(null, [Validators.required]),
      answerOne: new FormControl(null, [Validators.required]),
      questionTwo: new FormControl(null, [Validators.required]),
      answerTwo: new FormControl(null, [Validators.required])
    });
  }

  savePassword(): void {

    for (const index in this.firstLoginChangePasswordForm.controls) {
      if (this.firstLoginChangePasswordForm.controls[index]) {
        this.firstLoginChangePasswordForm.controls[index].markAsTouched();
      }
    }

    if (this.firstLoginChangePasswordForm.valid) {

      if (this.firstLoginChangePasswordForm.controls.password.value === this.firstLoginChangePasswordForm.controls.password2.value) {

        this.spin = true;

        this.employeeQuestionOne = {
          question_id: Number(this.firstLoginChangePasswordForm.controls.questionOne.value),
          answer: this.firstLoginChangePasswordForm.controls.answerOne.value,
        };

        this.employeeQuestionTwo = {
          question_id: Number(this.firstLoginChangePasswordForm.controls.questionTwo.value),
          answer: this.firstLoginChangePasswordForm.controls.answerTwo.value,
        };

        this.employeeQuestions = [];
        this.employeeQuestions.push(this.employeeQuestionOne);
        this.employeeQuestions.push(this.employeeQuestionTwo);

        this.firstTimeUser = {
          employee_id: this.authenticationService.currentUserValue.employeeID,
          password: this.firstLoginChangePasswordForm.controls.password2.value,
          employee_questions: this.employeeQuestions
        };
        
        this.employeeService.setFirstLogin(this.firstTimeUser).subscribe(response => {
            if (response) {
              this.showSuccessModal('Vos details ont été sauvegardé être avec succès');
              this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
            } else {
              this.showFailureModal('Vos details n\'ont pas pu être sauvegardé');
            }

            this.spin = false;
          },
          error => {
            this.showFailureModal('Vos details n\'ont pas pu être sauvegardé');
            this.spin = false;
          });

      } else {
        this.spin = false;
        this.isOnlineMsg = 'Les mots de passe devraient être identique';
      }

    }
  }

  getAllQuestions(): void {
     this.employeeService.getAllQuestionRecovery().subscribe(res => {
      this.recoveryQuestions = res;
      this.recoveryQuestions = this.recoveryQuestions.filter(qst => qst.question !== 'None');
    }, error => {
      this.spin = false;
    });
  }

  cancel(): void {
    this.authenticationService.logOut();
    // this.router.navigate([Constants.LOGIN_ROUTE]);
  }

  showSuccessModal(message: string): void {
    this.open(this.successSaveProduct);
    this.successModalMessage = message;
    // document.getElementById('showSuccesMessageAddProduct').click();
  }

  showFailureModal(message: string): void {
    this.open(this.failureSaveProduct);
    this.failureModalMessage = message;
    // document.getElementById('showFailureMessageAddProduct').click();
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
