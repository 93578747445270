import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../models/company/company';
import { UpdateCompany } from '../interface/updateCompany';
import { CompanyDetailToDisplay } from '../models/company/companyDetailToDisplay';
import { CompanySetting } from '../models/company/companySetting';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  
  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) { }

  getCompany(): Observable<Company>{
    return this.http.post<Company>(this.apiURL + '/company/getallcompanies', null);
  }

  getCompanySettingByBranch(branchId: number): Observable<CompanySetting>{
    return this.http.post<CompanySetting>(this.apiURL + '/company/getCompanySettingByBranch/' +  branchId , null)
  }

  getPublicCompanyDetailByBranchID(branchId: number): Observable<CompanyDetailToDisplay>{
    return this.http.post<CompanyDetailToDisplay>(this.apiURL + '/company/getDisplayCompanyDetail/' +  branchId , null)
  }

  getCompanyByID(companyID): Observable<Company>{
    return this.http.post<Company>(this.apiURL + '/company/getone?companyID=' +  companyID , null)
  }

  searchCompany(companyName): Observable<Company>{
    return this.http.post<Company>(this.apiURL + '/company/searchcompanybyname?companyName=' +  companyName , null)
  }
  saveCompany(body): Observable<Company> {
    return this.http.post<Company>(this.apiURL + '/company/save', body);
  }
  updateCompany(body): Observable<UpdateCompany> {
    return this.http.post<UpdateCompany>(this.apiURL + '/company/update', body);
  }
  deleteCompany(companyid): Observable<Company> {
    return this.http.post<Company>(this.apiURL + '/company/delete?companyID=' + companyid, null);
  }
}