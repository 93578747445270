import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { DataManagerService } from '../data-management/data-manager.service';
import { Constants } from 'src/app/common/Constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {
  constructor(private router: Router, private authenticationService: AuthenticationService, private httpClient: HttpClient, private dataManagerService: DataManagerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        this.router.navigate([Constants.LOGIN_ROUTE]);
      }
      // else if (err.status === 500) {
      //   this.dataManagerService.setHttpStatusCode(err.status);
      //   this.dataManagerService.setHttpError(err.error);
      // } else if (err.status === 400) {
      //   this.dataManagerService.setHttpStatusCode(err.status);
      // } else if (err.status === 409) {
      //   this.dataManagerService.setHttpStatusCode(err.status);
      // } else if (err.status === 423) {
      //   this.dataManagerService.setHttpError(err.error);
      // } else if (err.status === 406) {
      //   this.dataManagerService.setHttpStatusCode(err.status);
      // } else if (err.status === 404) {
      //   // console.log('err ' + JSON.stringify(err))
      //   this.dataManagerService.setHttpError(err.error);
      // }


      const error = err || err.error;
      return throwError(error);
    }));
  }
}
