import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Branch} from 'src/app/interface/branch';
import {Employee} from 'src/app/interface/createEmployee';
import {Role} from 'src/app/interface/roles';
import {CategoriesService} from 'src/app/services/categories.service';
import {EmployeeService} from 'src/app/services/employee.service';
import {FlowService} from 'src/app/services/flow.service';
import {RoleService} from 'src/app/services/role.service';
import {Constants} from '../../common/Constants';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {
  employeeForm: FormGroup;
  employees: Employee;
  spin: boolean;
  employee: boolean;
  branches: Branch[];
  allRoles: Role [];
  roles: Role [];

  successModalMessage: string;
  failureModalMessage: string;

  branch_type: number = 1;
  branchIdToBeUsed: number;

  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;
  @ViewChild('failureModal', {static: true}) failureModal: TemplateRef<any>;

  @ViewChild('selectedBranch') selectedBranch: ElementRef<any>;

  closeResult: string;

  constructor(private httpClient: HttpClient, private fb: FormBuilder, private employeeService: EmployeeService,
              private router: Router, private categoriesService: CategoriesService, private roleService: RoleService,
              private flowService: FlowService, private route: ActivatedRoute,
              private authenticationService: AuthenticationService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.spin = true;
    this.createForm();

    this.getAllRoles();
    this.getFranchise();

    var date = new Date();
    this.employeeForm.controls['date_hired'].setValue(new Date(date.getFullYear(), date.getMonth(), 1));
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getFranchise(): void {
    if((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) || (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.employeeForm.controls['branch_id'].disable();
      this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
      this.employeeForm.controls.branch_id.setValue(this.branchIdToBeUsed);
    } 

    this.categoriesService.getBranch(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.branchIdToBeUsed === 0) || (this.branchIdToBeUsed === undefined)){
        this.branch_type = this.branches[0].branch_type;
      } else{
        this.branch_type = this.branches.find(b => b.branch_id === this.branchIdToBeUsed).branch_type;
      }

      this.branch_type = this.branches.find(b => b.branch_id === this.branchIdToBeUsed).branch_type;
    }, error => {
      this.spin = false;
    });
  }

  changeBranch(event): void {
    const branchId = event.target.value;

    this.branch_type = this.branches.find(b => b.branch_id === Number(branchId)).branch_type;

  }

  createForm(): void {
    this.employeeForm = this.fb.group({
      staff_id: [''],
      employee_id: [''],
      employee_id_number: [''],
      employee_firstname: ['', Validators.required],
      employee_surname: [''],
      gender: ['', Validators.required],
      date_of_birth: [''],
      cell_number: ['', Validators.required],
      email: [''],
      address: [''],
      tax_number: [''],
      date_hired: [''],
      branch_id: ['', Validators.required],
      role_id: ['', Validators.required],
    });
  }

  getAllRoles(): void {
    this.roleService.getRoles().subscribe(res => {
      this.allRoles = res;

      this.roles = this.allRoles.filter(role1 => (role1.role_name !== Constants.ROLE_ADMINISTRATOR) && 
      (role1.role_name !== Constants.ROLE_DEPUTY_ADMINISTRATOR) && (role1.role_name !== Constants.ROLE_ADMIN_MASTER));

      console.log(this.roles);
      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  Cancel(): void {
    this.router.navigate(['/employees']);
    this.employeeForm.reset();
  }

  saveNewEmployee(): void {

    this.validate();
    this.spin = true;
    let newEmployee: Employee;

    newEmployee = {
      employee_id: 0,
      staff_id: this.employeeForm.controls.staff_id.value,
      employee_id_number: '',
      employee_firstname: this.employeeForm.controls.employee_firstname.value,
      employee_surname: this.employeeForm.controls.employee_surname.value,
      gender: this.employeeForm.controls.gender.value,
      date_of_birth: '1995-11-29',
      cell_number: this.employeeForm.controls.cell_number.value,
      email: this.employeeForm.controls.email.value,
      password: '',
      salt: '',
      address: this.employeeForm.controls.address.value,
      tax_number: '',
      date_hired: moment(this.employeeForm.controls['date_hired'].value).format('YYYY-MM-DD').toString(),
      branch_id: Number(this.employeeForm.controls.branch_id.value),
      role_id: Number(this.employeeForm.controls.role_id.value),
      role_name: '',
      company_id: Number(this.authenticationService.currentUserValue.companyID),
      is_user_first_login: 1,
      is_user_logged_in: 0,
      is_synced: 1,
      status: 1,
      date_created: '',
      last_modified: '',
      retry_answer: 0,
      question_id: 0,
      secret_answer: '',
      retry_attempts: 0
    };

    this.employeeService.saveEmployee(newEmployee).subscribe(res => {
      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('L\'employé a été enregistré avec succès');

        this.router.navigate(['/employees']);
      } else {
        this.showFailureModal('L\'ajout de l\'employé a échoué, réessayez plus tard');
      }
      this.spin = false;
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('L\'employé éxiste déjà');
      } else {
        this.showFailureModal('L\'ajout de l\'employé a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }


  validate(): void {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
