<div class="d-flex" id="wrapper">
  <div id="page-content-wrapper">

    <div class="container-fluid">
      <div class="panel mt-5">

        <div class="row mt-5 mb-3">
          <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">
            <div class="card mb-3 d-lg-none"
              *ngFor="let item of productDTOs | paginate: { itemsPerPage: 10, currentPage: pageLowStockProductReport, id: 'lowStockProductReport' }">
              <div class="card-body">
                <div class="row mb-2">
                  <div class="col-6 card-table-header">
                    Nom de l'article
                  </div>
                  <div class="col-6">
                    {{item.name}} {{item.type}}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6 card-table-header">
                    Quantité
                  </div>
                  <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                    <div *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity > item.pack_number_of_piece)">
                      {{(item.quantity % item.pack_number_of_piece) !== 0 ? (item.quantity /
                      item.pack_number_of_piece).toString()
                      .substring(0, (item.quantity / item.pack_number_of_piece).toString().indexOf('.')) :
                      (item.quantity / item.pack_number_of_piece) }}
                      casiers de {{item.drink_pack_name}} et {{item.quantity % item.pack_number_of_piece}}
                      bouteilles
                    </div>
                    <div *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity === item.pack_number_of_piece)"> 1 casier
                      de {{item.drink_pack_name}}
                    </div>
                    <div *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity < item.pack_number_of_piece)"> 0 casier et
                      {{item.quantity}} bouteilles 
                    </div>
                    <div *ngIf="item.pack_number_of_piece === 1">
                      {{item.quantity}} bouteilles
                    </div>
                  </div>
                  <div class="col-6" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                    {{item.quantity}} shot(s)
                  </div>
                  <div class="col-6" *ngIf="item.category_name === 'Nourriture'">
                    {{item.quantity}} plat(s)
                  </div>
                  <div class="col-6"
                    *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                    {{item.quantity}}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6 card-table-header">
                    Alerte de stock
                  </div>
                  <div class="col-6">
                    {{item.stock_alert}}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6 card-table-header">
                    Fournisseur
                  </div>
                  <div class="col-6">
                    {{item.supplier_name}}
                  </div>
                </div>
                <!-- <div class="row mt-4">
                              <div class="col-12 text-center">
                                <button class="btn btn-primary tn-bg-green-color" data-toggle="modal"
                                  data-target="#updateQuantity" (click)="updateStockQuantity(item)"><i
                                    class="fas fa-sort-numeric-up"></i></button> &nbsp;
                                <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"
                                  (click)="view(item)"><i class="fas fa-eye"></i></button> &nbsp;
                                <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                                  (click)="edit(item)"><i class="bi bi-pen"></i></button> &nbsp;
                                <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                                  (click)="delete(item)"><i class="bi bi-trash"></i></button>
      
                              </div>
                            </div> -->
              </div>
            </div>
            <pagination-controls *ngIf="tableHasLowStockProductReport === true" class="mb-3 text-center d-lg-none"
              (pageChange)="onMobileTableChangePage($event)" id="lowStockProductReport" previousLabel="Précédent"
              nextLabel="Suivant">
            </pagination-controls>



            <mat-table [dataSource]="dataSourceLowStockProductReport" class="d-none d-lg-block" matSort>
              <ng-container matColumnDef="Nom de l'article">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Nom de l'article
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.name}} {{row.type}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Quantity">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Quantité
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name !== 'Shot')">
                    <div *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity > row.pack_number_of_piece)">
                      {{(row.quantity % row.pack_number_of_piece) !== 0 ? (row.quantity /
                        row.pack_number_of_piece).toString()
                        .substring(0, (row.quantity / row.pack_number_of_piece).toString().indexOf('.')) : (row.quantity
                        / row.pack_number_of_piece) }}
                        casiers de {{row.drink_pack_name}} et {{row.quantity % row.pack_number_of_piece}}
                        bouteilles
                    </div>
                    <div *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity === row.pack_number_of_piece)"> 1 casier
                      de {{row.drink_pack_name}}
                    </div>
                    <div *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity < row.pack_number_of_piece)"> 0 casier et
                      {{row.quantity}} bouteilles 
                    </div>
                    <div *ngIf="row.pack_number_of_piece === 1">
                      {{row.quantity}} bouteilles
                    </div>
                  </div>
                  <div *ngIf="(row.category_name === 'Boisson') && (row.subcategory_name === 'Shot')">
                    {{row.quantity}} shot(s)
                  </div>
                  <div *ngIf="row.category_name === 'Nourriture'">
                    {{row.quantity}} plat(s)
                  </div>
                  <div
                    *ngIf="(row.category_name !== 'Nourriture') && (row.category_name !== 'Boisson')">
                    {{row.quantity}}
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="StockAlert">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Alerte de stock
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.stock_alert}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Fournisseur">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Fournisseur
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.supplier_name}}
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumnsLowStoctProduct">
              </mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsLowStoctProduct">
              </mat-row>
            </mat-table>
            <div class="mt-3" *ngIf="tableHasLowStockProductReport === false">Aucun article trouvé
            </div>
            <mat-paginator class="d-none d-lg-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

          </div>

          <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
            <form [formGroup]="selectBranchForLowStockProductForm">
              <div class="form-group">
                <small class="form-text text-muted">Sélectionnez un établissement</small>
                <select #selectedBranch class="form-control" (change)="loadLowStockProduct($event.target.value)"
                  formControlName="selectBranchLowStock">
                  <option value="0" selected>Tous les établissement</option>
                  <option *ngFor="let branch of branchListForLowStockProducts" value={{branch.branch_id}}>
                    {{branch.branch_name}}
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div>

        <!----success product modal -->
        <ng-template #successModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{successModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>


        <!----failure product modal -->
        <ng-template #failureModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{failureModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>