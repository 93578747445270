<!-- Printing div: Stock Entry Update -->
<div style="display: none;" id="print-section-update-stock">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card px-2">
          <div class="card-body">

            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-6 pl-0">
                <h6 class="mt-5 mb-2"><b>{{printCompanyName}} - {{printEstablishment}}</b></h6>
                <p>{{printCompanyAddress}},
                  <br>{{printCompanyCity}},
                  <br>{{printCompanyCountry}}.
                </p>
              </div>
              <div class="col-lg-6 pl-0 text-right">
                <p class="mb-0 mt-5">Date d'impression : {{printDate}}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h6 class="ml-4">Rapport de mis a jour d'article: Entrée</h6>
            </div>
          </div>

          <div class="container-fluid justify-content-center w-100 table-print mt-3">
            <div class="tn-table-container">
              <table class="table">
                  <tr class="text-secondary">
                    <th class="text-center">Article</th>
                    <th class="text-center">Ancienne Quantité</th>
                    <th class="text-center">Quantité ajouté</th>
                    <th class="text-center">Quantité actuelle</th>
                    <th class="text-center">Date de mise à jour</th>
                  </tr>
                <tbody>
                  <tr *ngFor="let item of stockUpdateReports" class="text-right">
                    <td class="text-center"> {{item.product_name}}</td>
                    <td class="text-center">{{item.old_quantity}} bouteilles</td>
                    <td class="text-center">{{item.quantity - item.old_quantity}} bouteilles</td>
                    <td class="text-center">{{item.quantity}} bouteilles</td>
                    <td class="text-center"> {{item.date_created}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Printing div: Stock Out Update -->
<div style="display: none;" id="print-section-update-stock-out">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card px-2">
          <div class="card-body">

            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-6 pl-0">
                <h6 class="mt-5 mb-2"><b>{{printCompanyName}} - {{printEstablishment}}</b></h6>
                <p>{{printCompanyAddress}},
                  <br>{{printCompanyCity}},
                  <br>{{printCompanyCountry}}.
                </p>
              </div>
              <div class="col-lg-6 pl-0 text-right">
                <p class="mb-0 mt-5">Date d'impression : {{printDate}}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h6 class="ml-4">Rapport de mis a jour d'article: Sortie</h6>
            </div>
          </div>

          <div class="container-fluid justify-content-center w-100 table-print mt-3">
            <div class="tn-table-container">
              <table class="table">
                  <tr class="text-secondary">
                    <th class="text-center">Article</th>
                    <th class="text-center">Quantité écoulée</th>
                    <th class="text-center">Quantité restante</th>
                    <th class="text-center">Date</th>
                    <th class="text-center">Etablissement</th>
                  </tr>
                <tbody>
                  <tr *ngFor="let product of stockUpdateOutgoingReports" class="text-right">
                    <td class="text-center"> {{product.product_name}}</td>
                    <td class="text-center">{{product.quantity_spent}} bouteilles</td>
                    <td class="text-center">{{product.product_remaining_quantity}} bouteilles</td>
                    <td class="text-center">{{product.date_sale_placed}}</td>
                    <td class="text-center"> {{product.branch_name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">

        <div class="container-fluid">
            <div class="panel mt-5">

                <div class="row mt-4 mb-4">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">Boisson la plus vendue</h6>
                          <p class="card-text">{{mostPopularDrink}} ({{numberOfBottleOfTheMostPopularDrink}} bouteilles)</p>
                        </div>
                      </div>
                    </div>
                    <div class="offset-lg-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-md-0 mt-3">
                      <form [formGroup]="filterFormStockUpdate">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="form-group">
                              <select #selectedBranch class="form-control" 
                              (change)="loadStockUpdate($event.target.value)" formControlName="selectBranch">
                                <option value="0">
                                  Tous les établissement
                                </option>
                                <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                                  {{branch.branch_name}}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <small class="form-text text-muted">Datant de</small>
                            <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtStockUpdateFrom"
                              [owlDateTime]="dtStockUpdateFrom" name="dtStockUpdateFrom" formControlName="dtStockUpdateFrom">
                            <owl-date-time #dtStockUpdateFrom></owl-date-time>
      
                          </div>
      
                          <div class="col-12">
                            <small class="form-text text-muted">à</small>
                            <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtStockUpdateTo"
                              [owlDateTime]="dtStockUpdateTo" name="dtStockUpdateTo" formControlName="dtStockUpdateTo">
                            <owl-date-time #dtStockUpdateTo></owl-date-time>
      
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-12">
                            <button type="button" class="btn btn-primary w-100"
                              (click)="filterStockUpdate(selectedBranch.value)">
                              <i class="fas fa-filter"></i> &nbsp;Filtre
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                      <h6 class="mt-5">Imprimer mise à jour</h6>
                      <div class="form-group">
                        <div class="row my-3">
                          <div class="col-md-12">
                            <button printTitle="{{printCompanyName}}_StockUpdateEntry" [useExistingCss]="true" printSectionId="print-section-update-stock"
                            ngxPrint type="button" class="btn btn-primary w-100">
                            <i class="fas fa-print"></i> &nbsp;Imprimer
                          </button> 
                          </div>
                        </div>
                      </div>

                      <h6 class="mt-4">Imprimer article vendu</h6>
                      <div class="form-group">
                        <div class="row my-3">
                          <div class="col-md-12">
                            <button printTitle="{{printCompanyName}}_StockUpdateOut" [useExistingCss]="true" printSectionId="print-section-update-stock-out"
                            ngxPrint type="button" class="btn btn-primary w-100">
                            <i class="fas fa-print"></i> &nbsp;Imprimer
                          </button> 
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
      
                <div class="row">
                  <div class="col-md-4 col-sm-6 col-12 mt-5">
                    <div class="form-group">
                      <small class="form-text text-muted">Quel type de rapport voulez vous voir?</small>
                      <select #selectedReportType class="form-control" (change)="changeReportType($event.target.value)">
                        <option value="0">
                          Mise à jour des articles (bouteilles)
                        </option>
                        <option value="1">
                          Articles vendu (bouteilles)
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row mb-4" *ngIf="reportType === 0">
                    <div class="col-md-12 col-sm-12 col-12">
                      <div class="row">
                        <div class="col-md-12">
                          <h6 #stockUpdateDiv>Mise à jour des articles (bouteilles)</h6>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
      
                          <div class="card mb-3 d-lg-none" 
                            *ngFor="let item of stockUpdateReports | paginate: { itemsPerPage: 10, currentPage: pageStockUpdateIncomingReport, id: 'stockUpdateIncomingReport' }">
                            <div class="card-body">
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Article
                                </div>
                                <div class="col-6">
                                  {{item.product_name}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Ancienne quantité
                                </div>
                                <div class="col-6">
                                  {{item.old_quantity}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Quantité ajouté
                                </div>
                                <div class="col-6">
                                  {{item.quantity - item.old_quantity}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Quantité apres ajout
                                </div>
                                <div class="col-6">
                                  {{item.quantity}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Date
                                </div>
                                <div class="col-6">
                                  {{item.date_created}}
                                </div>
                              </div>
                             
                            </div>
                          </div>
                          <pagination-controls *ngIf="tableHasDataStockUpdateReport === true"
                            class="mb-3 text-center d-lg-none" (pageChange)="onMobileTableChangePage($event)" id="stockUpdateIncomingReport"
                            previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>
      
      
      
                          <mat-table [dataSource]="dataSourceStockUpdateEntry" class="d-none d-lg-block" matSort>
                            <ng-container matColumnDef="Product">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Article
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.product_name}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="OldQuantity">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Ancienne quantité
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.old_quantity}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="QuantityAdded">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Quantité ajouté
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.quantity - row.old_quantity}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="CurrentQuantity">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Quantité apres ajout
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.quantity}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Date">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Date
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.date_created}}
                              </mat-cell>
                            </ng-container>
                           
                            <!-- <ng-container matColumnDef="Action">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Action
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                <button class="btn btn-primary"><i class="fas fa-eye"></i></button> &nbsp;
                              </mat-cell>
                            </ng-container> -->
                            <mat-header-row *matHeaderRowDef="displayedColumnsStockUpdateReport">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsStockUpdateReport">
                            </mat-row>
                          </mat-table>
                          <div class="mt-3" *ngIf="tableHasDataStockUpdateReport === false">Aucune mise à jour trouvé
                          </div>
                          <mat-paginator #paginatorStockUpdateReport class="d-none d-lg-block mb-4" [pageSize]="10"
                            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      
                        </div>
                      </div>
                    </div>
                </div>
                <div class="row mb-4" *ngIf="reportType === 1">
                    <div class="col-md-12 col-sm-12 col-12">
                      <div class="row">
                        <div class="col-md-12">
                          <h6 #articlesVendu >Articles vendu (bouteilles)</h6>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="card mb-3 d-lg-none"
                            *ngFor="let item of stockUpdateOutgoingReports | paginate: { itemsPerPage: 10, currentPage: pageStockUpdateOutgoingReport, id: 'stockUpdateOutgoingReport' }">
                            <div class="card-body">
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Article
                                </div>
                                <div class="col-6">
                                  {{item.product_name}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Quantité avant
                                </div>
                                <div class="col-6">
                                  {{item.quantity_spent}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Quantité écoulée
                                </div>
                                <div class="col-6">
                                  {{item.quantity_spent}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Quantité restante
                                </div>
                                <div class="col-6">
                                  {{item.product_remaining_quantity}} bouteilles
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Date
                                </div>
                                <div class="col-6">
                                  {{item.date_sale_placed}}
                                </div>
                              </div>
                              <div class="row mb-2">
                                <div class="col-6 card-table-header">
                                  Quantité pour rebalancer le stock
                                </div>
                                <div class="col-6">
                                  {{item.stock_default - item.product_remaining_quantity}}
                                </div>
                              </div>
      
                            </div>
                          </div>
                          <pagination-controls *ngIf="tableHasDataStockUpdateOutgoingReport === true"
                            class="mb-3 text-center d-lg-none" (pageChange)="onMobileTableChangePageOutgoing($event)" id="stockUpdateOutgoingReport"
                            previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>
      
      
                          <mat-table [dataSource]="dataSourceStockUpdateOutgoing" class="d-none d-lg-block" matSort>
                            <ng-container matColumnDef="Product">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Article
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.product_name}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="QuantityBefore">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Quantité avant
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.quantity_spent}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Quantity">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Quantité écoulée
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.quantity_spent}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="RemainingProductQuantity">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Quantité restante
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.product_remaining_quantity}} bouteilles
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Date">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Date
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.date_sale_placed}}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Branch">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>
                                Quantité pour rebalancer le stock
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{row.stock_default - row.product_remaining_quantity}}
                              </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumnsStockUpdateOutgoingReport">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsStockUpdateOutgoingReport">
                            </mat-row>
                          </mat-table>
                          <div class="mt-3" *ngIf="tableHasDataStockUpdateOutgoingReport === false">Aucune vente trouvé
                          </div>
                          <mat-paginator #paginatorStockUpdateOutgoingReport class="d-none d-lg-block mb-4" [pageSize]="10"
                            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                        </div>
                      </div>
                    </div>
                </div>

                <!----success product modal -->
                <ng-template #successModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{successModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>


                <!----failure product modal -->
                <ng-template #failureModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{failureModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>