export class FixDebtDTO {
    customer_id: number;
    customer_account_id?: number;
    order_id: number;
    branch_id: number;
    amount_paid_by_customer:  number;
    amount_paid_by_customer_currency_one: number;
    amount_paid_by_customer_currency_two: number;
    change_returned_to_customer: number;
    company_id: number;
}