<div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">

        <div class="container-fluid">
            <div class="panel mt-5">


                <div class="row mt-4">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="card-title">Total offert</h6>
                                <p class="card-text"> {{workingCurrency}} {{totalOrderAmountGifted}}</p>
                                <!-- <p class="card-text"> {{currencyDefault}} {{totalOrderAmountGifted}}</p> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
                        <form [formGroup]="searchGiftOrder">
                            <div class="form-group mt-4">
                                <small class="form-text text-muted">Rechercher une commande offerte</small>
                                <div class="input-group mb-3">
                                    <input type="search" class="form-control" formControlName="order_number"
                                        placeholder="Rechercher..." aria-label="Search..."
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-primary" type="button" (click)="searchOrder(selectedBranch.value)">
                                            <i class="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <form [formGroup]="filterFormGiftOrders">
                    <div class="row mt-1">

                        <div
                            class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                            <div class="card mb-3 d-md-none"
                                *ngFor="let item of giftOrderReports | paginate: { itemsPerPage: 10, currentPage: pageOrderReport, id: 'orderReport' }">
                                <div class="card-body">
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            No Commande
                                        </div>
                                        <div class="col-6">
                                            {{item.order_id}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Nom
                                        </div>
                                        <div class="col-6">
                                            {{item.employee_fullname}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Date et heure
                                        </div>
                                        <div class="col-6">
                                            {{item.order_start_time}}
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Montant payé
                                        </div>
                                        <div class="col-6">
                                            {{workingCurrency}}
                                            <!-- {{item.currency_one}} -->
                                            {{item.total_amount_brought_in}}
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary"
                                                (click)="loadOrderItemsByOrderID(item.order_id, item.total_amount_brought_in, item.currency_one)"><i
                                                    class="fas fa-eye"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <pagination-controls *ngIf="tableHasDataOrderReport === true"
                                class="mb-3 text-center d-md-none" (pageChange)="onMobileTableChangePage($event)"
                                id="orderReport" previousLabel="Précédent" nextLabel="Suivant">
                            </pagination-controls>


                            <mat-table [dataSource]="dataSourceGiftOrders" class="d-none d-md-block" matSort>
                                <ng-container matColumnDef="OrderID">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        No Commande
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.order_id}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Nom
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.employee_fullname}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Date & Time">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Date et heure
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.order_start_time}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Amount paid">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Montant payé
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{workingCurrency}}
                                        <!-- {{row.currency_one}} -->
                                        {{row.total_amount_brought_in}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Action">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Action
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <button
                                            (click)="loadOrderItemsByOrderID(row.order_id, row.total_amount_brought_in, row.currency_one)"
                                            class="btn btn-primary">
                                            <i class="fas fa-eye"></i></button> &nbsp;
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumnsGiftOrderReport">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsGiftOrderReport">
                                </mat-row>
                            </mat-table>
                            <div class="mt-3" *ngIf="tableHasDataOrderReport === false">Aucune vente trouvé
                            </div>
                            <mat-paginator #paginatorOrderReport class="d-none d-md-block mb-4" [pageSize]="10"
                                [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <select #selectedBranch class="form-control" formControlName="dpBranch"
                                            (change)="loadGiftOrders($event.target.value)">
                                            <option value="0">
                                                Tous les établissement
                                            </option>
                                            <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                                                {{branch.branch_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <select #selectedWaiters class="form-control" formControlName="dpWaiter"
                                            (change)="loadGiftOrdersPerWaiter($event.target.value)">
                                            <option value="0">
                                                Tous le staff
                                            </option>
                                            <option *ngFor="let waiter of employeeWaiters" value={{waiter.employee_id}}>
                                                {{waiter.employee_firstname}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <small class="form-text text-muted">Datant de</small>
                                    <input class="form-control" [readonly]="true"
                                        [owlDateTimeTrigger]="dtGiftOrdersFrom" [owlDateTime]="dtGiftOrdersFrom"
                                        name="dtGiftOrdersFrom" formControlName="dtGiftOrdersFrom">
                                    <owl-date-time #dtGiftOrdersFrom></owl-date-time>
                                </div>

                                <div class="col-12">
                                    <small class="form-text text-muted">à</small>
                                    <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtGiftOrdersTo"
                                        [owlDateTime]="dtGiftOrdersTo" name="dtGiftOrdersTo"
                                        formControlName="dtGiftOrdersTo">
                                    <owl-date-time #dtGiftOrdersTo></owl-date-time>

                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-primary w-100"
                                        (click)="filterGiftOrders(selectedBranch.value)">
                                        <i class="fas fa-filter"></i> &nbsp;Filtrer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <ng-template #viewOrderItem let-modal>
                    <div class="modal-header text-center">
                        <h5 class="modal-title">Commande #{{selectedOrderID_GiftOrders}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">

                        <div class="row mb-1"
                            *ngFor="let item of orderItems | paginate: { itemsPerPage: 5, currentPage: viewOrderItemPage, id: 'viewOrderItemPage' }">
                            <div class="col-6">
                                {{item.quantity}} x
                                {{item.product_name}}
                            </div>
                            <div class="col-6">
                                <!-- {{currencyDefault}} -->
                                {{workingCurrency}}
                                {{item.cost_to_pay}}
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <div class="offset-md-6 col-md-6 offset-6 col-6 font-weight-bold">
                                <!-- {{currencyDefault}} -->
                                {{workingCurrency}}
                                {{selectedOrderTotalAmount_GiftOrders}}
                            </div>
                        </div>
                        <pagination-controls *ngIf="tableHasDataOrderReport === true" class="mb-3 text-center"
                            (pageChange)="viewOrderItemPage = $event" id="viewOrderItemPage" previousLabel="Précédent"
                            nextLabel="Suivant"></pagination-controls>

                    </div>
                    <div class="modal-footer text-center">
                        <button type="button" class="btn btn-primary" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>

                <!----success product modal -->
                <ng-template #successModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{successModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>


                <!----failure product modal -->
                <ng-template #failureModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{failureModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>