import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Table } from '../interface/tables';
import { UpdateTable } from '../interface/updateTable';

@Injectable({
  providedIn: 'root'
})
export class TablesService {

  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {
  }

  getAllTables(branchId: number): Observable<Table[]> {
    return this.http.post<Table[]>(this.apiURL + '/table/getall?branchID=' + branchId, null);
  }

  searchTable(branchId: number, tableName: string, companyId: number): Observable<Table[]> {
    if ((tableName !== null) && (tableName !== '') && (tableName !== undefined)) {
      return this.http.post<Table[]>(this.apiURL + '/table/searchtablebyname?tableName=' + tableName + '&b=' + branchId + '&c=' + companyId, null);
    } else {
      return this.http.post<Table[]>(this.apiURL + '/table/getall?branchID=' + branchId, null);
    }
  }

  saveTable(body): Observable<Table> {
    return this.http.post<Table>(this.apiURL + '/table/save', body);
  }

  deleteTable(branchid): Observable<Table> {
    return this.http.post<Table>(this.apiURL + '/table/delete?branchID=' + branchid, null);
  }

  updateTable(body): Observable<UpdateTable> {
    return this.http.post<UpdateTable>(this.apiURL + '/table/update', body);
  }
}
