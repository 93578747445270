import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Category} from 'src/app/interface/category';
import {SubCategory} from 'src/app/interface/sub-category';
import {UpdateSubCategory} from 'src/app/interface/update-sub-category';
import {FlowService} from 'src/app/services/flow.service';
import {PagadoService} from 'src/app/services/pagado.service';
import {Subject} from 'rxjs';
import {SubCategoryServiceService} from 'src/app/services/sub-category-service.service';
import {Branch} from 'src/app/interface/branch';
import {CategoriesService} from 'src/app/services/categories.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DataManagerService} from '../../services/data-management/data-manager.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.scss']
})
export class CategoryMenuComponent implements OnInit {

  categories: Category [];
  subCategories: SubCategory [];
  searchSubCategories: SubCategory[];
  tempSubCategory: SubCategory;
  subCategoryForm: FormGroup;
  addSubCategoryForm: FormGroup;
  categoryFormDelete: FormGroup;
  subCategorySearch: FormGroup;
  selectCategoryForm: FormGroup;
  close: string;
  spin: boolean;
  branches: Branch;
  showTable: boolean;
  subCategoryNameToDelete: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();


  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;
  @ViewChild('failureModal', {static: true}) failureModal: TemplateRef<any>;
  @ViewChild('editSubCategory', {static: true}) editSubCategoryModal: TemplateRef<any>;
  @ViewChild('deleteSubCategory', {static: true}) deleteSubCategoryModal: TemplateRef<any>;
  @ViewChild('addSubCategory', {static: true}) addSubCategoryModal: TemplateRef<any>;

  closeResult: string;
  successModalMessage: string;
  failureModalMessage: string;

  displayedColumnsSubCategory = [
    'SubCategoryName',
    'Actions',
  ];

  dataSourceSubCategory: MatTableDataSource<SubCategory>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tableHasData: boolean;

  selectedCategory: Category;
  selectedCategoryName: string;

  categoryOfTheSubcategoryToDelete: string;

  page = 1;

  constructor(private flowService: FlowService, private pagadoService: PagadoService, private fb: FormBuilder,
              private SubCategoryService: SubCategoryServiceService, private categoriesService: CategoriesService,
              private router: Router, private authenticationService: AuthenticationService,
              private modalService: NgbModal, private dataManagerService: DataManagerService) {
  }

  ngOnInit(): void {
    this.createForm();
    this.getSubCategories();

    this.selectedCategory = this.dataManagerService.getCategory();

    this.getCategories(this.selectedCategory.branch_id);

    this.selectCategoryForm.controls.selectCategories.setValue(this.selectedCategory.category_id);
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getSubCategories(): void {
    this.spin = true;

    this.SubCategoryService.getSubCategories(this.flowService.getCategories()).subscribe(res => {
        this.subCategories = res;

        if (this.subCategories.length > 0) {
          this.tableHasData = true;

          this.dataSourceSubCategory = new MatTableDataSource(this.subCategories);
          this.dataSourceSubCategory.paginator = this.paginator;
          this.dataSourceSubCategory.sort = this.sort;
        } else {
          this.tableHasData = false;
          this.dataSourceSubCategory = new MatTableDataSource([]);
          this.dataSourceSubCategory.paginator = this.paginator;
          this.dataSourceSubCategory.sort = this.sort;
        }
        this.spin = false;

      }, err => {
        this.tableHasData = false;
        this.dataSourceSubCategory = new MatTableDataSource([]);
        this.dataSourceSubCategory.paginator = this.paginator;
        this.dataSourceSubCategory.sort = this.sort;
        this.spin = false;
      }
    );
  }

  getCategories(branchId): void {
    this.categoriesService.getCategories(this.authenticationService.currentUserValue.companyID, branchId).subscribe(res => {
      this.categories = res;
    }, error => {
      this.spin = false;
    });
  }

  createForm(): void {
    this.subCategoryForm = this.fb.group({
      subCategoryName: [''],
      editSubCategoryFormCategoryID: ['']
    });

    this.addSubCategoryForm = this.fb.group({
      addSubCategoryName: [''],
      addSubCategoryFormCategoryID: ['']
    });

    this.categoryFormDelete = this.fb.group({
      addSubCategoryName: ['']
    });

    this.subCategorySearch = this.fb.group({
      searchSubCategoryName: ['']
    });

    this.selectCategoryForm = this.fb.group({
      selectCategories: ['']
    });
  }

  edit(subCategory): void {

    this.tempSubCategory = subCategory;

    this.subCategoryForm.controls.editSubCategoryFormCategoryID.setValue(subCategory.category_id);
    this.subCategoryForm.controls.subCategoryName.setValue(this.tempSubCategory.sub_name);

    this.open(this.editSubCategoryModal);
  }

  saveNewSubCategoryName(): void {
    this.spin = true;
    const body: UpdateSubCategory = {
      subcategory_id: this.tempSubCategory.subcategory_id,
      sub_name: this.subCategoryForm.controls.subCategoryName.value
    };
    this.pagadoService.updateSubCategory(body).subscribe(res => {

      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Votre sous-catégorie a été modifié avec succès');

        this.getSubCategories();
      } else {
        this.spin = false;
        this.showFailureModal('La modification de la sous-catégorie a échoué, réessayez plus tard');
      }
    }, error => {

      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cette sous-catégorie existe déjà');
      } else {

        this.showFailureModal('La modification de la sous-catégorie a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  addSub(categoryId: number): void {
    this.addSubCategoryForm.reset();
    this.addSubCategoryForm.controls.addSubCategoryFormCategoryID.setValue(categoryId);
    this.open(this.addSubCategoryModal);
  }

  saveNewSubCategory(): void {
    this.spin = true;
    const categoryId = Number(localStorage.getItem('categoryId'));
    const body: SubCategory = {
      subcategory_id: 0,
      sub_name: this.addSubCategoryForm.controls.addSubCategoryName.value,
      category_id: categoryId,
      status: 0,
      date_created: '',
      last_modified: ''
    };
    this.pagadoService.saveSubCategories(body).subscribe(res => {

      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Votre sous-catégorie a été enregistré avec succès');

        this.getSubCategories();
      } else {
        this.spin = false;
        this.showFailureModal('L\'ajout de la sous-catégorie a échoué, réessayez plus tard');
      }
    }, error => {

      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cette sous-catégorie existe déjà');
      } else {

        this.showFailureModal('L\'ajout de la sous-catégorie a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  tempDelete(subCategory): void {
    this.tempSubCategory = subCategory;
    this.subCategoryNameToDelete = this.tempSubCategory.sub_name;
    this.categoryOfTheSubcategoryToDelete = this.categories.find(cat => cat.category_id === subCategory.category_id).name;

    this.open(this.deleteSubCategoryModal);
  }

  deleteSubCategory1(): void {
    this.spin = true;
    const numberValue = Number(this.tempSubCategory.category_id);
    let categoryDelete: SubCategory;
    categoryDelete = {
      subcategory_id: this.tempSubCategory.subcategory_id,
      sub_name: this.categoryFormDelete.controls.addSubCategoryName.value,
      category_id: this.tempSubCategory.category_id,
      status: this.tempSubCategory.status,
      date_created: this.tempSubCategory.date_created,
      last_modified: this.tempSubCategory.last_modified
    };

    this.SubCategoryService.deleteSubCategory(categoryDelete).subscribe(res => {
      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Votre sous-catégorie a été supprimé avec succès');

        this.getSubCategories();
      } else {
        this.spin = false;
        this.showFailureModal('La suppression de la sous-catégorie a échoué, réessayez plus tard');
      }
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('supprimer d\'abord les articles liées à cette sous-catégorie');
      } else {

        this.showFailureModal('La suppression de la sous-catégorie a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  goToProducts(subcategory: SubCategory): void {
    localStorage.setItem('subcategory', subcategory.subcategory_id.toString());
    this.router.navigate(['/products']);
  }

  getBranchCategories(event): void {
    const categoryId = event.target.value;
    const category: Category = {
      category_id: categoryId,
      name: '',
      branch_id: 0,
      company_id: 0,
      status: 0,
      subcategory_names: []
    };

    this.flowService.setCategories(category);

    localStorage.setItem('categoryId', category.category_id.toString());

    this.spin = true;

    this.SubCategoryService.getSubCategories(category).subscribe(res => {
        this.subCategories = res;

        if (this.subCategories.length > 0) {
          this.tableHasData = true;

          this.dataSourceSubCategory = new MatTableDataSource(this.subCategories);
          this.dataSourceSubCategory.paginator = this.paginator;
          this.dataSourceSubCategory.sort = this.sort;
        } else {
          this.tableHasData = false;
          this.dataSourceSubCategory = new MatTableDataSource([]);
          this.dataSourceSubCategory.paginator = this.paginator;
          this.dataSourceSubCategory.sort = this.sort;
        }
        this.spin = false;

      }, err => {
        this.tableHasData = false;
        this.dataSourceSubCategory = new MatTableDataSource([]);
        this.dataSourceSubCategory.paginator = this.paginator;
        this.dataSourceSubCategory.sort = this.sort;
        this.spin = false;
      }
    );
  }

  searchSubCategory(): void {
    this.spin = true;
    this.subCategories = null;

    const name = this.subCategorySearch.controls.searchSubCategoryName.value;
    this.SubCategoryService.searchSubCategory(name).subscribe(res => {

      this.searchSubCategories = res;

      if (this.searchSubCategories.length > 0) {
        this.tableHasData = true;

        this.dataSourceSubCategory = new MatTableDataSource(this.searchSubCategories);
        this.dataSourceSubCategory.paginator = this.paginator;
        this.dataSourceSubCategory.sort = this.sort;
      } else {
        this.tableHasData = false;
        this.dataSourceSubCategory = new MatTableDataSource([]);
        this.dataSourceSubCategory.paginator = this.paginator;
        this.dataSourceSubCategory.sort = this.sort;
      }
      this.spin = false;
    }, err => {
      this.tableHasData = false;
      this.dataSourceSubCategory = new MatTableDataSource([]);
      this.dataSourceSubCategory.paginator = this.paginator;
      this.dataSourceSubCategory.sort = this.sort;
      this.spin = false;
    });
  }

  validate(): void {
    const forms = document.getElementsByClassName('needs-validation');
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  openCreateNewSubCategoryModal(): void {

  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
