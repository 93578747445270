import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import { Constants } from '../../common/Constants';
import { CompanyService } from 'src/app/services/company.service';
import * as moment from 'moment';
import { Branch } from 'src/app/interface/branch';
import { BranchService } from 'src/app/services/branch.service';
import { environment } from 'src/environments/environment';
import { Company } from 'src/app/models/company/company';


@Component({
  selector: 'app-qr-code-generate',
  templateUrl: './qr-code-generate.component.html',
  styleUrls: ['./qr-code-generate.component.scss']
})
export class QrCodeGenerateComponent implements OnInit {

  public qrCodeValue: string = null;
  private readonly apiURL = environment.apiBase;

  spin: boolean;

  company: Company;
  
  branchIdToBeUsed: number;
  branchNameToBeUsed: string;

  //Printing
  printDate: string;
  printCompanyName: string;
  printCompanyAddress: string;
  printCompanyCity: string;
  printCompanyCountry: string;
  printEstablishment: string;

  selectBranchForm: FormGroup;

  branchList: Branch[];


  constructor(private companyService: CompanyService, private authenticationService: AuthenticationService,
    private fb: FormBuilder, private branchService: BranchService) {
    this.qrCodeValue = "29";
  }

  ngOnInit(): void {
    this.createForm();
    this.getFranchise();

    this.getCompanyDetails(this.authenticationService.currentUserValue.companyID)

  }

  createForm(): void {
    this.selectBranchForm = this.fb.group({
      selectBranch: ['']
    });
  }

  getFranchise(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchList = res;


      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.branchIdToBeUsed = this.branchList[0].branch_id;
        this.selectBranchForm.controls.selectBranch.enable();
        // this.isAdminManager = true;
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

        this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        this.selectBranchForm.controls.selectBranch.disable();
        // this.isAdminManager = false;
      } else {
        // this.isAdminManager = false;
      }
      this.selectBranchForm.controls.selectBranch.setValue(this.branchIdToBeUsed);
      this.loadQRCode(this.branchIdToBeUsed);

    }, error => {
      this.spin = false;
    });
  }

  loadQRCode(branchId: number): void {
    this.branchNameToBeUsed = this.branchList.find(branch => branch.branch_id === branchId).branch_name;
    this.qrCodeValue = 'https://restaurant.pagado.net/#/menu/' + branchId
    // this.qrCodeValue = 'http://restaurant.ecolinked.net/#/menu/' + branchId
  }

  getBranchQRCode(event): void {
    this.qrCodeValue = "29";
    const branchId = event.target.value;
    this.branchIdToBeUsed = branchId;
    this.loadQRCode(Number(branchId));
  }

  getCompanyDetails(companyId: number): void {
    var date = new Date();
    this.printDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds())).format('DD-MM-YYYY HH:mm:ss').toString();

    this.companyService.getCompanyByID(companyId).subscribe(res => {
      this.company = res;
      this.printCompanyName = this.company.company_name;
      this.printCompanyAddress = this.company.company_address + ', ' + this.company.company_address_suburb;
      this.printCompanyCity = this.company.company_address_city + ', ' + this.company.company_address_province;
      this.printCompanyCountry = this.company.company_address_country;
    }, err => {
      // this.spin = false;
    });
  }
}
