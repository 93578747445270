import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Constants } from 'src/app/common/Constants';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SideNavigationService } from 'src/app/services/side-navigation/side-navigation.service';
import * as $ from 'jquery';
import { DataManagerService } from '../../services/data-management/data-manager.service';
import { Warehouse } from 'src/app/models/warehouse/Warehouse';
import { WarehouseService } from 'src/app/services/warehouse/warehouse.service';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {

  currentState;
  currentChildState;
  pathEvent;

  // isAdminManager: boolean = false;
  roleLoggedIn: string = 'Manager Normal';
  shouldShowWarehouse: boolean = false;

  warehouse: Warehouse;
  wareHouseList: Warehouse[];

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private actRoute: ActivatedRoute, private headerService: SideNavigationService,
    private dataManagerService: DataManagerService, private warehouseService: WarehouseService) {

  }

  ngOnInit(): void {

    // $('#menu-toggle').on('click', function(e) {
    //   e.preventDefault();
    //   $('#wrapper').toggleClass('toggled');
    // });

    this.currentState = this.router.url;
    // this.router.events.filter(event => event instanceof NavigationEnd)
    //       .subscribe(event => 
    //        {
    //           this.currentRoute = event.url;          
    //           console.log(event);
    //        });
    // }


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pathEvent = event;
        this.headerService.setState(event.url);
      }
    });
    // this.headerService.getCurrentState.subscribe(ct => {
    //   this.currentState = ct;
    // });

    this.roleLoggedIn = this.authenticationService.currentUserValue.role;

    // if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
    //   (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
    //   (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
    //   (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

    //   this.isAdminManager = true;
    // } else {
    //   this.isAdminManager = false;
    // }

    // if (this.authenticationService.currentUserValue.role === Constants.ROLE_ADMIN_MANAGER) {
    //   this.shouldShowWarehouse = true;
    // } else if (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER) {

    //   this.warehouseService.doesEmployeeManagesAWarehouse(this.authenticationService.currentUserValue.employeeID, 
    //     this.authenticationService.currentUserValue.companyID).subscribe(res => {

    //     if (res) {
    //       this.shouldShowWarehouse = true;
    //     } else {
    //       this.shouldShowWarehouse = false;
    //     }

    //     // this.spin = false;
    //   }, error => {
    //     this.shouldShowWarehouse = false;
    //   });
    // }

  }

  goToOtherReport(): void{
    this.router.navigate([Constants.OTHER_ROUTE_REPORT]);
  }

  goToReport(): void {
    this.router.navigate([Constants.ROUTE_REPORT]);
  }

  goToExpense(): void {
    this.router.navigate([Constants.ROUTE_EXPENSE]);
  }

  goToDashboard(): void {
    this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
  }

  goToManageProducts(): void {
    this.router.navigate([Constants.MANAGE_PRODUCT_ROUTE]);
  }

  goToEmployees(): void {
    this.router.navigate([Constants.EMPLOYEE_ROUTE]);
  }

  goToSupplier(): void {
    this.router.navigate([Constants.SUPPLIER_ROUTE]);
  }

  goToWarehouse(): void {
    this.router.navigate([Constants.WAREHOUSE_ROUTE]);
  }
  
  goToTransactions(): void{
    this.router.navigate([Constants.TRANSACTIONS_ROUTE])
  }

  goToCompany(): void {

     if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
      (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
      (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.router.navigate([Constants.ROUTE_TABLE]);
    } else {
      this.router.navigate([Constants.ROUTE_TABLE]);
    }
  }

  goToProfile(): void {
    // this.dataManagerService.setStaffKey(this.authenticationService.currentUserValue.staffID);
    this.dataManagerService.setEmployees(null);
    this.router.navigate([Constants.ROUTE_PROFILE]);
  }

  logout(): void {
    this.authenticationService.logOut();
  }

}
