import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Company } from '../../models/company/company';
import { Supplier } from '../../interface/supplier';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { SupplierService } from '../../services/supplier.service';


@Component({
  selector: 'app-supplier-history',
  templateUrl: './supplier-history.component.html',
  styleUrls: ['./supplier-history.component.scss']
})
export class SupplierHistoryComponent implements OnInit {
  dataSource: any[];
  transactionFilterForm: FormGroup;
  suppliers: Supplier [];
  spin: boolean;
  showTable: boolean;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  tempSupplier: Supplier;
  tempSupplierName: any;
  companies: Company;


  dataSourceSupplierHistory: MatTableDataSource<any>;

  @ViewChild('paginatorTransReport') paginatorTransReport: MatPaginator;
  @ViewChild('MatSort') MatSort: MatSort;

  displayedColumnsSupplier = [
    'transaction_date',
    'product_name',
    'quantity_in_bottle',
    'sold_to',
    'amount_spent',
    'loyalty_gained'
  ];

  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;
  @ViewChild('failureModal', {static: true}) failureModal: TemplateRef<any>;
  @ViewChild('editSupplier', {static: true}) editSupplierModal: TemplateRef<any>;
  @ViewChild('deleteSupplier', {static: true}) deleteSupplierModal: TemplateRef<any>;
  @ViewChild('addSupplier', {static: true}) addSupplierModal: TemplateRef<any>;

  @ViewChild('selectedBranch') selectedBranch: ElementRef<any>;

  closeResult: string;

  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  supplierID: number;

  companyID: number;

  tableHasData: boolean;

  page = 1;

  loyaltyTotal : number = 0;

  constructor(private router: Router, private supplierService: SupplierService, private fb: FormBuilder,
              private authenticationService: AuthenticationService, private modalService: NgbModal,private route:ActivatedRoute) {
  }

  ngOnInit(): void {
    
    this.dataSource = [];
    this.createForm();
    var date = new Date();
    this.transactionFilterForm.controls['dateFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), 1));
    this.transactionFilterForm.controls['dateTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    this.companyID = this.authenticationService.currentUserValue.companyID;
    this.supplierID = Number(this.route.snapshot.paramMap.get('id'));
    this.preloadPastOrders(new Date(date.getFullYear(), date.getMonth(), 1),new Date(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  createForm(): void {
    this.transactionFilterForm = this.fb.group({
      dateFrom: [''],
      dateTo: [''],
      warehouseSelect:['']
    });
  }
  filterPastOrders() {
    this.spin = true;
    let dF = moment(this.transactionFilterForm.get('dateFrom').value).format('YYYY-MM-DD HH:mm:ss').toString();
    let dT = moment(this.transactionFilterForm.get('dateTo').value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.supplierService.getSupplierTransactions(Number(this.transactionFilterForm.get('warehouseSelect').value),this.companyID,dF,
      dT,this.supplierID).subscribe(val=>{
        this.dataSource = val;
        this.loyaltyTotal = this.dataSource.map(t => t.loyalty_gained).reduce((a, b) => a + b, 0);


        if (this.dataSource.length > 0) {
            this.tableHasData = true;
            this.dataSourceSupplierHistory = new MatTableDataSource(this.dataSource);
            this.dataSourceSupplierHistory.paginator = this.paginatorTransReport;
          } else {
            this.tableHasData = false;
          }
          this.spin = false;
      }, error =>{
        this.tableHasData = false;
        this.dataSourceSupplierHistory = new MatTableDataSource([]);
        this.dataSourceSupplierHistory.paginator = this.paginatorTransReport;
        this.spin = false;
      });
  }

  preloadPastOrders(df,dt) {
    this.spin = true;
    let dF = moment(df).format('YYYY-MM-DD HH:mm:ss').toString();
    let dT = moment(dt).format('YYYY-MM-DD HH:mm:ss').toString();
    
    this.supplierService.getSupplierTransactions(0,this.companyID,dF,
      dT,this.supplierID).subscribe(val=>{
        this.dataSource = val;
        this.loyaltyTotal = this.dataSource.map(t => t.loyalty_gained).reduce((a, b) => a + b, 0);


        if (this.dataSource.length > 0) {
            this.tableHasData = true;
            this.dataSourceSupplierHistory = new MatTableDataSource(this.dataSource);
            this.dataSourceSupplierHistory.paginator = this.paginatorTransReport;
          } else {
            this.tableHasData = false;
          }
          this.spin = false;
      }, error =>{
        this.tableHasData = false;
        this.dataSourceSupplierHistory = new MatTableDataSource([]);
        this.dataSourceSupplierHistory.paginator = this.paginatorTransReport;
        this.spin = false;
      });
  }
  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }


  onMatTableChangePage(event: any) {
    window.scroll(0,0);
  }
}
