<div class="panel">

  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
      <form [formGroup]="categorySearch">
        <div class="form-group mt-4">
          <small class="form-text text-muted">Rechercher un employé</small>
          <div class="input-group mb-3">
            <input type="search" class="form-control" formControlName="searchCategoryName" placeholder="Rechercher..."
              aria-label="Search..." aria-describedby="basic-addon2">
            <div class="input-group-append">
              <button class="btn btn-outline-primary" (click)="searchCategory(selectBranch.value)" type="button"><i
                  class="fas fa-search"></i></button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
      <div class="form-group mt-4">
        <small class="form-text text-muted mt-5"></small>
        <button type="button" class="btn btn-primary" (click)="openCreateNewCategoryModal()">
          <i class="fas fa-plus-square"></i> &nbsp; Ajouter une catégorie
        </button>
      </div>
    </div> 
  </div>

  <div class="row mt-1 mb-3">
    <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

      <div class="card mb-3 d-md-none"
        *ngFor="let row of categories | paginate: { itemsPerPage: 10, currentPage: page }">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-6 card-table-header">
              Nom de la catégorie
            </div>
            <div class="col-6">
              {{row.name}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6 card-table-header">
              Sous-catégorie
            </div>
            <div class="col-6">
              {{row.subcategory_names[0]}}
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 text-center">
              <button class="btn btn-primary" (click)="goInMenu(row)"><i class="fas fa-eye"></i></button> &nbsp;
              <button class="btn btn-primary" (click)="tempEdit(row)"><i class="bi bi-pen"></i></button> &nbsp;
              <button class="btn btn-danger" (click)="tempDelete(row)"><i class="bi bi-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
      <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-md-none" (pageChange)="page = $event"
        previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>


      <mat-table [dataSource]="dataSourceCategory" class="d-none d-md-block" matSort>
        <ng-container matColumnDef="CategoryName">
          <mat-header-cell class="col-3" *matHeaderCellDef mat-sort-header>
            Nom de la catégorie
          </mat-header-cell>
          <mat-cell class="col-3" *matCellDef="let row">
            {{row.name}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Subcategories">
          <mat-header-cell class="col-6" *matHeaderCellDef mat-sort-header>
            Sous-catégorie
          </mat-header-cell>
          <mat-cell class="col-6" *matCellDef="let row">
            {{row.subcategory_names[0]}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <mat-header-cell class="col-3" *matHeaderCellDef mat-sort-header>
            Actions
          </mat-header-cell>
          <mat-cell class="col-3" *matCellDef="let row">
            <button class="btn btn-primary" (click)="goInMenu(row)"><i class="fas fa-eye"></i></button> &nbsp;
            <button class="btn btn-primary" (click)="tempEdit(row)"><i class="bi bi-pen"></i></button> &nbsp;
            <button class="btn btn-danger" (click)="tempDelete(row)"><i class="bi bi-trash"></i></button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsCategory">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsCategory">
        </mat-row>
      </mat-table>
      <div class="mt-3" *ngIf="tableHasData === false">Aucune catégorie n'a été trouvée
      </div>
      <mat-paginator class="d-none d-md-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    </div>
    <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
      <form [formGroup]="selectBranchForm">
        <div class="form-group">
          <small class="form-text text-muted">Sélectionnez une établissement</small>
          <select #selectBranch class="form-control" (change)="getBranchCategories($event)" formControlName="selectBranch">
            <option *ngFor="let branch of branchList" value={{branch.branch_id}}>{{branch.branch_name}}</option>
          </select>
        </div>
      </form>
    </div>
  </div>


  <!--ADD CATEGORY Modal -->
  <ng-template #createCategory let-modal>
    <div class="modal-header text-center">
      <h5 class="modal-title w-100">Ajouter une nouvelle catégorie</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="categoryForm">
        <div class="form-group">
          <small class="form-text text-muted">Sélectionnez un établissement</small>
          <select class="form-control" formControlName="franchise" required>
            <option value="" disabled selected>Sélectionnez un établissement</option>
            <option *ngFor="let branch of branchList" value={{branch.branch_id}} placeholder="Sélectionnez un établissement">
              {{branch.branch_name}}</option>
          </select>
          <div *ngIf="categoryForm.controls['franchise'].invalid &&
                            (categoryForm.controls['franchise'].dirty || 
                            categoryForm.controls['franchise'].touched)" class="alert alert-light pt-0">
            <div *ngIf="categoryForm.controls['franchise'].errors.required">
              Ne devrait pas être vide
            </div>
          </div>
        </div>
        <div class="form-group">
          <small class="form-text text-muted">Nom de la catégorie</small>
          <input type="text" formControlName="categoryName" required class="form-control" placeholder="Nom de la catégorie">
          <div *ngIf="categoryForm.controls['categoryName'].invalid &&
                            (categoryForm.controls['categoryName'].dirty || 
                            categoryForm.controls['categoryName'].touched)" class="alert alert-light pt-0">
            <div *ngIf="categoryForm.controls['categoryName'].errors.required">
              Ne devrait pas être vide
            </div>
          </div>
        </div>
        <div class="form-group">
          <small class="form-text text-muted">Sous-catégories</small>
          <div class="editor-tag" (click)="focusTagInput()">
            <span class="tag-item" *ngFor="let tag of tags">
              {{tag}}
              <span class="remove-tag" (click)="removeTag(tag)">×</span>
            </span>
            <input placeholder="Sous-catégories" #tagInput type="text" class="input-tag" (keyup)="onKeyUp($event)"
              formControlName="subCategory">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
      <button type="button" [disabled]="!categoryForm.valid" class="btn btn-primary" 
      (click)='saveNewCategory()'>Sauvegarder</button>
    </div>
  </ng-template>

  <!--Edit Category Modal -->
  <ng-template #editCategory let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Modification de la catégorie</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="categoryFormEdit" class="needs-validation">
        <div class="form-group">
          <small class="form-text text-muted">Nom de la catégorie</small>
          <input type="text" formControlName="categoryNameEdit" class="form-control" required>
          <div *ngIf="categoryFormEdit.controls['categoryNameEdit'].invalid &&
                            (categoryFormEdit.controls['categoryNameEdit'].dirty || 
                            categoryFormEdit.controls['categoryNameEdit'].touched)" class="alert alert-light pt-0">
            <div *ngIf="categoryFormEdit.controls['categoryNameEdit'].errors.required">
              Ne devrait pas être vide
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close()">Annuler</button>
      <button type="button" class="btn btn-primary" (click)='saveNewCategoryName()'>Sauvegarder</button>
    </div>
  </ng-template>

  <!--Delete Modal -->
  <ng-template #deleteCategory let-modal>
    <div class="modal-header text-center">
      <h5 class="modal-title">Confirmation de suppression</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
      <form [formGroup]="categoryFormDelete">
        <div class="form-group">
          <label>Êtes-vous sûr de vouloir supprimer cette catégorie ?</label>
          <h5>{{categoryNameToDelete}}</h5>
        </div>
      </form>
    </div>
    <div class="modal-footer text-center">
      <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
      <button type="button" class="btn btn-primary" (click)="deleteCategory1()">Confirmer</button>
    </div>
  </ng-template>

  <!----success product modal -->
  <ng-template #successSaveProduct let-modal>
    <div class="modal-header text-center border-0">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body py-0 text-center">
      <div class="row">
        <div class="col-12">
          <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
        </div>
      </div>
      <label class="mt-3"> {{successModalMessage}} </label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
    </div>
  </ng-template>


  <!----failure product modal -->
  <ng-template #failureSaveProduct let-modal>
    <div class="modal-header text-center border-0">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body py-0 text-center">
      <div class="row">
        <div class="col-12">
          <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
        </div>
      </div>
      <label class="mt-3"> {{failureModalMessage}} </label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
    </div>
  </ng-template>

</div>

<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>