<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">

        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" id="prod">Historique des transactions</a>
          </li>
        </ul>
        <div class="tab-content">

          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
              <div class="row mt-4 mb-3">
                <div class="col-lg-8 col-md-6 col-sm-6 col-12">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title">Loyalty total</h6>
                      <p class="card-text"> FC {{ loyaltyTotal }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

         

          <div class="row mt-1 mb-3">
            <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

              <div class="card mb-3 d-lg-none" *ngFor="let item of dataSource | paginate: { itemsPerPage: 10, currentPage: page }">
                <div class="card-body">
                <div class="row mb-2">
                  <div class="col-6 card-table-header">
                    Transaction date
                  </div>
                  <div class="col-6">
                    {{item.transaction_date}}
                  </div>
                </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Quantity in bottle
                    </div>
                    <div class="col-6">
                      {{item.quantity_in_bottle}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Sold To
                    </div>
                    <div class="col-6">
                      {{item.sold_to}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Amount Spent
                    </div>
                    <div class="col-6">
                      {{item.amount_spent}}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 card-table-header">
                      Loyalty Gained
                    </div>
                    <div class="col-6">
                      {{item.loyalty_gained}}
                    </div>
                  </div>
                </div>
              </div>
              <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-lg-none" (pageChange)="page = $event" previousLabel="Précédent"
                                  nextLabel="Suivant"></pagination-controls>



              <mat-table [dataSource]="dataSourceSupplierHistory" matSort class="d-none d-lg-block">
                <ng-container matColumnDef="transaction_date">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Transaction date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.transaction_date}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="product_name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Product Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.product_name}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="quantity_in_bottle">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Quantity in bottle
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.quantity_in_bottle}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sold_to">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Sold To
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.sold_to}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="amount_spent">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Amount Spent
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    FC {{row.amount_spent}} 
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="loyalty_gained">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Loyalty Gained
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    FC {{row.loyalty_gained}}
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsSupplier">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsSupplier">
                </mat-row>
              </mat-table>
              <div class="mt-3" *ngIf="tableHasData === false">Aucun fournisseur trouvé
              </div>
              <mat-paginator #paginatorTransReport (page)="onMatTableChangePage($event)" class="d-none d-lg-block"  [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                              
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1" [formGroup]="transactionFilterForm">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <small class="form-text text-muted">Mode de lecture</small>
                    <select  class="form-control"  formControlName="warehouseSelect">
                      <option value="2" selected>Voir tous </option>
                      <option value="1">Par Depot</option>
                      <option value="0">Etablissement</option>
                    </select>
                  </div>
                </div>
  
                  <div class="col-12">
                      <small class="form-text text-muted">Datant de</small>
                      <input class="form-control" [readonly]="true"
                          [owlDateTimeTrigger]="dateFrom" [owlDateTime]="dateFrom"
                          name="dateFrom" formControlName="dateFrom">
                      <owl-date-time #dateFrom></owl-date-time>
                  </div>
  
                  <div class="col-12">
                      <small class="form-text text-muted">à</small>
                      <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dateTo"
                          [owlDateTime]="dateTo" name="dateTo"
                          formControlName="dateTo">
                      <owl-date-time #dateTo></owl-date-time>
  
                  </div>
              </div>
              <div class="row my-3">
                  <div class="col-md-12">
                      <button type="button" class="btn btn-primary w-100"
                          (click)="filterPastOrders()">
                          <i class="fas fa-filter"></i> &nbsp;Filtre
                      </button>
                  </div>
              </div>
            </div>
          </div>

          <!-- EDIT SUPPLIER MODAL -->
          <ng-template #editSupplier let-modal>
            <div class="modal-header">
              <h5 class="modal-title">Modification des détails du fournisseur</h5>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="editSupplierForm">
                <div class="form-group">
                  <small class="form-text text-muted">Nom du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_name"
                         placeholder="Nom" required>
                  <div *ngIf="editSupplierForm.controls['supplier_name'].invalid &&
                                                            (editSupplierForm.controls['supplier_name'].dirty ||
                                                            editSupplierForm.controls['supplier_name'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['supplier_name'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Numéro de téléphone du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_cell"
                         placeholder="Numéro de téléphone" required>
                  <div *ngIf="editSupplierForm.controls['supplier_cell'].invalid &&
                                                            (editSupplierForm.controls['supplier_cell'].dirty ||
                                                            editSupplierForm.controls['supplier_cell'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['supplier_cell'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Code de la part du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_code"
                         placeholder="Code" required>
                  <div *ngIf="editSupplierForm.controls['supplier_code'].invalid &&
                                                            (editSupplierForm.controls['supplier_code'].dirty ||
                                                            editSupplierForm.controls['supplier_code'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['supplier_code'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Adresse du fournisseur</small>
                  <input type="text" class="form-control" formControlName="supplier_address"
                         placeholder="Addresse" required>
                  <div *ngIf="editSupplierForm.controls['supplier_address'].invalid &&
                                                            (editSupplierForm.controls['supplier_address'].dirty ||
                                                            editSupplierForm.controls['supplier_address'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['supplier_address'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <small class="form-text text-muted">Réduction du fournisseur</small>
                  <input type="text" class="form-control" formControlName="loyalty_percent"
                         placeholder="discount %" required>
                  <div *ngIf="editSupplierForm.controls['loyalty_percent'].invalid &&
                                                        (addSupplierForm.controls['loyalty_percent'].dirty ||
                                                        addSupplierForm.controls['loyalty_percent'].touched)"
                       class="alert alert-light pt-0">
                    <div *ngIf="editSupplierForm.controls['loyalty_percent'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer text-center">
              <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
              </button>
              <button type="button" [disabled]="!editSupplierForm.valid" class="btn btn-primary btn-lg"
                      (click)='confirmEdidtSupplier()'>Sauvegarder
              </button>
            </div>
          </ng-template>

          <!----delete product modal -->
          <ng-template #deleteSupplier let-modal>
            <div class="modal-header text-center">
              <h5 class="modal-title">Confirmation de suppression</h5>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir supprimer ce fournisseur ?</label>
                <h5>{{tempSupplierName}}</h5>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
              </button>
              <button type="button" class="btn btn-primary btn-lg"
                      (click)='confirmDelete()'>Confirmer
              </button>
            </div>
          </ng-template>

          <!----success product modal -->
          <ng-template #successModal let-modal>
            <div class="modal-header text-center border-0">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-0 text-center">
              <div class="row">
                <div class="col-12">
                  <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                </div>
              </div>
              <label class="mt-3"> {{successModalMessage}} </label>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
            </div>
          </ng-template>


          <!----failure product modal -->
          <ng-template #failureModal let-modal>
            <div class="modal-header text-center border-0">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-0 text-center">
              <div class="row">
                <div class="col-12">
                  <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                </div>
              </div>
              <label class="mt-3"> {{failureModalMessage}} </label>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>

