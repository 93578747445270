import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {Branch} from '../interface/branch';
import {UpdateBranch} from '../interface/updateBranch';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {}

  getBranch(companyId): Observable<Branch[]> {
    return this.http.post<Branch[]>(this.apiURL + '/branch/getall?intCompanyID=' + companyId, null);
  }

  getOneBranchByBranchId(branchId: number, companyId: number): Observable<Branch> {
    return this.http.post<Branch>(this.apiURL + '/branch/getone?intBranchID=' + branchId + '&intCompanyID=' + companyId, null);
  }

  getBranchByCompany(companyId: number): Observable<Branch[]> {
    return this.http.post<Branch[]>(this.apiURL + '/branch/getall?intCompanyID=' + companyId, null);
  }

  deleteBranch(branchid): Observable<Branch> {
    return this.http.post<Branch>(this.apiURL + '/branch/delete?intBranchID=' + branchid, null);
  }

  updateBranch(body): Observable<UpdateBranch> {
    return this.http.post<UpdateBranch>(this.apiURL + '/branch/update', body);
  }

  searchBranch(companyId: number, branchName: string): Observable<Branch []> {

    if ((branchName !== null) && (branchName !== '') && (branchName !== undefined)) {
    return this.http.post<Branch []>(this.apiURL + '/branch/searchbranch?companyID=' + companyId +
      '&branchName=' + branchName, null);
    } else{
      return this.http.post<Branch[]>(this.apiURL + '/branch/getall?intCompanyID=' + companyId, null);
    }
  }

  saveBranch(body): Observable<Branch> {
    return this.http.post<Branch>(this.apiURL + '/branch/save', body);
  }
}
