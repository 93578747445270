<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">
        <div class="row">
          <div class="col-lg-3">
            <div class="employeeTab">
              <div class="heading">
                <h2>Modifier l'employé</h2>
              </div>
              <div class="personIcon">
                <i class="bi bi-person-circle"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-9 mt-19">
            <!-- <div class="container"> -->
            <div class="employeePassword">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link active" id="prod" (click)="showEmployeeInfo()">Informations de base</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " id="cat">Changer le mot de passe</a>
                </li>
              </ul>
            </div>
            <br>
            <form class="row g-3" [formGroup]="employeePasswordForm">
              <div class="col-md-12">
                <p>Votre nouveau mot de passe doit contenir au moins:
                  <br>
                  <br> un chiffre (0-9)
                  <br> une lettre majuscule (A...Z)
                  <br> une lettre minuscule (a...z)
                  <br> un caractère spécial (!@#%)
                  <br>
                  <br> Générer un mot de passe ou créer un nouveau mot de passe
                </p>
                <div class="col-md-6">
                  <label for="inputName" class="form-label"></label>
                  <input type="text" class="form-control" id="inputName"
                         placeholder="Generate Password">
                  <div class="generateButton mt-2">
                    <button type="submit" class="btn btn-primary">Sauvegarder le profil</button>
                  </div>

                </div>
              </div>
            </form>
            <!-- </div> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
