import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FirstTimeUser } from '../../models/user/first-time-user';
import { EmployeeQuestionAnswer } from '../../models/user/employee-question-answer';
import { RecoveryQuestion } from '../../models/user/recovery-question';
import { Router } from '@angular/router';
import { EmployeeService } from '../../services/employee.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../common/Constants';
import { Employee } from '../../interface/createEmployee';
import { QuestionAnswer } from 'src/app/models/user/questionAnswer';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  verifySecretQuestionForgotPasswordForm: FormGroup;
  verifyUsernameForgotPasswordForm: FormGroup;
  saveNewPasswordForm: FormGroup;
  isFirstPartCompleted = false;
  isSecondPartCompleted = false;
  spin: boolean;
  employee: Employee;
  questionAnswers: QuestionAnswer[];

  firstTimeUser: FirstTimeUser;
  employeeQuestionOne: EmployeeQuestionAnswer;
  employeeQuestionTwo: EmployeeQuestionAnswer;
  employeeQuestions: Array<EmployeeQuestionAnswer>;

  recoveryQuestions: RecoveryQuestion[];

  isOnlineMsg;

  retryAttempts = 0;

  @ViewChild('successSaveProduct', { static: true }) successSaveProduct: TemplateRef<any>;
  @ViewChild('failureSaveProduct', { static: true }) failureSaveProduct: TemplateRef<any>;

  closeResult: string;
  successModalMessage: string;
  failureModalMessage: string;

  validationMessage = {
    username: [{ type: 'required', message: 'Ne devrait pas être vide' }]
  };

  validationMessage2 = {
    questionOne: [{ type: 'required', message: 'Ne devrait pas être vide' }],
    answerOne: [{ type: 'required', message: 'Ne devrait pas être vide' }],
    questionTwo: [{ type: 'required', message: 'Ne devrait pas être vide' }],
    answerTwo: [{ type: 'required', message: 'Ne devrait pas être vide' }]
  };

  validationMessage3 = {
    password: [{ type: 'required', message: 'Ne devrait pas être vide' }],
    password2: [{ type: 'required', message: 'Ne devrait pas être vide' }]
  };

  constructor(private router: Router, private employeeService: EmployeeService, private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.createForm();
    this.getAllQuestions();
  }

  createForm(): void {
    this.verifySecretQuestionForgotPasswordForm = this.formBuilder.group({
      questionOne: new FormControl(null, [Validators.required]),
      answerOne: new FormControl(null, [Validators.required]),
      questionTwo: new FormControl(null, [Validators.required]),
      answerTwo: new FormControl(null, [Validators.required])
    });

    this.verifyUsernameForgotPasswordForm = this.formBuilder.group({
      username: new FormControl(null, [Validators.required])
    });

    this.saveNewPasswordForm = this.formBuilder.group({
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      password2: new FormControl(null, [Validators.required, Validators.minLength(8)])
    });
  }

  verifyUsername(): void {
    for (const index in this.verifyUsernameForgotPasswordForm.controls) {
      if (this.verifyUsernameForgotPasswordForm.controls[index]) {
        this.verifyUsernameForgotPasswordForm.controls[index].markAsTouched();
      }
    }

    if (this.verifyUsernameForgotPasswordForm.valid) {

      this.spin = true;
      this.employeeService.verifyUsername(this.verifyUsernameForgotPasswordForm.controls.username.value).subscribe(response => {
        this.questionAnswers = response;

        if (this.questionAnswers.length > 0) {
          this.isFirstPartCompleted = true;
          this.isSecondPartCompleted = false;

          this.verifySecretQuestionForgotPasswordForm.controls.questionOne.setValue(this.questionAnswers[0].question);
          this.verifySecretQuestionForgotPasswordForm.controls.questionTwo.setValue(this.questionAnswers[1].question);
        } else {

          this.isFirstPartCompleted = false;
          this.isSecondPartCompleted = false;
          this.showFailureModal('Votre nom d\'utilisateur n\'a pas pu être verifié');
        }

        this.spin = false;
      }, error => {
        if (error.status === 423) {
          this.showFailureModal('Vôtre compte a été bloqué');
        } else {
          this.showFailureModal('Votre nom d\'utilisateur n\'a pas pu être verifié');
        } 
        this.spin = false;
      });
    }
  }

  verifyAnswers(): void {
    for (const index in this.verifySecretQuestionForgotPasswordForm.controls) {
      if (this.verifySecretQuestionForgotPasswordForm.controls[index]) {
        this.verifySecretQuestionForgotPasswordForm.controls[index].markAsTouched();
      }
    }

    if (this.verifySecretQuestionForgotPasswordForm.valid) {

      this.spin = true;

      if (this.retryAttempts < 3) {
        if ((this.verifySecretQuestionForgotPasswordForm.controls.answerOne.value === this.questionAnswers[0].answer) &&
          ((this.verifySecretQuestionForgotPasswordForm.controls.answerTwo.value === this.questionAnswers[1].answer))) {
          this.isFirstPartCompleted = true;
          this.isSecondPartCompleted = true;
          this.spin = false;
        } else {
          this.isFirstPartCompleted = true;
          this.isSecondPartCompleted = false;
          this.showFailureModal('Une ou plusieurs réponses sont incorrectes');
          this.spin = false;
          this.retryAttempts ++;
        }
      }
      else {
        this.employeeService.lockUsername(this.verifyUsernameForgotPasswordForm.controls.username.value).subscribe(response => {
        
          this.showFailureModal('Vôtre compte a été bloqué');

          this.router.navigate([Constants.LOGIN_ROUTE]);

        }, error => {
          this.spin = false;
        });
      }
    }
  }

  // verifyAnswerEntered(): boolean {
  //   return true;
  // }

  savePassword(): void {

    for (const index in this.saveNewPasswordForm.controls) {
      if (this.saveNewPasswordForm.controls[index]) {
        this.saveNewPasswordForm.controls[index].markAsTouched();
      }
    }

    if (this.saveNewPasswordForm.valid) {

      if (this.saveNewPasswordForm.controls.password.value === this.saveNewPasswordForm.controls.password2.value) {

        this.spin = true;

        this.employeeService.changePassword(this.verifyUsernameForgotPasswordForm.controls.username.value,
          this.saveNewPasswordForm.controls.password2.value).subscribe(response => {
            if (response) {
              this.showSuccessModal('Vos details ont été sauvegardé avec succès');
              this.router.navigate([Constants.LOGIN_ROUTE]);
            } else {
              this.showFailureModal('Vos details n\'ont pas pu sauvegardé');
            }

            this.spin = false;
          },
            error => {
              this.showFailureModal('Vos details n\'ont pas pu sauvegardé');
              this.spin = false;
            });

      } else {
        this.spin = false;
        this.isOnlineMsg = 'Les mots de passe devraient être identique';
      }

    }
  }

  getAllQuestions(): void {
    this.employeeService.getAllQuestionRecovery().subscribe(res => {
      this.recoveryQuestions = res;
      this.recoveryQuestions = this.recoveryQuestions.filter(qst => qst.question !== 'None');
    }, error => {
      this.spin = false;
    });
  }

  cancel(): void {
    //this.authenticationService.logOut(this.firstLoginChangePasswordForm.controls.username.value);
    this.router.navigate([Constants.LOGIN_ROUTE]);
  }

  showSuccessModal(message: string): void {
    this.open(this.successSaveProduct);
    this.successModalMessage = message;
    // document.getElementById('showSuccesMessageAddProduct').click();
  }

  showFailureModal(message: string): void {
    this.open(this.failureSaveProduct);
    this.failureModalMessage = message;
    // document.getElementById('showFailureMessageAddProduct').click();
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
