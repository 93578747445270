<div class="body">
  <div class="container">
    <div class="row">
      <div class="offset-md-3 col-md-6 grid-margin stretch-card login-card">
        <div class="card mb-4">
          <div class="card-body">

            <h5>Mot de passe oublié</h5>
            <h6 class="font-weight-light mb-4">Veuillez suivre la procédure pour récupérer votre accès à votre compte.</h6>

            <div>
              <div class="form-group">
                <p class="color-fail">{{ isOnlineMsg }}</p>
              </div>
            </div>

            <form class="pt-3" class="forms-sample" [formGroup]="verifyUsernameForgotPasswordForm" *ngIf="!isFirstPartCompleted ">
              <h6>Étape 1</h6>
              <div class="form-group mt-2">
                <small class="form-text text-muted">Veillez entrer votre nom d'utilisateur</small>
                <input type="text" class="form-control form-control-lg" formControlName="username"
                       placeholder="Nom d'utilisateur">
                <ng-container *ngFor="let validation of validationMessage.username">
                  <div class="color-fail"
                       *ngIf="verifyUsernameForgotPasswordForm.get('username').hasError(validation.type) &&
                       (verifyUsernameForgotPasswordForm.get('username').dirty || verifyUsernameForgotPasswordForm.get('username').touched)">
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>

              <div class="mt-3">
                <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn tn-bg-green-color"
                     (click)="verifyUsername()">
                  CONFIRMER
                </div>
                <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn btn-danger"
                     (click)="cancel()">
                  ANNULER
                </div>
              </div>
            </form>

            <form class="pt-3" class="forms-sample" [formGroup]="verifySecretQuestionForgotPasswordForm" *ngIf="isFirstPartCompleted && !isSecondPartCompleted ">
              <h6>Étape 2</h6>
              <div class="form-group mt-2">
                <small class="form-text text-muted">Question secrète no 1</small>
                <input type="text" class="form-control form-control-lg" formControlName="questionOne" [attr.disabled]="true">

                <small class="form-text text-muted">Votre réponse</small>
                <input type="text" class="form-control form-control-lg" formControlName="answerOne" placeholder="Votre réponse">
                <ng-container *ngFor="let validation of validationMessage2.answerOne">
                  <div class="color-fail" *ngIf="verifySecretQuestionForgotPasswordForm.get('answerOne').hasError(validation.type) &&
                                    (verifySecretQuestionForgotPasswordForm.get('answerOne').dirty || verifySecretQuestionForgotPasswordForm.get('answerOne').touched)">
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>

              <div class="form-group">
                <small class="form-text text-muted">Question secrète no 2</small>
                <input type="text" class="form-control form-control-lg" formControlName="questionTwo" [attr.disabled]="true">

                <small class="form-text text-muted">Votre réponse</small>
                <input type="text" class="form-control form-control-lg" formControlName="answerTwo" placeholder="Votre réponse">
                <ng-container *ngFor="let validation of validationMessage2.answerTwo">
                  <div class="color-fail" *ngIf="verifySecretQuestionForgotPasswordForm.get('answerTwo').hasError(validation.type) &&
                                    (verifySecretQuestionForgotPasswordForm.get('answerTwo').dirty || verifySecretQuestionForgotPasswordForm.get('answerTwo').touched)">
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>

              <div class="mt-3">
                <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn tn-bg-green-color"
                     (click)="verifyAnswers()">
                  CONFIRMER VOS REPONSES
                </div>
                <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn btn-danger"
                     (click)="cancel()">
                  ANNULER
                </div>
              </div>
            </form>

            <form class="pt-3" class="forms-sample" [formGroup]="saveNewPasswordForm" *ngIf="isFirstPartCompleted && isSecondPartCompleted">
              <h6>Étape 3</h6>
              <div class="form-group mt-2">
                <small class="form-text text-muted">Veuillez entrer votre nouveau mot de passe</small>
                <input type="password" class="form-control form-control-lg" formControlName="password"
                       placeholder="Mot de passe">
                <ng-container *ngFor="let validation of validationMessage3.password">
                  <div class="color-fail" *ngIf="saveNewPasswordForm.get('password').hasError(validation.type) &&
                                    (saveNewPasswordForm.get('password').dirty || saveNewPasswordForm.get('password').touched)">
                    {{ validation.message }}
                  </div>
                  <div class="color-fail"  *ngIf="saveNewPasswordForm.get('password').hasError('minlength')">
                    Le mot de passe ne peut pas avoir moins de 8 caractères
               </div>
                </ng-container>
              </div>
              <div class="form-group">
                <small class="form-text text-muted">Veuillez répéter votre mot de passe entré ci-dessus</small>
                <input type="password" class="form-control form-control-lg" formControlName="password2"
                       placeholder="Mot de passe">
                <ng-container *ngFor="let validation of validationMessage3.password2">
                  <div class="color-fail" *ngIf="saveNewPasswordForm.get('password2').hasError(validation.type) &&
                                      (saveNewPasswordForm.get('password2').dirty || saveNewPasswordForm.get('password2').touched)">
                    {{ validation.message }}
                  </div>
                  <div class="color-fail"  *ngIf="saveNewPasswordForm.get('password2').hasError('minlength')">
                    Le mot de passe ne peut pas avoir moins de 8 caractères
               </div>
                </ng-container>
              </div>

              <div class="mt-3">
                <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn tn-bg-green-color"
                     (click)="savePassword()">
                  SAUVEGARDER
                </div>
                <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn btn-danger"
                     (click)="cancel()">
                  ANNULER
                </div>
              </div>

            </form>

            <!----success product modal -->
            <ng-template #successSaveProduct let-modal>
              <div class="modal-header text-center border-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body py-0 text-center">
                <div class="row">
                  <div class="col-12">
                    <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                  </div>
                </div>
                <label class="mt-3"> {{successModalMessage}} </label>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
              </div>
            </ng-template>


            <!----failure product modal -->
            <ng-template #failureSaveProduct let-modal>
              <div class="modal-header text-center border-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body py-0 text-center">
                <div class="row">
                  <div class="col-12">
                    <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                  </div>
                </div>
                <label class="mt-3"> {{failureModalMessage}} </label>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
              </div>
            </ng-template>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Chargement...</span>
    </div>
  </div>
</div>
