import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import { SaleReport } from 'src/app/models/order/SaleReport';
import { PastOrderReport } from 'src/app/models/order/PastOrderReport';
import { StockUpdateReport } from 'src/app/models/order/StockUpdateReport';
import { StockUpdateOutgoingReport } from 'src/app/models/order/StockUpdateOutgoingReport';
// import { EmployeePerformanceReport } from 'src/app/models/order/EmployeePerformanceReport';
import * as $ from 'jquery';
import { OrderItem } from 'src/app/models/order-items';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { Input } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { Employee } from 'src/app/interface/createEmployee';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company/company';

@Component({
  selector: 'app-report-past-order',
  templateUrl: './report-past-order.component.html',
  styleUrls: ['./report-past-order.component.scss']
})
export class ReportPastOrderComponent implements OnInit {
  searchForm: FormGroup;
  searchFormStockUpdateEntry: FormGroup;
  searchFormStockUpdateOutgoing: FormGroup;

  filterFormPastOrders: FormGroup;

  dataSourcePastOrders: MatTableDataSource<PastOrderReport>;
  dataSourceOrderItems: MatTableDataSource<OrderItem>;

  @ViewChild('paginatorOrderReport') paginatorOrderReport: MatPaginator;
  @ViewChild('sortOrderReport') sortOrderReport: MatSort;

  @ViewChild('paginatorOrderItem') paginatorOrderItem: MatPaginator;
  @ViewChild('sortOrderItem') sortOrderItem: MatSort;

  totalRevenue: number;
  currencyDefault: string;
  totalNumberOfOrders_SaleReport: number;
  totalNumberOfOrders: number;
  mostPopularDrink: string;
  numberOfBottleOfTheMostPopularDrink: number;
  waiterNameWithMostRevenue: string;
  waiterNameWithMostRevenueAmount: number;
  waiterNameWithLeastRevenue: string;
  waiterNameWithLeastRevenueAmount: number;
  waiterNameWithMostSale: string;
  waiterNameWithMostSaleAmount: number;
  waiterNameWithLeastSale: string;
  waiterNameWithLeastSaleAmount: number;

  tableHasDataOrderReport: boolean;

  tableHasOrderItemData: boolean;

  displayedColumnsPastOrderReport = [
    'OrderID',
    'Date & Time',
    'Waiter',
    'Amount paid',
    'Action'
  ];

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  pastOrderReports: PastOrderReport[];
  productDTOs: ProductDTO[];

  branches: Branch[];
  employeeWaiters: Employee[];

  startDateSaleReport: string;
  endDateSaleReport: string;

  startDatePastOrders: string;
  endDatePastOrders: string;

  @Input() id: string;

  pageOrderReport = 1;
  viewOrderItemPage = 1;

  orderItems: OrderItem[];
  closeResult: string;

  selectedOrderID_PastOrders: number;
  selectedOrderTotalAmount_PastOrders: number;
  selectedOrderDiscount_PastOrders: number;
  selectedOrderAmountToBePaid_PastOrders: number;
  selectedOrderCurrency_PastOrders: string;
  selectedOrderComment_PastOrders: string;
  selectedOrderStartTime_PastOrders: string;
  selectedOrderWaiterName_PastOrders: string;
  selectedOrderRate_PastOrders: string;
  selectedOrderAmountToBePaidInUSD_PastOrders: string;


  //Printing
  company: Company;
  printCompanyName: string;
  printCompanyAddress: string;
  printCompanyCity: string;
  printCompanyCountry: string;
  printEstablishment: string;
  printDate: string;
  orderItemsToPrint: OrderItem[];

  branch_type:number = 1;

  searchOrderForm: FormGroup;

  @ViewChild('viewOrderItem', { static: true }) viewOrderItem: TemplateRef<any>;

  isWaiterDropdownEnabled: boolean;

  workingCurrency: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private employeeService: EmployeeService,
    private formBuilder: FormBuilder, private companyService: CompanyService) {

    this.pastOrderReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.createForms();

    this.getCompanyData();

    this.userLoggedIn = this.authenticationService.currentUserValue;
    this.getWorkingCurrency(this.authenticationService.currentUserValue.branchID);

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.spin = true;

      this.loadBranchesPastOrders();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadPastOrders(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadPastOrders(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterFormPastOrders.controls['dtPastOrdersFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormPastOrders.controls['dtPastOrdersTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

      this.totalRevenue = 0;
      this.totalNumberOfOrders = 0;
      this.totalNumberOfOrders_SaleReport = 0;
      this.mostPopularDrink = '';
      this.numberOfBottleOfTheMostPopularDrink = 0;

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  getWorkingCurrency(branchID: number) {
    this.branchService.getOneBranchByBranchId(branchID,
      this.authenticationService.currentUserValue.companyID).subscribe(res => {

        this.workingCurrency = res.branch_currency;

        this.spin = false;
      }, error => {
        this.spin = false;
      });
  }

  getCompanyData() {
    this.spin = true;

    var date = new Date();
    this.printDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds())).format('DD-MM-YYYY HH:mm:ss').toString();

    this.companyService.getCompanyByID(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      
      this.company = res;
        this.printCompanyName = this.company.company_name;
        this.printCompanyAddress = this.company.company_address + ', ' + this.company.company_address_suburb;
        this.printCompanyCity = this.company.company_address_city + ', ' + this.company.company_address_province;
        this.printCompanyCountry = this.company.company_address_country;

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  createForms() {

    this.filterFormPastOrders = this.formBuilder.group({
      dtPastOrdersFrom: new FormControl(null, Validators.required),
      dtPastOrdersTo: new FormControl(null, Validators.required),
      dpWaiter: new FormControl(null),
      dpBranch: new FormControl(null),
    });

    this.searchOrderForm = this.formBuilder.group({
      searchOrderID: ['']
    });
  }

  searchOrder(branchId): void {
    this.spin = true;

    this.pastOrderReports = [];

    const order_id = this.searchOrderForm.controls.searchOrderID.value;
    this.orderService.searchOrder(this.authenticationService.currentUserValue.companyID, branchId, order_id).subscribe(res => {
      this.pastOrderReports = res;

      if (this.pastOrderReports.length > 0) {
        this.tableHasDataOrderReport = true;
        this.dataSourcePastOrders = new MatTableDataSource(this.pastOrderReports);
        this.dataSourcePastOrders.paginator = this.paginatorOrderReport;
        this.dataSourcePastOrders.sort = this.sortOrderReport;
      } else {
        this.tableHasDataOrderReport = false;
      }
      this.spin = false;

    }, err => {
      this.tableHasDataOrderReport = false;
      this.dataSourcePastOrders = new MatTableDataSource([]);
      this.dataSourcePastOrders.paginator = this.paginatorOrderReport;
      this.dataSourcePastOrders.sort = this.sortOrderReport;
      this.spin = false;
    });
  }

  searchReportPastOrders(branchIdPastOrders) {
    this.spin = true;

    var date = new Date();

    if (this.filterFormPastOrders.controls['dtPastOrdersFrom'].value !== null) {
      this.startDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDatePastOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDatePastOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadReportPastOrders(branchIdPastOrders, this.startDatePastOrders, this.endDatePastOrders);
  }

  loadBranchesPastOrders() {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.filterFormPastOrders.controls.dpBranch.enable();
        this.filterFormPastOrders.controls.dpBranch.setValue(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

        this.filterFormPastOrders.controls.dpBranch.disable();
        this.filterFormPastOrders.controls.dpBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  loadAllWaitersInBranch(branchIdPastOrders) {
    this.spin = true;
    this.employeeService.getAllWaiters(this.authenticationService.currentUserValue.companyID, branchIdPastOrders).subscribe(res => {
      this.employeeWaiters = res;
      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  loadPastOrdersPerWaiter(employeeID) {
    this.spin = true;

    var date = new Date();

    if (this.filterFormPastOrders.controls['dtPastOrdersFrom'].value !== null) {
      this.startDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDatePastOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDatePastOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.orderService.getPastOrdersReport(this.authenticationService.currentUserValue.companyID, Number(employeeID),
      Number(this.filterFormPastOrders.controls.dpBranch.value), this.startDatePastOrders, this.endDatePastOrders).subscribe(res => {
        this.pastOrderReports = res;

        if (this.pastOrderReports.length > 0) {
          this.tableHasDataOrderReport = true;
          this.dataSourcePastOrders = new MatTableDataSource(this.pastOrderReports);
          this.dataSourcePastOrders.paginator = this.paginatorOrderReport;
          this.dataSourcePastOrders.sort = this.sortOrderReport;

          this.currencyDefault = this.pastOrderReports[0].currency_one;

        } else {
          this.tableHasDataOrderReport = false;
        }
        this.spin = false;

      }, error => {
        this.tableHasDataOrderReport = false;
        this.dataSourcePastOrders = new MatTableDataSource([]);
        this.dataSourcePastOrders.paginator = this.paginatorOrderReport;
        this.dataSourcePastOrders.sort = this.sortOrderReport;
        this.spin = false;
      });
  }

  loadOrderItemsByOrderID(orderID: number, discount: number, orderTotalAmount: number, currency: string, comment:string, 
    orderStartTime: string, employeeFullname: string, order_rate: number) {
    this.spin = true;

    this.selectedOrderID_PastOrders = orderID;
    this.selectedOrderTotalAmount_PastOrders = discount + orderTotalAmount;
    this.selectedOrderDiscount_PastOrders = discount;
    this.selectedOrderAmountToBePaid_PastOrders = orderTotalAmount;
    this.selectedOrderCurrency_PastOrders = currency;
    this.selectedOrderComment_PastOrders = comment;
    this.selectedOrderStartTime_PastOrders = orderStartTime;
    this.selectedOrderWaiterName_PastOrders = employeeFullname;
    this.selectedOrderRate_PastOrders = order_rate.toString();
    this.selectedOrderAmountToBePaidInUSD_PastOrders = (orderTotalAmount / order_rate).toString();

    this.open(this.viewOrderItem);


    this.orderService.getAllOrderItem(orderID).subscribe(res => {

      this.orderItems = res;

      if (this.orderItems.length > 0) {
        this.tableHasOrderItemData = true;
        this.dataSourceOrderItems = new MatTableDataSource(this.orderItems);
        this.dataSourceOrderItems.paginator = this.paginatorOrderItem;
        this.dataSourceOrderItems.sort = this.sortOrderItem;

      } else {
        this.tableHasOrderItemData = false;
      }
      this.spin = false;

    }, error => {
      this.tableHasOrderItemData = false;
      this.dataSourceOrderItems = new MatTableDataSource([]);
      this.dataSourceOrderItems.paginator = this.paginatorOrderItem;
      // this.dataSourceOrderItems.sort = this.sortOrderItem;
      this.spin = false;
    });
  }

  loadReportPastOrders(branchIdPastOrders: number, fromDatePastOrders: string, toDatePastOrders: string) {
    this.spin = true;

    this.orderService.getPastOrdersReport(this.authenticationService.currentUserValue.companyID,
      0, branchIdPastOrders, fromDatePastOrders, toDatePastOrders).subscribe(res => {
        this.pastOrderReports = res;

        if (this.pastOrderReports.length > 0) {
          this.tableHasDataOrderReport = true;
          this.dataSourcePastOrders = new MatTableDataSource(this.pastOrderReports);
          this.dataSourcePastOrders.paginator = this.paginatorOrderReport;
          this.dataSourcePastOrders.sort = this.sortOrderReport;

          this.currencyDefault = this.pastOrderReports[0].currency_one;
        } else {
          this.tableHasDataOrderReport = false;
        }
        this.spin = false;

      }, error => {
        this.tableHasDataOrderReport = false;
        this.dataSourcePastOrders = new MatTableDataSource([]);
        this.dataSourcePastOrders.paginator = this.paginatorOrderReport;
        this.dataSourcePastOrders.sort = this.sortOrderReport;
        this.spin = false;
      });

  }

  filterPastOrders(branchIdPastOrders) {
    this.startDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    let employeeID_ = this.filterFormPastOrders.controls.dpWaiter.value;

    if ((employeeID_ !== 0) && (employeeID_ !== null)) {
      this.loadPastOrdersPerWaiter(employeeID_);
    } else {
      this.loadReportPastOrders(branchIdPastOrders, this.startDatePastOrders, this.endDatePastOrders);
    }
  }

  loadPastOrders(branchIdPastOrders) {

    this.searchOrderForm.controls.searchOrderID.reset();

    if (branchIdPastOrders === 0) {
      this.filterFormPastOrders.controls.dpWaiter.disable();
      this.isWaiterDropdownEnabled = false;
    } else {
      this.isWaiterDropdownEnabled = true;
      this.filterFormPastOrders.controls.dpWaiter.enable();
      this.loadAllWaitersInBranch(branchIdPastOrders);

    }

    var date = new Date();

    if (this.filterFormPastOrders.controls['dtPastOrdersFrom'].value !== null) {
      this.startDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDatePastOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDatePastOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadReportPastOrders(branchIdPastOrders, this.startDatePastOrders, this.endDatePastOrders);
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event: any) {
    this.pageOrderReport = event;
    window.scroll(0, 0);
  }
}
