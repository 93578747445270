import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Company} from 'src/app/models/company/company';
import {Supplier} from 'src/app/interface/supplier';
import {SupplierService} from 'src/app/services/supplier.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Constants} from '../../common/Constants';
import * as $ from 'jquery';
import { EmployeeService } from 'src/app/services/employee.service';
import { Warehouse } from 'src/app/models/warehouse/Warehouse';
import { WarehouseService } from 'src/app/services/warehouse/warehouse.service';
import { WarehouseCategoryService } from 'src/app/services/warehouseCategory/warehouse-category.service';
import { WarehouseSubcategoryService } from 'src/app/services/warehouseSubcategory/warehouse-subcategory.service';
import { Employee } from 'src/app/interface/createEmployee';

@Component({
  selector: 'app-warehouse-setup',
  templateUrl: './warehouse-setup.component.html',
  styleUrls: ['./warehouse-setup.component.scss']
})
export class WarehouseSetupComponent implements OnInit {

  warehouseSearchForm: FormGroup;
  editWarehouseForm: FormGroup;
  deleteWarehouseForm: FormGroup;
  addSupplierForm: FormGroup;
  spin: boolean;
  showTable: boolean;
  tempWarehouse: Warehouse;
  tempWarehouseName: any;
  companies: Company;
  warehouseForm: FormGroup;
  wareHouseLists: Warehouse[];
  warehouse: Warehouse;
  employees: Employee [];
  employeeListToShow: Employee [];


  dataSourceWarehouse: MatTableDataSource<Warehouse>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsWarehouse = [
    'Name',
    'Manager',
    'Cellphone',
    'Address',
    'Actions'
  ];

  // warehouse_id: number;
  // warehouse_name: string;
  // warehouse_cell: string;
  // warehouse_email: string;
  // warehouse_address: string;
  // warehouse_currency: string;
  // employee_manager_id: number;
  // company_id: number;
  // status: number;

  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;
  @ViewChild('failureModal', {static: true}) failureModal: TemplateRef<any>;
  @ViewChild('editWarehouse', {static: true}) editWarehouseModal: TemplateRef<any>;
  @ViewChild('deleteWarehouse', {static: true}) deleteWarehouseModal: TemplateRef<any>;
  @ViewChild('createNewWarehouse', { static: true }) createNewWarehouseModal: TemplateRef<any>;

  @ViewChild('selectedBranch') selectedBranch: ElementRef<any>;

  closeResult: string;

  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  tableHasData: boolean;

  pager = 1;

  constructor(private warehouseService: WarehouseService, private warehouseCategoryService: WarehouseCategoryService,
    private warehouseSubcategoryService: WarehouseSubcategoryService, private employeeService: EmployeeService,
     private supplierService: SupplierService, private fb: FormBuilder, private authenticationService: AuthenticationService, 
     private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.createForm();
    this.getWarehouses();
    this.getEmployees();
  }

  createForm(): void {
    this.warehouseForm = this.fb.group({
      warehouse_name: [''],
      warehouse_address: [''],
      warehouse_cell: [''],
      warehouse_email: [''],
      manager: ['']
    });

    this.warehouseSearchForm = this.fb.group({
      searchWarehouseName: ['']
    });

    this.editWarehouseForm = this.fb.group({
      warehouse_name: [''],
      warehouse_address: [''],
      warehouse_cell: [''],
      warehouse_email: [''],
      manager: ['']
    });

    this.deleteWarehouseForm = this.fb.group({
      company_id: [''],
      supplier_name: ['']
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  searchWarehouse(): void {

    this.spin = true;

    const name = this.warehouseSearchForm.controls.searchWarehouseName.value;
    this.warehouseService.searchWarehouse(this.authenticationService.currentUserValue.companyID, name).subscribe(res => {
      this.wareHouseLists = res;

      if (this.wareHouseLists.length > 0) {
        this.tableHasData = true;
        this.dataSourceWarehouse = new MatTableDataSource(this.wareHouseLists);
        this.dataSourceWarehouse.paginator = this.paginator;
        this.dataSourceWarehouse.sort = this.sort;
      } else {
        this.tableHasData = false;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceWarehouse = new MatTableDataSource([]);
      this.dataSourceWarehouse.paginator = this.paginator;
      this.dataSourceWarehouse.sort = this.sort;
      this.spin = false;

    });
  }

  getEmployees(): void {

    this.spin = true;

    this.employeeService.getAllCompanyEmployees(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.employees = res;

      this.employeeListToShow = this.employees.filter(x => (x.role_name !== Constants.ROLE_ADMINISTRATOR) && 
      (x.role_name !== Constants.ROLE_DEPUTY_ADMINISTRATOR));

      // // collection: any[] = someArrayOfThings;

      // if (this.suppliers.length > 0) {
      //   this.tableHasData = true;
      //   this.dataSourceSupplier = new MatTableDataSource(this.suppliers);
      //   this.dataSourceSupplier.paginator = this.paginator;
      //   this.dataSourceSupplier.sort = this.sort;
      // } else {
      //   this.tableHasData = false;
      // }
      this.spin = false;

    }, err => {
      // this.tableHasData = false;
      // this.dataSourceSupplier = new MatTableDataSource([]);
      // this.dataSourceSupplier.paginator = this.paginator;
      // this.dataSourceSupplier.sort = this.sort;
      // this.spin = false;

    });
  }


  saveNewWarehouse(): void {
    this.spin = true;

    let newWarehouse: Warehouse;
    newWarehouse = {
      warehouse_id: 0,
      warehouse_name: this.warehouseForm.controls.warehouse_name.value,
      warehouse_address: this.warehouseForm.controls.warehouse_address.value,
      warehouse_cell: this.warehouseForm.controls.warehouse_cell.value,
      warehouse_email: this.warehouseForm.controls.warehouse_email.value,
      warehouse_currency: 'FC',
      employee_manager_id: Number(this.warehouseForm.controls.manager.value),
      employee_manager_name:'',
      company_id: this.authenticationService.currentUserValue.companyID,
      status: 1
    };

    this.warehouseService.saveWarehouse(newWarehouse).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('Votre dépôt a été enregistré avec succès');
        this.getWarehouses();
        // if (this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMIN_MANAGER) {
        //   this.warehouseIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        // } else {
        //   this.warehouseIdToBeUsed = this.productForm.controls.warehouse.value;
        // }
        // this.productForm.reset();

        // this.loadWarehouseProducts(this.warehouseIdToBeUsed);
      } else {
        this.showFailureModal('L\'ajout de votre dépôt a échoué, réessayez plus tard');
      }
      this.spin = false;
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Ce dépôt éxiste déjà');
      } else {
        this.showFailureModal('L\'ajout du dépôt a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  getWarehouses(): void {

    this.spin = true;

    this.warehouseService.getWarehousesByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.wareHouseLists = res;

      if (this.wareHouseLists.length > 0) {
        this.tableHasData = true;
        this.dataSourceWarehouse = new MatTableDataSource(this.wareHouseLists);
        this.dataSourceWarehouse.paginator = this.paginator;
        this.dataSourceWarehouse.sort = this.sort;
      } else {
        this.tableHasData = false;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceWarehouse = new MatTableDataSource([]);
      this.dataSourceWarehouse.paginator = this.paginator;
      this.dataSourceWarehouse.sort = this.sort;
      this.spin = false;

    });
  }

  // addSuppleir(): void {
  //   this.spin = true;
  //   let supplier: Supplier;
  //   supplier = {
  //     supplier_id: 0,
  //     supplier_name: this.addSupplierForm.controls.supplier_name.value,
  //     supplier_address: this.addSupplierForm.controls.supplier_address.value,
  //     supplier_cell: this.addSupplierForm.controls.supplier_cell.value,
  //     supplier_code: this.addSupplierForm.controls.supplier_code.value,
  //     company_id: this.authenticationService.currentUserValue.companyID,
  //     status: 0,
  //     date_created: '',
  //     last_modified: ''
  //   };
  //   this.supplierService.addSupplier(supplier).subscribe(res => {
  //     if (res) {
  //       this.modalService.dismissAll();
  //       this.addSupplierForm.reset();
  //       this.showSuccessModal('Le dépôt a été enregistré avec succès');

  //       this.getSuppliers();
  //     } else {
  //       this.showFailureModal('L\'ajout du dépôt a échoué, réessayez plus tard');
  //     }
  //     this.spin = false;
  //     // document.getElementById('addSupplier').click();

  //   }, error => {
  //     if (error.status === 409) {
  //       this.modalService.dismissAll();
  //       this.showFailureModal('Ce dépôt éxiste déjà');
  //     } else {
  //       this.showFailureModal('L\'ajout du dépôt a échoué, réessayez plus tard');
  //     }
  //     this.spin = false;
  //   });
  // }

  editWarehouse1(warehouse: Warehouse): void {
    this.open(this.editWarehouseModal);
    this.getCompany(warehouse.company_id);
    this.tempWarehouse = warehouse;

    this.editWarehouseForm.controls.warehouse_email.setValue(warehouse.warehouse_email);
    this.editWarehouseForm.controls.warehouse_name.setValue(warehouse.warehouse_name);
    this.editWarehouseForm.controls.warehouse_address.setValue(warehouse.warehouse_address);
    this.editWarehouseForm.controls.warehouse_cell.setValue(warehouse.warehouse_cell);
    this.editWarehouseForm.controls.manager.setValue(warehouse.employee_manager_id);
    // this.editWarehouseForm.controls.manager.patchValue(product.supplier_id);

  }

  confirmEditWarehouse(): void {
    this.spin = true;
    let editWarehouse: Warehouse;

    editWarehouse = {
      warehouse_id: this.tempWarehouse.warehouse_id,
      warehouse_name: this.editWarehouseForm.controls.warehouse_name.value,
      warehouse_address: this.editWarehouseForm.controls.warehouse_address.value,
      warehouse_cell: this.editWarehouseForm.controls.warehouse_cell.value,
      warehouse_email: this.editWarehouseForm.controls.warehouse_email.value,
      warehouse_currency: 'FC',
      employee_manager_id: Number(this.editWarehouseForm.controls.manager.value),
      employee_manager_name: this.tempWarehouse.employee_manager_name,
      company_id: this.authenticationService.currentUserValue.companyID,
      status: 1
    };

    this.warehouseService.saveEditWarehouse(editWarehouse).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('Ce dépôt a été modifié avec succès');
        this.getWarehouses();
      } else {
        this.showFailureModal('Ce dépôt n\'a pas pu être mis à jour');
      }
      this.spin = false;

      // document.getElementById('editSupplier').click();

    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Ce dépôt éxiste déjà');
      } else {
        this.showFailureModal('Ce dépôt n\'a pas pu être mis à jour');
      }
      this.spin = false;
    });
  }

  deleteWarehouse1(warehouse: Warehouse): void {
    this.open(this.deleteWarehouseModal);
    this.tempWarehouse = warehouse;
    this.tempWarehouseName = this.tempWarehouse.warehouse_name;
  }

  confirmDelete(): void {
    this.spin = true;
    this.warehouseService.deleteWarehouse(this.tempWarehouse).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('Ce dépôt a été supprimé avec succès');
        this.getWarehouses();
      } else {
        this.showFailureModal('Ce dépôt n\'a pas pu être supprimé');
        this.spin = false;
      }
      
      // document.getElementById('deleteSupplier').click();
    }, error => {
      this.showFailureModal('Ce dépôt n\'a pas pu être supprimé');
      this.spin = false;
    });
  }

  // addSupplierButton(): void {
  //   this.open(this.addSupplierModal);
  //   // this.getCompanies();
  // }

  openCreateNewWarehouseModal(): void {
    this.warehouseForm.reset();
    
    this.open(this.createNewWarehouseModal);
    // this.getWarehouses();
  }

  getCompany(id): void {
    this.supplierService.getCompany(id).subscribe(res => {
      this.companies = res;
    }, error => {
      this.spin = false;
    });
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}