import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import { EmployeePerformanceReport } from 'src/app/models/order/EmployeePerformanceReport';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';

@Component({
  selector: 'app-report-employee-performance',
  templateUrl: './report-employee-performance.component.html',
  styleUrls: ['./report-employee-performance.component.scss']
})
export class ReportEmployeePerformanceComponent implements OnInit {
  userLoggedIn: UserLoggedIn;
  spin: boolean;

  closeResult: string;

  dataSourceEmployeePerformance: MatTableDataSource<EmployeePerformanceReport>;

  @ViewChild('paginatorEmployeePerformanceReport') paginatorEmployeePerformanceReport: MatPaginator;
  @ViewChild('sortEmployeePerformanceReport') sortEmployeePerformanceReport: MatSort;

  searchForm: FormGroup;

  tableHasDataEmployeePerformance: boolean;

  totalRevenue: number;
  currencyDefault: string;
  branchCurrencyForLowStockProduct: string;
  totalNumberOfOrders_SaleReport: number;
  totalNumberOfOrders: number;
  mostPopularDrink: string;
  numberOfBottleOfTheMostPopularDrink: number;
  waiterNameWithMostRevenue: string;
  waiterNameWithMostRevenueAmount: number;
  waiterNameWithLeastRevenue: string;
  waiterNameWithLeastRevenueAmount: number;
  waiterNameWithMostSale: string;
  waiterNameWithMostSaleAmount: number;
  waiterNameWithLeastSale: string;
  waiterNameWithLeastSaleAmount: number;

  tableHasOrderItemData: boolean;

  filterForm: FormGroup;

  displayedColumns = [
    'Name',
    'Branch',
    'Total Amount Brought In',
    'Number of Sale'
  ];

  employeePerformanceReport: EmployeePerformanceReport;

  employeePerformanceReports: EmployeePerformanceReport[];

  filterFormGroupValidationMessage = {
    dtEmployeePerformanceFrom: [{ type: 'required', message: 'Please enter from date' }],
    dtEmployeePerformanceTo: [{ type: 'required', message: 'Please enter to date' }]
  };

  branches: Branch[];

  startDate: string;
  endDate: string;

  @Input() id: string;
  employeePerformancePage = 1;

  workingCurrency: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private formBuilder: FormBuilder) {
    this.employeePerformanceReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;
    this.getWorkingCurrency(this.authenticationService.currentUserValue.branchID);

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.spin = true;

      this.loadBranches();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadEmployeePerformance(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadEmployeePerformance(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterForm.controls['dtEmployeePerformanceFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterForm.controls['dtEmployeePerformanceTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

      this.totalRevenue = 0;
      this.totalNumberOfOrders = 0;
      this.totalNumberOfOrders_SaleReport = 0;
      this.mostPopularDrink = '';
      this.numberOfBottleOfTheMostPopularDrink = 0;

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  createForms() {
    this.searchForm = this.formBuilder.group({
      searchEmployee: new FormControl(null, Validators.required)
    });


    this.filterForm = this.formBuilder.group({
      dtEmployeePerformanceFrom: new FormControl(null, Validators.required),
      dtEmployeePerformanceTo: new FormControl(null, Validators.required),
      selectBranch: ['']
    });

  }

  getWorkingCurrency(branchID: number) {
    this.branchService.getOneBranchByBranchId(branchID,
      this.authenticationService.currentUserValue.companyID).subscribe(res => {

        this.workingCurrency = res.branch_currency;

        this.spin = false;
      }, error => {
        this.spin = false;
      });
  }

  searchEmployeePerformanceReport(branchId) {
    this.spin = true;

    var date = new Date();

    if (this.filterForm.controls['dtEmployeePerformanceFrom'].value !== null) {
      this.startDate = moment(this.filterForm.controls['dtEmployeePerformanceFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(this.filterForm.controls['dtEmployeePerformanceTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadEmployeePerformanceReport(branchId, this.startDate, this.endDate);
  }

  loadBranches() {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.filterForm.controls.selectBranch.enable();
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

        this.filterForm.controls.selectBranch.disable();
        this.filterForm.controls.selectBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  filterEmployeePerformance(branchId) {
    this.spin = true;

    this.startDate = moment(this.filterForm.controls['dtEmployeePerformanceFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDate = moment(this.filterForm.controls['dtEmployeePerformanceTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadEmployeePerformanceReport(branchId, this.startDate, this.endDate);
  }

  loadEmployeePerformanceReport(branchId: number, fromDate: string, toDate: string) {
    this.spin = true;
    this.orderService.getEmployeePerformanceReport(this.authenticationService.currentUserValue.companyID,
      branchId, fromDate, toDate).subscribe(res => {
        this.employeePerformanceReports = res;

        if (this.employeePerformanceReports.length > 0) {
          this.tableHasDataEmployeePerformance = true;

          let mostRevenue = this.getDetailsOfTheMostRevenueMade(this.employeePerformanceReports);
          this.waiterNameWithMostRevenue = mostRevenue.employee_surname + ' ' + mostRevenue.employee_firstname;
          this.waiterNameWithMostRevenueAmount = mostRevenue.total_amount_brought_in;

          let leastRevenue = this.getDetailsOfTheLeastRevenueMade(this.employeePerformanceReports);
          this.waiterNameWithLeastRevenue = leastRevenue.employee_surname + ' ' + leastRevenue.employee_firstname;
          this.waiterNameWithLeastRevenueAmount = leastRevenue.total_amount_brought_in;

          let mostSale = this.getDetailsOfTheMostAmountOfSale(this.employeePerformanceReports);
          this.waiterNameWithMostSale = mostSale.employee_surname + ' ' + mostSale.employee_firstname;
          this.waiterNameWithMostSaleAmount = mostSale.numberOfSales;

          let leastSale = this.getDetailsOfTheLeastAmountOfSale(this.employeePerformanceReports);
          this.waiterNameWithLeastSale = leastSale.employee_surname + ' ' + leastSale.employee_firstname;
          this.waiterNameWithLeastSaleAmount = leastSale.numberOfSales;

          this.dataSourceEmployeePerformance = new MatTableDataSource(this.employeePerformanceReports);
          this.dataSourceEmployeePerformance.paginator = this.paginatorEmployeePerformanceReport;
          this.dataSourceEmployeePerformance.sort = this.sortEmployeePerformanceReport;

        } else {
          this.waiterNameWithMostRevenue = ' ';
          this.waiterNameWithMostRevenueAmount = 0;
          this.waiterNameWithLeastRevenue = ' ';
          this.waiterNameWithLeastRevenueAmount = 0;
          this.waiterNameWithMostSale = ' ';
          this.waiterNameWithMostSaleAmount = 0;
          this.waiterNameWithLeastSale = ' ';
          this.waiterNameWithLeastSaleAmount = 0;

          this.tableHasDataEmployeePerformance = false;
        }
        this.spin = false;
      }, error => {
        this.waiterNameWithMostRevenue = ' ';
        this.waiterNameWithMostRevenueAmount = 0;
        this.waiterNameWithLeastRevenue = ' ';
        this.waiterNameWithLeastRevenueAmount = 0;
        this.waiterNameWithMostSale = ' ';
        this.waiterNameWithMostSaleAmount = 0;
        this.waiterNameWithLeastSale = ' ';
        this.waiterNameWithLeastSaleAmount = 0;

        this.tableHasDataEmployeePerformance = false;
        this.dataSourceEmployeePerformance = new MatTableDataSource([]);
        this.dataSourceEmployeePerformance.paginator = this.paginatorEmployeePerformanceReport;
        this.dataSourceEmployeePerformance.sort = this.sortEmployeePerformanceReport;
        this.spin = false;
      });

  }

  getDetailsOfTheMostAmountOfSale(employeePerformanceReports: EmployeePerformanceReport[]) {
    let maxNumberOfSale = Math.max.apply(Math, employeePerformanceReports.map(function (o) {
      return o.numberOfSales;
    }));
    return employeePerformanceReports.find(x => x.numberOfSales === maxNumberOfSale);
  }

  getDetailsOfTheMostRevenueMade(employeePerformanceReports: EmployeePerformanceReport[]) {
    let maxRevenue = Math.max.apply(Math, employeePerformanceReports.map(function (o) {
      return o.total_amount_brought_in;
    }));
    return employeePerformanceReports.find(x => x.total_amount_brought_in === maxRevenue);
  }

  getDetailsOfTheLeastAmountOfSale(employeePerformanceReports: EmployeePerformanceReport[]) {
    let minNumberOfSale = Math.min.apply(Math, employeePerformanceReports.map(function (o) {
      return o.numberOfSales;
    }));
    return employeePerformanceReports.find(x => x.numberOfSales === minNumberOfSale);
  }

  getDetailsOfTheLeastRevenueMade(employeePerformanceReports: EmployeePerformanceReport[]) {
    let minRevenue = Math.min.apply(Math, employeePerformanceReports.map(function (o) {
      return o.total_amount_brought_in;
    }));
    return employeePerformanceReports.find(x => x.total_amount_brought_in === minRevenue);
  }

  loadEmployeePerformance(branchId) {
    var date = new Date();

    if (this.filterForm.controls['dtEmployeePerformanceFrom'].value !== null) {
      this.startDate = moment(this.filterForm.controls['dtEmployeePerformanceFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(this.filterForm.controls['dtEmployeePerformanceTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();

    }

    this.loadEmployeePerformanceReport(branchId, this.startDate, this.endDate);
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event) {
    this.employeePerformancePage = event;
    window.scroll(0, 0);
  }

}
