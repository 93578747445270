import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from 'src/app/common/Constants';
import { environment } from 'src/environments/environment';
import { Expense } from '../models/Expense';
import { ExpenseType } from '../models/ExpenseType';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {
  }

  getAllExpenseType(): Observable<ExpenseType[]> {
    return this.http.post<ExpenseType[]>(this.apiURL + '/expense/getAllExpenseType', null);
  }

  saveExpense(expense: Expense): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/expense/save', expense);
  }

  deleteExpense(expense: Expense): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/expense/delete?e=' + expense.expense_id, null);
  }

  // getExpenseByExpenseTypeAndBranches(branchID: number, startDate: string, endDate: string, expenseTypeSelected: any): Observable<Expense[]> {
  //   return this.http.post<Expense[]>(environment.apiBase
  //     .concat('/expense/getAllByExpenseTypeAndBranchID?et=').concat(expenseTypeSelected).concat('&b=').concat(branchID.toString())
  //     .concat('&df=').concat(startDate)
  //     .concat('&dt=').concat(endDate), null);
  // }

  public getExpenseByBranches(branchID: number, dateFrom: string, dateTo: string, expenseTypeSelected: number): Observable<Expense[]> {
    return this.http.post<Expense[]>(environment.apiBase
      .concat('/expense/getAllByBranchID?b=').concat(branchID.toString())
      .concat('&df=').concat(dateFrom)
      .concat('&dt=').concat(dateTo).concat('&et=').concat(expenseTypeSelected.toString()), null);
  }   

  public searchExpenseByBranches(branchID: number, expenseDescription: string, dateFrom: string, dateTo: string): Observable<Expense[]> {
    return this.http.post<Expense[]>(environment.apiBase
      .concat('/expense/searchExpenses?b=').concat(branchID.toString())
      .concat('&ed=').concat(expenseDescription)
      .concat('&df=').concat(dateFrom)
      .concat('&dt=').concat(dateTo), null);
  }
}
