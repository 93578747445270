<div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">


        <div class="container-fluid">
            <div class="panel mt-5">

                <form [formGroup]="filterFormDebtsdOrders">
                    <div class="row mt-1">
                        <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                            <div class="card mb-3 d-md-none"
                                *ngFor="let item of debtsOrderReports | paginate: { itemsPerPage: 10, currentPage: pageDebtsOrderReport, id: 'orderReport' }">
                                <div class="card-body">
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            OrderID
                                        </div>
                                        <div class="col-6">
                                            {{item.order_id}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Date et heure
                                        </div>
                                        <div class="col-6">
                                            {{item.order_start_time}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Serveur(se)
                                        </div>
                                        <div class="col-6">
                                            {{item.employee_fullname}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Montant total
                                        </div>
                                        <div class="col-6">
                                            {{item.currency_one}}
                                            {{item.total_amount}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Montant payé
                                        </div>
                                        <div class="col-6">
                                            {{item.currency_one}}
                                            {{item.total_amount_paid}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                        Dette a payé
                                        </div>
                                        <div class="col-6">
                                            {{item.currency_one}}
                                            {{item.remainder}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                        Client
                                        </div>
                                        <div class="col-6">
                                            {{item.customer_fullname}}
                                            {{item.customer_cellnumber}}
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary"
                                                (click)="openConfirmationModal(item.order_id, item.remainder, item.customer_id, item.orderRate, 
                                                item.total_amount_paid, item.total_amount_paidFC, item.total_amount_paidUSD, item.branch_id)"><i
                                                    class="fa fa-check"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <pagination-controls *ngIf="tableHasDataDebtOrderReport === true"
                                class="mb-3 text-center d-md-none" (pageChange)="onMobileTableChangePage($event)"
                                id="orderReport" previousLabel="Précédent" nextLabel="Suivant">
                            </pagination-controls>
                            
                            <mat-table [dataSource]="dataSource" class="d-none d-md-block" matSort>
                                <ng-container matColumnDef="OrderID">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        No de commande
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <div>
                                            {{row.order_id}}
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Date & Time">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Date et heure
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.order_start_time}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Waiter">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Serveur(se)
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.employee_fullname}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="TotalAmount">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Montant total
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.currency_one}}
                                        {{row.total_amount}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="AmountPayed">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Montant payé
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.currency_one}}
                                        {{row.total_amount_paid}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Remainder">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Dette à payé
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.currency_one}}
                                        {{row.remainder}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Customer">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Client
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.customer_fullname}}
                                        {{row.customer_cellnumber}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Action">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Action
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <div>
                                            <button
                                                (click)="openConfirmationModal(row.order_id, row.remainder, row.customer_id, row.orderRate, 
                                                row.total_amount_paid, row.total_amount_paidFC, row.total_amount_paidUSD, row.branch_id)" class="btn btn-primary">
                                                <i class="fa fa-check"></i></button>
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumnsDebtOrderReport">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsDebtOrderReport">
                                </mat-row>
                            </mat-table>
                            <div class="mt-3" *ngIf="tableHasDataDebtOrderReport === false">Aucune vente
                                trouvé
                            </div>
                            <mat-paginator #paginatorOrderReport class="d-none d-md-block mb-4" [pageSize]="10"
                                [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <select #selectedBranch class="form-control" formControlName="dpBranch"
                                            (change)="loadDebtsOrders($event.target.value)">
                                            <option value="0">
                                                Tous les établissement
                                            </option>
                                            <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                                                {{branch.branch_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <small class="form-text text-muted">Datant de</small>
                                    <input class="form-control" [readonly]="true"
                                        [owlDateTimeTrigger]="dtDebtOrdersFrom"
                                        [owlDateTime]="dtDebtOrdersFrom" name="dtDebtOrdersFrom"
                                        formControlName="dtDebtOrdersFrom">
                                    <owl-date-time #dtDebtOrdersFrom></owl-date-time>
                                </div>

                                <div class="col-12">
                                    <small class="form-text text-muted">à</small>
                                    <input class="form-control" [readonly]="true"
                                        [owlDateTimeTrigger]="dtDebtOrdersTo" [owlDateTime]="dtDebtOrdersTo"
                                        name="dtDebtOrdersTo" formControlName="dtDebtOrdersTo">
                                    <owl-date-time #dtDebtOrdersTo></owl-date-time>

                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-primary w-100"
                                        (click)="filterPastOrders(selectedBranch.value)">
                                        <i class="fas fa-filter"></i> &nbsp;Filtre
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <!-- <ng-template #viewOrderItem let-modal>
                    <div class="modal-header text-center">
                        <h5 class="modal-title">Commande #{{selectedOrderID_PastOrders}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <div class="row mb-1"
                            *ngFor="let item of orderItems | paginate: { itemsPerPage: 5, currentPage: viewOrderItemPage, id: 'viewOrderItemPage' }">
                            <div class="col-6">
                                {{item.quantity}} x
                                {{item.product_name}}
                            </div>
                            <div class="col-6">
                                {{currencyDefault}}
                                {{item.cost_to_pay}}
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                Total
                            </div>
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                {{currencyDefault}}
                                {{selectedOrderTotalAmount_PastOrders}}
                            </div>
                        </div>
                        <div class="row tn-failure-color">
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                Réduction
                            </div>
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                - {{currencyDefault}} {{selectedOrderDiscount_PastOrders}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                Total à payer
                            </div>
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                {{currencyDefault}} {{selectedOrderAmountToBePaid_PastOrders}}
                            </div>
                        </div>
                        <pagination-controls *ngIf="tableHasDataCancelledOrderReport === true" class="mb-3 text-center"
                            (pageChange)="viewOrderItemPage = $event" id="viewOrderItemPage" previousLabel="Précédent"
                            nextLabel="Suivant">
                        </pagination-controls>

                        <hr>

                        <div class="row tn-blue-color">
                            <div class="col-lg-12 col-md-12 col-12 font-weight-500">
                                <h6 class="text-left text-underline margin-left-10px">Comment:</h6>
                                <p class="text-left font-weight-normal margin-left-10px">
                                    {{selectedOrderComment_PastOrders}}</p>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer text-center">
                        <button type="button" class="btn btn-primary" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template> -->

                <ng-template #fixingOrderDebt let-modal>
                    <div class="modal-header text-center">
                      <h5 class="modal-title" id="exampleModalLabel">Êtes-vous sûr de vouloir régulariser cette commande ?</h5>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body text-center">
                        <h6>Montant a payer pour régulariser la dette {{remainderToBePaidWithCurrency}}</h6>

                        <div class="row">
                            <div class="col-12 text-right mt-4">
                                <a *ngIf="!isMoreDetailEnabled" class="tn-blue-color font-weight-bold" (click)="showMoreDetail()">Cliquez ici pour ajouter plus de details</a>
                                <a *ngIf="isMoreDetailEnabled" class="tn-blue-color font-weight-bold" (click)="hideMoreDetail()">Cacher les details</a>
                            </div>
                        </div>

                        <form [formGroup]="debtsFixForm" *ngIf="isMoreDetailEnabled"> 
                            <div class="row">
                                  <div class="offset-xl-4 col-xl-4 offset-lg-4 col-lg-4 offset-md-4 col-md-4 col-sm-12 col-12">
                                    <div class="form-group">
                                      <small class="form-text text-muted">Montant à payer en FC</small>
                                      <div class="input-group input-group-sm mb-3"> 
                                        <div class="input-group-prepend">
                                          <small class="input-group-text" id="inputGroup-sizing-sm">FC</small>
                                        </div>
                                        <input type="number" class="form-control" formControlName="amountFC"
                                            placeholder="Montant en Franc Congolais" required>
                                          <div *ngIf="debtsFixForm.controls['amountFC'].invalid && (debtsFixForm.controls['amountFC'].dirty ||
                                                        debtsFixForm.controls['amountFC'].touched)" class="pt-0 alert alert-light">
                                            <div *ngIf="debtsFixForm.controls['amountFC'].errors.required">
                                            Ne devrait pas être vide
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            </div>
                
                            <div class="row">                 
                                    <div class="offset-xl-4 col-xl-4 offset-lg-4 col-lg-4 offset-md-4 col-md-4 col-sm-12 col-12">
                                        <div class="form-group">
                                      <small class="form-text text-muted">Montant à payer en USD</small>
                                      <div class="input-group input-group-sm mb-3"> 
                                        <div class="input-group-prepend">
                                          <small class="input-group-text" id="inputGroup-sizing-sm">USD</small>
                                        </div>
                                        <input type="number" class="form-control" formControlName="amountUSD"
                                            placeholder="Montant en dollars">
                                        <div *ngIf="debtsFixForm.controls['amountUSD'].invalid && (debtsFixForm.controls['amountUSD'].dirty ||
                                                        debtsFixForm.controls['amountUSD'].touched)" class="pt-0 alert alert-light">
                                          <div *ngIf="debtsFixForm.controls['amountUSD'].errors.required">
                                              Ne devrait pas être vide
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer text-center">
                      <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
                      <button type="button" class="btn btn-primary" *ngIf="!isMoreDetailEnabled" [disabled]="!debtsFixForm.valid"
                              (click)="fixingPayment(false)">Confirmer Paiement
                      </button>
                      <button type="button" class="btn btn-primary" *ngIf="isMoreDetailEnabled"
                              (click)="fixingPayment(true)">Confirmer Paiement
                      </button>
                    </div>
                  </ng-template>

                <!----success product modal -->
                <ng-template #successModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{successModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>


                <!----failure product modal -->
                <ng-template #failureModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{failureModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>