import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import { SaleReport } from 'src/app/models/order/SaleReport';
import { PastOrderReport } from 'src/app/models/order/PastOrderReport';
import { StockUpdateReport } from 'src/app/models/order/StockUpdateReport';
import { StockUpdateOutgoingReport } from 'src/app/models/order/StockUpdateOutgoingReport';
import * as $ from 'jquery';
import { OrderItem } from 'src/app/models/order-items';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { Input } from '@angular/core';
import { SaleAndProfitAndLossReportToDisplay } from 'src/app/models/order/SaleAndProfitAndLossReportToDisplay';
import {Company} from 'src/app/models/company/company';
import { CompanyService } from 'src/app/services/company.service';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  searchForm: FormGroup;
  searchFormStockUpdateEntry: FormGroup;
  searchFormStockUpdateOutgoing: FormGroup;

  filterFormSaleReport: FormGroup;

  dataSourceSaleReport: MatTableDataSource<SaleReport>;
  dataSourcePastOrders: MatTableDataSource<PastOrderReport>;
  dataSourceStockUpdateEntry: MatTableDataSource<StockUpdateReport>;
  dataSourceStockUpdateOutgoing: MatTableDataSource<StockUpdateOutgoingReport>;
  dataSourceOrderItems: MatTableDataSource<OrderItem>;

  @ViewChild(MatPaginator) paginatorSaleReport: MatPaginator;
  @ViewChild(MatSort) sortSaleReport: MatSort;

  @ViewChild('paginatorOrderItem') paginatorOrderItem: MatPaginator;
  @ViewChild('sortOrderItem') sortOrderItem: MatSort;

  totalRevenue: number;
  currencyDefault: string;
  totalNumberOfOrders_SaleReport: number;
  totalNumberOfOrders: number;
  mostPopularDrink: string;
  numberOfBottleOfTheMostPopularDrink: number;
  waiterNameWithMostRevenue: string;
  waiterNameWithMostRevenueAmount: number;
  waiterNameWithLeastRevenue: string;
  waiterNameWithLeastRevenueAmount: number;
  waiterNameWithMostSale: string;
  waiterNameWithMostSaleAmount: number;
  waiterNameWithLeastSale: string;
  waiterNameWithLeastSaleAmount: number;

  tableHasDataSaleReport: boolean;
  tableHasDataOrderReport: boolean;
  tableHasDataStockUpdateReport: boolean;
  tableHasDataStockUpdateOutgoingReport: boolean;
  tableHasDataEmployeePerformance: boolean;
  tableHasOrderItemData: boolean;

  displayedColumnsSaleReport = [
    'Branch',
    'Manager',
    'Number of Sale',
    'RevenueBranchCurrency',
    'RevenueDollars',
    'TotalRevenue',
  ];

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  saleReports: SaleReport[];
  saleReportToBeDisplayed: SaleAndProfitAndLossReportToDisplay[];
  saleListToPrint: SaleAndProfitAndLossReportToDisplay[];
  saletoPrint: SaleAndProfitAndLossReportToDisplay;

  // pastOrderReports: PastOrderReport[];
  stockUpdateReports: StockUpdateReport[];
  stockUpdateOutgoingReports: StockUpdateOutgoingReport[];
  productDTOs: ProductDTO[];

  branches: Branch[];

  startDateSaleReport: string;
  endDateSaleReport: string;

  startDatePastOrders: string;
  endDatePastOrders: string;

  startDateStockUpdate: string;
  endDateStockUpdate: string;

  startDate: string;
  endDate: string;

  @Input() id: string;
  pageSaleReport = 1;

  orderItems: OrderItem[];
  closeResult: string;

  // Printing
  printDate: string;
  company: Company;
  printCompanyName: string;
  printCompanyAddress: string;
  printCompanyCity: string;
  printCompanyCountry: string;
  printEstablishment: string;
  printDateFrom: string;
  printDateTo: string;
  printDrinkTotalRevenu: number;
  printFoodTotalRevenu: number;
  printOtherTotalRevenu: number;
  printTotalRevenu: number;

  workingCurrency: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private formBuilder: FormBuilder, private companyService: CompanyService) {
    this.saleReports = [];
    this.branches = [];
    this.saleReportToBeDisplayed = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    this.getWorkingCurrency(this.authenticationService.currentUserValue.branchID);

    console.log('res0 [' + this.filterFormSaleReport.controls['selectBranch'].value + ']');
    console.log('res1 [' + this.workingCurrency);

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) || 
    (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL)|| (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) 
    {
      this.spin = true;

      this.loadSaleReportBranches();
 
      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) || 
      (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) 
      {
        this.loadSaleReport(0);
      }  else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) || 
      (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadSaleReport(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterFormSaleReport.controls['dtSaleReportFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormSaleReport.controls['dtSaleReportTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

      this.totalRevenue = 0;
      this.totalNumberOfOrders = 0;
      this.totalNumberOfOrders_SaleReport = 0;
      this.mostPopularDrink = '';
      this.numberOfBottleOfTheMostPopularDrink = 0;

      this.printDrinkTotalRevenu = 0;
      this.printFoodTotalRevenu = 0;
      this.printOtherTotalRevenu = 0;
      this.printTotalRevenu = 0;

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  getWorkingCurrency(branchID: number){
    this.branchService.getOneBranchByBranchId(branchID, 
      this.authenticationService.currentUserValue.companyID).subscribe(res => {
     
        this.workingCurrency = res.branch_currency;
        this.currencyDefault = this.workingCurrency;

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  createForms() {
    this.searchForm = this.formBuilder.group({
      searchEmployee: new FormControl(null, Validators.required)
    });

    this.filterFormSaleReport = this.formBuilder.group({
      dtSaleReportFrom: new FormControl(null, Validators.required),
      dtSaleReportTo: new FormControl(null, Validators.required),
      selectBranch: ['']
    });
  }

  //SALES REPORT

  searchSaleReport(branchIdSaleReport) {
    this.spin = true;

    var date = new Date();

    if (this.filterFormSaleReport.controls['dtSaleReportFrom'].value !== null) {
      this.startDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      // this.startDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      // this.endDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), 0)).format('YYYY-MM-DD HH:mm:ss').toString();
    
      this.startDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
  
    }

    this.loadCompanySaleReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  loadSaleReportBranches(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) || 
      (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) 
      {
        this.filterFormSaleReport.controls.selectBranch.enable();
      }  else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) || 
      (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.filterFormSaleReport.controls.selectBranch.disable();
        this.filterFormSaleReport.controls.selectBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }
      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }


  filterSaleReport(branchIdSaleReport): void {
    this.startDateSaleReport = moment(this.filterFormSaleReport.controls.dtSaleReportFrom.value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateSaleReport = moment(this.filterFormSaleReport.controls.dtSaleReportTo.value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadCompanySaleReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  resetSummaryInfo(): void {
    this.totalRevenue = 0;
    this.totalNumberOfOrders = 0;
    this.totalNumberOfOrders_SaleReport = 0;
    this.mostPopularDrink = '';
    this.numberOfBottleOfTheMostPopularDrink = 0;

    this.printDrinkTotalRevenu = 0;
      this.printFoodTotalRevenu = 0;
      this.printOtherTotalRevenu = 0;
      this.printTotalRevenu = 0;

    this.saleReportToBeDisplayed = [];
  }



  getCompanyDetails(companyId: number): void {
    var date = new Date();
    this.printDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds())).format('DD-MM-YYYY HH:mm:ss').toString();

    this.companyService.getCompanyByID(companyId).subscribe(res => {
      this.company = res;
      this.printCompanyName = this.company.company_name;
      this.printCompanyAddress = this.company.company_address + ', ' + this.company.company_address_suburb;
      this.printCompanyCity = this.company.company_address_city + ', ' + this.company.company_address_province;
      this.printCompanyCountry = this.company.company_address_country;
    }, err => {
      // this.showTable = false;
      // this.spin = false;
    });
  }

  // print(saleListToPrint: SaleAndProfitAndLossReportToDisplay[]) {

  //   let myDate = new Date().toISOString();
  //   var today = myDate.split('T')[0];
  //   const currentDateAndTime = moment().format('YYYY-MM-DD HH:mm:ss')

  //   var myText2 = " \n";
  //   myText2 += "**************************** \n";
  //   myText2 += "         ARTICLE VENDU \n";
  //   myText2 += "**************************** \n";
  //   myText2 += "Aujourd'hui: " + currentDateAndTime + " \n";
  //   myText2 += "\n";
  //   myText2 += "\n";
  //   myText2 += "De: " + this.startDateSaleReport + "  \n";
  //   myText2 += "A: " + this.endDateSaleReport + "  \n";
  //   myText2 += "---------------------------- \n";
  //   myText2 += "\n";

  //   saleListToPrint.forEach(item => {
  //     myText2 += item.quantity_sold + "x   " + item.product_name + "   " + (item.selling_cost * item.quantity_sold) + " Fc \n";
  //   });

  //   myText2 += "\n";
  //   myText2 += "---------------------------- \n";
  //   myText2 += "TOTAL en FC: " + this.totalDrinkRevenuWithProfit + " Fc \n";
  //   myText2 += "TOTAL en USD: " + (this.totalDrinkRevenuWithProfit / 2000).toFixed(2) + " $ \n";
  //   myText2 += " \n";
  //   myText2 += " \n";
  //   myText2 += "---------------------------- \n";
  //   myText2 += "************ MERCI ********** \n";
  //   myText2 += " \n";

  //   this.printService.init()
  //     .setBold(true)
  //     .writeLine(myText2)
  //     .setBold(false)
  //     .setJustification('center')
  //     .feed(2)
  //     .cut('full')
  //     .flush();
  // }

  getPrintData(branchIDSelected: number, fromDate: string, toDate: string) {

    this.saleListToPrint = [];
    
    var startDatePrint = moment(this.filterFormSaleReport.controls.dtSaleReportFrom.value).format('YYYY-MM-DD HH:mm:ss').toString();
    var endDatePrint = moment(this.filterFormSaleReport.controls.dtSaleReportTo.value).format('YYYY-MM-DD HH:mm:ss').toString();
    
    this.printDateFrom = fromDate.toString();
    this.printDateTo = toDate.toString();

    this.orderService.getSaleAndProfitReport(this.authenticationService.currentUserValue.companyID,
      branchIDSelected, startDatePrint, endDatePrint).subscribe(res => {

        // this.dataItemToPrint = res.saleAndProfitReportList;
        // this.currencyDefault = 'FC'; //this.saleDrinkReports[0].currency_one;
        this.currencyDefault = this.workingCurrency;

        if (res.saleAndProfitReportList.length > 0) {

          res.saleAndProfitReportList.forEach(product => {

            this.saletoPrint = new SaleAndProfitAndLossReportToDisplay();

            let lossProductFound = res.saleAndLossReportList.find(p => p.product_name === product.product_name);

            if ((lossProductFound !== null) && (lossProductFound !== undefined)) {
              this.saletoPrint.quantity_loss = lossProductFound.quantity_sold;
            } else {
              this.saletoPrint.quantity_loss = 0;
            }

            // this.saletoPrint.quantity_loss = 0;
            this.saletoPrint.date_sale_placed = product.date_sale_placed;
            this.saletoPrint.product_name = product.product_name;
            this.saletoPrint.branch_id = product.branch_id;
            this.saletoPrint.branch_name = product.branch_name;
            this.saletoPrint.quantity_sold = product.quantity_sold;
            this.saletoPrint.buying_cost = product.buying_cost;
            this.saletoPrint.selling_cost = product.selling_cost;
            this.saletoPrint.profit_on_product = product.profit_on_product;
            this.saletoPrint.company_id = product.company_id;
            this.saletoPrint.category_name = product.category_name;

            this.saleListToPrint.push(this.saletoPrint);
          });

          // console.log('sale drink: ', res.saleAndProfitReportList.filter(x => x.category_name === Constants.CATEGORY_DRINK));
          // console.log('sale drink1: ', res.saleAndProfitReportList.filter(x => x.category_name === Constants.CATEGORY_FOOD));

           (res.saleAndProfitReportList.filter(x => x.category_name === Constants.CATEGORY_DRINK)).forEach(a => this.printDrinkTotalRevenu += (a.selling_cost * a.quantity_sold));
           (res.saleAndProfitReportList.filter(x => x.category_name === Constants.CATEGORY_FOOD)).forEach(a => this.printFoodTotalRevenu += (a.selling_cost * a.quantity_sold));
           (res.saleAndProfitReportList.filter(x => x.category_name === Constants.CATEGORY_OTHER_ITEM)).forEach(a => this.printOtherTotalRevenu += (a.selling_cost * a.quantity_sold));
           res.saleAndProfitReportList.forEach(a => this.printTotalRevenu += (a.selling_cost * a.quantity_sold));
          // this.print(this.saleListToPrint);

        } else {
          this.tableHasDataSaleReport = false;
          this.resetSummaryInfo();
        }

        this.spin = false;

      }, error => {
        // this.tableHasDataSaleReport = false;

        this.resetSummaryInfo();

        // this.dataSourceSaleAndProfitReport = new MatTableDataSource([]);
        // this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
        // this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;
        this.spin = false;
      });
  }

  // loadSoldProductSummaryReport(branchId: number, fromDate: string, toDate: string) {
  //   this.spin = true;

  //   this.resetSummaryInfo();
  //   this.orderService.getSaleAndProfitReport(this.authenticationService.currentUserValue.companyID,
  //     branchId, fromDate, toDate).subscribe(res => {

  //       // this.saleDrinkReports = res.saleAndProfitReportList;
  //       // this.saleDrinkLossReports = res.saleAndLossReportList;
  //       // this.saleSummaryReports = res.summaryItems;
  //       // this.currencyDefault = 'FC'; //this.saleDrinkReports[0].currency_one;

  //       // if (this.saleSummaryReports !== undefined) {
  //       //   if (this.saleSummaryReports.length > 0) {

  //       //     let drinkItemSummaryInfo = this.saleSummaryReports.find(x => x.categoryName === Constants.CATEGORY_DRINK);
  //       //     if (drinkItemSummaryInfo !== undefined) {
  //       //       this.totalDrinkRevenuWithProfit = drinkItemSummaryInfo.totalRevenu;
  //       //       this.totalDrinkRevenueWithoutProfit = drinkItemSummaryInfo.totalRevenu - drinkItemSummaryInfo.profit;
  //       //     } else {
  //       //       this.totalDrinkRevenuWithProfit = 0;
  //       //       this.totalDrinkRevenueWithoutProfit = 0;
  //       //     }

  //       //   } else {
  //       //   }
  //       // }

  //       if (res.saleAndProfitReportList.length > 0) {
  //         // this.tableHasDataSaleReport = true;

  //         // this.saleDrinkReports = this.saleDrinkReports.filter(x => x.category_name === Constants.CATEGORY_DRINK);

  //         res.saleAndProfitReportList.forEach(product => {

  //           this.saletoDisplay = new SaleAndProfitAndLossReportToDisplay();

  //           let lossProductFound = this.saleDrinkLossReports.find(p => p.product_name === product.product_name);

  //           if ((lossProductFound !== null) && (lossProductFound !== undefined)) {
  //             this.saletoDisplay.quantity_loss = lossProductFound.quantity_sold;
  //           } else {
  //             this.saletoDisplay.quantity_loss = 0;
  //           }

  //           this.saletoDisplay.date_sale_placed = product.date_sale_placed;
  //           this.saletoDisplay.product_name = product.product_name;
  //           this.saletoDisplay.branch_id = product.branch_id;
  //           this.saletoDisplay.branch_name = product.branch_name;
  //           this.saletoDisplay.quantity_sold = product.quantity_sold;
  //           this.saletoDisplay.buying_cost = product.buying_cost;
  //           this.saletoDisplay.selling_cost = product.selling_cost;
  //           this.saletoDisplay.profit_on_product = product.profit_on_product;
  //           this.saletoDisplay.company_id = product.company_id;
  //           this.saletoDisplay.category_name = product.category_name;
  //           this.saletoDisplay.subcategory_name = product.subcategory_name;

  //           this.saleReportToBeDisplayed.push(this.saletoDisplay);
  //         });

  //         // this.dataSourceSaleAndProfitReport = new MatTableDataSource(this.saleReportToBeDisplayed);
  //         // this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
  //         // this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;

  //       } else {
  //         // this.tableHasDataSaleReport = false;
  //         this.resetSummaryInfo();
  //       }

  //       this.spin = false;

  //     }, error => {
  //       this.tableHasDataSaleReport = false;

  //       this.resetSummaryInfo();

  //       // this.dataSourceSaleAndProfitReport = new MatTableDataSource([]);
  //       // this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
  //       // this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;
  //       this.spin = false;
  //     });
  // }








  loadCompanySaleReport(branchId: number, fromDate: string, toDate: string) {

    this.spin = true;

    this.resetSummaryInfo();

    this.getPrintData(branchId, fromDate, toDate);

    //get company detail to show on printing rapport
    this.getCompanyDetails(this.authenticationService.currentUserValue.companyID);

    this.orderService.getSalesReport(this.authenticationService.currentUserValue.companyID,
      branchId, fromDate, toDate).subscribe(res => {

        this.saleReports = res;

        if (this.saleReports.length > 0) {
          this.tableHasDataSaleReport = true;
          // this.currencyDefault = this.saleReports[0].currency_one;
          this.currencyDefault = this.workingCurrency;
          this.saleReports.forEach(a => this.totalRevenue += a.total_revenue);
          this.saleReports.forEach(a => this.totalNumberOfOrders_SaleReport += a.number_of_sales);

          this.dataSourceSaleReport = new MatTableDataSource(this.saleReports);
          this.dataSourceSaleReport.paginator = this.paginatorSaleReport;
          this.dataSourceSaleReport.sort = this.sortSaleReport;

        } else {
          this.tableHasDataSaleReport = false;

          this.resetSummaryInfo();
        }
        this.spin = false;

      }, error => {
        this.resetSummaryInfo();

        this.tableHasDataSaleReport = false;
        this.dataSourceSaleReport = new MatTableDataSource([]);
        this.dataSourceSaleReport.paginator = this.paginatorSaleReport;
        this.dataSourceSaleReport.sort = this.sortSaleReport;
        this.spin = false;
      });
  }

  loadSaleReport(branchIdSaleReport) {
    var date = new Date();

    if (this.filterFormSaleReport.controls['dtSaleReportFrom'].value !== null) {
      this.startDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
    
      this.startDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
  
    }

    this.getWorkingCurrency(branchIdSaleReport);

    this.loadCompanySaleReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event: any) {
    this.pageSaleReport = event;
    window.scroll(0, 0);
  }


}
