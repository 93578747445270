import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../interface/category';
import { SaveSubCategory } from '../interface/save-sub-category';
import { SubCategory } from '../interface/sub-category';
import { UpdateSubCategory } from '../interface/update-sub-category';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryServiceService {

  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) { }

  getSubCategories(category: Category): Observable<SubCategory []> {
    return this.http.post<SubCategory []>(this.apiURL + '/subcategory/getall?intCategoryID='
    + category.category_id, null);
  }

  getOneSubCategories(categoryId , subCategoryId): Observable<SubCategory> {
    return this.http.post<SubCategory>(this.apiURL + '/subcategory/getone?intSubCategoryID='
    + subCategoryId + '&intCategoryID=' + categoryId, null);

  }

  getAllSubCategoriesBasedOnProductId(prodcutId): Observable<SubCategory []> {
    return this.http.post<SubCategory [] >(this.apiURL + '/subcategory/getAllSubcategoryByProductID?productID='
    + prodcutId, null);

  }

  getSubCategoriesById(id): Observable<SubCategory []> {
    return this.http.post<SubCategory []>(this.apiURL + '/subcategory/getall?intCategoryID=' + id, null);
  }

  saveSubCategories(body): Observable<SaveSubCategory> {
    return this.http.post<SaveSubCategory>(this.apiURL + '/subcategory/save', body);
  }
  updateSubCategory(body: UpdateSubCategory): Observable<SaveSubCategory> {
    return this.http.post<SaveSubCategory>(this.apiURL + '/subcategory/update?subcategoryName=' + body.sub_name
    + '&' + 'subcategoryId=' + body.subcategory_id, null);
  }

  deleteSubCategory(subCategory: SubCategory): Observable<Category> {
    return this.http.post<Category>(this.apiURL + '/subcategory/delete?intSubCategoryID=' + subCategory.subcategory_id, null);
  }
  searchSubCategory(name): Observable<SubCategory[]> {
    return this.http.post<SubCategory[]>(this.apiURL + '/subcategory/searchsubcategory?categoryID=' + localStorage.getItem('categoryId') +
    '&subcategoryName=' + name, null);
  }
}
