import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import * as $ from 'jquery';
import { OrderItem } from 'src/app/models/order-items';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { StockUpdateReport } from 'src/app/models/order/StockUpdateReport';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { ProductService } from 'src/app/services/product.service';


@Component({
  selector: 'app-report-deducted-stock',
  templateUrl: './report-deducted-stock.component.html',
  styleUrls: ['./report-deducted-stock.component.scss']
})
export class ReportDeductedStockComponent implements OnInit {

  filterDeductedStockForm: FormGroup;

  reportType: number = 0;
  searchForm: FormGroup;
  searchFormStockUpdateEntry: FormGroup;
 

  dataSourceDeductedStockUpdateEntry: MatTableDataSource<StockUpdateReport>;

  // dataSourceOrderItems: MatTableDataSource<OrderItem>;


  @ViewChild('paginatorStockUpdateReport') paginatorStockUpdateReport: MatPaginator;
  @ViewChild('sortStockUpdateReport') sortStockUpdateReport: MatSort;

  @ViewChild('paginatorOrderItem') paginatorOrderItem: MatPaginator;
  @ViewChild('sortOrderItem') sortOrderItem: MatSort;

  @ViewChild('stockUpdateDiv') stockUpdateDiv: ElementRef;
  
  @ViewChild('viewDetails', { static: true }) viewDetails: TemplateRef<any>;


  // totalRevenue: number;
  // currencyDefault: string;

  tableHasDataStockUpdateReport: boolean;
  // tableHasOrderItemData: boolean;

  displayedColumnsStockUpdateReport = [
    'Product',
    'OldQuantity',
    'QuantityAdded',
    'CurrentQuantity',
    'Date',
    'Action'
  ];

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  stockUpdateReports: StockUpdateReport[];
  productDTOs: ProductDTO[];

  branches: Branch[];

  startDateStockUpdate: string;
  endDateStockUpdate: string;

  @Input() id: string;

  pageStockUpdateIncomingReport = 1;

  orderItems: OrderItem[];
  closeResult: string;

  comment: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private productService: ProductService, private branchService: BranchService, private formBuilder: FormBuilder) {

    this.stockUpdateReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.spin = true;

      this.loadBranchesStockUpdate();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadDeductionStockUpdate(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadDeductionStockUpdate(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterDeductedStockForm.controls['dtStockUpdateFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterDeductedStockForm.controls['dtStockUpdateTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

     
    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  createForms() {

    // this.searchFormStockUpdateEntry = this.formBuilder.group({
    //   searchStockUpdateEntry: new FormControl(null, Validators.required)
    // });

    this.filterDeductedStockForm = this.formBuilder.group({
      dtStockUpdateFrom: new FormControl(null, Validators.required),
      dtStockUpdateTo: new FormControl(null, Validators.required),
      selectedBranch: ['']
    });
  }

  searchReportStockUpdate(branchIdStockUpdate) {
    this.spin = true;

    var date = new Date();

    if (this.filterDeductedStockForm.controls['dtStockUpdateFrom'].value !== null) {
      this.startDateStockUpdate = moment(this.filterDeductedStockForm.controls['dtStockUpdateFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateStockUpdate = moment(this.filterDeductedStockForm.controls['dtStockUpdateTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateStockUpdate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateStockUpdate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadDeductionStockUpdateReport(branchIdStockUpdate, this.startDateStockUpdate, this.endDateStockUpdate);
  }

  loadBranchesStockUpdate() {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.filterDeductedStockForm.controls.selectedBranch.enable();
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
          this.filterDeductedStockForm.controls.selectedBranch.disable();
        this.filterDeductedStockForm.controls.selectedBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  loadDeductionStockUpdateReport(branchIdStockUpdate: number, fromDateStockUpdate: string, toDateStockUpdate: string) {
    this.spin = true;

    this.productService.getDeductedStockUpdateReport(this.authenticationService.currentUserValue.companyID,
      branchIdStockUpdate, fromDateStockUpdate, toDateStockUpdate).subscribe(res => {
        this.stockUpdateReports = res;

        if (this.stockUpdateReports.length > 0) {
          this.tableHasDataStockUpdateReport = true;
          this.dataSourceDeductedStockUpdateEntry = new MatTableDataSource(this.stockUpdateReports);
          this.dataSourceDeductedStockUpdateEntry.paginator = this.paginatorStockUpdateReport;
          this.dataSourceDeductedStockUpdateEntry.sort = this.sortStockUpdateReport;

        } else {
          this.tableHasDataStockUpdateReport = false;
          this.dataSourceDeductedStockUpdateEntry = new MatTableDataSource([]);
          this.dataSourceDeductedStockUpdateEntry.paginator = this.paginatorStockUpdateReport;
          this.dataSourceDeductedStockUpdateEntry.sort = this.sortStockUpdateReport;
        }
        this.spin = false;
      }, error => {
        this.tableHasDataStockUpdateReport = false;
        this.dataSourceDeductedStockUpdateEntry = new MatTableDataSource([]);
        this.dataSourceDeductedStockUpdateEntry.paginator = this.paginatorStockUpdateReport;
        this.dataSourceDeductedStockUpdateEntry.sort = this.sortStockUpdateReport;
        this.spin = false;
      });
  }

  filterStockUpdate(branchIdStockUpdate): void {
    this.startDateStockUpdate = moment(this.filterDeductedStockForm.controls['dtStockUpdateFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateStockUpdate = moment(this.filterDeductedStockForm.controls['dtStockUpdateTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadDeductionStockUpdateReport(branchIdStockUpdate, this.startDateStockUpdate, this.endDateStockUpdate);
  }

  loadDeductionStockUpdate(branchIdStockUpdate) {
    var date = new Date();

    if (this.filterDeductedStockForm.controls['dtStockUpdateFrom'].value !== null) {
      this.startDateStockUpdate = moment(this.filterDeductedStockForm.controls['dtStockUpdateFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateStockUpdate = moment(this.filterDeductedStockForm.controls['dtStockUpdateTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateStockUpdate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateStockUpdate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadDeductionStockUpdateReport(branchIdStockUpdate, this.startDateStockUpdate, this.endDateStockUpdate);
  }

  loadRecordDetail(_comment:string) {
    // this.spin = true;

    this.comment = _comment;

    this.open(this.viewDetails);

  }

  // changeReportType(reportTypeValue: number) {
  //   this.reportType = Number(reportTypeValue);
  // }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event) {
    this.pageStockUpdateIncomingReport = event; 
    this.stockUpdateDiv.nativeElement.scrollIntoView()
    // window.scroll(0,0);
  }
}