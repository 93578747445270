import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import { PastOrderReport } from 'src/app/models/order/PastOrderReport';
import * as $ from 'jquery';
import { OrderItem } from 'src/app/models/order-items';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { Input } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { Employee } from 'src/app/interface/createEmployee';
import { SaleAndProfitAndLossReport } from 'src/app/models/order/SaleAndProfitAndLossReport';

@Component({
  selector: 'app-gift-orders',
  templateUrl: './gift-orders.component.html',
  styleUrls: ['./gift-orders.component.scss']
})
export class GiftOrdersComponent implements OnInit {
  searchForm: FormGroup;
  searchGiftOrder: FormGroup;
  // searchFormStockUpdateOutgoing: FormGroup;

  filterFormGiftOrders: FormGroup;

  dataSourceGiftOrders: MatTableDataSource<PastOrderReport>;
  dataSourceOrderItems: MatTableDataSource<OrderItem>;

  @ViewChild('paginatorOrderReport') paginatorOrderReport: MatPaginator;
  @ViewChild('sortOrderReport') sortOrderReport: MatSort;

  @ViewChild('paginatorOrderItem') paginatorOrderItem: MatPaginator;
  @ViewChild('sortOrderItem') sortOrderItem: MatSort;

  totalOrderAmountGifted: number;
  currencyDefault: string;

  tableHasDataOrderReport: boolean;

  tableHasOrderItemData: boolean;

  displayedColumnsGiftOrderReport = [
    'OrderID',
    'Name',
    'Date & Time',
    'Amount paid',
    'Action'
  ];

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  giftOrderReports: PastOrderReport[];
  productDTOs: ProductDTO[];

  branches: Branch[];
  employeeWaiters: Employee[];

  startDateSaleReport: string;
  endDateSaleReport: string;

  startDateGiftOrders: string;
  endDateGiftOrders: string;

  @Input() id: string;

  pageOrderReport = 1;
  viewOrderItemPage = 1;

  orderItems: OrderItem[];
  closeResult: string;

  selectedOrderID_GiftOrders: number;
  selectedOrderTotalAmount_GiftOrders: number;
  selectedOrderCurrency_GiftOrders: string;

  @ViewChild('viewOrderItem', { static: true }) viewOrderItem: TemplateRef<any>;

  isWaiterDropdownEnabled: boolean;

  workingCurrency: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private employeeService: EmployeeService,
    private formBuilder: FormBuilder) {

    this.giftOrderReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;
    this.getWorkingCurrency(this.authenticationService.currentUserValue.branchID);

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.spin = true;

      this.loadBranches();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadGiftOrders(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadGiftOrders(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterFormGiftOrders.controls['dtGiftOrdersFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormGiftOrders.controls['dtGiftOrdersTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

      this.totalOrderAmountGifted = 0;
    } else {
      this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
    }
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  createForms() {

    this.filterFormGiftOrders = this.formBuilder.group({
      dtGiftOrdersFrom: new FormControl(null, Validators.required),
      dtGiftOrdersTo: new FormControl(null, Validators.required),
      dpWaiter: new FormControl(null),
      dpBranch: new FormControl(null),
    });

    this.searchGiftOrder = this.formBuilder.group({
      order_number: ['']
    });
  }

  getWorkingCurrency(branchID: number) {
    this.branchService.getOneBranchByBranchId(branchID,
      this.authenticationService.currentUserValue.companyID).subscribe(res => {

        this.workingCurrency = res.branch_currency;

        this.spin = false;
      }, error => {
        this.spin = false;
      });
  }

  searchOrder(branchId): void {
    this.spin = true;

    this.giftOrderReports = [];

    const order_id = this.searchGiftOrder.controls.order_number.value;
    this.orderService.searchGiftOrder(this.authenticationService.currentUserValue.companyID, branchId, order_id).subscribe(res => {
      this.giftOrderReports = res;

      if (this.giftOrderReports.length > 0) {
        this.tableHasDataOrderReport = true;
        this.dataSourceGiftOrders = new MatTableDataSource(this.giftOrderReports);
        this.dataSourceGiftOrders.paginator = this.paginatorOrderReport;
        this.dataSourceGiftOrders.sort = this.sortOrderReport;
      } else {
        this.tableHasDataOrderReport = false;
      }
      this.spin = false;

    }, err => {
      this.tableHasDataOrderReport = false;
      this.dataSourceGiftOrders = new MatTableDataSource([]);
      this.dataSourceGiftOrders.paginator = this.paginatorOrderReport;
      this.dataSourceGiftOrders.sort = this.sortOrderReport;
      this.spin = false;
    });
  }

  loadBranches() {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.filterFormGiftOrders.controls.dpBranch.enable();
        this.filterFormGiftOrders.controls.dpBranch.setValue(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

        this.filterFormGiftOrders.controls.dpBranch.disable();
        this.filterFormGiftOrders.controls.dpBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  loadAllWaitersInBranch(branchIdPastOrders) {
    this.spin = true;
    this.employeeService.getallwaitersAndNonWaiter(this.authenticationService.currentUserValue.companyID, branchIdPastOrders).subscribe(res => {
      this.employeeWaiters = res;
      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  loadGiftOrdersPerWaiter(employeeID) {
    this.resetSummaryInfo();

    this.searchGiftOrder.controls.order_number.reset();

    this.spin = true;

    var date = new Date();

    if (this.filterFormGiftOrders.controls['dtGiftOrdersFrom'].value !== null) {
      this.startDateGiftOrders = moment(this.filterFormGiftOrders.controls['dtGiftOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateGiftOrders = moment(this.filterFormGiftOrders.controls['dtGiftOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateGiftOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateGiftOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.orderService.getGiftOrdersReport(this.authenticationService.currentUserValue.companyID, Number(employeeID),
      Number(this.filterFormGiftOrders.controls.dpBranch.value), this.startDateGiftOrders, this.endDateGiftOrders).subscribe(res => {
              
        this.giftOrderReports = res;

        if (this.giftOrderReports.length > 0) {
          this.tableHasDataOrderReport = true;
          this.dataSourceGiftOrders = new MatTableDataSource(this.giftOrderReports);
          this.dataSourceGiftOrders.paginator = this.paginatorOrderReport;
          this.dataSourceGiftOrders.sort = this.sortOrderReport;

          this.currencyDefault = this.giftOrderReports[0].currency_one;
          this.giftOrderReports.forEach(a => this.totalOrderAmountGifted += a.total_amount_brought_in);

        } else {
          this.tableHasDataOrderReport = false;
        }
        this.spin = false;

      }, error => {
        this.tableHasDataOrderReport = false;
        this.dataSourceGiftOrders = new MatTableDataSource([]);
        this.dataSourceGiftOrders.paginator = this.paginatorOrderReport;
        this.dataSourceGiftOrders.sort = this.sortOrderReport;
        this.spin = false;
      });
  }

  resetSummaryInfo(): void {
    this.totalOrderAmountGifted = 0;
  }

  loadOrderItemsByOrderID(orderID: number, orderTotalAmount: number, currency: string) {
    this.spin = true;

    this.selectedOrderID_GiftOrders = orderID;
    this.selectedOrderTotalAmount_GiftOrders = orderTotalAmount;
    this.selectedOrderCurrency_GiftOrders = currency;

    this.open(this.viewOrderItem);


    this.orderService.getAllOrderItem(orderID).subscribe(res => {

      this.orderItems = res;

      if (this.orderItems.length > 0) {
        this.tableHasOrderItemData = true;
        this.dataSourceOrderItems = new MatTableDataSource(this.orderItems);
        this.dataSourceOrderItems.paginator = this.paginatorOrderItem;
        this.dataSourceOrderItems.sort = this.sortOrderItem;

      } else {
        this.tableHasOrderItemData = false;
      }
      this.spin = false;

    }, error => {
      this.tableHasOrderItemData = false;
      this.dataSourceOrderItems = new MatTableDataSource([]);
      this.dataSourceOrderItems.paginator = this.paginatorOrderItem;
      // this.dataSourceOrderItems.sort = this.sortOrderItem;
      this.spin = false;
    });
  }

  loadReportGiftOrders(branchIdPastOrders: number, fromDatePastOrders: string, toDatePastOrders: string) {
    this.resetSummaryInfo();

    this.spin = true;

    this.orderService.getGiftOrdersReport(this.authenticationService.currentUserValue.companyID,
      0, branchIdPastOrders, fromDatePastOrders, toDatePastOrders).subscribe(res => {
        this.giftOrderReports = res;

        if (this.giftOrderReports.length > 0) {
          this.tableHasDataOrderReport = true;
          this.dataSourceGiftOrders = new MatTableDataSource(this.giftOrderReports);
          this.dataSourceGiftOrders.paginator = this.paginatorOrderReport;
          this.dataSourceGiftOrders.sort = this.sortOrderReport;

          this.currencyDefault = this.giftOrderReports[0].currency_one;

          this.giftOrderReports.forEach(a => this.totalOrderAmountGifted += a.total_amount_brought_in);

        } else {
          this.tableHasDataOrderReport = false;
        }
        this.spin = false;

      }, error => {
        this.tableHasDataOrderReport = false;
        this.dataSourceGiftOrders = new MatTableDataSource([]);
        this.dataSourceGiftOrders.paginator = this.paginatorOrderReport;
        this.dataSourceGiftOrders.sort = this.sortOrderReport;
        this.spin = false;
      });

  }

  filterGiftOrders(branchIdPastOrders) {
    this.startDateGiftOrders = moment(this.filterFormGiftOrders.controls['dtGiftOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateGiftOrders = moment(this.filterFormGiftOrders.controls['dtGiftOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    let employeeID_ = this.filterFormGiftOrders.controls.dpWaiter.value;

    if ((employeeID_ !== 0) && (employeeID_ !== null)) {
      this.loadGiftOrdersPerWaiter(employeeID_);
    } else {
      this.loadReportGiftOrders(branchIdPastOrders, this.startDateGiftOrders, this.endDateGiftOrders);
    }
  }

  loadGiftOrders(branchIdPastOrders) {

    this.searchGiftOrder.controls.order_number.reset();

    if (branchIdPastOrders === 0) {
      this.filterFormGiftOrders.controls.dpWaiter.disable();
      this.isWaiterDropdownEnabled = false;
    } else {
      this.isWaiterDropdownEnabled = true;
      this.filterFormGiftOrders.controls.dpWaiter.enable();
      this.loadAllWaitersInBranch(branchIdPastOrders);
    }

    var date = new Date();

    if (this.filterFormGiftOrders.controls['dtGiftOrdersFrom'].value !== null) {
      this.startDateGiftOrders = moment(this.filterFormGiftOrders.controls['dtGiftOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateGiftOrders = moment(this.filterFormGiftOrders.controls['dtGiftOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateGiftOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateGiftOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadReportGiftOrders(branchIdPastOrders, this.startDateGiftOrders, this.endDateGiftOrders);
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event) {
    this.pageOrderReport = event;
    window.scroll(0,0);

  }
}
