<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">

        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" id="table" data-toggle="tab" href="#table1" role="tab" aria-controls="table"
              aria-selected="true" >Tables</a>
          </li>
          <li *ngIf="isAdmin" class="nav-item">
            <a class="nav-link" id="branch" data-toggle="tab" href="#branch1" role="tab" aria-controls="branch"
              aria-selected="false" >Établissement</a>
          </li>
          <li *ngIf="isAdmin" class="nav-item">
            <a class="nav-link" id="qrCode" data-toggle="tab" href="#qrCode1" role="tab" aria-controls="qrCode"
              aria-selected="false" >QR Code</a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane fade show active" id="table1" role="tabpanel" aria-labelledby="table">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
                <form [formGroup]="searchTableName">
                  <div class="form-group mt-4">
                    <small class="form-text text-muted">Rechercher une table</small>
                    <div class="input-group mb-3">
                      <input type="search" class="form-control" formControlName="table_name" placeholder="Rechercher..."
                        aria-label="Search..." aria-describedby="basic-addon2">
                      <div class="input-group-append">
                        <button class="btn btn-outline-primary" (click)="searchTable(selectBranch.value)" type="button"><i
                            class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
                <div class="form-group mt-4">
                  <small class="form-text text-muted mt-5"></small>
                  <button type="button" class="btn btn-primary" (click)="addTableButton(selectBranch.value)"><i
                      class="fas fa-plus-square"></i> Ajouter une nouvelle table
                  </button>
                </div>
              </div>
              <!--          <div class="col-lg-3 col-md-3 col-sm-3 col-12">-->

              <!--          </div>-->
            </div>

            <div class="row mt-1 mb-3">
              <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                <div class="card mb-3 d-md-none"
                  *ngFor="let item of tables | paginate: { itemsPerPage: 10, currentPage: page }">
                  <div class="card-body">
                    <div class="row mb-2">
                      <div class="col-6 card-table-header">
                        Nom de la table
                      </div>
                      <div class="col-6">
                        {{item.table_name}}
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-12 text-center">
                        <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                          (click)="editTable1(item)"><i class="bi bi-pen"></i></button> &nbsp;
                        <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                          (click)="tempDelete(item)"><i class="bi bi-trash"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-md-none"
                  (pageChange)="onMobileTableChangePage($event)" previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>


                <mat-table [dataSource]="dataSourceTable" class="d-none d-md-block" matSort>
                  <ng-container matColumnDef="Name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                      Nom de la table
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{row.table_name}}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                      Actions
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <!--                    <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"-->
                      <!--                            (click)="view(row)"><i class="fas fa-eye"></i></button> &nbsp;-->
                      <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                        (click)="editTable1(row)"><i class="bi bi-pen"></i></button> &nbsp;
                      <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                        (click)="tempDelete(row)"><i class="bi bi-trash"></i></button>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumnsTable">
                  </mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsTable">
                  </mat-row>
                </mat-table>
                <div class="mt-3" *ngIf="tableHasData === false">Aucune table trouvé
                </div>
                <mat-paginator class="d-none d-md-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>

              </div>
              <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                <form [formGroup]="selectBranchForm">
                  <div class="form-group">
                    <small class="form-text text-muted">Sélectionnez une établissement</small>
                    <select #selectBranch class="form-control" (change)="getBranchCategories($event)" formControlName="selectBranch">
                      <option *ngFor="let branch of branchList" value={{branch.branch_id}}>{{branch.branch_name}}</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>

          </div>
          <div class="tab-pane fade" id="branch1" *ngIf="isAdmin" role="tabpanel" aria-labelledby="branch">
            <app-branches></app-branches>
          </div>
          <div class="tab-pane fade" id="qrCode1"  *ngIf="isAdmin" role="tabpanel" aria-labelledby="qrCode">
            <app-qr-code-generate></app-qr-code-generate>
          </div>



        <!---Add Modal-->
        <ng-template #createNewTable let-modal>
          <div class="modal-header">
            <h5 class="modal-title">Ajouter une nouvelle table</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="tableForm">
              <div class="form-group">
                <small class="form-text text-muted">
                  Établissement
                </small>
                <select class="form-control" [attr.disabled]="true" formControlName="addSubCategoryFormBranchID"
                  required>
                  <option value="" disabled selected>Établissement</option>
                  <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                    {{branch.branch_name}}</option>
                </select>
              </div>
              <div class="form-group">
                <small class="form-text text-muted">Nom de la table</small>
                <input type="text" class="form-control" formControlName="table_name" placeholder="Nom de la table">
                <div *ngIf="tableForm.controls['table_name'].invalid &&
                                                                    (tableForm.controls['table_name'].dirty ||
                                                                    tableForm.controls['table_name'].touched)"
                  class="alert alert-light pt-0">
                  <div *ngIf="tableForm.controls['table_name'].errors.required">
                    Ne devrait pas être vide
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
            <button type="button" class="btn btn-primary btn-lg" (click)="saveNewTable()">Sauvegarder</button>
          </div>
        </ng-template>

        <!--Edit modal-->
        <ng-template #editTable let-modal>
          <div class="modal-header">
            <h5 class="modal-title">Modification des détails de la table</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="editTableForm">
              <div class="form-group">
                <small class="form-text text-muted">
                  Établissement
                </small>
                <select class="form-control" [attr.disabled]="true" formControlName="editTableFormBranchID" required>
                  <option value="" disabled selected>Établissement</option>
                  <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                    {{branch.branch_name}}</option>
                </select>
              </div>
              <div class="form-group">
                <small class="form-text text-muted">Nom de la table</small>
                <input type="text" class="form-control" formControlName="table_name">
                <div *ngIf="editTableForm.controls['table_name'].invalid &&
                                                                    (editTableForm.controls['table_name'].dirty ||
                                                                    editTableForm.controls['table_name'].touched)"
                  class="alert alert-light pt-0">
                  <div *ngIf="editTableForm.controls['table_name'].errors.required">
                    Ne devrait pas être vide
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
            <button type="button" class="btn btn-primary btn-lg" (click)='editTableName()'>Sauvegarder</button>
          </div>
        </ng-template>

        <!--Delete Modal -->
        <ng-template #deleteTable let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation de suppression</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <div class="form-group">
              <label>Êtes-vous sûr de vouloir supprimer ce table ?</label>
              <h5>{{tableToDelete}}</h5>
              <label class="mt-3">de l'établissement <h6>{{branchOfTheTableToDelete}}</h6> </label>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
            <button type="button" class="btn btn-primary" (click)="deleteTable1()">Confirmer</button>
          </div>
        </ng-template>

        <!----success product modal -->
        <ng-template #successModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{successModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>


        <!----failure product modal -->
        <ng-template #failureModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{failureModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>

<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>
