import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Branch } from 'src/app/interface/branch';
import { Category } from 'src/app/interface/category';
import { CategoriesService } from 'src/app/services/categories.service';
import { FlowService } from 'src/app/services/flow.service';
import { find, pull } from 'lodash';
import { Subject } from 'rxjs';
import { EditCategory } from 'src/app/interface/edit-category';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/common/Constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataManagerService } from '../../services/data-management/data-manager.service';
import * as $ from 'jquery';
import { BranchService } from 'src/app/services/branch.service';

//declare var $: any;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  categories: Category[];
  tempCategory: Category;
  editCategory: EditCategory;
  subs: any[];
  num = 1;
  branches: Branch;
  spin: boolean;
  categoryForm: FormGroup;
  categoryFormEdit: FormGroup;
  categoryFormDelete: FormGroup;
  categorySearch: FormGroup;
  categoryNameToDelete: string;
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject();
  // dtInstance: DataTables.Api;
  @ViewChild('tagInput') tagInputRef: ElementRef;
  tags: string[] = [];
  dtElement: DataTableDirective;
  table: any;

  @ViewChild('successSaveProduct', { static: true }) successSaveProduct: TemplateRef<any>;
  @ViewChild('failureSaveProduct', { static: true }) failureSaveProduct: TemplateRef<any>;
  @ViewChild('editCategory', { static: true }) editCategoryModal: TemplateRef<any>;
  @ViewChild('deleteCategory', { static: true }) deleteCategoryModal: TemplateRef<any>;
  @ViewChild('createCategory', { static: true }) createCategoryModal: TemplateRef<any>;

  closeResult: string;
  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  branchList: Branch[];

  displayedColumnsCategory = [
    'CategoryName',
    'Subcategories',
    'Actions',
  ];

  dataSourceCategory: MatTableDataSource<Category>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tableHasData: boolean;
  deleteCategoryFormCategoryID: number;
  page = 1;
  isManager: boolean = false;
  selectBranchForm: FormGroup;

  constructor(private router: Router, private flowService: FlowService, private categoriesService: CategoriesService,
    private fb: FormBuilder, private authenticationService: AuthenticationService, private branchService: BranchService,
    private modalService: NgbModal, private dataManagerService: DataManagerService) {
  }

  ngOnInit(): void {
    this.spin = true;

    this.getFranchise();
    this.createForm();
  }

  createForm(): void {
    this.categoryForm = this.fb.group({
      categoryName: [''],
      franchise: [''],
      subCategory: ['']
      // subCategoryId: ['']
    });

    this.selectBranchForm = this.fb.group({
      selectBranch: ['']
    });

    this.categoryFormEdit = this.fb.group({
      categoryNameEdit: ['']
    });

    this.categoryFormDelete = this.fb.group({
      categoryName: ['']
    });
    this.categorySearch = this.fb.group({
      searchCategoryName: ['']
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getCategories(companyId: number, branchId: number): void {
    this.spin = true;

    this.categoriesService.getCategories(companyId, branchId).subscribe(res => {
      this.categories = res;

      if (this.categories.length > 0) {
        this.tableHasData = true;

        this.dataSourceCategory = new MatTableDataSource(this.categories);
        this.dataSourceCategory.paginator = this.paginator;
        this.dataSourceCategory.sort = this.sort;
      } else {
        this.tableHasData = false;
        this.dataSourceCategory = new MatTableDataSource([]);
        this.dataSourceCategory.paginator = this.paginator;
        this.dataSourceCategory.sort = this.sort;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceCategory = new MatTableDataSource([]);
      this.dataSourceCategory.paginator = this.paginator;
      this.dataSourceCategory.sort = this.sort;
      this.spin = false;

    });
  }

  searchCategory(branchId): void {
    this.spin = true;

    const name = this.categorySearch.controls.searchCategoryName.value;
    if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
      (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
      this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
    } else {
      this.branchIdToBeUsed = branchId;
    }

    this.categoriesService.searchCategory(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed, name)
      .subscribe(res => {
        this.categories = res;

        if (this.categories.length > 0) {
          this.tableHasData = true;

          this.dataSourceCategory = new MatTableDataSource(this.categories);
          this.dataSourceCategory.paginator = this.paginator;
          this.dataSourceCategory.sort = this.sort;
        } else {
          this.tableHasData = false;
          this.dataSourceCategory = new MatTableDataSource([]);
          this.dataSourceCategory.paginator = this.paginator;
          this.dataSourceCategory.sort = this.sort;
        }
        this.spin = false;

      }, err => {
        this.tableHasData = false;
        this.dataSourceCategory = new MatTableDataSource([]);
        this.dataSourceCategory.paginator = this.paginator;
        this.dataSourceCategory.sort = this.sort;
        this.spin = false;

      });
  }

  getFranchise(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchList = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.branchIdToBeUsed = this.branchList[0].branch_id;
        this.selectBranchForm.controls.selectBranch.enable();
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        
          this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        this.selectBranchForm.controls.selectBranch.disable();
      }
      this.selectBranchForm.controls.selectBranch.setValue(this.branchIdToBeUsed);
      this.getCategories(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed);

    }, error => {
      this.spin = false;
    });
  }

  getFranchiseOnly(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchList = res;
      this.branchIdToBeUsed = this.branchList[0].branch_id;
    }, error => {
      this.spin = false;
    });
  }

  goInMenu(category: Category): void {

    this.dataManagerService.setCategory(category);

    localStorage.setItem('categoryId', category.category_id.toString());
    const finalValue = category.name.replace(' ', '-');
    this.flowService.setCategories(category);
    this.router.navigate(['/inmenu', finalValue]);
  }

  addSubCategory(): void {
    this.num += 1;
    this.subs.push(this.num);
  }

  tempEdit(category): void {
    this.open(this.editCategoryModal);
    this.tempCategory = category;
    this.categoryFormEdit.controls.categoryNameEdit.setValue(category.name);
  }

  saveNewCategory(): void {

    this.spin = true;
    // this.validate();
    const numberValue = Number(this.categoryForm.controls.franchise.value);
    let category;
    category = {
      category_id: 0,
      name: this.categoryForm.controls.categoryName.value,
      branch_id: numberValue,
      company_id: this.authenticationService.currentUserValue.companyID,
      status: 0,
      subcategory_names: this.tags
    };

    this.categoriesService.saveNewCategory(category).subscribe(res => {
      if (res) {
        this.spin = false;
        this.categoryForm.reset();
        this.modalService.dismissAll();
        this.showSuccessModal('Votre catégorie a été enregistré avec succès');

        if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
          (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
          this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        }
        this.getCategories(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed);
      } else {
        this.spin = false;
        this.showFailureModal('L\'ajout de la catégorie a échoué, réessayez plus tard');
      }

    }, error => {

      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cette catégorie existe déjà');
      } else {

        this.showFailureModal('L\'ajout de la catégorie a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  saveNewCategoryName(): void {
    this.spin = true;
    this.validate();
    const numberValue = Number(this.tempCategory.branch_id);
    let categoryEdit = this.editCategory;
    categoryEdit = {
      category_id: this.tempCategory.category_id,
      name: this.categoryFormEdit.controls.categoryNameEdit.value,
      branch_id: numberValue,
      company_id: this.tempCategory.company_id,
      status: 0,
    };

    this.categoriesService.saveNewCategoryName(categoryEdit).subscribe(res => {
      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Votre catégorie a été modifié avec succès');

        if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
          (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
          this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        }
        this.getCategories(this.authenticationService.currentUserValue.companyID, numberValue);
      } else {
        this.spin = false;
        this.showFailureModal('La modification de la catégorie a échoué, réessayez plus tard');
      }

      this.spin = false;
      // this.getCategories(29, 1); // to add category id on local store
      // document.getElementById('editCategory').click();
    }, error => {

      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cette catégorie éxiste déjà');
      } else {
        this.showFailureModal('La modification de la catégorie a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  tempDelete(category): void {
    this.open(this.deleteCategoryModal);
    this.tempCategory = category;
    this.deleteCategoryFormCategoryID = category.category_id;
    this.categoryNameToDelete = category.name;
    // this.categoryFormDelete.controls.categoryName.setValue(this.tempCategory.name);
  }

  deleteCategory1(): void {
    this.spin = true;
    const numberValue = Number(this.tempCategory.branch_id);
    let categoryDelete: Category;
    categoryDelete = {
      category_id: this.tempCategory.category_id,
      name: this.categoryFormEdit.controls.categoryNameEdit.value,
      branch_id: numberValue,
      company_id: this.tempCategory.company_id,
      status: 0,
      subcategory_names: []
    };

    this.categoriesService.deleteCategory(categoryDelete).subscribe(res => {
      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Votre catégorie a été supprimé avec succès');

        if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
          (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
          this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        }
        this.getCategories(this.authenticationService.currentUserValue.companyID, numberValue);
      } else {
        this.spin = false;
        this.showFailureModal('La suppression de la catégorie a échoué, réessayez plus tard');
      }
    }, error => {

      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('supprimer d\'abord les sous-catégories liées à cette catégorie');
      } else {

        this.showFailureModal('La suppression de la catégorie a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  getBranchCategories(event): void {
    const branchId = event.target.value;
    this.getCategories(this.authenticationService.currentUserValue.companyID, branchId);
  }

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.categoryForm.controls.subCategory.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      // || event.code === 'Space'
      if (event.code === 'Comma' || event.code === 'Enter' || event.key === 'Tab') {
        this.addTag(inputValue);
        this.categoryForm.controls.subCategory.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.tags, tag)) {
      this.tags.push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.tags, tag);
    } else {
      this.tags.splice(-1);
    }
  }

  validate(): void {
    const forms = document.getElementsByClassName('needs-validation');
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  openCreateNewCategoryModal(): void {
    this.open(this.createCategoryModal);
    this.getFranchiseOnly();
  }

  showSuccessModal(message: string): void {
    this.open(this.successSaveProduct);
    this.successModalMessage = message;
    // document.getElementById('showSuccesMessageAddProduct').click();
  }

  showFailureModal(message: string): void {
    this.open(this.failureSaveProduct);
    this.failureModalMessage = message;
    // document.getElementById('showFailureMessageAddProduct').click();
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
