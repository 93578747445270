import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavigationService {

  private behaviourSubject = new BehaviorSubject('none');
  private isMobileSubject = new BehaviorSubject(false);
  // private currentState = new BehaviorSubject('/dashboard');
  // getCurrentState = this.currentState.asObservable();
  isMobile = this.isMobileSubject.asObservable();
  pageName = this.behaviourSubject.asObservable();

  constructor() {}

  changeValueClass(value: string) {
    this.behaviourSubject.next(value);
  }

  setScreenSize(width: number) {
    if (width < 768) {
      this.isMobileSubject.next(true);
    } else {
      this.isMobileSubject.next(false);
    }
  }

  setState(state: string) {
    // if (state === '/dashboard' || state === '/') {
    //   this.currentState.next('/dashboard');
    // } else {
    //   this.currentState.next(state);
    // }
  }
}
