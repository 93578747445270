<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container">
      <h4 class="mt-3 p-0 m-0">Bienvenu sur votre trableau de bord!</h4>

      <div class="panel mt-4">
        <div class="row color-standard justify-content-start mt-5" [formGroup]="dashboardDate">
          <!-- <div class="col-12 col-lg-6 mt-4 mt-lg-0 graph"> -->
          <!-- <h4>Ce mois-ci</h4> -->
          <!-- <div id="monthChart"></div> -->
          <!-- </div> -->
          <!-- <div class="col-12 col-lg-6 mt-lg-0 mt-5 border-black border-left border-gray graph"> -->
          <!-- <h4>Cette semaine</h4> -->
          <!-- <div id="weekChart"></div> -->
          <!-- <input class="form-control" type="date" formControlName="selectedWeek" name="selectedWeek"> -->
          <!-- <span class="text-danger" *ngIf="dashboardDate.controls['selectedWeek'].invalid && dashboardDate.controls['selectedWeek'].touched">La date n'est pas correctement saisie</span> -->
          <!-- <h5>Bonne journée à vous</h5> -->
          <!-- </div> -->
            <div class="col-12 col-lg-3">
              A partir du
              <input class="form-control" formControlName="dateFrom" name="dateFrom" [readOnly]="true"
                [owlDateTime]="sellReportFrom" [owlDateTimeTrigger]="sellReportFrom">
              <owl-date-time #sellReportFrom></owl-date-time>
              <!-- <span class="text-danger"
                *ngIf="(dashboardDate.controls['dateFrom'].invalid && dashboardDate.controls['dateFrom'].touched)">
                Aucun rapport pour cette date</span> -->
                <!-- <span class="text-danger" *ngIf="errorMessage[0][1] === true">{{errorMessage[0][0]}}</span> -->
              </div>
            <div class="col-12 col-lg-3">
              Jusqu'au
              <input class="form-control" formControlName="dateTo" name="dateTo" [readOnly]="true"
                [owlDateTime]="sellReportTo" [owlDateTimeTrigger]="sellReportTo">
              <owl-date-time #sellReportTo></owl-date-time>
              <!-- <span class="text-danger"
                *ngIf="(dashboardDate.controls['dateTo'].invalid && dashboardDate.controls['dateTo'].touched)">
                Aucun rapport pour cette date</span> -->
            </div>
            <div class="col-2 mt-4">
              <button class="btn btn-primary" (click)="setPieData()">Appliquer</button>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mt-2 mt-lg-5 graph">
              <div id="summary"></div>
              <p class="ms-3 mt-3" *ngIf="(categoryDetail.length < 1) && (errorMessage[0][1] === true)">{{errorMessage[0][0]}}</p>
            </div>
            <div class="col-12 col-lg-6 mt-3 graph items-list"
              *ngIf="(selectedData !== null) || (categoryDetail.length > 0)">
              <ul class="list-group">
                <h4>{{selectedData.name}} : {{selectedData.value}}</h4>
                <div class="overflow-auto">
                  <li class="list-group-item" *ngFor="let item of selectedData.items">
                    {{item.name}} : {{item.value}}
                  </li>
                </div>
              </ul>
            </div>
          </div>


        <!-- <div class="row admin-dashboard-content mt-xl-5 mt-lg-5 mt-md-5">
                    <div
                        class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive color-fail">

                        <h6>A court de stock</h6>

                        <div class="card mb-3 d-lg-none"
                            *ngFor="let item of productDTOs | paginate: { itemsPerPage: 10, currentPage: pageLowStockProductReport }">
                            <div class="card-body">
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Nom de l'article
                                    </div>
                                    <div class="col-6">
                                        {{item.name}} {{item.type}}
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Quantité
                                    </div>
                                    <div class="col-6">
                                        <p *ngIf="item.quantity > item.pack_number_of_piece">
                                            {{(item.quantity % item.pack_number_of_piece) !== 0 ? (item.quantity /
                                            item.pack_number_of_piece).toString()
                                            .substring(0, (item.quantity /
                                            item.pack_number_of_piece).toString().indexOf('.')) : (item.quantity /
                                            item.pack_number_of_piece) }}
                                            casiers de {{item.drink_pack_name}} et {{item.quantity %
                                            item.pack_number_of_piece}}
                                            bouteilles
                                        </p>
                                        <p *ngIf="item.quantity === item.pack_number_of_piece"> 1 casier
                                            de {{item.drink_pack_name}}</p>
                                        <p *ngIf="item.quantity < item.pack_number_of_piece"> 0 casier et
                                            {{item.quantity}} bouteilles </p>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                        Fournisseur
                                    </div>
                                    <div class="col-6">
                                        {{item.supplier_name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <pagination-controls *ngIf="tableHasLowStockProductReport === true"
                            class="mb-3 text-center d-lg-none" (pageChange)="page = $event" previousLabel="Précédent"
                            nextLabel="Suivant"></pagination-controls>



                        <mat-table [dataSource]="dataSourceLowStockProductReport" class="d-none d-lg-block" matSort>
                            <ng-container matColumnDef="Nom de l'article">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Nom de l'article
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{row.name}} {{row.type}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Quantity">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Quantité
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <p *ngIf="row.quantity > row.pack_number_of_piece">
                                        {{(row.quantity % row.pack_number_of_piece) !== 0 ? (row.quantity /
                                        row.pack_number_of_piece).toString()
                                        .substring(0, (row.quantity / row.pack_number_of_piece).toString().indexOf('.'))
                                        : (row.quantity / row.pack_number_of_piece) }}
                                        casiers de {{row.drink_pack_name}} et {{row.quantity %
                                        row.pack_number_of_piece}}
                                        bouteilles
                                    </p>
                                    <p *ngIf="row.quantity === row.pack_number_of_piece"> 1 casier
                                        de {{row.drink_pack_name}}</p>
                                    <p *ngIf="row.quantity < row.pack_number_of_piece"> 0 casier et
                                        {{row.quantity}} bouteilles </p>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Fournisseur">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Fournisseur
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{row.supplier_name}}
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumnsLowStoctProduct">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsLowStoctProduct">
                            </mat-row>
                        </mat-table>
                        <div class="mt-3" *ngIf="tableHasLowStockProductReport === false">Aucun article trouvé
                        </div>
                        <mat-paginator class="d-none d-lg-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                        </mat-paginator>

                    </div>

                    <div
                        class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-xl-last order-lg-last order-md-first order-sm-first order-first manager-dashboard-menu">
                        <div>
                            <div class="row mb-4">
                                <div class="col-md-12">
                                    <h1>
                                        <div [formGroup]="selectBranchForLowStockProductForm">
                                            <div class="form-group">
                                                <small class="form-text text-muted">Sélectionnez un
                                                    établissement</small>
                                                <select #selectedBranch class="form-control"
                                                    (change)="loadLowStockProduct($event.target.value)"
                                                    formControlName="selectBranchLowStock">
                                                    <option value="0" selected>Tous</option>
                                                    <option *ngFor="let branch of branchListForLowStockProducts"
                                                        value={{branch.branch_id}}>
                                                        {{branch.branch_name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </h1>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3 mb-3">
                                    <div class="backgroung-color-light-blue">
                                        <div class="row padding-25px-25px text-center">
                                            <div
                                                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3 mb-3">
                                                <i class="fa fa-shopping-cart font-30px" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                                <h5>
                                                    ZAR
                                                    2334343
                                                </h5>
                                                <h6>Revenu quotidien</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="backgroung-color-light-violet">
                                        <div class="row padding-25px-25px text-center">
                                            <div
                                                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3 mb-3">
                                                <i class="fa fa-trophy font-30px" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                                <h5>
                                                    Simba
                                                </h5>
                                                <h6>Produit du jour</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3 mt-3">
                                <div
                                    class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0 mb-3">
                                    <div class="backgroung-color-light-green">
                                        <div class="row padding-25px-25px text-center">
                                            <div
                                                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3 mb-3">
                                                <i class="fa fa-shopping-cart font-30px" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                                <h5>
                                                    454
                                                </h5>
                                                <h6>Nombre quotidien de ventes</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3 mb-3">
                                    <div class="backgroung-color-light-red">
                                        <div class="row padding-25px-25px text-center">
                                            <div
                                                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3 mb-3">
                                                <i class="fa fa-users font-30px" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                                <h5>
                                                    9
                                                </h5>
                                                <h6>Nombre d'employés</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</div>

<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>



<!--

<div style="display: none;" id="print-section">
  <div class="container">
      <div class="row elvis">
          <div class="col-lg-12">
              <div class="card px-2">
                  <div class="card-body">
                      <div class="container-fluid">
                          <div class="row">
                              <div class="col-lg-6 pl-0 text-left">
                                  <h3 class="my-5">Prestige</h3>
                                  <h4 class="my-5">Boite de nuit</h4>
                              </div>
                              <div class="col-lg-6 pr-0 text-right">
                                  <h3 class=" my-5">Inventaire des articles</h3>
                              </div>
                          </div>
                          <hr>
                      </div>
                      <div class="container-fluid d-flex justify-content-between">
                          <div class="col-lg-3 pl-0">
                              <p class="mt-5 mb-2"><b>JustDo Admin</b></p>
                              <p>104,<br>Minare SK,<br>Canada, K1A 0G9.</p>
                          </div>
                          <div class="col-lg-3 pr-0">
                              <p class="mt-5 mb-2 text-right"><b>Invoice to</b></p>
                              <p class="text-right">Gaala & Sons,<br> C-201, Beykoz-34800,<br> Canada, K1A 0G9.</p>
                          </div>
                      </div>
                      <div class="container-fluid d-flex justify-content-between">
                          <div class="col-lg-3 pl-0">
                              <p class="mb-0 mt-5">Invoice Date : 23rd Jan 2016</p>
                              <p>Due Date : 25th Jan 2017</p>
                          </div>
                      </div>
                      <div class="container-fluid mt-5 d-flex justify-content-center w-100">
                          <div class="table-responsive w-100">
                              <table class="table">
                                  <thead>
                                      <tr class="bg-dark text-white">
                                          <th>#</th>
                                          <th>Description</th>
                                          <th class="text-right">Quantity</th>
                                          <th class="text-right">Unit cost</th>
                                          <th class="text-right">Total</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr class="text-right">
                                          <td class="text-left">1</td>
                                          <td class="text-left">Brochure Design</td>
                                          <td>2</td>
                                          <td>$20</td>
                                          <td>$40</td>
                                      </tr>
                                      <tr class="text-right">
                                          <td class="text-left">2</td>
                                          <td class="text-left">Web Design Packages(Template) - Basic</td>
                                          <td>05</td>
                                          <td>$25</td>
                                          <td>$125</td>
                                      </tr>
                                      <tr class="text-right">
                                          <td class="text-left">3</td>
                                          <td class="text-left">Print Ad - Basic - Color</td>
                                          <td>08</td>
                                          <td>$500</td>
                                          <td>$4000</td>
                                      </tr>
                                      <tr class="text-right">
                                          <td class="text-left">4</td>
                                          <td class="text-left">Down Coat</td>
                                          <td>1</td>
                                          <td>$5</td>
                                          <td>$5</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      <div class="container-fluid mt-5 w-100">
                          <p class="text-right mb-2">Sub - Total amount: $12,348</p>
                          <p class="text-right">vat (10%) : $138</p>
                          <h4 class="text-right mb-5">Total : $13,986</h4>
                          <hr>
                      </div>
                      <div class="container-fluid w-100">
                          <a href="#" class="btn btn-primary float-right mt-4 ml-2"><i
                                  class="ti-printer mr-1"></i>Print</a>
                          <a href="#" class="btn btn-success float-right mt-4"><i class="ti-export mr-1"></i>Send
                              Invoice</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

 -->
