import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Branch} from 'src/app/interface/branch';
import {Company} from 'src/app/models/company/company';
import {BranchService} from 'src/app/services/branch.service';
import {CompanyService} from 'src/app/services/company.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import * as $ from 'jquery';
import { Constants } from 'src/app/common/Constants';
// import { threadId } from 'worker_threads';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent implements OnInit {
  spin: boolean;
  branches: Branch [];
  searchBranchName: FormGroup;
  branchForm: FormGroup;
  // branchFormDelete: FormGroup;
  tempBranch: Branch;
  dtTrigger: Subject<any> = new Subject();
  branchToDelete: any;
  branchesForm: FormGroup;
  editBranchForm: FormGroup;
  viewBranchForm: FormGroup;
  dtOptions: DataTables.Settings = {};
  companies: Company;
  disableEditField: boolean;
  page = 1;

  dataSourceBranch: MatTableDataSource<Branch>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsBranch = [
    'Name',
    'Cellphone',
    'Email',
    'Currency',
    'Address',
    'Actions'
  ];

  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;
  @ViewChild('failureModal', {static: true}) failureModal: TemplateRef<any>;
  @ViewChild('editBranch', {static: true}) editBranchModal: TemplateRef<any>;
  @ViewChild('deleteBranch', {static: true}) deleteBranchModal: TemplateRef<any>;
  @ViewChild('createNewBranch', {static: true}) createNewBranchModal: TemplateRef<any>;

  @ViewChild('selectedBranch') selectedBranch: ElementRef<any>;

  closeResult: string;

  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  tableHasData: boolean;


  constructor(private router: Router, private branchService: BranchService, private fb: FormBuilder,
              private companyService: CompanyService, private authenticationService: AuthenticationService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.spin = true;
    this.createForm();
    this.getCompanies();
    this.getBranches(this.authenticationService.currentUserValue.companyID);
    console.log(this.authenticationService.currentUserValue)
  }

  createForm(): void {

    this.branchForm = this.fb.group({
      branch_name: [''],
      branch_address: [''],
      branch_type: [''],
      branch_cell: [''],
      branch_email: [''],
      branch_currency: [''],
      dailyRate:['']
    });
    this.editBranchForm = this.fb.group({
      branch_name: [''],
      branch_address: [''],
      branch_type: [''],
      branch_cell: [''],
      branch_email: [''],
      branch_currency: [''],
      dailyRate:[''],
    });
    this.searchBranchName = this.fb.group({
      company_id: [''],
      branch_name: [''],
      branch_id: ['']
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  searchBranch(): void {
    this.spin = true;
    this.branches = null;

    const name = this.searchBranchName.controls.branch_name.value;
    this.branchService.searchBranch(this.authenticationService.currentUserValue.companyID, name)
      .subscribe(res => {
        this.branches = res;

        if (this.branches.length > 0) {
          this.tableHasData = true;
          this.dataSourceBranch = new MatTableDataSource(this.branches);
          this.dataSourceBranch.paginator = this.paginator;
          this.dataSourceBranch.sort = this.sort;
        } else {
          this.tableHasData = false;
        }
        this.spin = false;

      }, err => {
        this.tableHasData = false;
        this.dataSourceBranch = new MatTableDataSource([]);
        this.dataSourceBranch.paginator = this.paginator;
        this.dataSourceBranch.sort = this.sort;
        this.spin = false;

      });
  }

  getBranches(companyId): void {
    this.spin = true;

    this.branchService.getBranchByCompany(companyId).subscribe(res => {
      this.branches = res;

      if (this.branches.length > 0) {
        this.tableHasData = true;
        this.dataSourceBranch = new MatTableDataSource(this.branches);
        this.dataSourceBranch.paginator = this.paginator;
        this.dataSourceBranch.sort = this.sort;
      } else {
        this.tableHasData = false;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceBranch = new MatTableDataSource([]);
      this.dataSourceBranch.paginator = this.paginator;
      this.dataSourceBranch.sort = this.sort;
      this.spin = false;

    });
  }

  saveNewBranch(): void {
    this.spin = true;
    let newBranch: Branch;
    newBranch = {
      branch_id: 0,
      branch_name: this.branchForm.controls.branch_name.value,
      branch_address: this.branchForm.controls.branch_address.value,
      branch_type: Number(this.branchForm.controls.branch_type.value),
      branch_cell: this.branchForm.controls.branch_cell.value,
      branch_email: this.branchForm.controls.branch_email.value,
      company_id: this.authenticationService.currentUserValue.companyID,
      status: 1,
      date_created: '',
      last_modified: '',
      branch_currency: this.branchForm.controls.branch_currency.value,
      dailyRate: this.branchForm.controls.dailyRate.value,
    };
    this.branchService.saveBranch(newBranch).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.branchForm.reset();
        this.showSuccessModal('L\'établissement a été enregistré avec succès');

        this.getBranches(this.authenticationService.currentUserValue.companyID);
      } else {
        this.showFailureModal('L\'ajout de cet établissement a échoué, réessayez plus tard');
      }
      this.spin = false;
      // document.getElementById('addSupplier').click();

    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cet établissement éxiste déjà');
      } else {
        this.showFailureModal('L\'ajout de cet établissement a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  editBranch1(branch): void {
    this.open(this.editBranchModal);
    this.tempBranch = branch;
    this.editBranchForm.controls.branch_name.setValue(this.tempBranch.branch_name);
    this.editBranchForm.controls.branch_cell.setValue(this.tempBranch.branch_cell);
    this.editBranchForm.controls.branch_email.setValue(this.tempBranch.branch_email);
    this.editBranchForm.controls.branch_currency.setValue(this.tempBranch.branch_currency);
    this.editBranchForm.controls.branch_address.setValue(this.tempBranch.branch_address);
    this.editBranchForm.controls.branch_type.setValue(this.tempBranch.branch_type);
    this.editBranchForm.controls.dailyRate.setValue(this.tempBranch?.dailyRate)
  }

  createBranchButton(): void {
    this.open(this.createNewBranchModal);
  }

  editBranchName(): void {
    this.spin = true;
    let branch = this.branches[0];
    branch = {
      branch_id: this.tempBranch.branch_id,
      branch_name: this.editBranchForm.controls.branch_name.value,
      branch_address: this.editBranchForm.controls.branch_address.value,
      branch_type: Number(this.editBranchForm.controls.branch_type.value),
      branch_cell: this.editBranchForm.controls.branch_cell.value,
      branch_email: this.editBranchForm.controls.branch_email.value,
      branch_currency: this.editBranchForm.controls.branch_currency.value,
      dailyRate:this.editBranchForm.controls.dailyRate.value,
      company_id: this.tempBranch.company_id,
      status: 1,
      date_created: '',
      last_modified: '',
    };

    this.branchService.updateBranch(branch).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('Cet établissement a été modifié avec succès');
        this.getBranches(this.authenticationService.currentUserValue.companyID);
      } else {
        this.showFailureModal('Cet établissement n\'a pas pu être mis à jour');
      }
      this.spin = false;

      // document.getElementById('editSupplier').click();

    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cet établissement éxiste déjà');
      } else {
        this.showFailureModal('Cet établissement n\'a pas pu être mis à jour');
      }
      this.spin = false;
    });
  }


  tempDelete(branch): void {
    this.tempBranch = branch;
    this.branchToDelete = this.tempBranch.branch_name;

    this.open(this.deleteBranchModal);
  }

  deleteBranch1(): void {
    this.spin = true;

    this.branchService.deleteBranch(this.tempBranch.branch_id).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('Cet établissement a été supprimé avec succès');
        this.getBranches(this.authenticationService.currentUserValue.companyID);
      } else {
        this.showFailureModal('Cet établissement n\'a pas pu être supprimé');
      }
      this.spin = false;
      // document.getElementById('deleteSupplier').click();
    }, error => {
      this.showFailureModal('Cet établissement n\'a pas pu être supprimé');
      this.spin = false;
    });
  }


  getCompanies(): void {
    this.companyService.getCompany().subscribe(res => {
      this.companies = res;
    }, error => {
      this.spin = false;
    });
  }

  showCompany(): void {
    this.router.navigate(['/company']);
  }

  showTables(): void {
    this.router.navigate(['/tables']);
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
