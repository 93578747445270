import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../interface/category';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Employee } from '../interface/createEmployee';


@Injectable({
  providedIn: 'root'
})
export class FlowService {
  categories: Category;
  isUpdating: boolean;
  loggedIn = false;

  constructor(private http: HttpClient) {

  }

  setCategories(category): Category{
    return this.categories = category;
    }
  getCategories(): Category{
    return this.categories;
  }

}
