import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ProductDTO } from '../../models/product/ProductDTO';
import { Constants } from 'src/app/common/Constants';
import * as $ from 'jquery';
import * as moment from 'moment';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company/company';
import { BranchMenuService } from 'src/app/services/branch-menu/branch-menu.service';
import { MobileCategory } from 'src/app/models/product/MobileCategory';
import { MobileSubcategory } from 'src/app/models/product/MobileSubcategory';
import { MobileProduct } from 'src/app/models/product/MobileProduct';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyDetailToDisplay } from 'src/app/models/company/companyDetailToDisplay';
import { CompanySetting } from 'src/app/models/company/companySetting';

@Component({
  selector: 'app-branch-menu',
  templateUrl: './branch-menu.component.html',
  styleUrls: ['./branch-menu.component.scss'],
  styles: [`
        .twentynine29 {
          color: initial !important;
        }
      `],
})
export class BranchMenuComponent implements OnInit {

  category: MobileCategory;

  companySetting: CompanySetting;
  categories: MobileCategory [];
  subcategoriesDrinks: Array<MobileSubcategory>;
  subcategoriesFood: Array<MobileSubcategory>;
  subcategoriesOther: Array<MobileSubcategory>;
  
  subcategoriesDrinksToDisplay: Array<MobileSubcategory> = [];
  subcategoriesFoodToDisplay: Array<MobileSubcategory> = [];
  subcategoriesOtherToDisplay: Array<MobileSubcategory> = [];
  
  categoryFood: MobileCategory [];
 
  tableHasData: boolean;

  printDate: string;
  printProductDTOs: ProductDTO[];
  company: CompanyDetailToDisplay;
  printCompanyName: string;
  printBranchName: string;
  printCompanyAddress: string;
  printCompanyCity: string;
  printCompanyCountry: string;
  printEstablishment: string;
 
  spin: boolean;

  branchIdForMenu: number;
  companyIdForMenu: number;


  constructor(private branchMenuService: BranchMenuService, private companyService: CompanyService,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe((params: Params) => this.branchIdForMenu = params['branchId']);
      
    this.spin = true;

    this.getProducts(this.branchIdForMenu);
  }

  applyCSS(): void {

    let e_menu_subcategory_color_value_variable = this.companySetting.e_menu_subcategory_color_value !== null ? this.companySetting.e_menu_subcategory_color_value : '#dedede !important;';
    let e_menu_subcategory_font_family_value_variable = this.companySetting.e_menu_subcategory_font_family_value !== null ? this.companySetting.e_menu_subcategory_font_family_value : "'Lucida Console', 'Courier New', monospace !important;";
    let e_menu_subcategory_text_transform_value_variable = this.companySetting.e_menu_subcategory_text_transform_value !== null ? this.companySetting.e_menu_subcategory_text_transform_value : 'uppercase;'

    let e_menu_product_name_color_value_variable = this.companySetting.e_menu_product_name_color_value !== null ? this.companySetting.e_menu_product_name_color_value : '#dedede !important;'
    let e_menu_product_name_font_family_value_variable = this.companySetting.e_menu_product_name_font_family_value !== null ? this.companySetting.e_menu_product_name_font_family_value : + "'Lucida Console', 'Courier New', monospace !important;"
    let e_menu_product_name_text_transform_value_variable = this.companySetting.e_menu_product_name_text_transform_value !== null ? this.companySetting.e_menu_product_name_text_transform_value : 'uppercase;'

    let e_menu_branch_name_color_value_variable = this.companySetting.e_menu_branch_name_color_value !== null ? this.companySetting.e_menu_branch_name_color_value : '#dedede !important;'
    let e_menu_branch_name_font_family_value_variable = this.companySetting.e_menu_branch_name_font_family_value !== null ? this.companySetting.e_menu_branch_name_font_family_value : "'Lucida Console', 'Courier New', monospace !important;"
    let e_menu_branch_name_text_transform_value_variable = this.companySetting.e_menu_branch_name_text_transform_value !== null ? this.companySetting.e_menu_branch_name_text_transform_value : 'uppercase;'

    let e_menu_company_name_color_value_variable = this.companySetting.e_menu_company_name_color_value !== null ? this.companySetting.e_menu_company_name_color_value : '#dedede !important;'
    let e_menu_company_name_font_family_value_variable = this.companySetting.e_menu_company_name_font_family_value !== null ? this.companySetting.e_menu_company_name_font_family_value : "'Lucida Console', 'Courier New', monospace !important;"
    let e_menu_company_name_text_transform_value_variable = this.companySetting.e_menu_company_name_text_transform_value !== null ? this.companySetting.e_menu_company_name_text_transform_value : 'uppercase;'
    let e_menu_company_name_margin_top_value_variable = this.companySetting.e_menu_company_name_margin_top_value !== null ? this.companySetting.e_menu_company_name_margin_top_value : '35px;'




    let text = '.subcategory-name-menu-dynamic {' +
      'color: ' + e_menu_subcategory_color_value_variable +
      '; font-family: ' + e_menu_subcategory_font_family_value_variable +
      '; text-transform: ' + e_menu_subcategory_text_transform_value_variable +
    '; } ' +

    '.product-name-menu-dynamic {' +
      'color: ' + e_menu_product_name_color_value_variable +
      '; font-family: ' + e_menu_product_name_font_family_value_variable +
      '; text-transform: ' + e_menu_product_name_text_transform_value_variable +
    '; } ' +

    '.branch-name-menu-dynamic {' +
      'color: ' + e_menu_branch_name_color_value_variable +
      '; font-family: ' + e_menu_branch_name_font_family_value_variable +
      '; text-transform: ' + e_menu_branch_name_text_transform_value_variable +
    '; } ' +
    
    '.pagado-emenu-mt {' +
    '; margin-top: ' + e_menu_company_name_margin_top_value_variable +
    '; } ' +

    '.company-name-menu-dynamic {' +
      'color: ' + e_menu_company_name_color_value_variable +
      '; font-family: ' + e_menu_company_name_font_family_value_variable +
      '; text-transform: ' + e_menu_company_name_text_transform_value_variable +
    '; }';





    // let text = `
    // .subcategory-name-menu-dynamic {
    //   color: ` + this.companySetting.e_menu_subcategory_color_value !== null ? this.companySetting.e_menu_subcategory_color_value : `#dedede !important;
    //   font-family: ` + this.companySetting.e_menu_subcategory_font_family_value !== null ? this.companySetting.e_menu_subcategory_font_family_value : `'Lucida Console', 'Courier New', monospace !important;
    //   text-transform: ` + this.companySetting.e_menu_subcategory_text_transform_value !== null ? this.companySetting.e_menu_subcategory_text_transform_value : `uppercase;
    // }
    // .product-name-menu-dynamic {
    //   color: ` + this.companySetting.e_menu_product_name_color_value !== null ? this.companySetting.e_menu_product_name_color_value : `#dedede !important;
    //   font-family: ` + this.companySetting.e_menu_product_name_font_family_value !== null ? this.companySetting.e_menu_product_name_font_family_value : `'Lucida Console', 'Courier New', monospace !important;
    //   text-transform: ` + this.companySetting.e_menu_product_name_text_transform_value !== null ? this.companySetting.e_menu_product_name_text_transform_value : `uppercase;
    // }
    // .branch-name-menu-dynamic {
    //   color: ` + this.companySetting.e_menu_branch_name_color_value !== null ? this.companySetting.e_menu_branch_name_color_value : `#dedede !important;
    //   font-family: ` + this.companySetting.e_menu_branch_name_font_family_value !== null ? this.companySetting.e_menu_branch_name_font_family_value : `'Lucida Console', 'Courier New', monospace !important;
    //   text-transform: ` + this.companySetting.e_menu_branch_name_text_transform_value !== null ? this.companySetting.e_menu_branch_name_text_transform_value : `uppercase;
    // }
    // .company-name-menu-dynamic {
    //   color: ` + this.companySetting.e_menu_company_name_color_value !== null ? this.companySetting.e_menu_company_name_color_value : `#dedede !important;
    //   font-family: ` + this.companySetting.e_menu_company_name_font_family_value !== null ? this.companySetting.e_menu_company_name_font_family_value : `'Lucida Console', 'Courier New', monospace !important;
    //   text-transform: ` + this.companySetting.e_menu_company_name_font_family_value !== null ? this.companySetting.e_menu_company_name_font_family_value : `uppercase;
    //   margin-top: ` + this.companySetting.e_menu_company_name_margin_top_value !== null ? this.companySetting.e_menu_company_name_margin_top_value : `35px
    // }
    // `
    $(document).ready(function(){
      $("style").append(text);      
    });
  }

  getProducts(branchId: number): void {
    this.spin = true;

    forkJoin([this.branchMenuService.getAllCategoriesAndSubcategoriesAndProductsByBranch(branchId), 
      this.companyService.getPublicCompanyDetailByBranchID(branchId), this.companyService.getCompanySettingByBranch(branchId)]).pipe(
      map(([responseFirstRequest, responseSecondRequest, responseThirdRequest])=>{

        //handling first response
      this.categories = responseFirstRequest;
      this.spin = false;

      if (this.categories.length > 0) {
        this.tableHasData = true;


        // this.subcategoriesDrinks = this.categories.find(cat => cat.category_name === Constants.CATEGORY_DRINK).subcategories;
        let subcategoryDrinks = this.categories.find(cat => cat.category_name === Constants.CATEGORY_DRINK);
        if (subcategoryDrinks !== undefined){
          this.subcategoriesDrinks = subcategoryDrinks.subcategories
        } else{
          this.subcategoriesDrinks = [];
        }
        
        // this.subcategoriesFood = this.categories.find(cat => cat.category_name === Constants.CATEGORY_FOOD).subcategories;
        let categoryWithFood = this.categories.find(cat => cat.category_name === Constants.CATEGORY_FOOD);
        if (categoryWithFood !== undefined){
          this.subcategoriesFood = categoryWithFood.subcategories
        } else{
          this.subcategoriesFood = [];
        }

        let categoryWithOther = this.categories.find(cat => cat.category_name === Constants.CATEGORY_OTHER_ITEM);
        if (categoryWithOther !== undefined){
          this.subcategoriesOther = categoryWithOther.subcategories
        } else{
          this.subcategoriesOther = [];
        }

        this.subcategoriesDrinks.forEach(subcategory => {
            
          if (subcategory.products.length < Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE)
          {
            this.subcategoriesDrinksToDisplay.push(subcategory);
          } else{

            for (let i = 0; i < subcategory.products.length; i += Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE) {
                const chunk: Array<MobileProduct> = subcategory.products.slice(i, i + Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE);

                let tempSubcategory: MobileSubcategory = new MobileSubcategory();
                tempSubcategory.products = chunk;
                tempSubcategory.sub_name = subcategory.sub_name;
                tempSubcategory.subcategory_id = subcategory.subcategory_id

                this.subcategoriesDrinksToDisplay.push(tempSubcategory);
            }
          }
        });

        this.subcategoriesFood.forEach(subcategory => {
            
          if (subcategory.products.length < Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE)
          {
            this.subcategoriesFoodToDisplay.push(subcategory);
          } else{

            for (let i = 0; i < subcategory.products.length; i += Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE) {
                const chunk: Array<MobileProduct> = subcategory.products.slice(i, i + Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE);

                let tempSubcategory: MobileSubcategory = new MobileSubcategory();
                tempSubcategory.products = chunk;
                tempSubcategory.sub_name = subcategory.sub_name;
                tempSubcategory.subcategory_id = subcategory.subcategory_id

                this.subcategoriesFoodToDisplay.push(tempSubcategory);
            }
          }
        });

        this.subcategoriesOther.forEach(subcategory => {
            
          if (subcategory.products.length < Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE)
          {
            this.subcategoriesOtherToDisplay.push(subcategory);
          } else{

            for (let i = 0; i < subcategory.products.length; i += Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE) {
                const chunk: Array<MobileProduct> = subcategory.products.slice(i, i + Constants.NUMBER_OF_PRODUCT_PER_MENU_PAGE);

                let tempSubcategory: MobileSubcategory = new MobileSubcategory();
                tempSubcategory.products = chunk;
                tempSubcategory.sub_name = subcategory.sub_name;
                tempSubcategory.subcategory_id = subcategory.subcategory_id

                this.subcategoriesOtherToDisplay.push(tempSubcategory);
            }
          }
        });
      } else {
        this.tableHasData = false;
      }

      //handling second response
      this.company = responseSecondRequest;
      this.printCompanyName = this.company.company_name;
      this.printBranchName = this.company.branch_name;


      // handling third response
      this.companySetting = responseThirdRequest;

      this.applyCSS();

      this.spin = false;

      }, err => {
        this.tableHasData = false;
        this.spin = false;
      })
    ).subscribe()







    // this.branchMenuService.getAllCategoriesAndSubcategoriesAndProductsByBranch(branchId).subscribe(res => {
    //   this.categories = res;
    //   this.spin = false;

    //   const numberOfProductPerPage = 16;

    //   if (this.categories.length > 0) {
    //     this.tableHasData = true;

    //     this.subcategoriesDrinks = this.categories.find(cat => cat.category_name === Constants.CATEGORY_DRINK).subcategories;

    //     this.subcategoriesDrinks.forEach(subcategory => {
            
    //       if (subcategory.products.length < numberOfProductPerPage)
    //       {
    //         this.subcategoriesDrinksToDisplay.push(subcategory);
    //       } else{

    //         // const chunkSize = 10;
    //         for (let i = 0; i < subcategory.products.length; i += numberOfProductPerPage) {
    //             const chunk: Array<MobileProduct> = subcategory.products.slice(i, i + numberOfProductPerPage);

    //             let tempSubcategory: MobileSubcategory = new MobileSubcategory();
    //             tempSubcategory.products = chunk;
    //             tempSubcategory.sub_name = subcategory.sub_name;
    //             tempSubcategory.subcategory_id = subcategory.subcategory_id

    //             this.subcategoriesDrinksToDisplay.push(tempSubcategory);
    //             // do whatever
    //         }

    //         // let test: Array<MobileProduct> = subcategory.products.slice(0, 10);
            
            

            
    //       }

    //     });


    //   } else {
    //     this.tableHasData = false;
    //   }
    //   this.spin = false;

    // }, err => {
    //   this.tableHasData = false;
    //   this.spin = false;

    // });
  }
}
