import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import { SaleReport } from 'src/app/models/order/SaleReport';
import { PastOrderReport } from 'src/app/models/order/PastOrderReport';
import { StockUpdateReport } from 'src/app/models/order/StockUpdateReport';
import { StockUpdateOutgoingReport } from 'src/app/models/order/StockUpdateOutgoingReport';
import * as $ from 'jquery';
import { OrderItem } from 'src/app/models/order-items';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { Input } from '@angular/core';
import { Employee } from 'src/app/interface/createEmployee';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-report-with-issues',
  templateUrl: './report-with-issues.component.html',
  styleUrls: ['./report-with-issues.component.scss']
})
export class ReportWithIssuesComponent implements OnInit {
  // searchForm: FormGroup;
  // searchFormStockUpdateEntry: FormGroup;
  // searchFormStockUpdateOutgoing: FormGroup;

  filterFormCancelledOrders: FormGroup;

  dataSource: MatTableDataSource<PastOrderReport>;
  dataSourceOrderItems: MatTableDataSource<OrderItem>;

  @ViewChild('paginatorCancelledOrderReport') paginatorCancelledOrderReport: MatPaginator;
  @ViewChild('sortCancelledOrderReport') sortCancelledOrderReport: MatSort;

  @ViewChild('paginatorOrderItem') paginatorOrderItem: MatPaginator;
  @ViewChild('sortOrderItem') sortOrderItem: MatSort;

  // totalRevenue: number;
  // currencyDefault: string;
  // totalNumberOfOrders_SaleReport: number;
  // totalNumberOfOrders: number;
  // mostPopularDrink: string;
  // numberOfBottleOfTheMostPopularDrink: number;
  // waiterNameWithMostRevenue: string;
  // waiterNameWithMostRevenueAmount: number;
  // waiterNameWithLeastRevenue: string;
  // waiterNameWithLeastRevenueAmount: number;
  // waiterNameWithMostSale: string;
  // waiterNameWithMostSaleAmount: number;
  // waiterNameWithLeastSale: string;
  // waiterNameWithLeastSaleAmount: number;

  tableHasDataCancelledOrderReport: boolean;

  tableHasOrderItemData: boolean;

  displayedColumnsPastOrderReport = [
    'OrderID',
    'Date & Time',
    'Waiter',
    'Value',
    'Action'
  ];

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  cancelledOrderReports: PastOrderReport[];
  // productDTOs: ProductDTO[];

  branches: Branch[];
  employeeWaiters: Employee[];

  startDateCancelledOrderReport: string;
  endDateCancelledOrderReport: string;

  // startDatePastOrders: string;
  // endDatePastOrders: string;

  @Input() id: string;

  pageCancelledOrderReport = 1;
  viewOrderItemPage = 1;

  orderItems: OrderItem[];
  closeResult: string;

  selectedOrderID_PastOrders: number;
  selectedOrderTotalAmount_PastOrders: number;
  selectedOrderDiscount_PastOrders: number;
  selectedOrderAmountToBePaid_PastOrders: number;
  selectedOrderCurrency_PastOrders: string;
  selectedOrderComment_PastOrders: string;

  // searchOrderForm: FormGroup;

  @ViewChild('viewOrderItem', { static: true }) viewOrderItem: TemplateRef<any>;

  isWaiterDropdownEnabled: boolean;
  currencyDefault: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private employeeService: EmployeeService,
    private formBuilder: FormBuilder) {

    this.cancelledOrderReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.spin = true;

      this.loadBranchesPastOrders();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadOrders(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadOrders(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterFormCancelledOrders.controls['dtCancelledOrdersFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormCancelledOrders.controls['dtCancelledOrdersTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

      // this.totalRevenue = 0;
      // this.totalNumberOfOrders = 0;
      // this.totalNumberOfOrders_SaleReport = 0;
      // this.mostPopularDrink = '';
      // this.numberOfBottleOfTheMostPopularDrink = 0;

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  createForms() {

    this.filterFormCancelledOrders = this.formBuilder.group({
      dtCancelledOrdersFrom: new FormControl(null, Validators.required),
      dtCancelledOrdersTo: new FormControl(null, Validators.required),
      dpBranch: new FormControl(null),
    });

    // this.searchOrderForm = this.formBuilder.group({
    //   searchOrderID: ['']
    // });
  }

  // searchOrder(branchId): void {
  //   this.spin = true;

  //   this.pastOrderReports = [];

  //   const order_id = this.searchOrderForm.controls.searchOrderID.value;
  //   this.orderService.searchOrder(this.authenticationService.currentUserValue.companyID, branchId, order_id).subscribe(res => {
  //     this.pastOrderReports = res;

  //     if (this.pastOrderReports.length > 0) {
  //       this.tableHasDataOrderReport = true;
  //       this.dataSourcePastOrders = new MatTableDataSource(this.pastOrderReports);
  //       this.dataSourcePastOrders.paginator = this.paginatorOrderReport;
  //       this.dataSourcePastOrders.sort = this.sortOrderReport;
  //     } else {
  //       this.tableHasDataOrderReport = false;
  //     }
  //     this.spin = false;

  //   }, err => {
  //     this.tableHasDataOrderReport = false;
  //     this.dataSourcePastOrders = new MatTableDataSource([]);
  //     this.dataSourcePastOrders.paginator = this.paginatorOrderReport;
  //     this.dataSourcePastOrders.sort = this.sortOrderReport;
  //     this.spin = false;
  //   });
  // }

  // searchReportPastOrders(branchIdPastOrders) {
  //   this.spin = true;

  //   var date = new Date();

  //   if (this.filterFormPastOrders.controls['dtPastOrdersFrom'].value !== null) {
  //     this.startDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
  //     this.endDatePastOrders = moment(this.filterFormPastOrders.controls['dtPastOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
  //   } else {
  //     this.startDatePastOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
  //     this.endDatePastOrders = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
  //   }

  //   this.loadReportPastOrders(branchIdPastOrders, this.startDatePastOrders, this.endDatePastOrders);
  // }

  loadBranchesPastOrders() {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.filterFormCancelledOrders.controls.dpBranch.enable();
        this.filterFormCancelledOrders.controls.dpBranch.setValue(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

        this.filterFormCancelledOrders.controls.dpBranch.disable();
        this.filterFormCancelledOrders.controls.dpBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  loadAllWaitersInBranch(branchIdPastOrders) {
    this.spin = true;
    this.employeeService.getAllWaiters(this.authenticationService.currentUserValue.companyID, branchIdPastOrders).subscribe(res => {
      this.employeeWaiters = res;
      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  // loadCancelledOrdersPerWaiter(employeeID) {
  //   this.spin = true;

  //   var date = new Date();

  //   if (this.filterFormCancelledOrders.controls['dtCancelledOrdersFrom'].value !== null) {
  //     this.startDateCancelledOrderReport = moment(this.filterFormCancelledOrders.controls['dtCancelledOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
  //     this.endDateCancelledOrderReport = moment(this.filterFormCancelledOrders.controls['dtCancelledOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
  //   } else {
  //     this.startDateCancelledOrderReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
  //     this.endDateCancelledOrderReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
  //   }

  //   this.orderService.getCancelledOrdersReport(this.authenticationService.currentUserValue.companyID, Number(employeeID),
  //     Number(this.filterFormCancelledOrders.controls.dpBranch.value), this.startDateCancelledOrderReport, this.endDateCancelledOrderReport).subscribe(res => {
  //       this.cancelledOrderReports = res;

  //       if (this.cancelledOrderReports.length > 0) {
  //         this.tableHasDataCancelledOrderReport = true;
  //         this.dataSource = new MatTableDataSource(this.cancelledOrderReports);
  //         this.dataSource.paginator = this.paginatorCancelledOrderReport;
  //         this.dataSource.sort = this.sortCancelledOrderReport;

  //         this.currencyDefault = this.cancelledOrderReports[0].currency_one;

  //       } else {
  //         this.tableHasDataCancelledOrderReport = false;
  //       }
  //       this.spin = false;

  //     }, error => {
  //       this.tableHasDataCancelledOrderReport = false;
  //       this.dataSource = new MatTableDataSource([]);
  //       this.dataSource.paginator = this.paginatorCancelledOrderReport;
  //       this.dataSource.sort = this.sortCancelledOrderReport;
  //       this.spin = false;
  //     });
  // }


  loadOrderItemsByOrderID(orderID: number, discount: number, orderTotalAmount: number, currency: string, comment:string) {
    this.spin = true;

    this.selectedOrderID_PastOrders = orderID;
    this.selectedOrderTotalAmount_PastOrders = discount + orderTotalAmount;
    this.selectedOrderDiscount_PastOrders = discount;
    this.selectedOrderAmountToBePaid_PastOrders = orderTotalAmount;
    this.selectedOrderCurrency_PastOrders = currency;
    this.selectedOrderComment_PastOrders = comment;

    this.open(this.viewOrderItem);


    this.orderService.getAllOrderItem(orderID).subscribe(res => {

      this.orderItems = res;

      if (this.orderItems.length > 0) {
        this.tableHasOrderItemData = true;
        this.dataSourceOrderItems = new MatTableDataSource(this.orderItems);
        this.dataSourceOrderItems.paginator = this.paginatorOrderItem;
        this.dataSourceOrderItems.sort = this.sortOrderItem;

      } else {
        this.tableHasOrderItemData = false;
      }
      this.spin = false;

    }, error => {
      this.tableHasOrderItemData = false;
      this.dataSourceOrderItems = new MatTableDataSource([]);
      this.dataSourceOrderItems.paginator = this.paginatorOrderItem;
      // this.dataSourceOrderItems.sort = this.sortOrderItem;
      this.spin = false;
    });
  }

  loadCancelledOrders(branchIdPastOrders: number, fromDatePastOrders: string, toDatePastOrders: string) {
    this.spin = true;

    this.orderService.getCancelledOrdersReport(this.authenticationService.currentUserValue.companyID,
      0, branchIdPastOrders, fromDatePastOrders, toDatePastOrders).subscribe(res => {
        this.cancelledOrderReports = res;

        if (this.cancelledOrderReports.length > 0) {
          this.tableHasDataCancelledOrderReport = true;
          this.dataSource = new MatTableDataSource(this.cancelledOrderReports);
          this.dataSource.paginator = this.paginatorCancelledOrderReport;
          this.dataSource.sort = this.sortCancelledOrderReport;

          this.currencyDefault = this.cancelledOrderReports[0].currency_one;
        } else {
          this.tableHasDataCancelledOrderReport = false;
        }
        this.spin = false;

      }, error => {
        this.tableHasDataCancelledOrderReport = false;
        this.dataSource = new MatTableDataSource([]);
        this.dataSource.paginator = this.paginatorCancelledOrderReport;
        this.dataSource.sort = this.sortCancelledOrderReport;
        this.spin = false;
      });

  }

  filterPastOrders(branchIdPastOrders) {
    this.startDateCancelledOrderReport = moment(this.filterFormCancelledOrders.controls['dtCancelledOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateCancelledOrderReport = moment(this.filterFormCancelledOrders.controls['dtCancelledOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    // let employeeID_ = this.filterFormCancelledOrders.controls.dpWaiter.value;

    // if ((employeeID_ !== 0) && (employeeID_ !== null)) {
    //   this.loadCancelledOrdersPerWaiter(employeeID_);
    // } else {
      this.loadCancelledOrders(branchIdPastOrders, this.startDateCancelledOrderReport, this.endDateCancelledOrderReport);
    // }
  }

  loadOrders(branchIdPastOrders) {

    // this.searchOrderForm.controls.searchOrderID.reset();

    // if (branchIdPastOrders === 0) {
    //   this.filterFormCancelledOrders.controls.dpWaiter.disable();
    //   this.isWaiterDropdownEnabled = false;
    // } else {
    //   this.isWaiterDropdownEnabled = true;
    //   this.filterFormCancelledOrders.controls.dpWaiter.enable();
    //   this.loadAllWaitersInBranch(branchIdPastOrders);

    // }

    var date = new Date();

    if (this.filterFormCancelledOrders.controls['dtCancelledOrdersFrom'].value !== null) {
      this.startDateCancelledOrderReport = moment(this.filterFormCancelledOrders.controls['dtCancelledOrdersFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateCancelledOrderReport = moment(this.filterFormCancelledOrders.controls['dtCancelledOrdersTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateCancelledOrderReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateCancelledOrderReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadCancelledOrders(branchIdPastOrders, this.startDateCancelledOrderReport, this.endDateCancelledOrderReport);
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event: any) {
    this.pageCancelledOrderReport = event;
    window.scroll(0, 0);
  }
}
