import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Branch } from 'src/app/interface/branch';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Constants } from '../../common/Constants';
import * as $ from 'jquery';
import * as moment from 'moment';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { OrderService } from 'src/app/services/order/order.service';
import { SaleAndBottleProfitLoss } from 'src/app/models/order/SaleAndBottleProfitLoss';
import { SaleAndProfitAndLossReportToDisplay } from 'src/app/models/order/SaleAndProfitAndLossReportToDisplay';
import { Expense } from 'src/app/models/Expense';
import { Summary } from '@angular/compiler';
import { SummaryItem } from 'src/app/models/order/SummaryItem';
import { ExpenseSummaryReport } from 'src/app/models/ExpenseSummaryReport';
import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { PrintDriver } from 'ng-thermal-print/lib/drivers/PrintDriver';


@Component({
  selector: 'app-warehouse-refill',
  templateUrl: './warehouse-refill.component.html',
  styleUrls: ['./warehouse-refill.component.scss']
})
export class WarehouseRefillComponent implements OnInit {


  // Summary report
  totalDrinkRevenueWithoutProfit: number;
  totalDrinkRevenuWithProfit: number;
  currencyDefault: string;

  tableHasDataSaleReport: boolean;

  @ViewChild('successModal', { static: true }) successModal: TemplateRef<any>;
  @ViewChild('failureModal', { static: true }) failureModal: TemplateRef<any>;

  closeResult: string;
  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  displayedColumnsSaleReport = [
    'Product',
    'QuantitySold',
    'TotalRevenue'
  ];

  dataSourceSaleAndProfitReport: MatTableDataSource<SaleAndBottleProfitLoss>;

  @ViewChild(MatPaginator) paginatorSaleReport: MatPaginator;
  @ViewChild(MatSort) sortSaleReport: MatSort;

  @ViewChild(MatPaginator) paginatorSaleProfitDetailReport: MatPaginator;
  @ViewChild(MatSort) sortSaleProfitDetailReport: MatSort;

  filterFormSaleReport: FormGroup;

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  saleDrinkReports: SaleAndBottleProfitLoss[];
  dataItemToPrint: SaleAndBottleProfitLoss[];
  saleDrinkLossReports: SaleAndBottleProfitLoss[];
  saleSummaryReports: SummaryItem[];
  saleReportToBeDisplayed: SaleAndProfitAndLossReportToDisplay[];
  saleListToPrint: SaleAndProfitAndLossReportToDisplay[];
  branches: Branch[];

  startDateSaleReport: string;
  endDateSaleReport: string;
  startDate: string;
  endDate: string;

  pageSaleReport1 = 1;

  saletoDisplay: SaleAndProfitAndLossReportToDisplay;
  saletoPrint: SaleAndProfitAndLossReportToDisplay;

  status: boolean = false;
  usbPrintDriver: UsbDriver;
  webPrintDriver: WebPrintDriver;
  ip: string = '';

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private printService: PrintService,
    private formBuilder: FormBuilder) {
    this.saleSummaryReports = [];
    this.saleDrinkReports = [];
    this.saleReportToBeDisplayed = [];
    this.saleListToPrint = [];
    // this.saleDrinkLossReports = [];
    this.branches = [];

    this.usbPrintDriver = new UsbDriver();
    this.printService.isConnected.subscribe(result => {
      this.status = result;
      if (result) {
        // console.log('Connected to printer!!!');
      } else {
        // console.log('Not connected to printer.');
      }
    });
  }

  ngOnInit(): void {
    this.spin = true;

    this.createForm();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_WAREHOUSE)) {

      this.loadSaleReportBranches();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadSaleAndProfitReport(0);
        // console.log('a');
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_WAREHOUSE)) {
        this.loadSaleAndProfitReport(this.authenticationService.currentUserValue.branchID);
        // console.log('b');
      }

      var date = new Date();

      this.filterFormSaleReport.controls['dtSaleReportFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormSaleReport.controls['dtSaleReportTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  createForm(): void {
    this.filterFormSaleReport = this.formBuilder.group({
      dtSaleReportFrom: new FormControl(null, Validators.required),
      dtSaleReportTo: new FormControl(null, Validators.required),
      selectBranch: ['']
    });
  }

  requestUsb() {
    this.usbPrintDriver.requestUsb().subscribe(result => {
      this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    });
  }

  connectToWebPrint() {
    this.webPrintDriver = new WebPrintDriver(this.ip);
    this.printService.setDriver(this.webPrintDriver, 'WebPRNT');
  }

  print(saleListToPrint: SaleAndProfitAndLossReportToDisplay[]) {

    let myDate = new Date().toISOString();
    var today = myDate.split('T')[0];
    const currentDateAndTime = moment().format('YYYY-MM-DD HH:mm:ss')

    var myText2 = " \n";
    myText2 += "**************************** \n";
    myText2 += "         ARTICLE VENDU \n";
    myText2 += "**************************** \n";
    myText2 += "Aujourd'hui: " + currentDateAndTime + " \n";
    myText2 += "\n";
    myText2 += "\n";
    myText2 += "De: " + this.startDateSaleReport + "  \n";
    myText2 += "A: " + this.endDateSaleReport + "  \n";
    myText2 += "---------------------------- \n";
    myText2 += "\n";

    saleListToPrint.forEach(item => {
      myText2 += item.quantity_sold + "x   " + item.product_name + "   " + (item.selling_cost * item.quantity_sold) + " Fc \n";
    });

    myText2 += "\n";
    myText2 += "---------------------------- \n";
    myText2 += "TOTAL en FC: " + this.totalDrinkRevenuWithProfit + " Fc \n";
    myText2 += "TOTAL en USD: " + (this.totalDrinkRevenuWithProfit / 2000).toFixed(2) + " $ \n";
    myText2 += " \n";
    myText2 += " \n";
    myText2 += "---------------------------- \n";
    myText2 += "************ MERCI ********** \n";
    myText2 += " \n";

    this.printService.init()
      .setBold(true)
      .writeLine(myText2)
      .setBold(false)
      .setJustification('center')
      .feed(2)
      .cut('full')
      .flush();
  }

  getPrintData(branchIDSelected) {

    this.saleListToPrint = [];
    
    var startDatePrint = moment(this.filterFormSaleReport.controls.dtSaleReportFrom.value).format('YYYY-MM-DD HH:mm:ss').toString();
    var endDatePrint = moment(this.filterFormSaleReport.controls.dtSaleReportTo.value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.orderService.getSaleAndProfitReport(this.authenticationService.currentUserValue.companyID,
      branchIDSelected, startDatePrint, endDatePrint).subscribe(res => {

        this.dataItemToPrint = res.saleAndProfitReportList;

        this.currencyDefault = 'FC'; //this.saleDrinkReports[0].currency_one;

        if (this.dataItemToPrint.length > 0) {

          this.dataItemToPrint.forEach(product => {

            this.saletoPrint = new SaleAndProfitAndLossReportToDisplay();

            this.saletoPrint.quantity_loss = 0;
            this.saletoPrint.date_sale_placed = product.date_sale_placed;
            this.saletoPrint.product_name = product.product_name;
            this.saletoPrint.branch_id = product.branch_id;
            this.saletoPrint.branch_name = product.branch_name;
            this.saletoPrint.quantity_sold = product.quantity_sold;
            this.saletoPrint.buying_cost = product.buying_cost;
            this.saletoPrint.selling_cost = product.selling_cost;
            this.saletoPrint.profit_on_product = product.profit_on_product;
            this.saletoPrint.company_id = product.company_id;
            this.saletoPrint.category_name = product.category_name;

            this.saleListToPrint.push(this.saletoPrint);
          });

          this.print(this.saleListToPrint);

        } else {
          this.tableHasDataSaleReport = false;
          this.resetSummaryInfo();
        }

        this.spin = false;

      }, error => {
        // this.tableHasDataSaleReport = false;

        this.resetSummaryInfo();

        // this.dataSourceSaleAndProfitReport = new MatTableDataSource([]);
        // this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
        // this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;
        this.spin = false;
      });
  }


  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  loadSaleReportBranches(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.filterFormSaleReport.controls.selectBranch.enable();
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.filterFormSaleReport.controls.selectBranch.disable();
        this.filterFormSaleReport.controls.selectBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  filterSaleReport(branchIdSaleReport): void {
    this.startDateSaleReport = moment(this.filterFormSaleReport.controls.dtSaleReportFrom.value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateSaleReport = moment(this.filterFormSaleReport.controls.dtSaleReportTo.value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadCompanySaleAndProfitReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  resetSummaryInfo(): void {
    this.totalDrinkRevenueWithoutProfit = 0;
    this.totalDrinkRevenuWithProfit = 0;
    this.saleReportToBeDisplayed = [];
  }

  loadCompanySaleAndProfitReport(branchId: number, fromDate: string, toDate: string) {
    this.spin = true;

    this.resetSummaryInfo();
    this.orderService.getSaleAndProfitReport(this.authenticationService.currentUserValue.companyID,
      branchId, fromDate, toDate).subscribe(res => {

        this.saleDrinkReports = res.saleAndProfitReportList;
        this.saleDrinkLossReports = res.saleAndLossReportList;
        this.saleSummaryReports = res.summaryItems;
        this.currencyDefault = 'FC'; //this.saleDrinkReports[0].currency_one;

        if (this.saleSummaryReports !== undefined) {
          if (this.saleSummaryReports.length > 0) {

            let drinkItemSummaryInfo = this.saleSummaryReports.find(x => x.categoryName === Constants.CATEGORY_DRINK);
            if (drinkItemSummaryInfo !== undefined) {
              this.totalDrinkRevenuWithProfit = drinkItemSummaryInfo.totalRevenu;
              this.totalDrinkRevenueWithoutProfit = drinkItemSummaryInfo.totalRevenu - drinkItemSummaryInfo.profit;
            } else {
              this.totalDrinkRevenuWithProfit = 0;
              this.totalDrinkRevenueWithoutProfit = 0;
            }

          } else {
          }
        }

        if (this.saleDrinkReports.length > 0) {
          this.tableHasDataSaleReport = true;

          this.saleDrinkReports = this.saleDrinkReports.filter(x => x.category_name === Constants.CATEGORY_DRINK);

          this.saleDrinkReports.forEach(product => {

            this.saletoDisplay = new SaleAndProfitAndLossReportToDisplay();

            let lossProductFound = this.saleDrinkLossReports.find(p => p.product_name === product.product_name);

            if ((lossProductFound !== null) && (lossProductFound !== undefined)) {
              this.saletoDisplay.quantity_loss = lossProductFound.quantity_sold;
            } else {
              this.saletoDisplay.quantity_loss = 0;
            }

            this.saletoDisplay.date_sale_placed = product.date_sale_placed;
            this.saletoDisplay.product_name = product.product_name;
            this.saletoDisplay.branch_id = product.branch_id;
            this.saletoDisplay.branch_name = product.branch_name;
            this.saletoDisplay.quantity_sold = product.quantity_sold;
            this.saletoDisplay.buying_cost = product.buying_cost;
            this.saletoDisplay.selling_cost = product.selling_cost;
            this.saletoDisplay.profit_on_product = product.profit_on_product;
            this.saletoDisplay.company_id = product.company_id;
            this.saletoDisplay.category_name = product.category_name;
            this.saletoDisplay.subcategory_name = product.subcategory_name;

            this.saleReportToBeDisplayed.push(this.saletoDisplay);
          });

          this.dataSourceSaleAndProfitReport = new MatTableDataSource(this.saleReportToBeDisplayed);
          this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
          this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;

        } else {
          this.tableHasDataSaleReport = false;
          this.resetSummaryInfo();
        }

        this.spin = false;

      }, error => {
        this.tableHasDataSaleReport = false;

        this.resetSummaryInfo();

        this.dataSourceSaleAndProfitReport = new MatTableDataSource([]);
        this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
        this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;
        this.spin = false;
      });
  }

  loadSaleAndProfitReport(branchIdSaleReport) {
    var date = new Date();

    if (this.filterFormSaleReport.controls['dtSaleReportFrom'].value !== null) {
      this.startDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadCompanySaleAndProfitReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}