<div class="body">
  <div class="container">
    <div class="row">
      <div class="offset-md-2 col-md-8 grid-margin stretch-card login-card">
        <div class="card mb-4">
          <div class="card-body">

            <h5>Première expérience</h5>
            <h6 class="font-weight-light mb-5">Veuillez changer votre mot de passe et selectionner vos questions et
              réponses secrète avant d'utiliser le système pour la première fois.</h6>

            <div>
              <div class="form-group">
                <p class="color-fail">{{ isOnlineMsg }}</p>
              </div>
            </div>

            <form class="pt-3" class="forms-sample" [formGroup]="firstLoginChangePasswordForm">
              <div class="row">
                <div class="col-md-12 col-12">
                  <div class="form-group">
                    <small class="form-text text-muted">Voici votre nom d'utilisateur</small>
                    <input type="text" class="form-control form-control-lg" formControlName="username"
                      placeholder="Nom d'utilisateur" [attr.disabled]="true">
                    <ng-container *ngFor="let validation of validationMessage.username">
                      <div class="color-fail"
                        *ngIf="firstLoginChangePasswordForm.get('username').hasError(validation.type) && (firstLoginChangePasswordForm.get('username').dirty || firstLoginChangePasswordForm.get('username').touched)">
                        {{ validation.message }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <small class="form-text text-muted">Veuillez entrer votre nouveau mot de passe</small>
                    <input type="password" class="form-control form-control-lg" formControlName="password"
                      placeholder="Mot de passe">
                    <ng-container *ngFor="let validation of validationMessage.password">
                      <div class="color-fail"
                        *ngIf="firstLoginChangePasswordForm.get('password').hasError(validation.type) &&
                                        (firstLoginChangePasswordForm.get('password').dirty || firstLoginChangePasswordForm.get('password').touched)">
                        {{ validation.message }}
                      </div>
                      <div class="color-fail"
                        *ngIf="firstLoginChangePasswordForm.get('password').hasError('minlength')">
                        Le mot de passe ne peut pas avoir moins de 8 caractères
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <small class="form-text text-muted">Veuillez répéter le mot de passe entré</small>
                    <input type="password" class="form-control form-control-lg" formControlName="password2"
                      placeholder="Mot de passe">
                    <ng-container *ngFor="let validation of validationMessage.password2">
                      <div class="color-fail"
                        *ngIf="firstLoginChangePasswordForm.get('password2').hasError(validation.type) &&
                                          (firstLoginChangePasswordForm.get('password2').dirty || firstLoginChangePasswordForm.get('password2').touched)">
                        {{ validation.message }}
                      </div>
                      <div class="color-fail"
                        *ngIf="firstLoginChangePasswordForm.get('password2').hasError('minlength')">
                        Le mot de passe ne peut pas avoir moins de 8 caractères
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              
              <h6 class="mt-4">
                Question 1
              </h6>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <small class="form-text text-muted">Question 1</small>
                    <select class="form-control" formControlName="questionOne" required>
                      <option value="" disabled selected>Selectionnez une question</option>
                      <option *ngFor="let recoveryQuestion of recoveryQuestions"
                        value={{recoveryQuestion.recover_question_id}}>
                        {{recoveryQuestion.question}}</option>
                    </select>
                    <ng-container *ngFor="let validation of validationMessage.questionOne">
                      <div class="color-fail"
                        *ngIf="firstLoginChangePasswordForm.get('questionOne').hasError(validation.type) &&
                                        (firstLoginChangePasswordForm.get('questionOne').dirty || firstLoginChangePasswordForm.get('questionOne').touched)">
                        {{ validation.message }}
                      </div>
                    </ng-container>

                  </div>

                </div>
                <div class="col-md-6 col-12">
                  <small class="form-text text-muted">Réponse à la question 1
                  </small>
                  <input type="text" class="form-control form-control-lg" formControlName="answerOne"
                    placeholder="Votre réponse">
                  <ng-container *ngFor="let validation of validationMessage.answerOne">
                    <div class="color-fail"
                      *ngIf="firstLoginChangePasswordForm.get('answerOne').hasError(validation.type) &&
                                      (firstLoginChangePasswordForm.get('answerOne').dirty || firstLoginChangePasswordForm.get('answerOne').touched)">
                      {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
              </div>

              <h6 class="mt-4">
                Question 2
              </h6>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <small class="form-text text-muted">Question 2</small>
                    <select class="form-control" formControlName="questionTwo" required>
                      <option value="" disabled selected>Selectionnez une question</option>
                      <option *ngFor="let recoveryQuestion of recoveryQuestions"
                        value={{recoveryQuestion.recover_question_id}}>
                        {{recoveryQuestion.question}}</option>
                    </select>
                    <ng-container *ngFor="let validation of validationMessage.questionTwo">
                      <div class="color-fail"
                        *ngIf="firstLoginChangePasswordForm.get('questionTwo').hasError(validation.type) &&
                                        (firstLoginChangePasswordForm.get('questionTwo').dirty || firstLoginChangePasswordForm.get('questionTwo').touched)">
                        {{ validation.message }}
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <small class="form-text text-muted">Réponse à la question 2</small>
                  <input type="text" class="form-control form-control-lg" formControlName="answerTwo"
                    placeholder="Votre réponse">
                  <ng-container *ngFor="let validation of validationMessage.answerTwo">
                    <div class="color-fail"
                      *ngIf="firstLoginChangePasswordForm.get('answerTwo').hasError(validation.type) &&
                                        (firstLoginChangePasswordForm.get('answerTwo').dirty || firstLoginChangePasswordForm.get('answerTwo').touched)">
                      {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-6 col-12 text-right">
                  <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn tn-bg-green-color"
                    (click)="savePassword()">
                    CONFIRMER
                  </div> &nbsp;
                </div>
                <div class="col-md-6 col-12 text-left">
                  <div class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn btn-danger"
                    (click)="cancel()">
                    ANNULER
                  </div>
                </div>
              </div>
            </form>

            <!----success product modal -->
            <ng-template #successSaveProduct let-modal>
              <div class="modal-header text-center border-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body py-0 text-center">
                <div class="row">
                  <div class="col-12">
                    <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                  </div>
                </div>
                <label class="mt-3"> {{successModalMessage}} </label>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
              </div>
            </ng-template>


            <!----failure product modal -->
            <ng-template #failureSaveProduct let-modal>
              <div class="modal-header text-center border-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body py-0 text-center">
                <div class="row">
                  <div class="col-12">
                    <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                  </div>
                </div>
                <label class="mt-3"> {{failureModalMessage}} </label>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
              </div>
            </ng-template>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Chargement...</span>
    </div>
  </div>
</div>