import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {Branch} from '../interface/branch';
import {Category} from '../interface/category';
import {FlowService} from './flow.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient, private flowService: FlowService) {
  }

  getBranch(companyId: number): Observable<any> {
    return this.http.post<any>(this.apiURL + '/branch/getall?intCompanyID=' + companyId, null);
  }

  getCategories(companyId: number, branchId: number): Observable<Category []> {
    return this.http.post<Category []>(this.apiURL + '/category/getcategoriesandsubcategories?companyID=' +
      companyId + '&branchID=' + branchId,
      null);
  }

  saveNewCategory(category): Observable<Category> {
    return this.http.post<Category>(this.apiURL + '/category/savenew', category);
  }

  saveNewCategoryName(category): Observable<Category> {
    return this.http.post<Category>(this.apiURL + '/category/update', category);
  }

  deleteCategory(category: Category): Observable<Category> {
    return this.http.post<Category>(this.apiURL + '/category/delete?intCategoryID=' + category.category_id, null);
  }

  searchCategory(companyId: number, branchID: number, name: string): Observable<Category []> {
    return this.http.post<Category []>(this.apiURL + '/category/searchCategoryByNameAndBranchID?companyID=' + companyId +
      '&branchID=' + branchID + '&strCategoryName=' + name, null);
  }
}
