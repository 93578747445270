import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Constants } from '../../common/Constants';
import { Category } from '../../interface/category';
import { CategoriesService } from '../../services/categories.service';
import { Branch } from '../../interface/branch';
import { BranchService } from 'src/app/services/branch.service';
import * as $ from 'jquery';
import { Expense } from 'src/app/models/Expense';
import { ExpenseService } from 'src/app/services/expense.service';
import * as moment from 'moment';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ExpenseType } from 'src/app/models/ExpenseType';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent implements OnInit {

  searchExpenseName: FormGroup;
  expenses: Expense[];
  spin: boolean;
  addExpenseForm: FormGroup;
  expenseToDelete: any;
  tempExpense: Expense;
  expense: Expense;
  showTable: boolean;
  selectBranchForm: FormGroup;
  isAdminManager: boolean = false;

  dataSourceExpense: MatTableDataSource<Expense>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsTable = [
    'ExpenseAmount',
    'ExpenseDescription',
    'ExpenseDate',
    'Actions'
  ];

  @ViewChild('successModal', { static: true }) successModal: TemplateRef<any>;
  @ViewChild('failureModal', { static: true }) failureModal: TemplateRef<any>;
  // @ViewChild('editTable', {static: true}) editTableModal: TemplateRef<any>;
  @ViewChild('deleteExpense', { static: true }) deleteExpenseModal: TemplateRef<any>;
  @ViewChild('createNewExpense', { static: true }) createNewExpenseModal: TemplateRef<any>;

  closeResult: string;

  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  tableHasData: boolean;
  categories: Category[];
  branchList: Branch[];
  expenseTypeList: ExpenseType[];

  currencyDefault: string;
  totalExpense: number;

  branchOfTheExpenseToDelete: string;

  page = 1;

  selectBranchFormGroupValidationMessage = {
    dtExpenseFrom: [{ type: 'required', message: 'Please enter from date' }],
    dtExpenseTo: [{ type: 'required', message: 'Please enter to date' }]
  };

  startDate: string;
  endDate: string;

  constructor(private router: Router, private expenseService: ExpenseService, private fb: FormBuilder,
    private authenticationService: AuthenticationService, private modalService: NgbModal,
    private branchService: BranchService) {
  }

  ngOnInit(): void {
    this.spin = true;
    this.createForm();

    var date = new Date();

    this.selectBranchForm.controls['dtExpenseFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
    this.selectBranchForm.controls['dtExpenseTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

    this.currencyDefault = 'FC';
    this.totalExpense = 0;

    this.getFranchise();
    this.getAllExpenseTypes();
    this.spin = false;
    this.searchExpenseName.controls['expense_description'].valueChanges.subscribe(val=>{
      if(val ===''){
        this.searchExpense(this.branchIdToBeUsed);
      }
    });
  }

  createForm(): void {
    this.searchExpenseName = this.fb.group({
      branch_id: [''],
      expense_description: ['']
    });
    this.addExpenseForm = this.fb.group({
      expense_description: [''],
      expense_type: [''],
      addSubCategoryFormBranchID: [''],
      amount: [''],
      expense_date: ['']
    });
    // this.viewTableForm = this.fb.group({
    //   table_name: [''],
    //   table_id: ['']
    // });
    // this.editTableForm = this.fb.group({
    //   table_name: [''],
    //   table_id: [''],
    //   editTableFormBranchID: ['']
    // });
    this.selectBranchForm = this.fb.group({
      dtExpenseFrom: new FormControl(null, Validators.required),
      dtExpenseTo: new FormControl(null, Validators.required),
      selectBranch: [''],
      expense_type: ['']
    });
  }

  resetSummaryInfo(): void {
    this.totalExpense = 0;
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getFranchise(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchList = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.branchIdToBeUsed = this.branchList[0].branch_id;
        this.selectBranchForm.controls.selectBranch.enable();
        this.isAdminManager = true;

      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {

        this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        this.selectBranchForm.controls.selectBranch.disable();
        this.isAdminManager = false;
      } else {
        this.isAdminManager = false;
      }
      this.selectBranchForm.controls.selectBranch.setValue(this.branchIdToBeUsed);
      this.selectBranchForm.controls.expense_type.setValue(0);
      this.getExpenses(this.branchIdToBeUsed);

    }, error => {
      this.spin = false;
    });
  }

  getAllExpenseTypes(): void {
    this.expenseService.getAllExpenseType().subscribe(res => {
      this.expenseTypeList = res;
    }, error => {
      this.spin = false;
    });
  }

  getBranchCategories(event): void {
    const branchId = event.target.value;
    this.branchIdToBeUsed = branchId;

    this.selectBranchForm.controls.expense_type.setValue(0);
    this.getExpenses(branchId);
  }

  searchExpense(branchId): void {
    this.spin = true;
    this.expenses = null;

    const expense_description = this.searchExpenseName.controls.expense_description.value;

    if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
      (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
      this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
    } else {
      this.branchIdToBeUsed = branchId;
    }

    var date = new Date();

    if (this.selectBranchForm.controls['dtExpenseFrom'].value !== null) {
      this.startDate = moment(this.selectBranchForm.controls['dtExpenseFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(this.selectBranchForm.controls['dtExpenseTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.expenseService
      .searchExpenseByBranches(this.branchIdToBeUsed, expense_description, this.startDate, this.endDate)
      .subscribe(res => {
        this.expenses = res;

        if (this.expenses.length > 0) {
          this.tableHasData = true;
          this.dataSourceExpense = new MatTableDataSource(this.expenses);
          this.dataSourceExpense.paginator = this.paginator;
          this.dataSourceExpense.sort = this.sort;
        } else {
          this.tableHasData = false;
        }
        this.spin = false;
      }, err => {
        this.tableHasData = false;
        this.dataSourceExpense = new MatTableDataSource([]);
        this.dataSourceExpense.paginator = this.paginator;
        this.dataSourceExpense.sort = this.sort;
        this.spin = false;

      });
  }

  addExpenseButton(branchId): void {
    this.addExpenseForm.reset();

    // var date = new Date();

    // let todayDate = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD').toString();
    // this.addExpenseForm.controls['expense_date'].setValue(todayDate);

    this.addExpenseForm.controls.addSubCategoryFormBranchID.setValue(branchId);
    this.open(this.createNewExpenseModal);
  }

  saveNewExpense(): void {
    this.spin = true;
    let expenseToBeAdded: Expense;

    expenseToBeAdded = {
      expense_id: 0,
      expense_name: "New",
      expense_description: this.addExpenseForm.controls.expense_description.value,
      expense_type_id: Number(this.addExpenseForm.controls.expense_type.value),
      branch_id: Number(this.addExpenseForm.controls.addSubCategoryFormBranchID.value),
      amount: Number(this.addExpenseForm.controls.amount.value),
      expense_date: moment(this.addExpenseForm.controls.expense_date.value).format('YYYY-MM-DD').toString() + ' 09:00:00',
      status: 1
    };

    this.expenseService.saveExpense(expenseToBeAdded).subscribe(res => {

      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Votre dépense a été enregistré avec succès');

        if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
          (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
          this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        }

        this.addExpenseForm.reset();
        this.getExpenses(this.branchIdToBeUsed);
      } else {
        this.spin = false;
        this.showFailureModal('L\'ajout de la dépense a échoué, réessayez plus tard');
      }
    }, error => {

      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cette dépense existe déjà');
      } else {

        this.showFailureModal('L\'ajout de la dépense a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  // viewTable(expense): void {
  //   this.tempExpense = expense;
  //   this.viewTableForm.controls.table_name.setValue(this.tempExpense.table_name);
  // }

  // editTable1(table): void {

  //   this.editTableForm.controls.editTableFormBranchID.setValue(table.branch_id);

  //   this.tempTable = table;
  //   this.editTableForm.controls.table_name.setValue(this.tempTable.table_name);

  //   this.open(this.editTableModal);
  // }

  // editTableName(): void {
  //   this.spin = true;
  //   let table = this.tables[0];
  //   table = {
  //     table_name: this.editTableForm.controls.table_name.value,
  //     table_id: this.tempTable.table_id,
  //     branch_id: this.tempTable.branch_id,
  //     status: 0,
  //     date_created: '',
  //     last_modified: ''
  //   };
  //   this.tablesService.updateTable(table).subscribe(res => {

  //     if (res) {
  //       this.spin = false;
  //       this.modalService.dismissAll();
  //       this.showSuccessModal('Cette table a été modifié avec succès');

  //       this.getTables(table.branch_id);
  //     } else {
  //       this.spin = false;
  //       this.showFailureModal('La modification de la table a échoué, réessayez plus tard');
  //     } 
  //   }, error => {

  //     if (error.status === 409) {
  //       this.modalService.dismissAll();
  //       this.showFailureModal('Cette table existe déjà');
  //     } else {

  //       this.showFailureModal('La modification de la table a échoué, réessayez plus tard');
  //     }
  //     this.spin = false;
  //   });
  // }

  tempDelete(expense): void {
    this.tempExpense = expense;
    this.expenseToDelete = this.tempExpense.expense_description.substring(0, 7).concat('...');

    this.branchOfTheExpenseToDelete = this.branchList.find(br => br.branch_id === expense.branch_id).branch_name;

    this.open(this.deleteExpenseModal);
  }

  deleteExpense1(): void {
    this.spin = true;
    this.expenseService.deleteExpense(this.tempExpense).subscribe(res => {

      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Cette dépense a été supprimé avec succès');

        this.getExpenses(this.tempExpense.branch_id);
      } else {
        this.spin = false;
        this.showFailureModal('La suppression de la dépense a échoué, réessayez plus tard');
      }
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('La suppression de la dépense a échoué, réessayez plus tard');
      } else {
        this.showFailureModal('La suppression de la dépense a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  showCompany(): void {
    this.router.navigate(['/company']);
  }

  showBranch(): void {
    this.router.navigate(['/branches']);
  }

  filterExpense(branchId) {
    this.spin = true;
    this.getExpenses(branchId);
  }

  getExpensesByExpenseType(event): void {
    this.resetSummaryInfo();
    const expenseTypeSelected = event.target.value;

    this.spin = true;

    var date = new Date();

    if (this.selectBranchForm.controls['dtExpenseFrom'].value !== null) {
      this.startDate = moment(this.selectBranchForm.controls['dtExpenseFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(this.selectBranchForm.controls['dtExpenseTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.expenseService.getExpenseByBranches(this.selectBranchForm.controls.selectBranch.value, this.startDate, this.endDate, expenseTypeSelected).subscribe(res => {
      this.expenses = res;

      if (this.expenses.length > 0) {
        this.tableHasData = true;
        this.dataSourceExpense = new MatTableDataSource(this.expenses);
        this.dataSourceExpense.paginator = this.paginator;
        this.dataSourceExpense.sort = this.sort;

        this.expenses.forEach(a => this.totalExpense += a.amount);
      } else {
        this.tableHasData = false;
      }
      this.spin = false;
    }, err => {
      this.tableHasData = false;
      this.dataSourceExpense = new MatTableDataSource([]);
      this.dataSourceExpense.paginator = this.paginator;
      this.dataSourceExpense.sort = this.sort;
      this.spin = false;

    });
  }

  getExpenses(branchId): void {
    this.resetSummaryInfo();
    this.spin = true;

    var date = new Date();

    if (this.selectBranchForm.controls['dtExpenseFrom'].value !== null) {
      this.startDate = moment(this.selectBranchForm.controls['dtExpenseFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(this.selectBranchForm.controls['dtExpenseTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.expenseService.getExpenseByBranches(branchId, this.startDate, this.endDate, this.selectBranchForm.controls.expense_type.value).subscribe(res => {
      this.expenses = res;

      if (this.expenses.length > 0) {
        this.tableHasData = true;
        this.dataSourceExpense = new MatTableDataSource(this.expenses);
        this.dataSourceExpense.paginator = this.paginator;
        this.dataSourceExpense.sort = this.sort;

        this.expenses.forEach(a => this.totalExpense += a.amount);
      } else {
        this.tableHasData = false;
      }
      this.spin = false;
    }, err => {
      this.tableHasData = false;
      this.dataSourceExpense = new MatTableDataSource([]);
      this.dataSourceExpense.paginator = this.paginator;
      this.dataSourceExpense.sort = this.sort;
      this.spin = false;

    });
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event) {
    this.page = event;
    window.scroll(0,0);
  }

}
