import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from 'src/app/models/user/user';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserLoggedIn } from '../../models/user/user-logged-in';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/common/Constants';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserLoggedIn>;
  public currentUser: Observable<UserLoggedIn>;

  private staffKey: string;
  private loginEndpoint = environment.apiBase.concat('/login');
  private logoutEndpoint = environment.apiBase.concat('/user/logout');

  constructor(private router: Router, private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<UserLoggedIn>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserLoggedIn {
    return this.currentUserSubject.value;
  }

  login(user: User) {

    return this.httpClient.post<UserLoggedIn>(this.loginEndpoint, user)
      .pipe(map(response => {

        if (response && response.token) {

          localStorage.setItem('currentUser', JSON.stringify(response));
          sessionStorage.setItem('access-token', 'Bearer ' + response.token);
          this.currentUserSubject.next(response);
        }

        return response;
      }));
  }

  isUserLoggedIn() {
    const currentUser = localStorage.getItem('currentUser');

    return !(currentUser === null);
  }

  logOut(username?: string) {

    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('access-token');

    window.location.reload();
  }
}
