import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import { StockUpdateReport } from 'src/app/models/order/StockUpdateReport';
import { StockUpdateOutgoingReport } from 'src/app/models/order/StockUpdateOutgoingReport';
import * as $ from 'jquery';
import { OrderItem } from 'src/app/models/order-items';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { Input } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import {Company} from 'src/app/models/company/company';


@Component({
  selector: 'app-report-stock-update',
  templateUrl: './report-stock-update.component.html',
  styleUrls: ['./report-stock-update.component.scss']
})
export class ReportStockUpdateComponent implements OnInit {

  reportType: number = 0;
  searchForm: FormGroup;
  searchFormStockUpdateEntry: FormGroup;
  searchFormStockUpdateOutgoing: FormGroup;

  filterFormStockUpdate: FormGroup;

  dataSourceStockUpdateEntry: MatTableDataSource<StockUpdateReport>;
  dataSourceStockUpdateOutgoing: MatTableDataSource<StockUpdateOutgoingReport>;

  dataSourceOrderItems: MatTableDataSource<OrderItem>;


  @ViewChild('paginatorStockUpdateReport') paginatorStockUpdateReport: MatPaginator;
  @ViewChild('sortStockUpdateReport') sortStockUpdateReport: MatSort;

  @ViewChild('paginatorStockUpdateOutgoingReport') paginatorStockUpdateOutgoingReport: MatPaginator;
  @ViewChild('sortStockUpdateOutgoingReport') sortStockUpdateOutgoingReport: MatSort;

  @ViewChild('paginatorOrderItem') paginatorOrderItem: MatPaginator;
  @ViewChild('sortOrderItem') sortOrderItem: MatSort;

  @ViewChild('stockUpdateDiv') stockUpdateDiv: ElementRef;
  
  @ViewChild('articlesVendu') articlesVendu: ElementRef;


  totalRevenue: number;
  currencyDefault: string;
  totalNumberOfOrders_SaleReport: number;
  totalNumberOfOrders: number;
  mostPopularDrink: string;
  numberOfBottleOfTheMostPopularDrink: number;
  waiterNameWithMostRevenue: string;
  waiterNameWithMostRevenueAmount: number;
  waiterNameWithLeastRevenue: string;
  waiterNameWithLeastRevenueAmount: number;
  waiterNameWithMostSale: string;
  waiterNameWithMostSaleAmount: number;
  waiterNameWithLeastSale: string;
  waiterNameWithLeastSaleAmount: number;

  tableHasDataStockUpdateReport: boolean;
  tableHasDataStockUpdateOutgoingReport: boolean;
  tableHasOrderItemData: boolean;

  //Printing
  company: Company;
  printDate: string;
  printCompanyName: string;
  printCompanyAddress: string;
  printCompanyCity: string;
  printCompanyCountry: string;
  printEstablishment: string;


  displayedColumnsStockUpdateReport = [
    'Product',
    'OldQuantity',
    'QuantityAdded',
    'CurrentQuantity',
    'Date'
  ];

  displayedColumnsStockUpdateOutgoingReport = [
    'Product',
    'QuantityBefore',
    'Quantity',
    'RemainingProductQuantity',
    'Date',
    'Branch'
  ];

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  stockUpdateReports: StockUpdateReport[];
  stockUpdateOutgoingReports: StockUpdateOutgoingReport[];
  productDTOs: ProductDTO[];

  branches: Branch[];

  startDateStockUpdate: string;
  endDateStockUpdate: string;

  @Input() id: string;

  pageStockUpdateIncomingReport = 1;
  pageStockUpdateOutgoingReport = 1;

  orderItems: OrderItem[];
  closeResult: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private formBuilder: FormBuilder,
    private companyService: CompanyService,) {

    this.stockUpdateReports = [];
    this.stockUpdateOutgoingReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {
      this.spin = true;

      this.loadBranchesStockUpdate();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadStockUpdate(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadStockUpdate(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterFormStockUpdate.controls['dtStockUpdateFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormStockUpdate.controls['dtStockUpdateTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

      this.totalRevenue = 0;
      this.totalNumberOfOrders = 0;
      this.totalNumberOfOrders_SaleReport = 0;
      this.mostPopularDrink = '';
      this.numberOfBottleOfTheMostPopularDrink = 0;

      this.getCompanyDetails(this.authenticationService.currentUserValue.companyID);

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  getCompanyDetails(companyId: number): void {
    var date = new Date();
    this.printDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds())).format('DD-MM-YYYY HH:mm:ss').toString();

    this.companyService.getCompanyByID(companyId).subscribe(res => {
      this.company = res;
      this.printCompanyName = this.company.company_name;
      this.printCompanyAddress = this.company.company_address + ', ' + this.company.company_address_suburb;
      this.printCompanyCity = this.company.company_address_city + ', ' + this.company.company_address_province;
      this.printCompanyCountry = this.company.company_address_country;
    }, err => {
      // this.spin = false;
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  createForms() {

    this.searchFormStockUpdateEntry = this.formBuilder.group({
      searchStockUpdateEntry: new FormControl(null, Validators.required)
    });

    this.searchFormStockUpdateOutgoing = this.formBuilder.group({
      searchStockUpdateOutgoing: new FormControl(null, Validators.required)
    });

    this.filterFormStockUpdate = this.formBuilder.group({
      dtStockUpdateFrom: new FormControl(null, Validators.required),
      dtStockUpdateTo: new FormControl(null, Validators.required),
      selectBranch: ['']
    });
  }

  //PRINTING
  // getDataToPrintOnDesktop(branchIDSelected, fromDate: string, toDate: string) {
  //   let categoryId = this.selectForm.controls.categorySelect.value;

  //   this.printProductDTOs = [];

  //   if (categoryId !== null) {
  //     this.productService.getProductByCategory(categoryId, this.authenticationService.currentUserValue.companyID,
  //       branchIDSelected).subscribe(res => {
  //         this.printProductDTOs = res;

  //         if (this.productDTOs.length > 0) {
  //           this.branchCurrency = 'FC';
  //           this.print(this.printProductDTOs);
  //         } else {
  //           this.printProductDTOs = [];
  //         }
  //         this.spin = false;

  //       }, err => {
  //         this.printProductDTOs = [];
  //         this.spin = false;

  //       });
  //   } else {
  //     this.productService.fistLoadProducts(this.authenticationService.currentUserValue.companyID,
  //       branchIDSelected).subscribe(res => {
  //         this.printProductDTOs = res;

  //         if (this.printProductDTOs.length > 0) {
  //           this.branchCurrency = 'FC';
  //           this.printProductDTOs = res;
  //           this.print(this.printProductDTOs);
  //         } else {
  //           this.printProductDTOs = [];
  //         }
  //         this.spin = false;

  //       }, err => {
  //         this.printProductDTOs = [];
  //         this.spin = false;

  //       });
  //   }
  // }


  // STOCK UPDATE
  searchReportStockUpdate(branchIdStockUpdate) {
    this.spin = true;

    var date = new Date();

    if (this.filterFormStockUpdate.controls['dtStockUpdateFrom'].value !== null) {
      this.startDateStockUpdate = moment(this.filterFormStockUpdate.controls['dtStockUpdateFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateStockUpdate = moment(this.filterFormStockUpdate.controls['dtStockUpdateTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateStockUpdate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateStockUpdate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadStockUpdateReport(branchIdStockUpdate, this.startDateStockUpdate, this.endDateStockUpdate);
  }

  loadBranchesStockUpdate() {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.filterFormStockUpdate.controls.selectBranch.enable();
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
          this.filterFormStockUpdate.controls.selectBranch.disable();
        this.filterFormStockUpdate.controls.selectBranch.setValue(this.authenticationService.currentUserValue.branchID);
      }

      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  loadStockUpdateReport(branchIdStockUpdate: number, fromDateStockUpdate: string, toDateStockUpdate: string) {
    this.spin = true;

    //Load Stock update for stock loading
    this.orderService.getStockUpdateEntryReport(this.authenticationService.currentUserValue.companyID,
      branchIdStockUpdate, fromDateStockUpdate, toDateStockUpdate).subscribe(res => {
        this.stockUpdateReports = res;

        if (this.stockUpdateReports.length > 0) {
          this.tableHasDataStockUpdateReport = true;
          this.dataSourceStockUpdateEntry = new MatTableDataSource(this.stockUpdateReports);
          this.dataSourceStockUpdateEntry.paginator = this.paginatorStockUpdateReport;
          this.dataSourceStockUpdateEntry.sort = this.sortStockUpdateReport;

        } else {
          this.tableHasDataStockUpdateReport = false;
          this.dataSourceStockUpdateEntry = new MatTableDataSource([]);
          this.dataSourceStockUpdateEntry.paginator = this.paginatorStockUpdateReport;
          this.dataSourceStockUpdateEntry.sort = this.sortStockUpdateReport;
        }
        this.spin = false;
      }, error => {
        this.tableHasDataStockUpdateReport = false;
        this.dataSourceStockUpdateEntry = new MatTableDataSource([]);
        this.dataSourceStockUpdateEntry.paginator = this.paginatorStockUpdateReport;
        this.dataSourceStockUpdateEntry.sort = this.sortStockUpdateReport;
        this.spin = false;
      });

    //Load Stock update for product sale
    this.orderService.getStockUpdateOutgoingReport(this.authenticationService.currentUserValue.companyID,
      branchIdStockUpdate, fromDateStockUpdate, toDateStockUpdate).subscribe(res => {
        this.stockUpdateOutgoingReports = res;

        if (this.stockUpdateOutgoingReports.length > 0) {
          this.tableHasDataStockUpdateOutgoingReport = true;

          this.mostPopularDrink = this.stockUpdateOutgoingReports[0].product_name;
          this.numberOfBottleOfTheMostPopularDrink = this.stockUpdateOutgoingReports[0].quantity_spent;

          this.dataSourceStockUpdateOutgoing = new MatTableDataSource(this.stockUpdateOutgoingReports);
          this.dataSourceStockUpdateOutgoing.paginator = this.paginatorStockUpdateOutgoingReport;
          this.dataSourceStockUpdateOutgoing.sort = this.sortStockUpdateOutgoingReport;

        } else {
          this.tableHasDataStockUpdateOutgoingReport = false;
          this.dataSourceStockUpdateOutgoing = new MatTableDataSource([]);
          this.dataSourceStockUpdateOutgoing.paginator = this.paginatorStockUpdateOutgoingReport;
          this.dataSourceStockUpdateOutgoing.sort = this.sortStockUpdateOutgoingReport;
        }
        this.spin = false;
      }, error => {
        this.tableHasDataStockUpdateOutgoingReport = false;
        this.dataSourceStockUpdateOutgoing = new MatTableDataSource([]);
        this.dataSourceStockUpdateOutgoing.paginator = this.paginatorStockUpdateOutgoingReport;
        this.dataSourceStockUpdateOutgoing.sort = this.sortStockUpdateOutgoingReport;
        this.spin = false;
      });

  }

  filterStockUpdate(branchIdStockUpdate): void {
    this.startDateStockUpdate = moment(this.filterFormStockUpdate.controls['dtStockUpdateFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateStockUpdate = moment(this.filterFormStockUpdate.controls['dtStockUpdateTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadStockUpdateReport(branchIdStockUpdate, this.startDateStockUpdate, this.endDateStockUpdate);
  }

  loadStockUpdate(branchIdStockUpdate) {
    var date = new Date();

    if (this.filterFormStockUpdate.controls['dtStockUpdateFrom'].value !== null) {
      this.startDateStockUpdate = moment(this.filterFormStockUpdate.controls['dtStockUpdateFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateStockUpdate = moment(this.filterFormStockUpdate.controls['dtStockUpdateTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateStockUpdate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateStockUpdate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadStockUpdateReport(branchIdStockUpdate, this.startDateStockUpdate, this.endDateStockUpdate);
  }

  changeReportType(reportTypeValue: number) {
    this.reportType = Number(reportTypeValue);
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event) {
    this.pageStockUpdateIncomingReport = event; 
    this.stockUpdateDiv.nativeElement.scrollIntoView()
    // window.scroll(0,0);
  }

  onMobileTableChangePageOutgoing(event) {
    this.pageStockUpdateOutgoingReport = event; 
    this.articlesVendu.nativeElement.scrollIntoView()
    // window.scroll(0,0);
  }

}