<div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">
  
      <div class="container-fluid">
        <div class="panel mt-5">
  
            <form [formGroup]="filterDeductedStockForm">
                <div class="row mt-1">
                    <div
                        class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                        <div class="card mb-3 d-lg-none"
                            *ngFor="let item of stockUpdateReports | paginate: { itemsPerPage: 10, currentPage: pageStockUpdateIncomingReport, id: 'stockUpdateIncomingReport' }">
                            <div class="card-body">
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                      Article
                                    </div>
                                    <div class="col-6">
                                      {{item.product_name}}
                                    </div>
                                  </div>
                                  <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                      Ancienne quantité
                                    </div>
                                    <div class="col-6">
                                      {{item.old_quantity}}
                                    </div>
                                  </div>
                                  <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                      Quantité retranché
                                    </div>
                                    <div class="col-6">
                                      {{item.quantity - item.old_quantity}}
                                    </div>
                                  </div>
                                  <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                      Quantité apres ajout
                                    </div>
                                    <div class="col-6">
                                      {{item.quantity}}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-6 card-table-header">
                                      Date
                                    </div>
                                    <div class="col-6">
                                      {{item.date_created}}
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary"
                                            (click)="loadRecordDetail(row.comment)"><i
                                                class="fas fa-eye"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <pagination-controls *ngIf="tableHasDataStockUpdateReport === true"
                        class="mb-3 text-center d-lg-none" (pageChange)="onMobileTableChangePage($event)" id="stockUpdateIncomingReport"
                        previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>
  


                        <mat-table [dataSource]="dataSourceDeductedStockUpdateEntry" class="d-none d-lg-block" matSort>
                            <ng-container matColumnDef="Product">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                  Article
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  {{row.product_name}}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="OldQuantity">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                  Ancienne quantité
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  {{row.old_quantity}}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="QuantityAdded">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                  Quantité retranché
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  {{row.quantity - row.old_quantity}}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="CurrentQuantity">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                  Quantité apres ajout
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  {{row.quantity}}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="Date">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                  Date
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  {{row.date_created}}
                                </mat-cell>
                              </ng-container>
                            <ng-container matColumnDef="Action">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Action
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <div>
                                        <button
                                            (click)="loadRecordDetail(row.comment)"
                                            class="btn btn-primary">
                                            <i class="fas fa-eye"></i></button>
                                    </div>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumnsStockUpdateReport">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsStockUpdateReport">
                            </mat-row>
                        </mat-table>
                        <div class="mt-3" *ngIf="tableHasDataStockUpdateReport === false">Aucune mise a jour
                            trouvé
                        </div>
                        <mat-paginator #paginatorStockUpdateReport class="d-none d-lg-block mb-4" [pageSize]="10"
                            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                    </div>

                    <div
                        class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <select #selectedBranch class="form-control" formControlName="selectedBranch"
                                        (change)="loadDeductionStockUpdate($event.target.value)">
                                        <option value="0">
                                            Tous les établissement
                                        </option>
                                        <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                                            {{branch.branch_name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <small class="form-text text-muted">Datant de</small>
                                <input class="form-control" [readonly]="true"
                                    [owlDateTimeTrigger]="dtStockUpdateFrom"
                                    [owlDateTime]="dtStockUpdateFrom" name="dtStockUpdateFrom"
                                    formControlName="dtStockUpdateFrom">
                                <owl-date-time #dtStockUpdateFrom></owl-date-time>
                            </div>

                            <div class="col-12">
                                <small class="form-text text-muted">à</small>
                                <input class="form-control" [readonly]="true"
                                    [owlDateTimeTrigger]="dtStockUpdateTo"
                                    [owlDateTime]="dtStockUpdateTo" name="dtStockUpdateTo"
                                    formControlName="dtStockUpdateTo">
                                <owl-date-time #dtStockUpdateTo></owl-date-time>

                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-md-12">
                                <button type="button" class="btn btn-primary w-100"
                                    (click)="filterStockUpdate(selectedBranch.value)">
                                    <i class="fas fa-filter"></i> &nbsp;Filtre
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <ng-template #viewDetails let-modal>
                <div class="modal-header text-center">
                    <h5 class="modal-title">Commentaire</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="modal.dismiss()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            {{(comment === null || comment === '') ? 'Aucun Commentaire disponible' : comment}}
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-primary" (click)="modal.close()">Fermer</button>
                </div>
            </ng-template>

            
        </div>
      </div>
    </div>
  </div>
  
  
  <div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-success loader" role="status">
        <span class="sr-only ">Loading...</span>
      </div>
    </div>
  </div>