import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Branch } from 'src/app/interface/branch';
import { environment } from 'src/environments/environment';
import { DataManagerService } from '../data-management/data-manager.service';
import {UpdateBranch} from '../../interface/updateBranch';
import { DrinkPack } from 'src/app/models/drinkpack/drinkpack';

@Injectable({
  providedIn: 'root'
})
export class DrinkPackService {

  private readonly apiBase = environment.apiBase;

  constructor(private dataManServ: DataManagerService, private http: HttpClient) {
  }

  getAllDrinkPacks(): Observable<DrinkPack []> {
    return this.http.post<DrinkPack[]>(this.apiBase + '/drinkpack/getall', null);
  }
}
