<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">
        <ul class="nav nav-tabs">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="basicInfo-tab" data-toggle="tab" href="#basicInfo" role="tab"
              aria-controls="basicInfo" aria-selected="true">Informations de base
            </a>
          </li>
          <li class="nav-item" role="presentation" *ngIf="showChangePasswordForm">
            <a class="nav-link" id="changePassword-tab" data-toggle="tab" href="#changePassword" role="tab"
              aria-controls="changePassword" aria-selected="false">Mot de passe</a>
          </li>
        </ul>
        <div class="tab-content mb-5">
          <div class="tab-pane fade show active" id="basicInfo" role="tabpanel" aria-labelledby="basicInfo-tab">

            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">

                </div>
                <div class="col-lg-8 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
                  <div class="form-group">
                    <small class="form-text text-muted mt-5"></small>
                    <button type="button" class="btn btn-primary" *ngIf="!isEnabled" (click)='enableField()'>Éditer
                    </button>&nbsp;
                    <button type="submit" class="btn btn-primary" *ngIf="isEnabled" [disabled]="employeeForm.invalid"
                      (click)='saveNewEmployee()'>
                      Sauvegarder
                    </button>&nbsp;
                    <button type="button" class="btn btn-danger" (click)="Cancel()">Annuler</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="container mb-5 mt-3 white-container">
              <div class="row">
                <div class="col-12">
                  <form [formGroup]="employeeForm">


                    <div class="row mt-3">
                      <div class="col-md-6 col-12">
                        <small class="form-text text-muted">Nom d'utilisateur</small>
                        <input type="text" formControlName="staff_id" class="form-control" id="staff_id">
                      </div>
                    </div>


                    <div class="row mt-3 g-3 needs-validation" novalidate>
                      <div class="col-md-6 col-12">
                        <small class="form-text text-muted">Prenom & Postnom</small>
                        <input type="text" formControlName="employee_firstname" class="form-control" id="inputName">
                        <div *ngIf="employeeForm.controls['employee_firstname'].invalid &&
                                (employeeForm.controls['employee_firstname'].dirty ||
                                employeeForm.controls['employee_firstname'].touched)" class="alert alert-light pt-0">
                          <div *ngIf="employeeForm.controls['employee_firstname'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12 mt-md-0 mt-sm-3 mt-3">
                        <small class="form-text text-muted">Nom</small>
                        <input type="text" formControlName="employee_surname" class="form-control" id="inputSurname">
                      </div>
                    </div>
                    <div class="row mt-3">
                      <!--                    <div class="col-md-6 col-12">-->
                      <!--                      <small class="form-text text-muted">Date de naissance</small>-->
                      <!--                      <input class="form-control" [readonly]="true"-->
                      <!--                             [owlDateTimeTrigger]="date_of_birth"-->
                      <!--                             [owlDateTime]="date_of_birth"-->
                      <!--                             name="date_of_birth"-->
                      <!--                             formControlName="date_of_birth">-->
                      <!--                      <owl-date-time [pickerType]="'calendar'" #date_of_birth></owl-date-time>-->

                      <!--                      <div *ngIf="employeeForm.controls['date_of_birth'].invalid &&-->
                      <!--                                (employeeForm.controls['date_of_birth'].dirty ||-->
                      <!--                                employeeForm.controls['date_of_birth'].touched)" class="alert alert-light pt-0">-->
                      <!--                        <div *ngIf="employeeForm.controls['date_of_birth'].errors.required">-->
                      <!--                          Ne devrait pas être vide-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                      <!--                    </div>-->
                      <div class="col-md-6 col-12">
                        <small class="form-text text-muted">Genre</small>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" formControlName="gender" type="radio" name="gender"
                            id="inlineRadio1" value="Male" [formGroup]="employeeForm">
                          <label class="form-check-label" for="inlineRadio1"> M &nbsp;</label>

                          <input class="form-check-input" formControlName="gender" type="radio" name="gender"
                            id="inlineRadio2" value="Female" [formGroup]="employeeForm">
                          <label class="form-check-label" for="inlineRadio2">F</label>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6 col-12">
                        <small class="form-text text-muted">Numéro de téléphone</small>
                        <input type="text" formControlName="cell_number" class="form-control" id="inputCellNumber">
                        <div *ngIf="employeeForm.controls['cell_number'].invalid &&
                                (employeeForm.controls['cell_number'].dirty ||
                                employeeForm.controls['cell_number'].touched)" class="alert alert-light pt-0">
                          <div *ngIf="employeeForm.controls['cell_number'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12 mt-md-0 mt-sm-3 mt-3">
                        <small class="form-text text-muted">Email</small>
                        <input type="text" formControlName="email" class="form-control" id="inputE-mail">
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12 col-12">
                        <small class="form-text text-muted">Addresse</small>
                        <input type="text" formControlName="address" class="form-control" id="inputAddress">
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6 col-12">
                        <small class="form-text text-muted">Établissement</small>
                        <select class="form-control" formControlName="branch_id">
                          <option value="" disabled selected>Selectionnez un établissement</option>
                          <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                            {{branch.branch_name}}</option>
                        </select>
                      </div>
                      <div class="col-md-6 col-12 mt-md-0 mt-sm-3 mt-3">
                        <small class="form-text text-muted">Position</small>
                        <select class="form-control" formControlName="role_id">
                          <option value="" disabled selected>Selectionnez une position</option>

                          <option *ngFor="let role of roles" value={{role.role_id}}>
                            {{role.role_name === 'Waiter' ? 'Serveur' : 
                            role.role_name === 'Administrator' ? 'Propriétaire' : 
                            role.role_name === 'Deputy Administrator' ? 'Manager Général' : 
                            role.role_name === 'Manager Normal' ? 'Gérant Normal' : 
                            role.role_name === 'Manager Warehouse' ? 'Gérant Dépot' :
                            role.role_name === 'Manager Full' ? 'Gérant Full (Normal + Dépot)' : 
                            role.role_name === 'Bar Person' ? 'Barman' : 'Autre'}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6 col-12">
                        <small class="form-text text-muted">Date d'embauche</small>
                        <input type="text" formControlName="date_hired" class="form-control" id="date_hired">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="changePassword" role="tabpanel" aria-labelledby="changePassword-tab"
            *ngIf="showChangePasswordForm">
            <div class="container my-5 white-container">
              <div class="row">
                <div class="offset-lg-3 col-lg-6 offset-md-3 col-md-6 col-12">
                  <form [formGroup]="saveNewPasswordForm">
                    <div class="row g-3 needs-validation" novalidate>
                      <div class="col-md-12 col-12">
                        <small class="form-text text-muted" placeholder="Ancien mot de passe">Ancien mot de
                          passe</small>
                        <input type="password" formControlName="oldPassword" class="form-control">
                        <div *ngIf="saveNewPasswordForm.controls['oldPassword'].invalid &&
                                (saveNewPasswordForm.controls['oldPassword'].dirty ||
                                saveNewPasswordForm.controls['oldPassword'].touched)" class="alert alert-light pt-0">

                          <div *ngIf="saveNewPasswordForm.controls['oldPassword'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-12">
                        <div class="form-group mt-2">
                          <small class="form-text text-muted">Veuillez entrer votre nouveau mot de passe</small>
                          <input type="password" class="form-control form-control-lg" formControlName="password"
                            placeholder="Nouveau mot de passe">
                          <div
                            *ngIf="saveNewPasswordForm.controls['password'].invalid && (saveNewPasswordForm.controls['password'].dirty || saveNewPasswordForm.controls['password'].touched)"
                            class="alert alert-light pt-0">
                            <div *ngIf="saveNewPasswordForm.controls['password'].errors.required">
                              Ne devrait pas être vide
                            </div>
                            <div class="color-fail" *ngIf="saveNewPasswordForm.get('password').hasError('minlength')">
                              Le mot de passe ne peut pas avoir moins de 8 caractères
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-12">
                        <div class="form-group">
                          <small class="form-text text-muted">Veuillez répéter votre mot de passe entré ci-dessus
                          </small>
                          <input type="password" class="form-control form-control-lg" formControlName="password2"
                            placeholder="Répéter votre nouveau mot de passe">
                          <div
                            *ngIf="saveNewPasswordForm.controls['password2'].invalid && (saveNewPasswordForm.controls['password2'].dirty || saveNewPasswordForm.controls['password2'].touched)"
                            class="alert alert-light pt-0">
                            <div *ngIf="saveNewPasswordForm.controls['password2'].errors.required">
                              Ne devrait pas être vide
                            </div>
                            <div class="color-fail" *ngIf="saveNewPasswordForm.get('password2').hasError('minlength')">
                              Le mot de passe ne peut pas avoir moins de 8 caractères
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row mt-5">
                <div class="offset-lg-3 col-lg-6 offset-md-3 col-md-6 col-12">
                  <button type="button" class="btn btn-danger" (click)="Cancel()">Annuler</button>
                  <button type="submit" class="btn btn-primary" [disabled]="saveNewPasswordForm.invalid"
                    (click)='savePassword()'>
                    Sauvegarder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>



        <!----success product modal -->
        <ng-template #successModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{successModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>

        <!----failure product modal -->
        <ng-template #failureModal let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{failureModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>



<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>