import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Branch } from 'src/app/interface/branch';
import { Category } from 'src/app/interface/category';
import { DeleteProduct } from 'src/app/interface/deleteProduct';
import { Product } from 'src/app/interface/products';
import { SubCategory } from 'src/app/interface/sub-category';
import { CategoriesService } from 'src/app/services/categories.service';
import { ProductService } from 'src/app/services/product.service';
import { SubCategoryServiceService } from 'src/app/services/sub-category-service.service';
import { Supplier } from 'src/app/interface/supplier';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { ProductDTO } from '../../models/product/ProductDTO';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/common/Constants';
import { DrinkPackService } from 'src/app/services/drink-pack/drink-pack.service';
import { DrinkPack } from 'src/app/models/drinkpack/drinkpack';
import * as $ from 'jquery';
import { WarehouseService } from 'src/app/services/warehouse/warehouse.service';
import { Warehouse } from 'src/app/models/warehouse/Warehouse';
import * as moment from 'moment';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company/company';
import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { StockUpdate } from 'src/app/models/StockUpdate';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products: Product;
  productDTO: ProductDTO;
  productDTOs: ProductDTO[];
  tempProduct: ProductDTO;
  deleteProduct: DeleteProduct;
  subs: any[];
  num = 1;
  categories: [{}];
  sideNavCategories: Category[];
  sideNavSubCategories: SubCategory[];
  viewProductSubcategories: SubCategory[];
  spin: boolean;
  branches: Branch;
  branchList: Branch[];
  searchProducts: Product;
  product: boolean;
  category: boolean;
  tempProductName: string;
  showTable: boolean;
  deleteProductForm: FormGroup;
  productForm: FormGroup;
  viewEditproductForm: FormGroup;
  productSearchForm: FormGroup;
  editproductForm: FormGroup;
  updateQuantityForm: FormGroup;
  negativeUpdateQuantityForm: FormGroup;
  selectForm: FormGroup;
  disableEditField: boolean;
  suppliers: Supplier[];
  supplier: Supplier;
  branchCurrency: string;

  addFormCategoryEnable = false;
  addFormSubCategoryEnable = false;
  addFormCategories: Category[];
  categoryListByBranch: Category[];
  addFormSubCategories: SubCategory[];
  addFormBranches: Branch;
  successModalMessage: string;
  failureModalMessage: string;

  dataSourceProduct: MatTableDataSource<ProductDTO>;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsProduct = [
    'Nom de l\'article',
    'Prix ​​de vente',
    'Pack',
    'Fournisseur',
    'Actions',
  ];

  editStock = false;
  stockForm:FormGroup;
  itemsToUpdate:FormArray;
  productDTOforUpdate = []

  @ViewChild('successSaveProduct', { static: true }) successSaveProduct: TemplateRef<any>;
  @ViewChild('failureSaveProduct', { static: true }) failureSaveProduct: TemplateRef<any>;
  @ViewChild('editProduct', { static: true }) editProductModal: TemplateRef<any>;
  @ViewChild('viewProduct', { static: true }) viewProductModal: TemplateRef<any>;
  @ViewChild('deleteProduct', { static: true }) deleteProductModal: TemplateRef<any>;
  @ViewChild('createNewProduct', { static: true }) createNewProductModal: TemplateRef<any>;
  @ViewChild('updateQuantity', { static: true }) updateQuantityModal: TemplateRef<any>;
  @ViewChild('ConfirmUpdateProductStock', { static: true }) ConfirmUpdateProductStockModal: TemplateRef<any>;
  @ViewChild('ConfirmDeductionProductStock', { static: true }) ConfirmDeductionProductStock: TemplateRef<any>;

  @ViewChild('selectedBranch') selectedBranch: ElementRef<any>;

  closeResult: string;

  viewProductFormOnLoad = true;
  viewProductFormProductID: number;
  editProductFormProductID: number;
  deleteProductFormProductID: number;

  branchIdToBeUsed: number;

  tableHasData: boolean;

  drinkPacks: DrinkPack[];
  companyID: any;
  page = 1;
  quantityAdded: number;
  warehouses: Warehouse[];
  providers: any[];

  printDate: string;
  printProductDTOs: ProductDTO[];
  company: Company;
  printCompanyName: string;
  printCompanyAddress: string;
  printCompanyCity: string;
  printCompanyCountry: string;
  printEstablishment: string;

  status: boolean = false;
  usbPrintDriver: UsbDriver;
  webPrintDriver: WebPrintDriver;
  ip: string = '';

  isCategoryFood: boolean = false;

  shouldShowReductionDiv: boolean = false;
  roleLoggedIn: string;

  shouldShowUpdateQtyFormDetails: boolean = false;

  constructor(private authenticationService: AuthenticationService,
    private productService: ProductService, private fb: FormBuilder,
    private categoriesService: CategoriesService, private warehouseSevice: WarehouseService,
    private subCategoryService: SubCategoryServiceService, private branchService: BranchService,
    private modalService: NgbModal, private drinkPackService: DrinkPackService, private companyService: CompanyService,
    private printService: PrintService) {

    this.usbPrintDriver = new UsbDriver();
    this.printService.isConnected.subscribe(result => {
      this.status = result;
      if (result) {
        console.log('Connected to printer!!!');
      } else {
        console.log('Not connected to printer.');
      }
    });

  }

  ngOnInit(): void {

    this.spin = true;
    this.product = true;
    this.category = false;
    this.createForm();
    this.companyID = this.authenticationService.currentUserValue.companyID;

    this.getFranchise();

    // this.getAllDrinkPacks();
    // this.getSupplier();
    this.getWarehouses();
    // this.getCompanyDetails(this.authenticationService.currentUserValue.companyID);

    this.productSearchForm.controls['searchProducName'].valueChanges.subscribe(val => {
      this.searchProduct(this.branchIdToBeUsed);
    });

    this.updateQuantityForm.controls['provider_type'].valueChanges.subscribe(val => {
      if (val === 'supplier') {
        this.providers = [];
        this.suppliers.map((supplier) => {
          let provider = {
            id: supplier.supplier_id,
            name: supplier.supplier_name
          }
          this.providers.push(provider);
        })
      } else {
        this.providers = [];
        this.warehouses.map((warehouse) => {
          let provider = {
            id: warehouse.warehouse_id,
            name: warehouse.warehouse_name
          }
          this.providers.push(provider);
        })
      }
      this.updateQuantityForm.controls['supplier_id'].setValue("");
    });

    this.roleLoggedIn = this.authenticationService.currentUserValue.role;

    this.disableEditField = true;
    this.selectForm.reset();
    this.stockForm = new FormGroup({
      itemsToUpdate: new FormArray([])
    })
  }

  loadInitialData(_companyId: number, _branchId: number) {

    var date = new Date();
    this.printDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds())).format('DD-MM-YYYY HH:mm:ss').toString();


    forkJoin([this.drinkPackService.getAllDrinkPacks(), this.companyService.getCompanyByID(_companyId),
    this.productService.getSupplier(this.authenticationService.currentUserValue.companyID, _branchId)]).pipe(
      map(([responseDrinkPacks, responseCompany, responseSuppliers]) => {


        //handling first response
        this.drinkPacks = responseDrinkPacks;
        this.spin = false;

        //handling second response
        this.company = responseCompany;
        this.printCompanyName = this.company.company_name;
        this.printCompanyAddress = this.company.company_address + ', ' + this.company.company_address_suburb;
        this.printCompanyCity = this.company.company_address_city + ', ' + this.company.company_address_province;
        this.printCompanyCountry = this.company.company_address_country;

        // handling third response
        this.suppliers = responseSuppliers;

        this.spin = false;

      }, err => {
        this.tableHasData = false;
        this.spin = false;
      })
    ).subscribe()
  }

  createForm(): void {
    this.productForm = this.fb.group({
      productName: [''],
      franchise: [''],
      barcode: [''],
      type: [''],
      profit_on_product: [],
      vip_profit_on_product: [],
      selling_price: [],
      selling_price_usd: [],
      vip_selling_price: [],
      vip_selling_price_usd: [],
      pack_price: [],
      subcategory_id: [''],
      supplier_id: [''],
      quantity: [''],
      stock_default: [''],
      stock_alert: [''],
      category_id: [''],
      drinkPack: ['']
    });


    this.editproductForm = this.fb.group({
      productName: [''],
      franchise: [''],
      barcode: [''],
      type: [''],
      profit_on_product: [],
      vip_profit_on_product: [],
      pack_price: [],
      selling_price: [],
      selling_price_usd: [],
      vip_selling_price: [],
      vip_selling_price_usd: [],
      subcategory_id: [''],
      supplier_id: [''],
      quantity: [''],
      stock_default: [''],
      stock_alert: [''],
      drinkPack: ['']
    });

    this.viewEditproductForm = this.fb.group({
      productName: [''],
      franchise: [''],
      barcode: [''],
      type: [''],
      profit_on_product: [],
      vip_profit_on_product: [],
      pack_price: [],
      selling_price: [],
      selling_price_usd: [],
      vip_selling_price: [],
      vip_selling_price_usd: [],
      subcategory_id: [''],
      supplier_id: [''],
      quantity: [''],
      stock_default: [''],
      stock_alert: [''],
      viewProductSubcategoryIdSelect: [''],
      viewProductBranchIdSelect: [''],
      viewProductSupplierIdSelect: [''],
      drinkPack: ['']
    });

    this.deleteProductForm = this.fb.group({
      productName: ['']
    });

    this.productSearchForm = this.fb.group({
      searchProducName: ['']
    });

    this.selectForm = this.fb.group({
      categorySelect: [''],
      subCategorySelect: [''],
      selectBranch: ['']
    });

    this.updateQuantityForm = this.fb.group({
      productName: [''],
      type: [''],
      current_quantity: [''],
      quantity: [''],
      provider_type: [''],
      supplier_id: ['']
    });

    this.negativeUpdateQuantityForm = this.fb.group({
      productName: [''],
      type: [''],
      current_quantity: [''],
      quantity: [''],
      comment: ['']
    });
  }

  requestUsb() {
    this.usbPrintDriver.requestUsb().subscribe(result => {
      this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    });
  }

  connectToWebPrint() {
    this.webPrintDriver = new WebPrintDriver(this.ip);
    this.printService.setDriver(this.webPrintDriver, 'WebPRNT');
  }

  print(printProductDTOs: ProductDTO[]) {

    const currentDateAndTime = moment().format('YYYY-MM-DD HH:mm:ss')

    var myText2 = " \n";
    myText2 += "**************************** \n";
    myText2 += "         LISTE DES ARTICLE            \n";
    myText2 += "**************************** \n";
    myText2 += "Aujourd'hui: " + currentDateAndTime + " \n";
    myText2 += "\n";
    myText2 += "\n";
    myText2 += "---------------------------- \n";
    myText2 += "\n";

    printProductDTOs.forEach(item => {

      if ((item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')) {
        if ((item.pack_number_of_piece !== 1) && (item.quantity > item.pack_number_of_piece)) {
          myText2 += item.name + "\n" + ((item.quantity % item.pack_number_of_piece) !== 0 ? (item.quantity /
            item.pack_number_of_piece).toString()
            .substring(0, (item.quantity / item.pack_number_of_piece).toString().indexOf('.')) :
            (item.quantity / item.pack_number_of_piece)) + " casiers et " + item.quantity % item.pack_number_of_piece + " bouteilles \n \n";

        } else if ((item.pack_number_of_piece !== 1) && (item.quantity === item.pack_number_of_piece)) {
          myText2 += item.name + "\n1 casier \n \n";
        } else if ((item.pack_number_of_piece !== 1) && (item.quantity < item.pack_number_of_piece)) {
          myText2 += item.name + "\n0 casier et " + item.quantity + " bouteille(s) \n \n";
        } else if (item.pack_number_of_piece === 1) {
          myText2 += item.name + "\n" + item.quantity + " \n \n";
        }
      } else if ((item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')) {
        myText2 += item.name + "\n" + item.quantity + " shot(s) \n \n";
      } else if (item.category_name === 'Nourriture') {
        myText2 += item.name + "\n" + item.quantity + " plat(s) \n \n";
      } else {
        myText2 += item.name + "\n" + item.quantity + " \n \n";
      }
    });

    myText2 += " \n";
    myText2 += " \n";
    myText2 += "---------------------------- \n";
    myText2 += "************ MERCI ********** \n";

    this.printService.init()
      .setBold(true)
      .writeLine(myText2)
      .setBold(false)
      .setJustification('center')
      .feed(2)
      .cut('full')
      .flush();
  }

  getDataToPrintOnDesktop(branchIDSelected, fromDate: string, toDate: string) {
    let categoryId = this.selectForm.controls.categorySelect.value;

    this.printProductDTOs = [];

    if (categoryId !== null) {
      this.productService.getProductByCategory(categoryId, this.authenticationService.currentUserValue.companyID,
        branchIDSelected).subscribe(res => {
          this.printProductDTOs = res;

          if (this.productDTOs.length > 0) {
            this.branchCurrency = 'FC';
            this.print(this.printProductDTOs);
          } else {
            this.printProductDTOs = [];
          }
          this.spin = false;

        }, err => {
          this.printProductDTOs = [];
          this.spin = false;

        });
    } else {
      this.productService.fistLoadProducts(this.authenticationService.currentUserValue.companyID,
        branchIDSelected).subscribe(res => {
          this.printProductDTOs = res;

          if (this.printProductDTOs.length > 0) {
            this.branchCurrency = 'FC';
            this.printProductDTOs = res;
            this.print(this.printProductDTOs);
          } else {
            this.printProductDTOs = [];
          }
          this.spin = false;

        }, err => {
          this.printProductDTOs = [];
          this.spin = false;

        });
    }
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getFranchise(): void {
    this.selectForm.reset();
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchList = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.branchIdToBeUsed = this.branchList[0].branch_id;
        this.branchCurrency = this.branchList[0].branch_currency;

        this.selectForm.controls.selectBranch.enable();

      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_STOCK)) {

        this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        this.branchCurrency = this.branchList.find(br => br.branch_id === this.branchIdToBeUsed).branch_currency;
        this.productForm.controls['franchise'].disable();
        this.selectForm.controls.selectBranch.disable();

      }

      this.selectFanchise(this.branchIdToBeUsed);

      this.printEstablishment = this.branchList.find(br => br.branch_id === this.branchIdToBeUsed).branch_name;

      this.getCategories(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed);
      this.selectForm.controls.selectBranch.setValue(this.branchIdToBeUsed);
      this.productForm.controls.franchise.setValue(this.branchIdToBeUsed);

      // console.log('a ', this.branchIdToBeUsed);
      // console.log('a ', this.productForm.controls.franchise);
      // console.log('c ', this.branchList);


      this.fistLoadProducts(this.branchIdToBeUsed);

      this.loadInitialData(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed);

    }, error => {
      this.spin = false;
    });
  }

  getAllDrinkPacks(): void {
    this.selectForm.reset();
    this.drinkPackService.getAllDrinkPacks().subscribe(res => {
      this.drinkPacks = res;
    }, error => {
      this.spin = false;
    });
  }

  showUpdateQtyFormDetails() {
    this.shouldShowUpdateQtyFormDetails = true;
  }

  hideUpdateQtyFormDetails() {
    this.shouldShowUpdateQtyFormDetails = false;
  }

  getCategories(companyId: number, branchId: number): void {
    this.categoriesService.getCategories(companyId, branchId).subscribe(res => {
      this.sideNavCategories = res;
    }, err => {

      if (err.status === 404) {

      } else {

      }
      this.showTable = false;
      this.spin = false;
    });
  }

  fistLoadProducts(branchId: number): void {
    this.spin = true;

    this.productService.fistLoadProducts(this.authenticationService.currentUserValue.companyID,
      branchId).subscribe(res => {
        this.productDTOs = res;

        if (this.productDTOs.length > 0) {
          this.tableHasData = true;
          this.dataSourceProduct = new MatTableDataSource(this.productDTOs);
          this.dataSourceProduct.paginator = this.paginator;
          this.dataSourceProduct.sort = this.sort;
        } else {
          this.tableHasData = false;
          this.dataSourceProduct = new MatTableDataSource([]);
          this.dataSourceProduct.paginator = this.paginator;
          this.dataSourceProduct.sort = this.sort;
        }
        this.spin = false;

      }, err => {
        this.tableHasData = false;
        this.dataSourceProduct = new MatTableDataSource([]);
        this.dataSourceProduct.paginator = this.paginator;
        this.dataSourceProduct.sort = this.sort;
        this.spin = false;

      });
  }

  // getCompanyDetails(companyId: number): void {
  //   var date = new Date();
  //   this.printDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(),
  //     date.getHours(), date.getMinutes(), date.getSeconds())).format('DD-MM-YYYY HH:mm:ss').toString();

  //   this.companyService.getCompanyByID(companyId).subscribe(res => {
  //     this.company = res;
  //     this.printCompanyName = this.company.company_name;
  //     this.printCompanyAddress = this.company.company_address + ', ' + this.company.company_address_suburb;
  //     this.printCompanyCity = this.company.company_address_city + ', ' + this.company.company_address_province;
  //     this.printCompanyCountry = this.company.company_address_country;
  //   }, err => {
  //     // this.spin = false;
  //   });
  // }

  getBranchCategories(event): void {
    const branchId = event.target.value;
    this.getCategories(this.authenticationService.currentUserValue.companyID, branchId);

    this.branchCurrency = this.branchList.find(branch => branch.branch_id.toString() === branchId).branch_currency;

    this.fistLoadProducts(branchId);
    this.sideNavSubCategories = null;
  }

  getProductBySubCategory(event, branchId): void {
    const subcategoryId = event.target.value;

    this.spin = true;

    this.productService.getProductBySubCategoryById(subcategoryId, this.authenticationService.currentUserValue.companyID,
      branchId).subscribe(res => {
        this.productDTOs = res;

        this.dataSourceProduct = new MatTableDataSource(this.productDTOs);
        this.dataSourceProduct.paginator = this.paginator;
        this.dataSourceProduct.sort = this.sort;

        this.spin = false;
      }, err => {
        this.spin = false;
      });
  }

  getProducts(companyID: number, branchID: number, subcategoryId: number): void {
    this.spin = true;

    this.productService.getProduct(companyID, branchID, subcategoryId).subscribe(res => {
      this.productDTOs = res;
      this.spin = false;

      if (this.productDTOs.length > 0) {
        this.tableHasData = true;
        this.dataSourceProduct = new MatTableDataSource(this.productDTOs);
        this.dataSourceProduct.paginator = this.paginator;
        this.dataSourceProduct.sort = this.sort;
      } else {
        this.tableHasData = false;
        this.dataSourceProduct = new MatTableDataSource([]);
        this.dataSourceProduct.paginator = this.paginator;
        this.dataSourceProduct.sort = this.sort;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceProduct = new MatTableDataSource([]);
      this.dataSourceProduct.paginator = this.paginator;
      this.dataSourceProduct.sort = this.sort;
      this.spin = false;

    });
  }

  // getSupplier(): void {
  //   const branchIdSelected = this.productForm.controls.franchise.value;

  //   this.productService.getSupplier(this.authenticationService.currentUserValue.companyID,
  //     branchIdSelected).subscribe(res => {
  //       this.suppliers = res;
  //     }, error => {
  //       this.spin = false;
  //     });
  // }
  getWarehouses(): void {
    this.warehouseSevice.getWarehousesByCompany(this.companyID).subscribe(res => {
      this.warehouses = res;
    }, error => {
      this.spin = false;
    });
  }

  getSubCategories(event): void {
    const categoryId = event.target.value;
    this.subCategoryService.getSubCategoriesById(categoryId).subscribe(res => {
      this.sideNavSubCategories = res;
      this.viewProductSubcategories = this.sideNavSubCategories;
    }, error => {
      this.spin = false;
    });
  }

  enableEdit(): void {
    this.viewProductFormOnLoad = false;
    this.disableEditField = false;
  }

  save(): void {
    this.num += 1;
    this.subs.push(this.num);
  }

  edit(product: ProductDTO): void {
    this.open(this.editProductModal);
    this.editProductFormProductID = product.product_id;
    let subCategoryName;
    let supplierName;

    if ((this.selectForm.controls.subCategorySelect.value !== null) && (this.selectForm.controls.subCategorySelect.value !== undefined)) {

      this.editproductForm.controls.subcategory_id.setValue(product.subcategory_id);

      this.editproductForm.controls.supplier_id.setValue(supplierName);
    } else {
      this.subCategoryService
        .getAllSubCategoriesBasedOnProductId(product.product_id).subscribe(res => {
          this.viewProductSubcategories = res;

          this.editproductForm.controls.subcategory_id.setValue(product.subcategory_id);
        }, error => {
          this.spin = false;
        });
    }

    this.editproductForm.controls.productName.setValue(product.name);
    this.editproductForm.controls.barcode.setValue(product.barcode);
    this.editproductForm.controls.type.setValue(product.type);
    this.editproductForm.controls.profit_on_product.setValue(product.profit_on_product);
    this.editproductForm.controls.vip_profit_on_product.setValue(product.vip_profit_on_product);
    this.editproductForm.controls.selling_price.setValue(product.selling_price);
    this.editproductForm.controls.selling_price_usd.setValue(product.selling_price_usd);
    this.editproductForm.controls.vip_selling_price.setValue(product.vip_selling_price);
    this.editproductForm.controls.vip_selling_price_usd.setValue(product.vip_selling_price_usd);
    this.editproductForm.controls.pack_price.setValue(product.pack_price);
    this.editproductForm.controls.quantity.setValue(product.quantity);
    this.editproductForm.controls.stock_default.setValue(product.stock_default);
    this.editproductForm.controls.stock_alert.setValue(product.stock_alert);
    // this.editproductForm.controls.provider_type.patchValue(product.provider_type);
    this.editproductForm.controls.drinkPack.patchValue(product.pack_id);
    this.editproductForm.controls.franchise.setValue(product.branch_id);
    this.editproductForm.controls.supplier_id.patchValue(product.supplier_id);

  }

  openCreateNewProductModal(): void {
    // this.productForm.reset();

    this.getAllDrinkPacks();
    // console.log('11 ', this.drinkPacks);

    this.editproductForm.controls.productName.reset();
    this.editproductForm.controls.barcode.reset();
    this.editproductForm.controls.type.reset();
    this.editproductForm.controls.profit_on_product.reset();
    this.editproductForm.controls.vip_profit_on_product.reset();
    this.editproductForm.controls.selling_price.reset();
    this.editproductForm.controls.selling_price_usd.reset();
    this.editproductForm.controls.vip_selling_price.reset();
    this.editproductForm.controls.vip_selling_price_usd.reset();
    this.editproductForm.controls.pack_price.reset();
    this.editproductForm.controls.quantity.reset();
    this.editproductForm.controls.stock_default.reset();
    this.editproductForm.controls.stock_alert.reset();

    // this.getAllDrinkPacks();

    this.addFormCategoryEnable = false;
    this.addFormSubCategoryEnable = false;

    this.productForm.controls.vip_selling_price.setValue(0);
    this.productForm.controls.vip_profit_on_product.setValue(0);

    this.open(this.createNewProductModal);
    // this.getFranchise();

// console.log('b ', this.branchList);


    if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
      (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.branchIdToBeUsed = this.branchList[0].branch_id;
      this.productForm.controls.franchise.setValue(this.branchIdToBeUsed);
    } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
      (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL) ||
      (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_STOCK)) {

        this.productForm.controls.franchise.setValue(this.branchIdToBeUsed);
    }

    this.selectFanchise(this.branchIdToBeUsed);
  }

  updateStockQuantity(product): void {
    this.updateQuantityForm.reset();
    this.open(this.updateQuantityModal);

    this.updateQuantityForm.controls.productName.setValue(product.name);
    this.updateQuantityForm.controls.type.setValue(product.type);
    this.updateQuantityForm.controls.current_quantity.setValue(product.quantity);

    this.negativeUpdateQuantityForm.controls.productName.setValue(product.name);
    this.negativeUpdateQuantityForm.controls.type.setValue(product.type);
    this.negativeUpdateQuantityForm.controls.current_quantity.setValue(product.quantity);
    this.negativeUpdateQuantityForm.controls.quantity.setValue(-1);

    this.tempProduct = product;
  }

  openConfirmUpdateStock(): void {
    this.quantityAdded = this.updateQuantityForm.controls.quantity.value;

    if (this.shouldShowReductionDiv) {
      this.quantityAdded = this.negativeUpdateQuantityForm.controls.quantity.value;
      this.open(this.ConfirmDeductionProductStock);
    } else {
      this.quantityAdded = this.updateQuantityForm.controls.quantity.value;
      this.open(this.ConfirmUpdateProductStockModal);
    }
  }

  SaveNewStockQuantity(): void {

    if (this.shouldShowReductionDiv) {
      this.saveStockQuantityDeduction();
    } else {
      if (Number(this.updateQuantityForm.controls.quantity.value) > 0) {
        this.spin = true;

        if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
          (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
          this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        }

        let newEnteredQuantity = this.updateQuantityForm.controls.quantity.value;
        let newTotalQuantity = this.tempProduct.quantity + newEnteredQuantity;

        this.productService.updateStockQuantity(this.authenticationService.currentUserValue.employeeID, this.tempProduct.product_id,
          newEnteredQuantity, newTotalQuantity, this.tempProduct.branch_id, this.tempProduct.subcategory_id, this.tempProduct.supplier_id,
          this.authenticationService.currentUserValue.companyID, this.tempProduct.pack_number_of_piece, this.tempProduct.pack_price,
          '').subscribe(res => {

            if (res) {
              this.modalService.dismissAll();
              this.fistLoadProducts(this.branchIdToBeUsed);
              this.showSuccessModal('Le stock de cet article a été mis à jour avec succès');
            } else {
              this.showFailureModal('Le stock de cet article n\'a pas pu être mis à jour');
            }

            this.spin = false;
          }, error => {
            this.showFailureModal('Le stock de cet article n\'a pas pu être mis à jour');
            this.spin = false;
          });
      } else {
        this.showFailureModal('Le stock de cet article doit être supérieur à zéro');
      }
    }
  }

  saveStockQuantityDeduction(): void {

    if (Number(this.negativeUpdateQuantityForm.controls.quantity.value) < 0) {
      this.spin = true;

      if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
        (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
      }

      let newEnteredQuantity = this.negativeUpdateQuantityForm.controls.quantity.value;
      let newTotalQuantity = this.tempProduct.quantity + Number(newEnteredQuantity);

      this.productService.updateStockQuantity(this.authenticationService.currentUserValue.employeeID, this.tempProduct.product_id,
        newEnteredQuantity, newTotalQuantity, this.tempProduct.branch_id, this.tempProduct.subcategory_id, this.tempProduct.supplier_id,
        this.authenticationService.currentUserValue.companyID, this.tempProduct.pack_number_of_piece, this.tempProduct.pack_price,
        this.negativeUpdateQuantityForm.controls.comment.value).subscribe(res => {

          if (res) {
            this.modalService.dismissAll();
            this.fistLoadProducts(this.branchIdToBeUsed);
            this.shouldShowReductionDiv = false;
            this.negativeUpdateQuantityForm.controls.quantity.reset();
            this.negativeUpdateQuantityForm.controls.comment.reset();

            this.showSuccessModal('Le stock de cet article a été mis à jour avec succès');
          } else {
            this.showFailureModal('Le stock de cet article n\'a pas pu être mis à jour');
          }

          this.spin = false;
        }, error => {
          this.showFailureModal('Le stock de cet article n\'a pas pu être mis à jour');
          this.spin = false;
        });
    } else {
      this.showFailureModal('La quantite rétranché doit etre un montant négatif');
    }
  }

  loadPrintingDetails() {

  }

  view(product): void {
    this.disableEditField = true;
    this.open(this.viewProductModal);

    this.viewProductFormProductID = product.product_id;

    this.viewProductFormOnLoad = true;

    let subCategoryName;
    let supplierName;

    if ((this.selectForm.controls.subCategorySelect.value !== null) && (this.selectForm.controls.subCategorySelect.value !== undefined)) {
      const subcategoryname = this.viewProductSubcategories.find(sub => sub.subcategory_id === product.subcategory_id).sub_name;
      this.viewEditproductForm.controls.subcategory_id.setValue(subcategoryname);
      this.viewEditproductForm.controls.viewProductSubcategoryIdSelect.setValue(product.subcategory_id);

      this.viewEditproductForm.controls.supplier_id.setValue(supplierName);
    } else {
      this.subCategoryService
        .getAllSubCategoriesBasedOnProductId(product.product_id).subscribe(res => {
          this.viewProductSubcategories = res;

          const subcategoryName = this.viewProductSubcategories.find(sub => sub.subcategory_id === product.subcategory_id).sub_name;
          this.viewEditproductForm.controls.subcategory_id.setValue(subcategoryName);
          this.viewEditproductForm.controls.viewProductSubcategoryIdSelect.setValue(product.subcategory_id);
        }, error => {
          this.spin = false;
        });
    }

    this.viewEditproductForm.controls.productName.setValue(product.name);
    this.viewEditproductForm.controls.barcode.setValue(product.barcode);
    this.viewEditproductForm.controls.type.setValue(product.type);
    this.viewEditproductForm.controls.profit_on_product.setValue(product.profit_on_product);
    this.viewEditproductForm.controls.vip_profit_on_product.setValue(product.vip_profit_on_product);
    this.viewEditproductForm.controls.selling_price.setValue(product.selling_price);
    this.viewEditproductForm.controls.selling_price_usd.setValue(product.selling_price_usd);
    this.viewEditproductForm.controls.vip_selling_price.setValue(product.vip_selling_price);
    this.viewEditproductForm.controls.vip_selling_price_usd.setValue(product.vip_selling_price_usd);
    this.viewEditproductForm.controls.pack_price.setValue(product.pack_price);
    this.viewEditproductForm.controls.quantity.setValue(product.quantity);

    this.viewEditproductForm.controls.drinkPack.setValue(product.pack_id);

    this.viewEditproductForm.controls.stock_default.setValue(product.stock_default);
    this.viewEditproductForm.controls.stock_alert.setValue(product.stock_alert);

    const viewProductBranchName = this.branchList.find(branch1 => branch1.branch_id === product.branch_id).branch_name;
    this.viewEditproductForm.controls.franchise.setValue(viewProductBranchName);
    this.viewEditproductForm.controls.viewProductBranchIdSelect.setValue(product.branch_id);

    const viewProductSupplierName = this.suppliers.find(supplier1 => supplier1.supplier_id === product.supplier_id).supplier_name;

    // if (viewProductSupplierName !=undefined){
    this.viewEditproductForm.controls.supplier_id.setValue(viewProductSupplierName);
    // }else{
    //   this.viewEditproductForm.controls.supplier_id.setValue('Fournisseur n\'éxiste plus');
    // }

    this.viewEditproductForm.controls.viewProductSupplierIdSelect.patchValue(product.supplier_id);
  }

  editProductName(): void {
    this.spin = true;

    let product = this.products;
    product = {
      product_id: this.editProductFormProductID,
      barcode: this.editproductForm.controls.barcode.value,
      name: this.editproductForm.controls.productName.value,
      type: ((this.editproductForm.controls.type.value === null) || (this.editproductForm.controls.type.value === undefined) || (this.editproductForm.controls.type.value === '')) ? ' ' : this.editproductForm.controls.type.value,
      profit_on_product: Number(this.editproductForm.controls.profit_on_product.value),
      vip_profit_on_product: Number(this.editproductForm.controls.vip_profit_on_product.value),
      cost: Number(this.editproductForm.controls.selling_price.value) - Number(this.editproductForm.controls.profit_on_product.value),
      selling_price: Number(this.editproductForm.controls.selling_price.value),
      selling_price_usd: Number(this.editproductForm.controls.selling_price_usd.value),
      vip_selling_price: Number(this.editproductForm.controls.vip_selling_price.value),
      vip_selling_price_usd: Number(this.editproductForm.controls.vip_selling_price_usd.value),
      pack_price: Number(this.editproductForm.controls.pack_price.value),
      vat: 0,
      pic_id: '0',
      subcategory_id: Number(this.editproductForm.controls.subcategory_id.value),
      supplier_id: Number(this.editproductForm.controls.supplier_id.value),
      branch_id: Number(this.editproductForm.controls.franchise.value),
      quantity: Number(this.editproductForm.controls.quantity.value),
      pack_id: Number(this.editproductForm.controls.drinkPack.value),
      stock_default: Number(this.editproductForm.controls.stock_default.value),
      stock_alert: Number(this.editproductForm.controls.stock_alert.value),
      company_id: Number(this.authenticationService.currentUserValue.companyID),
      status: 0,
      date_created: '',
      last_modified: ''
    };


    if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
      (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
      this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
    } else {
      this.branchIdToBeUsed = Number(this.editproductForm.controls.franchise.value);
    }

    this.productService.updateProduct(product).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.getProducts(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed, product.subcategory_id);
        this.showSuccessModal('Cet article a été modifié avec succès');
      } else {
        this.showFailureModal('Cet article n\'a pas pu être mis à jour');
      }

      this.spin = false;
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cet article éxiste déjà');
      } else {
        this.showFailureModal('Cet article n\'a pas pu être mis à jour');
      }
      this.spin = false;
    });
  }

  saveEditProductNameFromViewProductDetail(): void {
    this.spin = true;
    let product = this.products;
    product = {
      product_id: this.viewProductFormProductID,
      barcode: this.viewEditproductForm.controls.barcode.value,
      name: this.viewEditproductForm.controls.productName.value,
      type: ((this.viewEditproductForm.controls.type.value === null) || (this.viewEditproductForm.controls.type.value === undefined) || (this.viewEditproductForm.controls.type.value === '')) ? ' ' : this.viewEditproductForm.controls.type.value,
      cost: Number(this.viewEditproductForm.controls.selling_price.value) - Number(this.viewEditproductForm.controls.profit_on_product.value),
      selling_price: Number(this.viewEditproductForm.controls.selling_price.value),
      selling_price_usd: Number(this.viewEditproductForm.controls.selling_price_usd.value),
      vip_selling_price: Number(this.viewEditproductForm.controls.vip_selling_price.value),
      vip_selling_price_usd: Number(this.viewEditproductForm.controls.vip_selling_price_usd.value),
      profit_on_product: Number(this.viewEditproductForm.controls.profit_on_product.value),
      vip_profit_on_product: Number(this.viewEditproductForm.controls.vip_profit_on_product.value),
      pack_price: Number(this.viewEditproductForm.controls.pack_price.value),
      vat: 0,
      pic_id: '0',
      subcategory_id: Number(this.viewEditproductForm.controls.viewProductSubcategoryIdSelect.value),
      supplier_id: Number(this.viewEditproductForm.controls.viewProductSupplierIdSelect.value),
      branch_id: Number(this.viewEditproductForm.controls.viewProductBranchIdSelect.value),
      quantity: Number(this.viewEditproductForm.controls.quantity.value),
      pack_id: Number(this.viewEditproductForm.controls.drinkPack.value),
      stock_default: Number(this.viewEditproductForm.controls.stock_default.value),
      stock_alert: Number(this.viewEditproductForm.controls.stock_alert.value),
      company_id: Number(this.authenticationService.currentUserValue.companyID),
      status: 1,
      date_created: '',
      last_modified: ''
    };

    if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
      (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
      this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
    } else {
      this.branchIdToBeUsed = Number(this.viewEditproductForm.controls.viewProductBranchIdSelect.value);
    }

    this.productService.updateProduct(product).subscribe(res => {
      if (res) {
        this.modalService.dismissAll();
        // this.getProducts(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed, product.subcategory_id);
        this.showSuccessModal('Cet article a été modifié avec succès');
        this.fistLoadProducts(this.branchIdToBeUsed);
      } else {
        this.showFailureModal('Cet article n\'a pas pu être mis à jour');
      }
      this.spin = false;
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cet article éxiste déjà');
      } else {
        this.showFailureModal('Cet article n\'a pas pu être mis à jour');
      }
      this.spin = false;
    });
  }

  saveNewProduct(): void {
    this.spin = true;
    let product = this.products;
    product = {
      product_id: 0,
      barcode: this.productForm.controls.barcode.value,
      name: this.productForm.controls.productName.value,
      type: ((this.productForm.controls.type.value === null) || (this.productForm.controls.type.value === undefined) || (this.editproductForm.controls.type.value === '')) ? ' ' : this.productForm.controls.type.value,
      cost: Number(this.productForm.controls.selling_price.value) - Number(this.productForm.controls.profit_on_product.value),
      selling_price: Number(this.productForm.controls.selling_price.value),
      selling_price_usd: Number(this.productForm.controls.selling_price_usd.value),
      vip_selling_price: Number(this.productForm.controls.vip_selling_price.value),
      vip_selling_price_usd: Number(this.productForm.controls.vip_selling_price_usd.value),
      profit_on_product: Number(this.productForm.controls.profit_on_product.value),
      vip_profit_on_product: Number(this.productForm.controls.vip_profit_on_product.value),
      pack_price: Number(this.productForm.controls.pack_price.value),
      vat: 0,
      pic_id: '0',
      subcategory_id: Number(this.productForm.controls.subcategory_id.value),
      supplier_id: Number(this.productForm.controls.supplier_id.value),
      branch_id: Number(this.productForm.controls.franchise.value),
      quantity: Number(this.productForm.controls.quantity.value),
      pack_id: Number(this.productForm.controls.drinkPack.value),
      stock_default: Number(this.productForm.controls.stock_default.value),
      stock_alert: Number(this.productForm.controls.stock_alert.value),
      company_id: Number(this.authenticationService.currentUserValue.companyID),
      status: 1,
      date_created: '',
      last_modified: ''

    };

    this.productService.saveProduct(product, this.authenticationService.currentUserValue.employeeID).subscribe(res => {

      if (res) {
        this.modalService.dismissAll();
        this.showSuccessModal('Votre article a été enregistré avec succès');

        if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
          (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
          this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        } else {
          this.branchIdToBeUsed = this.productForm.controls.franchise.value;
        }
        this.productForm.reset();

        this.fistLoadProducts(this.branchIdToBeUsed);
      } else {
        this.showFailureModal('L\'ajout de l\'article a échoué, réessayez plus tard');
      }
    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('Cet article éxiste déjà');
      } else {
        this.showFailureModal('L\'ajout de cet article a échoué, réessayez plus tard');
      }
      this.spin = false;
    });
  }

  delete(product): void {

    this.deleteProductFormProductID = product.product_id;

    this.open(this.deleteProductModal);

    this.tempProduct = product;
    this.tempProductName = this.tempProduct.name;
    this.deleteProductForm.controls.productName.setValue(this.tempProduct.name);
  }

  yes(): void {
    this.spin = true;

    this.tempProduct.name = this.deleteProductForm.controls.productName.value;

    if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
      (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
      this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
    }

    this.productService.deleteProduct(this.tempProduct).subscribe(res => {
      if (res) {
        this.modalService.dismissAll();
        this.getProducts(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed, this.tempProduct.subcategory_id);
        this.showSuccessModal('Votre article a été supprimé avec succès');
      } else {
        this.showFailureModal('Votre article n\'a pas pu être supprimé');
      }

      this.spin = false;
    }, error => {
      this.showFailureModal('Votre article n\'a pas pu être supprimé');
      this.spin = false;
    });
  }

  showProduct(): void {
    this.product = true;
    this.category = false;

    document.getElementById('prod').style.backgroundColor = 'white';
    document.getElementById('cat').style.backgroundColor = '';
  }

  onMatTableChangePage(event: any) {
    window.scroll(0, 0);
  }

  onMobileTableChangePage(event: any) {
    this.page = event;
    window.scroll(0, 0);
  }

  showCategory(): void {
    this.category = true;
    this.product = false;

    document.getElementById('cat').style.backgroundColor = 'white';
    document.getElementById('prod').style.backgroundColor = '';
  }

  addFormSubCategoriesChange(event): void {
    const categoryId = event.target.value;
    this.addFormSubCategoryEnable = true;

    // console.log('c ', this.addFormCategories);
    // console.log('d ', this.categoryListByBranch);
    // console.log('e ', categoryId);



    // let categoryName = this.addFormCategories.find(c => c.category_id = categoryId).name;

    // console.log(categoryName)

    // if (categoryName === Constants.CATEGORY_FOOD) {
    //   this.isCategoryFood = true;
    // } else {
    //   this.isCategoryFood = false;
    // }

    this.subCategoryService.getSubCategoriesById(categoryId).subscribe(res => {
      this.addFormSubCategories = res;
    }, error => {
      this.spin = false;
    }
    );
  }

  selectFanchise(branchId: number): void {
    this.addFormCategoryEnable = true;

    this.categoriesService.getCategories(this.authenticationService.currentUserValue.companyID, branchId).subscribe(res => {
      this.addFormSubCategories = null;
      this.addFormCategories = res;
      this.categoryListByBranch = this.addFormCategories;
    });
  }

  searchProduct(branchId): void {
    this.spin = true;

    const name = this.productSearchForm.controls.searchProducName.value;
    this.productService.searchProduct(this.authenticationService.currentUserValue.companyID, branchId, name).subscribe(res => {
      this.productDTOs = res;

      if (this.productDTOs.length > 0) {
        this.tableHasData = true;
        this.dataSourceProduct = new MatTableDataSource(this.productDTOs);
        this.dataSourceProduct.paginator = this.paginator;
        this.dataSourceProduct.sort = this.sort;
      } else {
        this.tableHasData = false;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceProduct = new MatTableDataSource([]);
      this.spin = false;

    });
  }

  showReductionStock(): void {
    this.shouldShowReductionDiv = true;
  }

  cancelQuantityReduction(): void {
    this.negativeUpdateQuantityForm.controls.quantity.reset();
    this.shouldShowReductionDiv = false;
  }

  validate(): void {

    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  showSuccessModal(message: string): void {
    this.open(this.successSaveProduct);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureSaveProduct);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  hideActionColumn(){
    this.productDTOs.forEach((productDTO) => {
      this.addItem(productDTO.product_id);
    })
    this.editStock = true;
    this.displayedColumnsProduct = [
      'Nom de l\'article',
      // 'Prix ​​de vente',
      'Pack',
      // 'Fournisseur',
      // 'Actions',
      "Valeur a ajouter"
    ];
    // this.itemsToUpdate.clear();
  }
  createItem(productId): FormGroup{
    return this.fb.group({
      valueToAdd: null,
      productId: productId
    })
  }
  addItem(productId){
    this.itemsToUpdate = this.stockForm.get("itemsToUpdate") as FormArray;
    this.itemsToUpdate.push(this.createItem(productId));
  }
  showActionColumn(){
    this.itemsToUpdate.clear();
    this.editStock = false;
    this.displayedColumnsProduct = [
      'Nom de l\'article',
      'Prix ​​de vente',
      'Pack',
      'Fournisseur',
      'Actions',
    ];
  }
  updatItemValue(id){
    let value = (<HTMLInputElement>document.getElementById("product"+id)).value;
    if(Number(value) < 0){
      value = '0';
    }
    this.itemsToUpdate.controls.forEach((item, index)=>{
      if(item.value.productId === id){
        this.itemsToUpdate.get(`${index}`).setValue({productId: id, valueToAdd: value })
        this.itemsToUpdate.get(`${index}`).markAsDirty()
        this.itemsToUpdate.get(`${index}`).markAsTouched()
      }
    })
  }
  getItemUpdateValue(id){
    let value;
    this.itemsToUpdate.controls.forEach(item=>{
      if(item.value.productId === id){
        value = item.value.valueToAdd;
      }
    })
    return value;
  }
  saveStockvalueToUpdate(){
    this.spin = true;
    this.fistLoadProducts(this.branchIdToBeUsed)
    let itemsToUpdate:StockUpdate[] = [];
    this.itemsToUpdate.controls.forEach((item)=>{
      if(item.dirty && item.touched){
        this.productDTOs.forEach((productDTO)=>{
          if(productDTO.product_id === item.value.productId){
            itemsToUpdate.push({
              branchID:Number(this.branchIdToBeUsed),
              comment:"",
              companyID:Number(this.companyID),
              newEnteredQuantity: Number(item.value.valueToAdd),
              newTotalQuantity: Number(productDTO.quantity) + Number(item.value.valueToAdd),
              packPrice: Number(productDTO.pack_price),
              productID:Number(productDTO.product_id),
              productPackNumber:Number(productDTO.pack_number_of_piece),
              subcategoryID:Number(productDTO.subcategory_id),
              supplierID:Number(productDTO.supplier_id),
              employeeID: Number(this.authenticationService.currentUserValue.employeeID)
            })
          }
        })
      }
    });
    this.productService.updateItemsQuantity(itemsToUpdate).subscribe((res)=>{
      if(res === true){
        this.showSuccessModal("Le stock à été mis à jour avec succès");
        this.fistLoadProducts(this.branchIdToBeUsed);
      } else {
        this.showFailureModal("Quelque chose à mal tourné vérifiez les éléments qui n'ont pas été mis à jour");
      }
      this.spin = false;
      this.showActionColumn();
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
