import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Employee} from '../interface/createEmployee';
import {EmployeeResponse} from '../interface/employee-response';
import {UpdateEmployee} from '../interface/updateEmployee';
import {FirstTimeUser} from '../models/user/first-time-user';
import {RecoveryQuestion} from '../models/user/recovery-question';
import {environment} from '../../environments/environment';
import {QuestionAnswer} from '../models/user/questionAnswer';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  
  private readonly apiURL = environment.apiBase;

  constructor(
    private http: HttpClient
  ) {
  }
  
  getAllCompanyEmployees(companyId: number): Observable<Employee[]> {
    return this.http.post<Employee[]>(this.apiURL + '/employee/get?c='
      + companyId, null);
  }

  getEmployees(companyId: number, branchId: number): Observable<Employee[]> {
    return this.http.post<Employee[]>(this.apiURL + '/employee/getall?companyID='
      + companyId + '&branchID=' + branchId, null);
  }

  getAllWaiters(companyId: number, branchId: number): Observable<Employee[]> {
    return this.http.post<Employee[]>(this.apiURL + '/employee/getallwaiters?c='
      + companyId + '&b=' + branchId, null);
  }

  getallwaitersAndNonWaiter(companyId: number, branchId: number): Observable<Employee[]> {
    return this.http.post<Employee[]>(this.apiURL + '/employee/getallwaitersAndNonWaiter?c='
      + companyId + '&b=' + branchId, null);
  }

  saveEmployee(employeeBody: Employee): Observable<EmployeeResponse> {
    return this.http.post<EmployeeResponse>(this.apiURL + '/employee/save', employeeBody);
  }

  searchEmployee(companyId: number, branchId: number, employeeSurname): Observable<Employee[]> {

    if ((employeeSurname !== null) && (employeeSurname !== '') && (employeeSurname !== undefined)) {
      return this.http
        .post<Employee[]>(this.apiURL + '/employee/searchemployeesurnameandbranch?companyID='
          + companyId + '&branchID=' + branchId + '&employeeSurname=' + employeeSurname, null);
    } else {
      return this.http.post<Employee[]>(this.apiURL + '/employee/getall?companyID='
        + companyId + '&branchID=' + branchId, null);
    }
  }

  deleteEmployee(employeeId): Observable<Employee> {
    return this.http
      .post<Employee>(this.apiURL + '/employee/delete?intEmployeeID=' + employeeId, null);
  }

  updateEmployee(employeeBody: Employee): Observable<UpdateEmployee> {
    return this.http.post<UpdateEmployee>(this.apiURL + '/employee/update', employeeBody);
  }

  getEmployeeByBranchIdAndStaffKey(branchID: number, employeeID: number): Observable<Employee> {
    return this.http
      .post<Employee>(this.apiURL + '/employee/getone?intEmployeeID='
        + employeeID + '&intBranchID=' + branchID, null);
  }

  setFirstLogin(firstTimeUser: FirstTimeUser): Observable<boolean> {
    return this.http
      .post<boolean>(this.apiURL + '/employee/firstLoginSetup', firstTimeUser);
  }

  getAllQuestionRecovery(): Observable<RecoveryQuestion []> {
    return this.http
      .post<RecoveryQuestion []>(this.apiURL + '/employee/getAllSecretQuestion', null);
  }

  verifyUsername(username: string): Observable<QuestionAnswer[]> {
    return this.http
      .post<QuestionAnswer[]>(this.apiURL + '/employee/verifyUsername?u=' + username, null);
  }

  changePassword(username: string, password: string): Observable<boolean> {
    return this.http
      .post<boolean>(this.apiURL + '/employee/changePassword?u=' + username + '&p=' + password, null);
  }

  changePasswordWhileLoggediN(username: string, oldpassword: string, newPassword: string): Observable<boolean> {
    return this.http
      .post<boolean>(this.apiURL + '/employee/changePasswordWhileLoggedIn?u=' + username + '&op=' + oldpassword + '&np=' + newPassword, null);
  }

  lockUsername(username: string): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/employee/lockUsername?u=' + username, null);
  }
}
