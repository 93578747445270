import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/common/Constants';
// import { User } from 'src/app/models/user/User';
import { User } from 'src/app/models/user/user';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DataManagerService } from 'src/app/services/data-management/data-manager.service';
import { FlowService } from 'src/app/services/flow.service';
import { PagadoService } from 'src/app/services/pagado.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() counter = 0;
  @Output() counterChange = new EventEmitter<number>();

  loginForm: FormGroup;
  loggedIn: boolean;
  spin: boolean;

  user: User;
  currentUserSubscription: Subscription;
  currentUser: UserLoggedIn;

  isOnlineMsg;

  validationMessage = {
    username: [{ type: 'required', message: 'Veuillez entrer votre nom d\'utilisateur' }],
    password: [{ type: 'required', message: 'Veuillez entrer votre mot de passe' }]
  };

  @Output() messageEvent = new EventEmitter<boolean>();

  constructor(private router: Router, private pagadoService: PagadoService, private formBuilder: FormBuilder,
    private flowService: FlowService, private authenticationService: AuthenticationService,
    private dataManagerService: DataManagerService) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.loginForm = this.formBuilder.group({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required])
    });
  }

  login(): void {

    for (const index in this.loginForm.controls) {
      if (this.loginForm.controls[index]) {
        this.loginForm.controls[index].markAsDirty();
      }
    }

    if (this.loginForm.valid) {

      this.user = {
        username: this.loginForm.controls.username.value,
        password: this.loginForm.controls.password.value
      };

      this.dataManagerService.setUsername(this.user.username.toString());

      if (this.loginForm.valid) {

        this.spin = true;

        this.authenticationService.login(this.user).subscribe(response => {

          this.spin = false;
          if ((response !== undefined) && (response !== null)) {

            if (response.success) {
              this.spin = false;

                if (response.isUserFirstLogin === 0) {
                  if ((response.role === Constants.ROLE_WAITER) || (response.role === Constants.ROLE_BAR_PERSON)) {
                    this.spin = false;
                    this.isOnlineMsg = 'Vous n\'êtes pas autorisé à accéder à cette plateforme';
                    this.resetLoginForm()
                  } else {
                    this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
                  }
                } else {
                  this.router.navigate([Constants.ROUTE_FIRST_LOGIN]);
                }

            } else {
              this.spin = false;
              if (response.errorMessage === 'Account Locked') {
                this.isOnlineMsg = 'Vôtre compte a été bloqué';
                this.resetLoginForm()
              } else {
                this.isOnlineMsg = 'Nom d\'utilisateur / Mot de passe incorrect';
                this.resetLoginForm()
              }
            }
          } else {
            this.spin = false;
            this.isOnlineMsg = 'Nom d\'utilisateur / Mot de passe incorrect';
            this.resetLoginForm()
          }
        },
          error => {
            this.spin = false;
            if (error.status === 401) {
              this.spin = false;
              this.isOnlineMsg = 'Nom d\'utilisateur / Mot de passe incorrect';
              this.resetLoginForm()
            } else {
              this.isOnlineMsg = 'Un problème est survenu, réessayez plus tard';
              this.resetLoginForm()
            }
          });
      } else {
        this.spin = false;
        this.isOnlineMsg = 'Un problème est survenu, réessayez plus tard';
        this.resetLoginForm()
      }
    }
  }

  goToForgotPassword(): void {
    this.router.navigate([Constants.ROUTE_FORGOT_PASSWORD]);
  }
  resetLoginForm(){
    this.loginForm.reset()
  }
}
