import { Injectable } from '@angular/core';
import { Company } from 'src/app/models/company/company';
import {Category} from '../../interface/category';
import {Employee} from '../../interface/createEmployee';

@Injectable({
  providedIn: 'root'
})
export class DataManagerService {

  constructor() { }


  public httpStatusCode: number;
  private username: string;
  private httpError: any;
  private category: Category;
  private branchID: any;
  private staffKey: any;
  private employees: Employee;
  private employeeViewMode: string;
  // private company: Company;


  getHttpStatusCode(): number {
    return this.httpStatusCode;
  }

  setHttpStatusCode(httpStatusCode: number) {
    this.httpStatusCode = httpStatusCode;
  }

  getUsername(): string {
    return this.username;
  }

  setUsername(username: string) {
    this.username = username;
  }

  setHttpError(httpError: any) {
    this.httpError = httpError;
  }

  getHttpError(): any {
    return this.httpError;
  }

  setCategory(category: any) {
    this.category = category;
  }

  getCategory(): any {
    return this.category;
  }

  setBranchID(branchID: any) {
    this.branchID = branchID;
  }

  getBranchID(): any {
    return this.branchID;
  }

  // setCompanyDetail(company: Company) {
  //   this.company = company;
  // }

  // getCompanyDetail(): Company {
  //   return this.company;
  // }

  setStaffKey(staffKey: any) {
    this.staffKey = staffKey;
  }

  getStaffKey(): any {
    return this.staffKey;
  }

  setEmployees(employees){
    this.employees = employees;
  }
  getEmployees(){
    return this.employees;
  }

  setEmployeeViewMode(employeeViewMode: string) {
    this.employeeViewMode = employeeViewMode;
  }


  getEmployeeViewMode(): string {
    return this.employeeViewMode;
  }
}
