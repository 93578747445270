import { Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatTableDataSource } from '@angular/material/table';
import { Constants } from 'src/app/common/Constants';
import * as $ from 'jquery';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getChartByID, ApexOptions,  } from 'apexcharts';
import 'node_modules/apexcharts/dist/apexcharts.min.js'
import * as moment from 'moment';
import { DashboardServiceService } from 'src/app/services/dashboard-service.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public dashboardDate: FormGroup;
  chart;
  summaryOptions;
  selectedData:any = null;
  errorMessage = [["Pas de rapport pour les dates selectionnees", false]];
  canDestroy = false;
  categoryDetail = [];

  selectBranchForLowStockProductForm: FormGroup;

  dataSourceLowStockProductReport: MatTableDataSource<ProductDTO>;

  @ViewChild('paginatorLowStockProductReport') paginatorLowStockProductReport: MatPaginator;
  @ViewChild('sortLowStockProductReport') sortLowStockProductReport: MatSort;

  tableHasLowStockProductReport: boolean;

  displayedColumnsLowStoctProduct = [
    'Nom de l\'article',
    'Quantity',
    'Fournisseur'
  ];

  branchListForLowStockProducts: Branch[];
  branchCurrencyForLowStockProduct: string;
  userLoggedIn: UserLoggedIn;
  spin: boolean;
  productDTOs: ProductDTO[];
  loggedInUserFullName: string;

  pageLowStockProductReport = 1;

  constructor(private authenticationService: AuthenticationService, private router: Router,
    private orderService: OrderService, private branchService: BranchService,
    private formBuilder: FormBuilder, private modalService: NgbModal,
    private dashboardService:DashboardServiceService) {

    // this.stockUpdateOutgoingReports = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;
    let date = new Date();
    this.dashboardDate.controls['dateFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
    this.dashboardDate.controls['dateTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()))

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

      this.loadBranchesForLowStockProduct();
      this.loadLowStockProduct(0);

      // var date = new Date();

      // this.filterFormSaleReport.controls['dtSaleReportFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), 1));
      // this.filterFormSaleReport.controls['dtSaleReportTo'].setValue(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    } else {

    }
  }



  createForms() {
    this.selectBranchForLowStockProductForm = this.formBuilder.group({
      selectBranchLowStock: new FormControl(null, Validators.required)
    });
    this.dashboardDate = this.formBuilder.group({
      dateFrom: [null, Validators.required],
      dateTo: [null, Validators.required],
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  loadLowStockProduct(branchID: number) {
    this.spin = true;

    this.selectBranchForLowStockProductForm.controls.selectBranchLowStock.setValue(branchID);

    // console.log('lol', JSON.stringify(this.branchListForLowStockProducts));

    // this.branchCurrencyForLowStockProduct = this.branchListForLowStockProducts.find(br => br.branch_id === branchID).branch_currency;
    this.orderService.getAllLowStockProduct(branchID, this.authenticationService.currentUserValue.companyID).subscribe(res => {

      this.productDTOs = res;

      if (this.productDTOs.length > 0) {
        this.tableHasLowStockProductReport = true;
        this.dataSourceLowStockProductReport = new MatTableDataSource(this.productDTOs);
        this.dataSourceLowStockProductReport.paginator = this.paginatorLowStockProductReport;
        this.dataSourceLowStockProductReport.sort = this.sortLowStockProductReport;

      } else {
        this.tableHasLowStockProductReport = false;
      }
      this.spin = false;

    }, error => {
      this.tableHasLowStockProductReport = false;
      this.dataSourceLowStockProductReport = new MatTableDataSource([]);
      this.dataSourceLowStockProductReport.paginator = this.paginatorLowStockProductReport;
      this.dataSourceLowStockProductReport.sort = this.sortLowStockProductReport;
      this.spin = false;
    });
  }

  loadBranchesForLowStockProduct(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchListForLowStockProducts = res;

      this.selectBranchForLowStockProductForm.controls.selectBranchLowStock.setValue(0);
      //this.loadLowStockProduct(this.branchIdToBeUsed);

    }, error => {
      this.spin = false;
    });
  }
  showPieChartDetail(selected:number){
    this.categoryDetail.forEach(category => {
      if(selected === category.id){
        this.selectedData = category;
      }
    });
  }

  async setPieData(){
    if(this.canDestroy === true){
      this.chart.destroy();
      this.categoryDetail = [];
      this.selectedData = null;
      this.canDestroy = false;
      this.errorMessage[0][1] = false
    }
    this.spin = true
    let pieData = await this.dashboardService.GetPieData(this.dashboardDate.controls["dateFrom"].value, this.dashboardDate.controls["dateTo"].value)
    console.log(pieData);
    if(pieData.length < 1){
      this.errorMessage[0][1] = true;
      this.dashboardDate.markAllAsTouched();
      let date = new Date();
      this.dashboardDate.controls['dateFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.dashboardDate.controls['dateTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
      this.spin = false;
      return 0
    }
    this.summaryOptions = {
      chart:{
        type:'pie',
        events:{
          click:(e)=>{
            // console.log("clicked", e.target.getAttribute("data:value"), e.target.getAttribute("data:pieClicked"))
            let selectedPie = Number(e.target.getAttribute("data:value"));
            this.showPieChartDetail(selectedPie);
          }
        }
      },
      series: pieData.map((e)=>{ return e.total_revenue}),
      labels: pieData.map((e)=>{return e.name}),
      // colors:['#F44336', '#E91E63', '#9C27B0']
    }
    console.log(this.summaryOptions);
    this.categoryDetail = pieData;
    this.chart = new ApexCharts(document.querySelector('#summary'), this.summaryOptions);
    this.chart.render();
    this.canDestroy = true
    this.selectedData = this.categoryDetail[0]
    this.spin = false
  }
}
