import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { OrderService } from 'src/app/services/order/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Branch } from 'src/app/interface/branch';
import { Constants } from 'src/app/common/Constants';
import { OrderItem } from 'src/app/models/order-items';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDTO } from 'src/app/models/product/ProductDTO';
import { Input } from '@angular/core';

@Component({
  selector: 'app-report-low-stock',
  templateUrl: './report-low-stock.component.html',
  styleUrls: ['./report-low-stock.component.scss']
})
export class ReportLowStockComponent implements OnInit {
  userLoggedIn: UserLoggedIn;
  spin: boolean;
  productDTOs: ProductDTO[];
  selectBranchForLowStockProductForm: FormGroup;
  dataSourceLowStockProductReport: MatTableDataSource<ProductDTO>;

  closeResult: string;

  @ViewChild('paginatorLowStockProductReport') paginatorLowStockProductReport: MatPaginator;
  @ViewChild('sortLowStockProductReport') sortLowStockProductReport: MatSort;

  branchCurrencyForLowStockProduct: string;
  tableHasLowStockProductReport: boolean;

  displayedColumnsLowStoctProduct = [
    'Nom de l\'article',
    'Quantity',
    'StockAlert',
    'Fournisseur',
  ];

  branchListForLowStockProducts: Branch[];

  @Input() id: string;

  pageLowStockProductReport = 1;

  selectedOrderID_PastOrders: number;
  selectedOrderTotalAmount_PastOrders: number;
  selectedOrderCurrency_PastOrders: string;

  orderItems: OrderItem[];

  @ViewChild('viewOrderItem', { static: true }) viewOrderItem: TemplateRef<any>;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {

      this.spin = true;

      this.loadBranchesForLowStockProduct();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadLowStockProduct(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadLowStockProduct(this.authenticationService.currentUserValue.branchID);
      }

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }
  }

  createForms() {

    this.selectBranchForLowStockProductForm = this.formBuilder.group({
      selectBranchLowStock: new FormControl(null, Validators.required)
    });
  }

  loadOrderItemsByOrderID(orderID: number, orderTotalAmount: number, currency: string) {
    this.spin = true;

    this.selectedOrderID_PastOrders = orderID;
    this.selectedOrderTotalAmount_PastOrders = orderTotalAmount;
    this.selectedOrderCurrency_PastOrders = currency;

    this.open(this.viewOrderItem);


    this.orderService.getAllOrderItem(orderID).subscribe(res => {

      this.orderItems = res;

      // if (this.orderItems.length > 0) {
      //   this.tableHasOrderItemData = true;
      //   this.dataSourceOrderItems = new MatTableDataSource(this.orderItems);
      //   this.dataSourceOrderItems.paginator = this.paginatorOrderItem;
      //   this.dataSourceOrderItems.sort = this.sortOrderItem;

      // } else {
      //   this.tableHasOrderItemData = false;
      // }
      this.spin = false;

    }, error => {
      // this.tableHasOrderItemData = false;
      // this.dataSourceOrderItems = new MatTableDataSource([]);
      // this.dataSourceOrderItems.paginator = this.paginatorOrderItem;
      // this.dataSourceOrderItems.sort = this.sortOrderItem;
      this.spin = false;
    });
  }

  loadLowStockProduct(branchID: number) {
    this.spin = true;

    this.selectBranchForLowStockProductForm.controls.selectBranchLowStock.setValue(branchID);

    this.orderService.getAllLowStockProduct(branchID, this.authenticationService.currentUserValue.companyID).subscribe(res => {

      this.productDTOs = res;

      if (this.productDTOs.length > 0) {
        this.tableHasLowStockProductReport = true;
        this.dataSourceLowStockProductReport = new MatTableDataSource(this.productDTOs);
        this.dataSourceLowStockProductReport.paginator = this.paginatorLowStockProductReport;
        this.dataSourceLowStockProductReport.sort = this.sortLowStockProductReport;

      } else {
        this.tableHasLowStockProductReport = false;
      }
      this.spin = false;

    }, error => {
      this.tableHasLowStockProductReport = false;
      this.dataSourceLowStockProductReport = new MatTableDataSource([]);
      this.dataSourceLowStockProductReport.paginator = this.paginatorLowStockProductReport;
      this.dataSourceLowStockProductReport.sort = this.sortLowStockProductReport;
      this.spin = false;
    });
  }

  loadBranchesForLowStockProduct(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branchListForLowStockProducts = res;

      // if (this.authenticationService.currentUserValue.role === Constants.ROLE_ADMIN_MANAGER) {
      //   this.branchIdToBeUsed = this.branchListForLowStockProducts[0].branch_id;
      //   this.branchCurrencyForLowStockProduct = this.branchListForLowStockProducts[0].branch_currency;
      //   this.selectBranchForLowStockProductForm.controls.selectBranch.enable();
      // } else if (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER){
      //   this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
      //   this.branchCurrencyForLowStockProduct = this.branchListForLowStockProducts.find(br => br.branch_id === this.branchIdToBeUsed).branch_currency;
      //   this.selectBranchForLowStockProductForm.controls.selectBranch.disable();

      // }

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) || 
      (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.selectBranchForLowStockProductForm.controls.selectBranchLowStock.enable();
        this.selectBranchForLowStockProductForm.controls.selectBranchLowStock.setValue(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.selectBranchForLowStockProductForm.controls.selectBranchLowStock.disable();
        this.selectBranchForLowStockProductForm.controls.selectBranchLowStock.setValue(this.authenticationService.currentUserValue.branchID);
      }


      //this.loadLowStockProduct(this.branchIdToBeUsed);

    }, error => {
      this.spin = false;
    });
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event) {
  this.pageLowStockProductReport = event;
  window.scroll(0,0);
  }

}
