<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" id="prod" data-toggle="tab" href="#product" role="tab" aria-controls="product"
              aria-selected="true" (click)="showProduct()">Liste des articles du dépôt</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="warehouse-finance-tab" data-toggle="tab" href="#warehouse-finance" role="tab"
              aria-controls="warehouse-finance" aria-selected="false">Historique des ventes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="warehouse-transaction-tab" data-toggle="tab" href="#warehouse-transaction"
              role="tab" aria-controls="warehouse-transaction" aria-selected="false">Historique des transactions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="warehouse-refill-tab" data-toggle="tab" href="#warehouse-refill" role="tab"
              aria-controls="warehouse-refill" aria-selected="false">Résumé des articles écoulé</a>
          </li>
          <li class="nav-item" *ngIf='shouldShowWarehouseSetup'>
            <a class="nav-link" id="warehouse-setup-tab" data-toggle="tab" href="#warehouse-setup" role="tab"
              aria-controls="warehouse-setup" aria-selected="false">Mes dépôts</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="product" role="tabpanel" aria-labelledby="prod">
            <div class="product" *ngIf="product">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
                  <form [formGroup]="productSearchForm">
                    <div class="form-group mt-4">
                      <small class="form-text text-muted">Rechercher un article</small>
                      <div class="input-group mb-3">
                        <input type="search" class="form-control" formControlName="searchProducName"
                          placeholder="Rechercher..." aria-label="Search..." aria-describedby="basic-addon2">
                        <div *ngIf="productSearchForm.controls['searchProducName'].invalid &&
                              (productSearchForm.controls['searchProducName'].dirty || 
                              productSearchForm.controls['searchProducName'].touched)" class="alert alert-light pt-0">
                          <div *ngIf="productSearchForm.controls['searchProducName'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                        <div class="input-group-append">
                          <button class="btn btn-outline-primary" (click)="searchProduct(selectedWarehouse.value)"
                            type="button"><i class="fas fa-search"></i></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
                  <div class="form-group mt-md-4 mt-0">
                    <small class="form-text text-muted mt-md-5 mt-3"></small>
                    <button type="button" class="btn btn-primary mr-xl-3 mr-lg-3 mr-md-3"
                      (click)="openCreateNewProductModal()">
                      <i class="fas fa-plus-square"></i> &nbsp;Ajouter un article
                    </button>

                  </div>
                </div>

                <!--  <div class="col-lg-2 col-md-3 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
                    <div class="form-group mt-md-4 mt-0">
                      <small class="form-text text-muted mt-md-5 mt-3"></small>
                      
                      <button type="button" class="btn btn-primary" (click)="openCreateNewProductModal()">
                        <i class="fas fa-plus-square"></i> &nbsp;Ajouter un dépôt
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
                    <div class="form-group mt-md-4 mt-0">
                      <small class="form-text text-muted mt-md-5 mt-3"></small>
                      <button type="button" class="btn btn-primary" (click)="openCreateNewProductModal()">
                        <i class="fas fa-plus-square"></i> &nbsp;Ajouter un article
                      </button>
                    </div>
                  </div> -->

              </div>

              <div class="row mt-1 mb-3">
                <div
                  class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">
                  <div class="card mb-3 d-lg-none"
                    *ngFor="let item of productDTOs | paginate: { itemsPerPage: 10, currentPage: page }">
                    <div class="card-body">
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Nom de l'article
                        </div>
                        <div class="col-6">
                          {{item.name}} {{item.type}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Prix ​​de vente par casier
                        </div>
                        <div class="col-6">
                          {{branchCurrency}} {{item.selling_price}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Quantité
                        </div>
                        <div class="col-6">
                          <p *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity > item.pack_number_of_piece)">
                            {{(item.quantity % item.pack_number_of_piece) !== 0 ? (item.quantity /
                            item.pack_number_of_piece).toString()
                            .substring(0, (item.quantity / item.pack_number_of_piece).toString().indexOf('.')) :
                            (item.quantity / item.pack_number_of_piece) }}
                            casiers de {{item.drink_pack_name}} et {{item.quantity % item.pack_number_of_piece}}
                            bouteilles
                          </p>
                          <p *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity === item.pack_number_of_piece)">
                            1 casier
                            de {{item.drink_pack_name}}</p>
                          <p *ngIf="(item.pack_number_of_piece !== 1) && (item.quantity < item.pack_number_of_piece)"> 0
                            casier et
                            {{item.quantity}} bouteilles </p>
                          <p *ngIf="item.pack_number_of_piece === 1">
                            {{item.quantity}} bouteilles
                          </p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Fournisseur
                        </div>
                        <div class="col-6">
                          {{item.supplier_name}}
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-12 text-center">
                          <button class="btn btn-primary tn-bg-green-color" data-toggle="modal"
                            data-target="#updateQuantity" (click)="updateStockQuantity(item)"><i
                              class="fas fa-sort-numeric-up"></i></button> &nbsp;
                          <button class="btn btn-primary tn-bg-grey-color" data-toggle="modal"
                            data-target="#warehouseProductSale" (click)="saleWarehouseProduct(item)"><i
                              class="fas fa-money-bill-alt"></i></button> &nbsp;
                          <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"
                            (click)="view(item)"><i class="fas fa-eye"></i></button> &nbsp;
                          <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                            (click)="delete(item)"><i class="bi bi-trash"></i></button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-lg-none"
                    (pageChange)="onMobileTableChangePage($event)" previousLabel="Précédent" nextLabel="Suivant">
                  </pagination-controls>



                  <mat-table [dataSource]="dataSourceProduct" class="d-none d-lg-block" matSort>
                    <ng-container matColumnDef="Nom de l'article">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nom de l'article
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{row.name}} {{row.type}}
                      </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Prix ​​d'achat">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Prix ​​d'achat
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{branchCurrency}} {{row.cost}}
                      </mat-cell>
                    </ng-container> -->
                    <ng-container matColumnDef="Prix ​​de vente">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Prix ​​de vente par casier
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{branchCurrency}} {{row.selling_price}}
                      </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Quantité">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Quantité
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{row.quantity}}
                      </mat-cell>
                    </ng-container> -->
                    <ng-container matColumnDef="Pack">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Quantité
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <p *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity > row.pack_number_of_piece)">
                          {{(row.quantity % row.pack_number_of_piece) !== 0 ? (row.quantity /
                          row.pack_number_of_piece).toString()
                          .substring(0, (row.quantity / row.pack_number_of_piece).toString().indexOf('.')) :
                          (row.quantity / row.pack_number_of_piece) }}
                          casiers de {{row.drink_pack_name}} et {{row.quantity % row.pack_number_of_piece}}
                          bouteilles
                        </p>
                        <p *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity === row.pack_number_of_piece)"> 1
                          casier de {{row.drink_pack_name}}</p>
                        <p *ngIf="(row.pack_number_of_piece !== 1) && (row.quantity < row.pack_number_of_piece)"> 0
                          casier et {{row.quantity}} bouteilles </p>
                        <p *ngIf="row.pack_number_of_piece === 1">
                          {{row.quantity}} bouteilles
                        </p>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Fournisseur">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Fournisseur
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{row.supplier_name}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Actions
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <button class="btn btn-primary tn-bg-green-color" data-toggle="modal"
                          data-target="#updateQuantity" (click)="updateStockQuantity(row)"><i
                            class="fas fa-sort-numeric-up"></i></button> &nbsp;
                        <button class="btn btn-primary tn-bg-grey-color" data-toggle="modal"
                          data-target="#warehouseProductSale" (click)="saleWarehouseProduct(row)"><i
                            class="fas fa-money-bill-alt"></i></button> &nbsp;
                        <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"
                          (click)="view(row)"><i class="fas fa-eye"></i></button> &nbsp;
                        <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                          (click)="delete(row)"><i class="bi bi-trash"></i></button>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsProduct">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsProduct">
                    </mat-row>
                  </mat-table>
                  <div class="mt-3" *ngIf="tableHasData === false">Aucun article trouvé
                  </div>
                  <mat-paginator class="d-none d-lg-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>

                </div>

                <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                  <form [formGroup]="selectForm">
                    <div class="form-group">
                      <small class="form-text text-muted">Sélectionnez un dépot</small>
                      <select #selectedWarehouse class="form-control" (change)="getWarehouseProductByWarehouse($event)"
                        formControlName="selectWarehouse">
                        <option *ngFor="let warehouse of wareHouseList" value={{warehouse.warehouse_id}}>
                          {{warehouse.warehouse_name}}
                        </option>
                      </select>
                    </div>

                    <!-- <div class="form-group">
                        <small class="form-text text-muted">Sélectionnez une catégorie</small>
                        <select class="form-control" *ngIf="sideNavCategories !== undefined"
                          formControlName="categorySelect" (change)="getSubCategories($event)">
                          <option disabled selected>Sélectionnez une catégorie</option>
                          <option *ngFor="let category of sideNavCategories" value={{category.category_id}}>
                            {{category.name}}</option>
                        </select>
                        <input *ngIf="sideNavCategories === undefined" type="emptyCategory" class="form-control" disabled
                          placeholder="Aucune catégorie trouvée.">
  
                        <small class="form-text text-muted mt-2">Sélectionnez une sous-catégorie</small>
                        <select *ngIf="(sideNavSubCategories !== undefined)  && (sideNavSubCategories !== null)"
                          class="form-control" formControlName="subCategorySelect"
                          (change)="getProductBySubCategory($event, selectedBranch.value)">
                          <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                          <option *ngFor="let subcategory of sideNavSubCategories" value={{subcategory.subcategory_id}}>
                            {{subcategory.sub_name}}
                          </option>
                        </select>
                        <input *ngIf="(sideNavSubCategories === undefined) || (sideNavSubCategories === null)"
                          type="emptySubCategory" class="form-control" disabled
                          placeholder="Aucune sous-catégorie trouvée.">
                      </div> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="warehouse-finance" role="tabpanel" aria-labelledby="warehouse-finance-tab">
            <app-warehouse-sale-history></app-warehouse-sale-history>
          </div>
          <div class="tab-pane fade" id="warehouse-transaction" role="tabpanel"
            aria-labelledby="warehouse-transaction-tab">
            <app-warehouse-transaction-history></app-warehouse-transaction-history>
          </div>
          <div class="tab-pane fade" id="warehouse-setup" role="tabpanel" aria-labelledby="warehouse-setup-tab">
            <app-warehouse-setup></app-warehouse-setup>
          </div>
          <div class="tab-pane fade" id="warehouse-refill" role="tabpanel" aria-labelledby="warehouse-refill-tab">
            <app-warehouse-refill></app-warehouse-refill>
          </div>
        </div>

        <!-- Add new product to warehouse-->
        <ng-template #createNewProduct let-modal>

          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Ajouter un nouveau produit au dépôt</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="productForm">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Nom de l'article</small>
                      <input type="text" class="form-control" formControlName="productName" placeholder="Nom du produit"
                        required>
                      <div *ngIf="productForm.controls['productName'].invalid &&
                                          (productForm.controls['productName'].dirty ||
                                          productForm.controls['productName'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['productName'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Type d'article</small>
                      <input type="text" class="form-control" formControlName="type" placeholder="Type de produit">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Barcode</small>
                      <input type="text" class="form-control" formControlName="barcode" placeholder="Barcode">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Coût d'achat d'un casier</small>
                      <input type="number" class="form-control" formControlName="cost" placeholder="Cout" required>
                      <div *ngIf="productForm.controls['cost'].invalid &&
                                          (productForm.controls['cost'].dirty ||
                                          productForm.controls['cost'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['cost'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Coût de vente d'un casier</small>
                      <input type="number" class="form-control" formControlName="selling_price"
                        placeholder="Prix de vente" required>
                      <div *ngIf="productForm.controls['selling_price'].invalid &&
                                          (productForm.controls['selling_price'].dirty ||
                                          productForm.controls['selling_price'].touched)"
                        class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['selling_price'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Nombre de casier</small>
                      <input type="number" class="form-control" formControlName="quantity" placeholder="Quantité"
                        required>
                      <div *ngIf="productForm.controls['quantity'].invalid &&
                                          (productForm.controls['quantity'].dirty ||
                                          productForm.controls['quantity'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['quantity'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="form-group">
                        <small class="form-text text-muted">Type de Casier</small>
                        <select class="form-control" formControlName="drinkPack" required>
                          <option value="" disabled selected>Selectionnez un casier</option>
                          <option *ngFor="let drinkP of drinkPacks" value={{drinkP.drink_pack_id}}>
                            {{drinkP.drink_pack_name}}</option>
                        </select>
                        <div *ngIf="productForm.controls['drinkPack'].invalid &&
                                            (productForm.controls['drinkPack'].dirty ||
                                            productForm.controls['drinkPack'].touched)" class="pt-0 alert alert-light">
                          <div *ngIf="productForm.controls['drinkPack'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-6">
                      <div class="form-group">
                        <small class="form-text text-muted">Alerte de stock</small>
                        <input type="number" class="form-control" formControlName="stock_alert"
                          placeholder="Alerte de stock" required>
                        <div *ngIf="productForm.controls['stock_alert'].invalid &&
                                          (productForm.controls['stock_alert'].dirty ||
                                          productForm.controls['stock_alert'].touched)" class="pt-0 alert alert-light">
                          <div *ngIf="productForm.controls['stock_alert'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div> -->
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Sélectionnez un dépôt</small>
                      <select class="form-control" formControlName="warehouse" required>
                        <!-- <option value="" selected>Sélectionnez un dépôt</option> -->
                        <option *ngFor="let warehouse of wareHouseList" value={{warehouse.warehouse_id}}>
                          {{warehouse.warehouse_name}}
                        </option>
                      </select>
                      <div *ngIf="productForm.controls['warehouse'].invalid &&
                                          (productForm.controls['warehouse'].dirty ||
                                          productForm.controls['warehouse'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['warehouse'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Catégorie</small>
                      <select class="form-control" formControlName="category_id"
                        (change)="addFormWarehouseSubcategoriesChange($event)" required>
                        <option value="" selected>Sélectionnez une catégorie</option>
                        <option *ngFor="let category of addFormWarehouseCategories"
                          value={{category.warehouse_category_id}}>
                          {{category.warehouse_category_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['category_id'].invalid &&
                                          (productForm.controls['category_id'].dirty ||
                                          productForm.controls['category_id'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['category_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group" *ngIf="addFormSubCategoryEnable">
                      <small class="form-text text-muted">Sous-catégorie</small>
                      <select class="form-control" formControlName="subcategory_id" required>
                        <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                        <option *ngFor="let subcategory of addFormWarehouseSubcategories"
                          value={{subcategory.warehouse_subcategory_id}}>
                          {{subcategory.warehouse_subcategory_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['subcategory_id'].invalid &&
                                          (productForm.controls['subcategory_id'].dirty ||
                                          productForm.controls['subcategory_id'].touched)"
                        class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['subcategory_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Sélectionnez un fournisseur</small>
                      <select class="form-control" formControlName="supplier_id" required>
                        <option value="" disabled selected>Sélectionnez un fournisseur</option>
                        <option *ngFor="let supplier of suppliers" value={{supplier.supplier_id}}>
                          {{supplier.supplier_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['supplier_id'].invalid &&
                                            (productForm.controls['supplier_id'].dirty ||
                                            productForm.controls['supplier_id'].touched)"
                        class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['supplier_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
              </button>
              <button type="button" [disabled]="!productForm.valid" class="btn btn-primary btn-lg"
                (click)="saveNewProductToWarehouse()">Sauvegarder
              </button>
            </div>
          </div>

        </ng-template>

        <!-- edit product modal -->
        <!-- <ng-template #editProduct let-modal>
            <div class="modal-header">
              <h5 class="modal-title">Modification de l'article </h5>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="editproductForm">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Nom de l'article
                      </small>
                      <input type="text" class="form-control" formControlName="productName" placeholder="Nom du produit"
                        required>
                      <div *ngIf="editproductForm.controls['productName'].invalid &&
                                          (editproductForm.controls['productName'].dirty ||
                                          editproductForm.controls['productName'].touched)"
                        class="alert alert-light pt-0">
                        <div *ngIf="editproductForm.controls['productName'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Type d'article
                      </small>
                      <input type="text" class="form-control" formControlName="type" placeholder="Type">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Barcode
                      </small>
                      <input type="text" class="form-control" formControlName="barcode" placeholder="Barcode">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Coût d'achat
                      </small>
                      <input type="number" class="form-control" formControlName="cost" placeholder="Coût" required>
                      <div *ngIf="editproductForm.controls['cost'].invalid &&
                                          (editproductForm.controls['cost'].dirty ||
                                          editproductForm.controls['cost'].touched)" class="alert alert-light pt-0">
                        <div *ngIf="editproductForm.controls['cost'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Coût de vente
                      </small>
                      <input type="number" class="form-control" formControlName="selling_price"
                        placeholder="Coût de vente" required>
                      <div *ngIf="editproductForm.controls['selling_price'].invalid &&
                                          (editproductForm.controls['selling_price'].dirty ||
                                          editproductForm.controls['selling_price'].touched)"
                        class="alert alert-light pt-0">
                        <div *ngIf="editproductForm.controls['selling_price'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Quantité
                      </small>
                      <input type="number" class="form-control" [attr.disabled]="true"
                       formControlName="quantity" placeholder="Quantité" required>
                      <div *ngIf="editproductForm.controls['quantity'].invalid &&
                                          (editproductForm.controls['quantity'].dirty ||
                                          editproductForm.controls['quantity'].touched)" class="alert alert-light pt-0">
                        <div *ngIf="editproductForm.controls['quantity'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="form-group">
                        <small class="form-text text-muted">Casier de boisson</small>
                        <select class="form-control" formControlName="drinkPack" required>
                          <option value="" disabled selected>Selectionnez un casier</option>
                          <option *ngFor="let drinkP of drinkPacks" value={{drinkP.drink_pack_id}}>
                            {{drinkP.drink_pack_name}}</option>
                        </select>
                        <div *ngIf="productForm.controls['drinkPack'].invalid &&
                                          (productForm.controls['drinkPack'].dirty ||
                                          productForm.controls['drinkPack'].touched)" class="pt-0 alert alert-light">
                          <div *ngIf="productForm.controls['drinkPack'].errors.required">
                            Ne devrait pas être vide
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Alerte de stock
                      </small>
                      <input type="number" class="form-control" formControlName="stock_alert"
                        placeholder="Alerte de stock" required>
                      <div *ngIf="editproductForm.controls['stock_alert'].invalid &&
                                          (editproductForm.controls['stock_alert'].dirty ||
                                          editproductForm.controls['stock_alert'].touched)"
                        class="alert alert-light pt-0">
                        <div *ngIf="editproductForm.controls['stock_alert'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Sélectionnez un établissement
                      </small>
                      <select class="form-control" (change)="selectFanchise($event)" [attr.disabled]="true"
                        formControlName="franchise">
                        <option value="" disabled selected>Sélectionnez un établissement</option>
                        <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                          {{branch.branch_name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Sous-catégorie
                      </small>
                      <select class="form-control" formControlName="subcategory_id" required>
                        <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                        <option *ngFor="let subCategory of viewProductSubcategories" value={{subCategory.subcategory_id}}>
                          {{subCategory.sub_name}}</option>
                      </select>
                      <div *ngIf="editproductForm.controls['subcategory_id'].invalid &&
                                          (editproductForm.controls['subcategory_id'].dirty ||
                                          editproductForm.controls['subcategory_id'].touched)"
                        class="alert alert-light pt-0">
                        <div *ngIf="editproductForm.controls['subcategory_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Fournisseur
                      </small>
                      <select class="form-control" formControlName="supplier_id" required>
                        <option value="" disabled selected>Sélectionnez un fournisseur</option>
                        <option *ngFor="let supplier of suppliers" value={{supplier.supplier_id}}>
                          {{supplier.supplier_name}}</option>
                      </select>
                      <div *ngIf="editproductForm.controls['supplier_id'].invalid &&
                                          (editproductForm.controls['supplier_id'].dirty ||
                                          editproductForm.controls['supplier_id'].touched)"
                        class="alert alert-light pt-0">
                        <div *ngIf="editproductForm.controls['supplier_id'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
              <button type="button" [disabled]="!editproductForm.valid" class="btn btn-primary btn-lg"
                (click)='editProductName()'>Sauvegarder
              </button>
            </div>
          </ng-template> -->

        <!-- edit quantity stock product modal -->
        <ng-template #updateQuantity let-modal>
          <div class="modal-header">
            <h5 class="modal-title" *ngIf="!shouldShowReductionDiv">Mise à jour du stock depot </h5>
            <h5 class="modal-title" *ngIf="shouldShowReductionDiv">Réduction du stock depot</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="tn-failure-color font-italic" *ngIf="shouldShowReductionDiv">N'utilisez cette option qu'en cas d'extrême nécessité</p>
            <div class="row mb-2" *ngIf="!shouldShowReductionDiv">
              <div class="col-6">
                <a class="tn-failure-color font-weight-bold" *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator' 
                || roleLoggedIn == 'Manager Full'" (click)="showReductionStock()">Cliquez ici pour une réduction du stock</a>
              </div>
            </div>
            <form [formGroup]="updateQuantityForm" *ngIf="!shouldShowReductionDiv">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nom de l'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="productName"
                      placeholder="Nom du produit" required>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Type d'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="type"
                      placeholder="Type">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nombre actuel de casier dans le depot
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true"
                      formControlName="currentQuantityInWarehouse">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Fournisseur (<a class="tn-failure-color font-weight-bold" (click)="changerFournisseur()">Changer fournisseur</a>)
                    </small>
                    <!-- <select class="form-control" formControlName="supplier_id" [attr.disabled]="editproductForm.controls['provier_type']==='warehouse'" required> -->
                    <select class="form-control" formControlName="supplier_id" required>
                      <option value="" disabled selected>Sélectionnez un fournisseur</option>
                      <option *ngFor="let supplier of suppliers" value={{supplier.supplier_id}}>
                        {{supplier.supplier_name}}</option>
                    </select>
                    <!-- <input type="text" class="form-control" formControlName="supplier_id" placeholder="Fournisseur"
                        required> -->
                    <div *ngIf="updateQuantityForm.controls['supplier_id'].invalid &&
                                        (updateQuantityForm.controls['supplier_id'].dirty ||
                                        updateQuantityForm.controls['supplier_id'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="updateQuantityForm.controls['supplier_id'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Combien de casier souhaitez-vous ajouter?
                    </small>
                    <input type="number" class="form-control" formControlName="quantity" placeholder="Quantité"
                      required>
                    <div *ngIf="updateQuantityForm.controls['quantity'].invalid &&
                                          (updateQuantityForm.controls['quantity'].dirty ||
                                          updateQuantityForm.controls['quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="updateQuantityForm.controls['quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>


            <form [formGroup]="negativeUpdateQuantityForm" *ngIf="shouldShowReductionDiv">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nom de l'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="productName"
                      placeholder="Nom du produit" required>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Type d'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="type"
                      placeholder="Type">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Quantité actuelle
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="true" formControlName="current_quantity"
                      placeholder="Quantité" required>
                    <div *ngIf="negativeUpdateQuantityForm.controls['current_quantity'].invalid &&
                                        (negativeUpdateQuantityForm.controls['current_quantity'].dirty ||
                                        negativeUpdateQuantityForm.controls['current_quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="negativeUpdateQuantityForm.controls['current_quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Combien de casier souhaitez-vous rétrancher?
                    </small>
                    <input type="number" class="form-control" formControlName="quantity" placeholder="Quantité"
                      required>
                    <div *ngIf="negativeUpdateQuantityForm.controls['quantity'].invalid &&
                                        (negativeUpdateQuantityForm.controls['quantity'].dirty ||
                                        negativeUpdateQuantityForm.controls['quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="negativeUpdateQuantityForm.controls['quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Pourquoi souhaitez vous faire cette opération?
                    </small>
                    <input type="text" class="form-control" formControlName="comment" placeholder="Votre commentaire"
                      required>
                    <div *ngIf="negativeUpdateQuantityForm.controls['comment'].invalid &&
                                        (negativeUpdateQuantityForm.controls['comment'].dirty ||
                                        negativeUpdateQuantityForm.controls['comment'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="negativeUpdateQuantityForm.controls['comment'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" *ngIf="!shouldShowReductionDiv" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
            <button type="button" *ngIf="shouldShowReductionDiv" class="btn btn-danger btn-lg" (click)="cancelQuantityReduction()">Annuler</button>
            <button type="button" *ngIf="!shouldShowReductionDiv" [disabled]="!updateQuantityForm.valid" class="btn btn-primary btn-lg"
              (click)='openConfirmUpdateStock()'>Sauvegarder
            </button>
            <button type="button" *ngIf="shouldShowReductionDiv" [disabled]="!negativeUpdateQuantityForm.valid" class="btn btn-primary btn-lg"
              (click)='openConfirmUpdateStock()'>Sauvegarder
            </button>
          </div>
        </ng-template>

        <!-- warehouse sale of product modal -->
        <ng-template #warehouseProductSale1 let-modal>
          <div class="modal-header">
            <h5 class="modal-title">Vente des articles du dépôt</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="warehouseProductSaleForm">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Dépôt
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="warehouse">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nom de l'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="productName"
                      placeholder="Nom du produit" required>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Type d'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true" formControlName="type"
                      placeholder="Type">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nombre actuel de casier dans le dépôt
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="true"
                      formControlName="currentQuantityInWarehouse">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Sélectionnez un établissement</small>
                    <select class="form-control" formControlName="franchise" required>
                      <option value="" selected>Sélectionnez un établissement</option>
                      <option *ngFor="let branch of branchList" value={{branch.branch_id}}>
                        {{branch.branch_name}}</option>
                    </select>
                    <div *ngIf="warehouseProductSaleForm.controls['franchise'].invalid &&
                                        (warehouseProductSaleForm.controls['franchise'].dirty ||
                                        warehouseProductSaleForm.controls['franchise'].touched)"
                      class="pt-0 alert alert-light">
                      <div *ngIf="warehouseProductSaleForm.controls['franchise'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Combien de casier souhaitez-vous vendre?
                    </small>
                    <input type="number" class="form-control" formControlName="quantitySold" (keyup)="calculatePrice()"
                      required>
                    <div *ngIf="warehouseProductSaleForm.controls['quantitySold'].invalid &&
                                          (warehouseProductSaleForm.controls['quantitySold'].dirty ||
                                          warehouseProductSaleForm.controls['quantitySold'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="warehouseProductSaleForm.controls['quantitySold'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <small class="form-text text-muted mb-4">
                    Prix total de casier a vendre
                  </small>
                  <h3>FC {{priceToPay}}</h3>

                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler</button>
            <button type="button" [disabled]="!warehouseProductSaleForm.valid" class="btn btn-primary btn-lg"
              (click)='openConfirmWarehouseStockSale()'>Sauvegarder
            </button>
          </div>
        </ng-template>

        <!-- VIEW PRODUCT MODAL -->
        <ng-template #viewProduct let-modal>
          <div class="modal-header">
            <h5 class="modal-title">Détails de l'article</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="viewEditproductForm">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nom de l'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="productName" placeholder="Nom du produit" required>
                    <div *ngIf="viewEditproductForm.controls['productName'].invalid &&
                                              (viewEditproductForm.controls['productName'].dirty ||
                                              viewEditproductForm.controls['productName'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['productName'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Type d'article
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="type" placeholder="Type">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Barcode
                    </small>
                    <input type="text" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="barcode" placeholder="Barcode">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Coût d'achat du casier
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="cost" placeholder="Coût" required>
                    <div *ngIf="viewEditproductForm.controls['cost'].invalid &&
                                              (viewEditproductForm.controls['cost'].dirty ||
                                              viewEditproductForm.controls['cost'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['cost'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Coût de vente du casier
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="selling_price" placeholder="Coût de vente" required>
                    <div *ngIf="viewEditproductForm.controls['selling_price'].invalid &&
                                              (viewEditproductForm.controls['selling_price'].dirty ||
                                              viewEditproductForm.controls['selling_price'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['selling_price'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Nombre de casier disponible
                    </small>
                    <input type="number" class="form-control" [attr.disabled]="true" formControlName="quantity"
                      placeholder="Quantité" required>
                    <div *ngIf="viewEditproductForm.controls['quantity'].invalid &&
                                              (viewEditproductForm.controls['quantity'].dirty ||
                                              viewEditproductForm.controls['quantity'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['quantity'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <div class="form-group">
                      <small class="form-text text-muted">Casier de boisson</small>
                      <select class="form-control" formControlName="drinkPack"
                        [attr.disabled]="disableEditField == true ? true : null" required>
                        <option value="" disabled selected>Selectionnez un casier</option>
                        <option *ngFor="let drinkP of drinkPacks" value={{drinkP.drink_pack_id}}>
                          {{drinkP.drink_pack_name}}</option>
                      </select>
                      <div *ngIf="productForm.controls['drinkPack'].invalid &&
                                          (productForm.controls['drinkPack'].dirty ||
                                          productForm.controls['drinkPack'].touched)" class="pt-0 alert alert-light">
                        <div *ngIf="productForm.controls['drinkPack'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-6">
                    <div class="form-group">
                      <small class="form-text text-muted">
                        Alerte de stock
                      </small>
                      <input type="number" class="form-control" [attr.disabled]="disableEditField == true ? true : null"
                        formControlName="stock_alert" placeholder="Alerte de stock" required>
                      <div *ngIf="viewEditproductForm.controls['stock_alert'].invalid &&
                                                                  (viewEditproductForm.controls['stock_alert'].dirty ||
                                                                  viewEditproductForm.controls['stock_alert'].touched)"
                        class="alert alert-light pt-0">
                        <div *ngIf="viewEditproductForm.controls['stock_alert'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div>
                  </div> -->
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Sélectionnez un dépôt
                    </small>
                    <select class="form-control" [attr.disabled]="true" formControlName="viewProductWarehouseIdSelect"
                      required>
                      <option value="" disabled selected>Sélectionnez un dépôt</option>
                      <option *ngFor="let warehouse of wareHouseList" value={{warehouse.warehouse_id}}>
                        {{warehouse.warehouse_name}}
                      </option>
                    </select>
                    <input type="text" class="form-control" *ngIf="0 !== 0" [attr.disabled]="true"
                      formControlName="warehouse" placeholder="Sélectionnez un dépôt">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Sous-catégorie
                    </small>
                    <select class="form-control" *ngIf="viewProductFormOnLoad === false"
                      [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="viewProductSubcategoryIdSelect" required>
                      <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                      <option *ngFor="let subCategory of viewProductSubcategories"
                        value={{subCategory.warehouse_subcategory_id}}>
                        {{subCategory.warehouse_subcategory_name}}</option>
                    </select>
                    <input type="text" class="form-control" *ngIf="viewProductFormOnLoad === true"
                      [attr.disabled]="disableEditField == true ? true : null" formControlName="subcategory_id"
                      placeholder="Sous-catégorie" required>
                    <div *ngIf="viewEditproductForm.controls['viewProductSubcategoryIdSelect'].invalid &&
                                              (viewEditproductForm.controls['viewProductSubcategoryIdSelect'].dirty ||
                                              viewEditproductForm.controls['viewProductSubcategoryIdSelect'].touched)"
                      class="alert alert-light pt-0">
                      <div *ngIf="viewEditproductForm.controls['viewProductSubcategoryIdSelect'].errors.required">
                        Ne devrait pas être vide
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Fournisseur
                    </small>
                    <select class="form-control" *ngIf="viewProductFormOnLoad === false"
                      [attr.disabled]="disableEditField == true ? true : null"
                      formControlName="viewProductSupplierIdSelect" required>
                      <option value="" disabled selected>Sélectionnez un fournisseur</option>
                      <option *ngFor="let supplier of suppliers" value={{supplier.supplier_id}}>
                        {{supplier.supplier_name}}</option>
                    </select>
                    <input type="text" class="form-control" *ngIf="viewProductFormOnLoad === true"
                      [attr.disabled]="disableEditField == true ? true : null" formControlName="supplier_id"
                      placeholder="Fournisseur">
                  </div>
                </div>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
            </button>
            <button type="button" [disabled]="!viewEditproductForm.valid" class="btn btn-primary btn-lg"
              *ngIf="!disableEditField" (click)='saveEditProductNameFromViewProductDetail()'>Sauvegarder
            </button>
            <button type="button" class="btn btn-primary btn-lg" *ngIf="disableEditField" (click)='enableEdit()'>Éditer
            </button>
          </div>
        </ng-template>

        <!----add quantity confirmation modal -->
        <ng-template #ConfirmUpdateProductStock let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form>
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir ajouter {{quantityAdded}} casier(s) à cet article </label>
                <h5>{{tempProductName}}</h5>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Non
            </button>
            <button type="button" class="btn btn-primary btn-lg" (click)='SaveNewStockQuantity()'>Oui
            </button>
          </div>
        </ng-template>

        <!----deduction quantity confirmation modal -->
        <ng-template #ConfirmDeductionProductStock let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form>
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir rétrancher {{quantityAdded}} casier(s) à cet article </label>
                <h5>{{tempProductName}}</h5>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Non
            </button>
            <button type="button" class="btn btn-primary btn-lg" (click)='SaveNewStockQuantity()'>Oui
            </button>
          </div>
        </ng-template>


        <!----warehouse product sale confirmation modal -->
        <ng-template #ConfirmWarehouseProductSale let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form>
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir vendre {{quantityToBeSoldToBranches}} casier(s) à {{tempBranchName}}
                </label>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Non
            </button>
            <button type="button" class="btn btn-primary btn-lg" (click)='SaveWarehouseSale()'>Oui
            </button>
          </div>
        </ng-template>

        <!----delete product modal -->
        <ng-template #deleteProduct let-modal>
          <div class="modal-header text-center">
            <h5 class="modal-title">Confirmation de suppression</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <form [formGroup]="deleteProductForm">
              <div class="form-group">
                <label>Êtes-vous sûr de vouloir supprimer cet article ?</label>
                <h5>{{tempProductName}}</h5>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
            </button>
            <button type="button" class="btn btn-primary btn-lg" (click)='yes()'>Confirmer
            </button>
          </div>
        </ng-template>

        <!----success product modal -->
        <ng-template #successSaveProduct let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{successModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>


        <!----failure product modal -->
        <ng-template #failureSaveProduct let-modal>
          <div class="modal-header text-center border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-0 text-center">
            <div class="row">
              <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
              </div>
            </div>
            <label class="mt-3"> {{failureModalMessage}} </label>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>
<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>