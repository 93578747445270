import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { Branch } from 'src/app/interface/branch';
import { UpdateBranch } from 'src/app/interface/updateBranch';
import { Warehouse } from 'src/app/models/warehouse/Warehouse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
 
  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {}

  // getBranch(companyId): Observable<Branch[]> {
  //   return this.http.post<Branch[]>(this.apiURL + '/branch/getall?intCompanyID=' + companyId, null);
  // }

  getWarehousesByCompany(companyId: number): Observable<Warehouse[]> {
    return this.http.post<Warehouse[]>(this.apiURL + '/warehouse/getall?c=' + companyId, null);
  }

  deleteWarehouse(warehouse: Warehouse): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/warehouse/delete?w=' + warehouse.warehouse_id, null);
  }

  doesEmployeeManagesAWarehouse(employee_id: number, companyId: number): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/warehouse/haswarehouse?e=' + employee_id + '&c=' + companyId, null);
  }

  searchWarehouse(companyId: number, warehouseName: string): Observable<Warehouse []> {

    if ((warehouseName !== null) && (warehouseName !== '') && (warehouseName !== undefined)) {
    return this.http.post<Warehouse []>(this.apiURL + '/warehouse/search?c=' + companyId + '&w=' + warehouseName, null);
    } else{
      return this.http.post<Warehouse[]>(this.apiURL + '/warehouse/getall?c=' + companyId, null);
    }
  }

  saveWarehouse(warehouse): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/warehouse/save', warehouse);
  }

  saveEditWarehouse(warehouse): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/warehouse/update', warehouse);
  }
}
