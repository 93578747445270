<!-- Printing div -->
<div style="display: none;" id="print-section-qr-code">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card px-2">
          <div class="card-body">
            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-6 pl-0">
                <h6 class="mt-5 mb-2"><b>{{printCompanyName}} - {{printEstablishment}}</b></h6>
                <p>{{printCompanyAddress}},
                  <br>{{printCompanyCity}},
                  <br>{{printCompanyCountry}}.
                </p>
              </div>
              <div class="col-lg-6 pl-0 text-right">
                <p class="mb-0 mt-5">Date d'impression : {{printDate}}</p>
              </div>
            </div>
          </div>

          <div class="container-fluid justify-content-center w-100 table-print mt-3">
            <div class="row">
              <div class="col-md-6 text-center border border-1">
                <div class="p-5">
                  <!-- <img id="logo" src="assets/branch/prestigeLogo.png" width="100px" alt="logo"> -->
                  <qrcode [qrdata]="qrCodeValue" [width]="300" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"
                    [elementType]="'img'"></qrcode>
                </div>
              </div>
              <div class="col-md-6 text-center border border-1">
                <div class="p-5">
                  <!-- <img id="logo" src="assets/branch/prestigeLogo.png" width="100px" alt="logo"> -->
                  <qrcode [qrdata]="qrCodeValue" [width]="300" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"
                    [elementType]="'img'"></qrcode>
                </div>
              </div>
              <div class="col-md-6 text-center border border-1">
                <div class="p-5">
                  <!-- <img id="logo" src="assets/branch/prestigeLogo.png" width="100px" alt="logo"> -->
                  <qrcode [qrdata]="qrCodeValue" [width]="300" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"
                    [elementType]="'img'"></qrcode>
                </div>
              </div>
              <div class="col-md-6 text-center border border-1">
                <div class="p-5">
                  <!-- <img id="logo" src="assets/branch/prestigeLogo.png" width="100px" alt="logo"> -->
                  <qrcode [qrdata]="qrCodeValue" [width]="300" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"
                    [elementType]="'img'"></qrcode>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="panel mt-5">
    <div class="tab-content">
      <div class="row mt-1 mb-3">
        <div class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 text-center">
          <div class="row mb-md-5 my-3">
            <div class="col-md-12 col-sm-12 col-12 text-center">
              QR Code pour l'etablissement {{branchNameToBeUsed}}
            </div>
          </div>
          <div>
            <qrcode [qrdata]="qrCodeValue" [width]="300" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"
              [elementType]="'img'"></qrcode>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
          <form [formGroup]="selectBranchForm">
            <div class="form-group">
              <small class="form-text text-muted">Sélectionnez une établissement</small>
              <select #selectBranch class="form-control" (change)="getBranchQRCode($event)"
                formControlName="selectBranch">
                <option *ngFor="let branch of branchList" value={{branch.branch_id}}>{{branch.branch_name}}</option>
              </select>
            </div>

            <div class="form-group mt-3">
              <div class="row my-3">
                <div class="col-md-12">
                  <button printTitle="{{printCompanyName}}_Inventaire" [useExistingCss]="true"
                    printSectionId="print-section-qr-code" ngxPrint type="button" class="btn btn-primary w-100">
                    <i class="fas fa-print"></i> &nbsp;Imprimer
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>