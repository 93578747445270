import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import * as $ from 'jquery';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { WarehouseService } from 'src/app/services/warehouse/warehouse.service';
import { Warehouse } from 'src/app/models/warehouse/Warehouse';
import { WarehouseProductService } from 'src/app/services/warehouseProduct/warehouse-product.service';
import { TransactionHistory } from 'src/app/models/warehouse/TransactionHistory';

@Component({
  selector: 'app-warehouse-sale-history',
  templateUrl: './warehouse-sale-history.component.html',
  styleUrls: ['./warehouse-sale-history.component.scss']
})
export class WarehouseSaleHistoryComponent implements OnInit {

  searchForm: FormGroup;
  // searchFormStockUpdateEntry: FormGroup;
  // searchFormStockUpdateOutgoing: FormGroup;

  filterFormWarehouseTransactionHistory: FormGroup;

  dataSourceWarehouseTransaction: MatTableDataSource<TransactionHistory>;
  // dataSourceOrderItems: MatTableDataSource<OrderItem>;

  @ViewChild('paginatorWarehouseTransactionReport') paginatorWarehouseTransactionReport: MatPaginator;
  @ViewChild('sortWarehouseTransactionReport') sortWarehouseTransactionReport: MatSort;

  // @ViewChild('paginatorOrderItem') paginatorOrderItem: MatPaginator;
  // @ViewChild('sortOrderItem') sortOrderItem: MatSort;

  totalRevenue: number;
  currencyDefault: string;

  // selectedWarehouse: string;
  tableHasDataReport: boolean;

  // tableHasOrderItemData: boolean;

  displayedColumnsWarehouseTransaction = [
    'BranchTo',
    'SoldBy',
    'DateTime',
    'Item',
    'Quantity',
    'Amount paid',
  ];
  wareHouseList: Warehouse[];
  warehouse: Warehouse;
  warehouseIdToBeUsed: number;
  warehouseCurrency: string;
  userLoggedIn: UserLoggedIn;
  spin: boolean;

  warehouseProductTransactionReports: TransactionHistory[];
  // productDTOs: ProductDTO[];

  // branches: Branch[];
  // employeeWaiters: Employee[];

  // startDateSaleReport: string;
  // endDateSaleReport: string;

  startDateWarehouseTransactionHistory: string;
  endDateWarehouseTransactionHistory: string;

  @Input() id: string;

  pageTransactionHistoryReport = 1;

  closeResult: string;

  @ViewChild('viewOrderItem', { static: true }) viewOrderItem: TemplateRef<any>;

  isWaiterDropdownEnabled: boolean;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private warehouseProductService: WarehouseProductService, private branchService: BranchService, private employeeService: EmployeeService,
    private formBuilder: FormBuilder, private warehouseService: WarehouseService) {

    this.warehouseProductTransactionReports = [];
    // this.branches = [];
  }

  ngOnInit(): void {
    this.createForms();
    this.userLoggedIn = this.authenticationService.currentUserValue;

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_WAREHOUSE)) {
      this.spin = true;

      this.getWarehouses();

      var date = new Date();

      this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

      this.currencyDefault = 'FC';
      this.totalRevenue = 0;

    } else {
      this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
    }
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  resetSummaryInfo(): void {
    this.totalRevenue = 0;
  }

  createForms() {

    this.filterFormWarehouseTransactionHistory = this.formBuilder.group({
      dtTransactionHistoryFrom: new FormControl(null, Validators.required),
      dtTransactionHistoryTo: new FormControl(null, Validators.required),
      selectWarehouse: new FormControl(null),
    });
  }

  getWarehouses(): void {
    // this.selectForm.reset();
    this.warehouseService.getWarehousesByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.wareHouseList = res;
      console.log(this.wareHouseList);
      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.warehouseIdToBeUsed = this.wareHouseList[0].warehouse_id;
        this.warehouseCurrency = this.wareHouseList[0].warehouse_currency;

      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_WAREHOUSE)) {

        this.wareHouseList = this.wareHouseList.filter(wr => wr.employee_manager_id === this.authenticationService.currentUserValue.employeeID);

        if (this.wareHouseList.length > 0) {
          this.warehouseIdToBeUsed = this.wareHouseList[0].warehouse_id;
          this.warehouseCurrency = this.wareHouseList[0].warehouse_currency;
        } else {
          this.warehouseIdToBeUsed = 0;
          this.warehouseCurrency = '';
        }

      } else {
        this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
      }

      this.filterFormWarehouseTransactionHistory.controls.selectWarehouse.setValue(this.warehouseIdToBeUsed);

      this.loadWarehouseSaleTransaction(this.warehouseIdToBeUsed);
      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  searchReportPastOrders(branchIdPastOrders) {
    this.spin = true;

    var date = new Date();

    if (this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value !== null) {
      this.startDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateWarehouseTransactionHistory = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateWarehouseTransactionHistory = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadReportOfWarehouseTransaction(branchIdPastOrders, this.startDateWarehouseTransactionHistory, this.endDateWarehouseTransactionHistory);
  }

  loadReportOfWarehouseTransaction(warehouseId: number, fromDatePastOrders: string, toDatePastOrders: string) {
    this.spin = true;

    this.warehouseProductService.getTransactionSaleHistoryReport(this.authenticationService.currentUserValue.companyID,
      warehouseId, fromDatePastOrders, toDatePastOrders).subscribe(res => {
        this.warehouseProductTransactionReports = res;

        if (this.warehouseProductTransactionReports.length > 0) {
          this.tableHasDataReport = true;
          this.dataSourceWarehouseTransaction = new MatTableDataSource(this.warehouseProductTransactionReports);
          this.dataSourceWarehouseTransaction.paginator = this.paginatorWarehouseTransactionReport;
          this.dataSourceWarehouseTransaction.sort = this.sortWarehouseTransactionReport;

          this.warehouseProductTransactionReports.forEach(a => this.totalRevenue += a.total_amount);

        } else {
          this.tableHasDataReport = false;
        }
        this.spin = false;

      }, error => {
        this.tableHasDataReport = false;
        this.dataSourceWarehouseTransaction = new MatTableDataSource([]);
        this.dataSourceWarehouseTransaction.paginator = this.paginatorWarehouseTransactionReport;
        this.dataSourceWarehouseTransaction.sort = this.sortWarehouseTransactionReport;
        this.spin = false;
      });
  }

  filterWarehouseTransactionHistory(branchIdPastOrders) {
    this.startDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadReportOfWarehouseTransaction(branchIdPastOrders, this.startDateWarehouseTransactionHistory, this.endDateWarehouseTransactionHistory);
  }

  loadWarehouseSaleTransaction(warehouseId) {

    var date = new Date();

    if (this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value !== null) {
      this.startDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateWarehouseTransactionHistory = moment(this.filterFormWarehouseTransactionHistory.controls['dtTransactionHistoryTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateWarehouseTransactionHistory = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateWarehouseTransactionHistory = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();

    }

    this.loadReportOfWarehouseTransaction(warehouseId, this.startDateWarehouseTransactionHistory, this.endDateWarehouseTransactionHistory);
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
