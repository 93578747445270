import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {Company} from '../models/company/company';
import {Supplier} from '../interface/supplier';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
   
  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {
  }

  getSupplier(companyId: number): Observable<Supplier []> {
    return this.http.post<Supplier []>(this.apiURL + '/supplier/getall?companyID='
      + companyId, null);
  }
  getSupplierTransactions(isWarehouseSelected:number, companyID: number, dateFrom: string, dateTo: string, supplierID: number): Observable<Supplier []> {
    return this.http.post<Supplier[]>(environment.apiBase
      .concat('/supplier/transaction/getall?isWarehouseSelected=').concat(isWarehouseSelected.toString())
      .concat('&df=').concat(dateFrom)
      .concat('&dt=').concat(dateTo)
      .concat('&s=').concat(supplierID.toString())
      .concat('&c=').concat(companyID.toString()),null);
  }
  // http://elvis310791-002-site2.itempurl.com/api/v1/supplier/transaction/getall?isWarehouseSelected=1&df=2021-01-13&dt=2021-10-13&s=2&c=2
  
  searchSupplier(companyId: number, name: string): Observable<Supplier []> {

    if ((name !== null) && (name !== '') && (name !== undefined)) {
      return this.http.post<Supplier []>(this.apiURL + '/supplier/searchsupplier?companyID='
        + companyId + '&supplierName=' + name, null);
    } else {
      return this.http.post<Supplier []>(this.apiURL + '/supplier/getall?companyID='
        + companyId, null);
    }
  }

  addSupplier(supplier: Supplier): Observable<boolean> {
    console.log('1 ', supplier);
    return this.http.post<boolean>(this.apiURL + '/supplier/save', supplier);
  }

  editSupplier(supplier: Supplier): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/supplier/update', supplier);
  }

  deleteSupplier(supplier: Supplier): Observable<boolean> {
    return this.http.post<boolean>(this.apiURL + '/supplier/delete?intSupplierID=' +
      supplier.supplier_id, supplier);
  }

  getCompany(companyId: number): Observable<Company> {
    return this.http.post<Company>(this.apiURL + '/company/getone?companyID=' + companyId, null);
  }

  getCompanies(): Observable<Company> {
    return this.http.post<Company>(this.apiURL + '/company/getallcompanies', null);
  }
}
