import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Branch } from 'src/app/interface/branch';
import { Category } from 'src/app/interface/category';
import { Employee } from 'src/app/interface/createEmployee';
import { CategoriesService } from 'src/app/services/categories.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { FlowService } from 'src/app/services/flow.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Constants } from '../../common/Constants';
import { DataManagerService } from '../../services/data-management/data-manager.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-employee-landing',
  templateUrl: './employee-landing.component.html',
  styleUrls: ['./employee-landing.component.scss']
})
export class EmployeeLandingComponent implements OnInit {
  spin: boolean;
  employees: Employee[];
  employee: boolean;
  genders: any;
  branches: Branch[];
  employeeToDelete: string;
  tempEmployee: Employee;
  employeeFormDelete: FormGroup;
  employeeFormEdit: FormGroup;
  categories: Category;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  // employeeSearch: FormGroup;
  searchEmployeeName: FormGroup;
  disable: true;
  employeeForm: FormGroup;

  formSelectBranch: FormGroup;

  @ViewChild('successModal', { static: true }) successModal: TemplateRef<any>;
  @ViewChild('failureModal', { static: true }) failureModal: TemplateRef<any>;
  @ViewChild('deleteEmployee', { static: true }) deleteEmployeeModal: TemplateRef<any>;

  closeResult: string;
  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  displayedColumnsEmployee = [
    'Firstname',
    'Surname',
    'cellNumber',
    'Position',
    'Actions'
  ];

  dataSourceEmployee: MatTableDataSource<Employee>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tableHasData: boolean;

  selectedBranchName: string;

  page = 1;

  constructor(private router: Router, private employeeService: EmployeeService,
    private categoriesService: CategoriesService, private fb: FormBuilder,
    private flowService: FlowService, private authenticationService: AuthenticationService,
    private branchService: BranchService, private modalService: NgbModal, private dataManagerService: DataManagerService) {
  }

  ngOnInit(): void {
    this.spin = true;
    this.employee = true;
    this.genders = ['Male', 'Female'];
    this.createForm();

    this.getFranchise();
    this.searchEmployeeName.controls['employee_surname'].valueChanges.subscribe(val=>{
      if(val ===''){
        this.searchEmployee(this.branchIdToBeUsed);
      }
    });
  }

  createForm(): void {
    this.searchEmployeeName = this.fb.group({
      employee_surname: [''],
      company_id: [''],
      employee_id: [''],

    });
    this.employeeFormDelete = this.fb.group({
      employee_id: ['']
    });
    this.employeeFormEdit = this.fb.group({
      employeeNameEdit: ['']
    });

    this.formSelectBranch = this.fb.group({
      selectBranch: ['']
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  getBranchEmployees(event): void {
    const branchId = event.target.value;
    this.getEmployees(branchId);
  }

  fistLoadEmployees(branchId: number): void {
    this.spin = true;

    this.employeeService.getEmployees(this.authenticationService.currentUserValue.companyID,
      branchId).subscribe(res => {
        this.employees = res;

        // this.selectedBranchName = this.branches.find(branch1 => branch1.branch_id = branchId).branch_name;


        if (this.employees.length > 0) {
          this.tableHasData = true;
          this.dataSourceEmployee = new MatTableDataSource(this.employees);
          this.dataSourceEmployee.paginator = this.paginator;
          this.dataSourceEmployee.sort = this.sort;
        } else {
          this.tableHasData = false;
        }
        this.spin = false;

      }, err => {
        this.tableHasData = false;
        this.dataSourceEmployee = new MatTableDataSource([]);
        this.spin = false;

      });
  }

  addEmployee(): void {
    this.router.navigate(['/addemployees', 'add']);
  }

  getEmployees(branchId: number): void {
    this.spin = true;

    this.employeeService.getEmployees(this.authenticationService.currentUserValue.companyID, branchId).subscribe(res => {
      this.employees = res;

      if (this.employees.length > 0) {
        this.tableHasData = true;
        this.dataSourceEmployee = new MatTableDataSource(this.employees);
        this.dataSourceEmployee.paginator = this.paginator;
        this.dataSourceEmployee.sort = this.sort;
      } else {
        this.tableHasData = false;
        this.dataSourceEmployee = new MatTableDataSource([]);
        this.dataSourceEmployee.paginator = this.paginator;
        this.dataSourceEmployee.sort = this.sort;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceEmployee = new MatTableDataSource([]);
      this.dataSourceEmployee.paginator = this.paginator;
      this.dataSourceEmployee.sort = this.sort;
      this.spin = false;

    });
  }

  tempDelete(employee): void {
    this.open(this.deleteEmployeeModal);
    this.tempEmployee = employee;
    this.employeeToDelete = this.tempEmployee.employee_firstname;

  }

  deleteEmployee1(): void {
    this.employeeService.deleteEmployee(this.tempEmployee.employee_id).subscribe(res => {

      if (res) {
        this.spin = false;
        this.modalService.dismissAll();
        this.showSuccessModal('Votre employé a été supprimé avec succès');


        if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
          (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
            this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        } else {
          this.branchIdToBeUsed = this.tempEmployee.branch_id;
        }
        this.getEmployees(this.branchIdToBeUsed);
      } else {
        this.spin = false;
        this.showFailureModal('La suppression de l\'employé a échoué, réessayez plus tard');
      }

    }, error => {
      this.showFailureModal('La suppression de l\'employé a échoué, réessayez plus tard');
      this.spin = false;
    });
  }

  searchEmployee(branchId): void {
    this.spin = true;
    this.employees = null;

    const name = this.searchEmployeeName.controls.employee_surname.value;

    if ((this.authenticationService.currentUserValue.role !== Constants.ROLE_ADMINISTRATOR) &&
      (this.authenticationService.currentUserValue.role !== Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
      this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
    } else {
      this.branchIdToBeUsed = branchId;
    }

    this.employeeService.searchEmployee(this.authenticationService.currentUserValue.companyID, this.branchIdToBeUsed, name).subscribe(res => {
      this.employees = res;

      if (this.employees.length > 0) {
        this.tableHasData = true;

        // this.selectedBranchName = this.branches.find(branch1 => branch1.branch_id = this.branchIdToBeUsed).branch_name;

        this.dataSourceEmployee = new MatTableDataSource(this.employees);
        this.dataSourceEmployee.paginator = this.paginator;
        this.dataSourceEmployee.sort = this.sort;
      } else {
        this.tableHasData = false;
        this.dataSourceEmployee = new MatTableDataSource([]);
        this.dataSourceEmployee.paginator = this.paginator;
        this.dataSourceEmployee.sort = this.sort;
      }
      this.spin = false;

    }, err => {
      this.tableHasData = false;
      this.dataSourceEmployee = new MatTableDataSource([]);
      this.dataSourceEmployee.paginator = this.paginator;
      this.dataSourceEmployee.sort = this.sort;
      this.spin = false;

    });
  }

  editEmployee(employee: Employee): void {
    this.dataManagerService.setEmployees(employee);

    this.flowService.isUpdating = true;
    this.dataManagerService.setEmployeeViewMode('Edit');
    this.router.navigate(['/view-profile']);


  }

  viewEmployee(employee): void {
    this.dataManagerService.setEmployees(employee);
    this.dataManagerService.setEmployeeViewMode('View');
    this.router.navigate(['/view-profile']);
    // this.router.navigate(['/addemployees', 'View']);
  }

  getFranchise(): void {

    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.branchIdToBeUsed = this.branches[0].branch_id;
        this.formSelectBranch.controls.selectBranch.enable();
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_WAREHOUSE)) {

        this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
        this.formSelectBranch.controls.selectBranch.disable();
      } 
      this.formSelectBranch.controls.selectBranch.setValue(this.branchIdToBeUsed);
      this.fistLoadEmployees(this.branchIdToBeUsed);
    }, error => {
      this.spin = false;
    });
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event: any) {
    this.page = event;
    window.scroll(0, 0);
  }

}
