<!-- Printing div -->
<div style="display: none;" id="print-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="card px-2">
          <div class="card-body">

            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-6 pl-0">
                <h6 class="mt-5 mb-2"><b>{{printCompanyName}} - {{printEstablishment}}</b></h6>
                <p>{{printCompanyAddress}},
                  <br>{{printCompanyCity}},
                  <br>{{printCompanyCountry}}.
                </p>
              </div>
              <div class="col-lg-6 pl-0 text-right">
                <p class="mb-0 mt-5">Date d'impression : {{printDate}}</p>
              </div>
            </div>
            <div class="container-fluid d-flex justify-content-between">
              <div class="col-lg-6 pl-0">
                <p class="mt-3">Rapport de vente du <b>{{printDateFrom}}</b> au <b>{{printDateTo}}</b></p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="card-body">
                  <h5 class="tn-blue-color mb-0 font-15px">Revenue sur la boisson</h5>
                  <h5 class="card-text tn-blue-color"> {{currencyDefault}} {{printDrinkTotalRevenu | number}}</h5>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="card-body">
                  <h5 class="tn-blue-color mb-0 font-15px">Revenue sur la nourriture</h5>
                  <h5 class="card-text tn-blue-color"> {{currencyDefault}} {{printFoodTotalRevenu | number}}</h5>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="card-body">
                  <h5 class="tn-blue-color mb-0 font-15px">Revenue sur le diver</h5>
                  <h5 class="card-text tn-blue-color"> {{currencyDefault}} {{printOtherTotalRevenu | number}}</h5>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="card-body">
                  <h5 class="tn-blue-color mb-0 font-15px">Revenue total sans reduction</h5>
                  <h3 class="card-text tn-blue-color"> {{currencyDefault}} {{printTotalRevenu | number}}</h3>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <div class="card-body">
                  <h5 class="tn-blue-color mb-0 font-15px">Revenue total avec reduction</h5>
                  <h3 class="card-text tn-blue-color"> {{currencyDefault}} {{totalRevenue | number}}</h3>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid justify-content-center w-100 table-print mt-3">
            <div class="tn-table-container">
              <table class="table">
                  <tr class="text-secondary">
                    <th class="text-center">Article</th>
                    <th class="text-center">Prix Unitaire de vente</th>
                    <th class="text-center">Quantité vendue</th>
                    <th class="text-center">Revenu total</th>
                  </tr>
                <tbody>
                  <tr *ngFor="let item of saleListToPrint" class="text-right">
                    <td class="text-center">{{item.product_name}}</td>
                    <td class="text-center">{{currencyDefault}} {{item.selling_cost}}</td>
                    <td class="text-center">
                      <div class="col-12" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name !== 'Shot')">
                        {{item.quantity_sold}} bouteille(s)
                      </div>
                      <div class="col-12" *ngIf="(item.category_name === 'Boisson') && (item.subcategory_name === 'Shot')">
                        {{item.quantity_sold}} shot(s)
                      </div>
                      <div class="col-12" *ngIf="item.category_name === 'Nourriture'">
                        {{item.quantity_sold}} plat(s)
                      </div>
                      <div class="col-12"
                        *ngIf="(item.category_name !== 'Nourriture') && (item.category_name !== 'Boisson')">
                        {{item.quantity_sold}}
                      </div>
                    </td>
                    <td class="text-center">FC {{item.quantity_sold * item.selling_cost}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex" id="wrapper">
  <app-side-navigation class="side-bar"></app-side-navigation>
  <div id="page-content-wrapper">
    <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav>

    <div class="container-fluid">
      <div class="panel mt-5">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="sales-tab" data-toggle="tab" href="#sales" role="tab" aria-controls="sales"
              aria-selected="true">Ventes</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="past_orders-tab" data-toggle="tab" href="#past_orders" role="tab"
              aria-controls="past_orders" aria-selected="false">Commandes passées</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="stock_update-tab" data-toggle="tab" href="#stock_update" role="tab"
              aria-controls="stock_update" aria-selected="false">Mise à jour des stocks</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="employee_performance-tab" data-toggle="tab" href="#employee_performance" role="tab"
              aria-controls="employee_performance" aria-selected="false">Performance des employés</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="low-stock-product-tab" data-toggle="tab" href="#low_stock_product" role="tab"
              aria-controls="low_stock_product" aria-selected="false">Article à faible stock</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="profit-tab" data-toggle="tab" href="#profit" role="tab" aria-controls="profit"
              aria-selected="false">Bénéfices</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="gift-orders-tab" data-toggle="tab" href="#gift_orders" role="tab" aria-controls="gift-orders"
              aria-selected="false">Commande offerte</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="extra-tab" data-toggle="tab" href="#extra" role="tab" aria-controls="extra"
              aria-selected="false">Article spécial</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="sales" role="tabpanel" aria-labelledby="sales-tab">

            <div class="row mt-4">
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card">
                  <div class="card-body">
                    <h6 class="card-title">Revenu total</h6>
                    <p class="card-text"> {{currencyDefault}} {{totalRevenue | number}}</p>
                    <p class="card-text"> {{totalNumberOfOrders_SaleReport}} ventes</p>
                    <h6 class="tn-red-color">NB: Ce montant inclus les réductions et gratuités, il pourrait etre different (moins) du montant affiché dans la section bénéfice</h6>
                  </div>
                </div>
              </div>
            </div>

            <form [formGroup]="filterFormSaleReport">
              <div class="row mt-4">
                <div
                  class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                  <div class="card mb-3 d-md-none" *ngFor="let item1 of saleReports | paginate: { itemsPerPage: 10, currentPage: pageSaleReport, id: 'first' }">
                    <div class="card-body">
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Établissement
                        </div>
                        <div class="col-6">
                          {{item1.branch_name}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Gérant
                        </div>
                        <div class="col-6">
                          {{item1.manager_fullname}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Nombre de vente
                        </div>
                        <div class="col-6">
                          {{item1.number_of_sales}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Revenu ({{currencyDefault}})
                        </div>
                        <div class="col-6">
                          {{currencyDefault}}
                          {{item1.revenue_branch_currency | number}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Revenu (USD)
                        </div>
                        <div class="col-6">
                          USD
                          {{item1.revenue_dollars | number}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 card-table-header">
                          Revenu total sur les ventes
                        </div>
                        <div class="col-6">
                          {{currencyDefault}}
                          {{item1.total_revenue | number}}
                        </div>
                      </div>

                    </div>
                  </div>
                  <pagination-controls *ngIf="tableHasDataSaleReport === true" class="mb-3 text-center d-md-none"
                    (pageChange)="onMobileTableChangePage($event)" id="first" previousLabel="Précédent" nextLabel="Suivant">
                  </pagination-controls>


                  <mat-table [dataSource]="dataSourceSaleReport" class="d-none d-md-block" matSort>
                    <ng-container matColumnDef="Branch">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Établissement
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{row.branch_name}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Manager">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Gérant
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{row.manager_fullname}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Number of Sale">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nombre de vente
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{row.number_of_sales}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="RevenueBranchCurrency">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Revenu ({{currencyDefault}})
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{currencyDefault}}
                        {{row.revenue_branch_currency | number}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="RevenueDollars">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Revenu (USD)
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        USD
                        {{row.revenue_dollars | number}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="TotalRevenue">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Revenu total ({{currencyDefault}})
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        {{currencyDefault}}
                        {{row.total_revenue | number}}
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsSaleReport">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsSaleReport">
                    </mat-row>
                  </mat-table>
                  <div class="mt-3" *ngIf="tableHasDataSaleReport === false">Aucune vente trouvé
                  </div>
                  <mat-paginator class="d-none d-md-block mb-4" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <small class="form-text text-muted">Sélectionnez un établissement</small>
                        <select #selectedBranch class="form-control" (change)="loadSaleReport($event.target.value)" formControlName="selectBranch">
                          <option value="0" selected>Tous les établissement</option>
                          <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                            {{branch.branch_name}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <small class="form-text text-muted">Datant de</small>
                      <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtSaleReportFrom"
                        [owlDateTime]="dtSaleReportFrom" name="dtSaleReportFrom" formControlName="dtSaleReportFrom">
                      <owl-date-time #dtSaleReportFrom></owl-date-time>
                    </div>

                    <div class="col-12">
                      <small class="form-text text-muted">à</small>
                      <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtSaleReportTo"
                        [owlDateTime]="dtSaleReportTo" name="dtSaleReportTo" formControlName="dtSaleReportTo">
                      <owl-date-time #dtSaleReportTo></owl-date-time>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-md-12">
                      <button type="button" class="btn btn-primary w-100"
                        (click)="filterSaleReport(selectedBranch.value)">
                        <i class="fas fa-filter"></i> &nbsp;Filtre
                      </button>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-md-12">
                      <button  [useExistingCss]="true"
                        printSectionId="print-section" ngxPrint type="button" class="btn btn-primary w-100">
                        <i class="fas fa-print"></i> &nbsp;Imprimer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="tab-pane fade" id="past_orders" role="tabpanel" aria-labelledby="past_orders-tab">
            <app-report-past-order></app-report-past-order>
          </div>
          <div class="tab-pane fade" id="stock_update" role="tabpanel" aria-labelledby="stock_update-tab">
           <app-report-stock-update></app-report-stock-update>
          </div>
          <div class="tab-pane fade" id="employee_performance" role="tabpanel" aria-labelledby="employee_performance-tab">
            <app-report-employee-performance></app-report-employee-performance>
          </div>
          <div class="tab-pane fade" id="low_stock_product" role="tabpanel" aria-labelledby="low-stock-product-tab">
            <app-report-low-stock></app-report-low-stock>
          </div>
          <div class="tab-pane fade" id="profit" role="tabpanel" aria-labelledby="profit-tab">
            <app-report-profit></app-report-profit>
          </div>
          <div class="tab-pane fade" id="gift_orders" role="tabpanel" aria-labelledby="gift-orders-tab">
            <app-gift-orders></app-gift-orders>
          </div>
          <div class="tab-pane fade" id="extra" role="tabpanel" aria-labelledby="extra-tab">
            <app-report-extra></app-report-extra>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>