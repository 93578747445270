import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { MobileCategory } from 'src/app/models/product/MobileCategory';

@Injectable({
  providedIn: 'root'
})
export class BranchMenuService {

  private readonly apiURL = environment.apiBase;

  constructor(private http: HttpClient) {}

  getAllCategoriesAndSubcategoriesAndProductsByBranch(branchID: number): Observable<MobileCategory[]> {
    return this.http.post<MobileCategory[]>(this.apiURL + '/category/getAllCategoryAndSubcategoryAndProducts?branchID=' + branchID, null);
  }
}
