import {HttpClient} from '@angular/common/http';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Branch} from 'src/app/interface/branch';
import {Employee} from 'src/app/interface/createEmployee';
import {Role} from 'src/app/interface/roles';
import {CategoriesService} from 'src/app/services/categories.service';
import {EmployeeService} from 'src/app/services/employee.service';
import {FlowService} from 'src/app/services/flow.service';
import {RoleService} from 'src/app/services/role.service';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Constants} from '../../common/Constants';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataManagerService} from '../../services/data-management/data-manager.service';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit {

  employeeForm: FormGroup;
  employees: Employee[];
  spin: boolean;
  employee: Employee;
  branches: Branch;
  roles: Role[];
  allRoles: Role[];
  saveNewPasswordForm: FormGroup;
  showChangePasswordForm = true;

  successModalMessage: string;
  failureModalMessage: string;


  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;
  @ViewChild('failureModal', {static: true}) failureModal: TemplateRef<any>;

  closeResult: string;

  isEnabled: boolean;

  constructor(private httpClient: HttpClient, private fb: FormBuilder, private employeeService: EmployeeService,
              private router: Router, private categoriesService: CategoriesService, private roleService: RoleService,
              private flowService: FlowService, private route: ActivatedRoute,
              private authenticationService: AuthenticationService, private modalService: NgbModal,
              private dataManagerService: DataManagerService) {
  }

  ngOnInit(): void {

    this.createForm();

    this.spin = true;

    this.getAllRoles();
    this.getFranchise();

    var date = new Date();

    this.employeeForm.controls.date_hired.setValue(new Date(date.getFullYear(), date.getMonth(), 1));

    this.employeeForm.disable();
    this.isEnabled = false;

    if ((this.dataManagerService.getEmployees() !== null) && (this.dataManagerService.getEmployees() !== undefined)) {
      this.employee = this.dataManagerService.getEmployees();
      this.getEmployees();
      this.showChangePasswordForm = false;
    } else {
      this.showChangePasswordForm = true;
      this.getCurrentEmployeesProfile(this.authenticationService.currentUserValue.employeeID, this.authenticationService.currentUserValue.branchID);
    }
  }

  createForm(): void {
    this.employeeForm = this.fb.group({
      staff_id: [''],
      employee_id: [''],
      employee_id_number: [''],
      employee_firstname: ['', Validators.required],
      employee_surname: [''],
      gender: ['', Validators.required],
      date_of_birth: [''],
      cell_number: [''],
      email: [''],
      address: [''],
      tax_number: [''],
      date_hired: ['', Validators.required],
      branch_id: ['', Validators.required],
      role_id: ['', Validators.required],
    });
    this.saveNewPasswordForm = this.fb.group({
      oldPassword: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      password2: new FormControl(null, [Validators.required, Validators.minLength(8)])
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  private getCurrentEmployeesProfile(employeeID: number, branchID: number): void {

    this.spin = true;
    this.employeeService.getEmployeeByBranchIdAndStaffKey(branchID, employeeID)
      .subscribe(res => {

        this.employee = res;

        this.employeeForm.controls.staff_id.setValue(this.employee.staff_id);
        this.employeeForm.controls.employee_firstname.setValue(this.employee.employee_firstname);
        this.employeeForm.controls.employee_surname.setValue(this.employee.employee_surname);
        this.employeeForm.controls.employee_id_number.setValue(this.employee.employee_id_number);
        this.employeeForm.controls.gender.setValue(this.employee.gender);
        this.employeeForm.controls.cell_number.setValue(this.employee.cell_number);
        this.employeeForm.controls.email.setValue(this.employee.email);
        this.employeeForm.controls.address.setValue(this.employee.address);
        this.employeeForm.controls.date_hired.setValue(moment(this.employee.date_hired).format('YYYY-MM-DD').toString());
        this.employeeForm.controls.branch_id.setValue(this.employee.branch_id);
        this.employeeForm.controls.role_id.setValue(this.employee.role_id);

        this.spin = false;
      }, error => {
        this.spin = false;
      });
  }

  getEmployees(): void {
    this.employeeForm.controls.staff_id.setValue(this.employee.staff_id);
    this.employeeForm.controls.employee_firstname.setValue(this.employee.employee_firstname);
    this.employeeForm.controls.employee_surname.setValue(this.employee.employee_surname);
    this.employeeForm.controls.employee_id_number.setValue(this.employee.employee_id_number);
    this.employeeForm.controls.gender.setValue(this.employee.gender);
    this.employeeForm.controls.cell_number.setValue(this.employee.cell_number);
    this.employeeForm.controls.email.setValue(this.employee.email);
    this.employeeForm.controls.address.setValue(this.employee.address);
    this.employeeForm.controls.date_hired.setValue(moment(this.employee.date_hired).format('YYYY-MM-DD').toString());
    this.employeeForm.controls.branch_id.setValue(this.employee.branch_id);
    this.employeeForm.controls.role_id.setValue(this.employee.role_id);
  }

  getAllRoles(): void {
    this.roleService.getRoles().subscribe(res => {
      this.allRoles = res;

      this.roles = this.allRoles.filter(role1 => role1.role_name !== Constants.ROLE_ADMIN_MASTER);
      this.spin = false;

      console.log(JSON.stringify(this.roles));

    }, error => {
      this.spin = false;
    });
  }

  getFranchise(): void {
    this.categoriesService.getBranch(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;
    }, error => {
      this.spin = false;
    });
  }

  Cancel(): void {
    if (this.isEnabled) {
      this.isEnabled = false;
      this.employeeForm.disable();
    } else {
      this.router.navigate([Constants.ADMIN_DASHBOARD_ROUTE]);
    }
  }

  enableField(): void {
    this.isEnabled = true;
    this.employeeForm.enable();

    this.employeeForm.controls.date_hired.disable();
    this.employeeForm.controls.staff_id.disable();

    if ((this.dataManagerService.getEmployees() !== null) && (this.dataManagerService.getEmployees() !== undefined)) {

    } else {
      this.employeeForm.controls.role_id.disable();
    }
  }

  saveNewEmployee(): void {
    this.validate();
    this.spin = true;
    let newEmployee: Employee;

    newEmployee = {
      employee_id: 0,
      staff_id: this.employeeForm.controls.staff_id.value,
      employee_id_number: this.employeeForm.controls.employee_id_number.value,
      employee_firstname: this.employeeForm.controls.employee_firstname.value,
      employee_surname: this.employeeForm.controls.employee_surname.value,
      gender: this.employeeForm.controls.gender.value,
      date_of_birth: moment(this.employee.date_of_birth).format('YYYY-MM-DD').toString(),
      cell_number: this.employeeForm.controls.cell_number.value,
      email: this.employeeForm.controls.email.value,
      password: '',
      salt: '',
      address: this.employeeForm.controls.address.value,
      tax_number: this.employeeForm.controls.tax_number.value,
      date_hired: moment(this.employee.date_hired).format('YYYY-MM-DD').toString(),
      branch_id: Number(this.employeeForm.controls.branch_id.value),
      role_id: Number(this.employeeForm.controls.role_id.value),
      role_name: '',
      company_id: Number(this.authenticationService.currentUserValue.companyID),
      is_user_first_login: 1,
      is_user_logged_in: 0,
      is_synced: 1,
      status: 1,
      date_created: '',
      last_modified: '',
      retry_answer: 0,
      question_id: 0,
      secret_answer: '',
      retry_attempts: 0
    };

    if ((this.dataManagerService.getEmployees() !== null) && (this.dataManagerService.getEmployees() !== undefined)) {
      newEmployee.employee_id = this.employee.employee_id;
    } else {
      newEmployee.employee_id = this.authenticationService.currentUserValue.employeeID;
    }


    this.employeeService.updateEmployee(newEmployee).subscribe(res => {
      if (res) {
        this.modalService.dismissAll();

        this.employeeForm.disable();
        this.isEnabled = false;

        if ((this.dataManagerService.getEmployees() !== null) && (this.dataManagerService.getEmployees() !== undefined)) {
          this.getCurrentEmployeesProfile(newEmployee.employee_id, newEmployee.branch_id);
        } else {
          this.getCurrentEmployeesProfile(this.authenticationService.currentUserValue.employeeID, this.authenticationService.currentUserValue.branchID);
        }

        this.showSuccessModal('L\'employé a été mis à jour avec succès');
       
      } else {
        this.showFailureModal('La mise à jour de l\'employé a échoué, réessayez plus tard');
      }
      this.spin = false;

    }, error => {
      if (error.status === 409) {
        this.modalService.dismissAll();
        this.showFailureModal('L\'employé éxiste déjà');
      } else {
        this.showFailureModal('La mise à jour de l\'employé a échoué, réessayez plus tard');
      }
      this.spin = false;
    });

  }

  savePassword(): void {

    if (this.saveNewPasswordForm.controls.password.value === this.saveNewPasswordForm.controls.password2.value) {

      this.spin = true;

      this.employeeService.changePasswordWhileLoggediN(this.authenticationService.currentUserValue.staffID, this.saveNewPasswordForm.controls.oldPassword.value,
        this.saveNewPasswordForm.controls.password2.value).subscribe(response => {
          if (response) {
            this.showSuccessModal('Vos details ont été sauvegardé avec succès');
            this.saveNewPasswordForm.reset();
          } else {
            this.showFailureModal('Vos details n\'ont pas pu sauvegardé, Réessayez plus tard');
          }

          this.spin = false;
        },
        error => {
          this.showFailureModal('Vos details n\'ont pas pu sauvegardé, Réessayez plus tard');
          this.spin = false;
        });

    } else {
      this.spin = false;
      this.showFailureModal('Les mots de passe devraient être identique, Réessayez plus tard');
    }
  }

  validate(): void {
    // (() => {
    //   'use strict';
    //   window.addEventListener('load', () => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
    //   }, false);
    // })();
  }

  showEmployeePassword(): void {
    this.router.navigate(['/employee-change-password']);
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
