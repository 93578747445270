<div class="d-flex" id="wrapper">
  <!-- <app-side-navigation class="side-bar"></app-side-navigation> -->
  <div id="page-content-wrapper">
    <!-- <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
      <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
    </nav> -->

    <div class="container-fluid">
      <!-- <div class="panel mt-5"> -->

      <!-- <ul class="nav nav-tabs">


          <li class="nav-item">
            <a class="nav-link" id="table" (click)="showTables()">Tables</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" id="branch">Établissement</a>
          </li>
        </ul> -->

      <!-- <div class="tab-pane fade" id="branch" role="tabpanel" aria-labelledby="branch"> -->
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
          <form [formGroup]="searchBranchName">
            <div class="form-group mt-4">
              <small class="form-text text-muted">Rechercher un établissement</small>
              <div class="input-group mb-3">
                <input type="search" class="form-control" formControlName="branch_name" placeholder="Rechercher..."
                  aria-label="Search..." aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn btn-outline-primary" (click)="searchBranch()" type="button"><i
                      class="fas fa-search"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-lg-8 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
          <div class="form-group mt-4">
            <small class="form-text text-muted mt-5"></small>
            <button type="button" class="btn btn-primary" (click)="createBranchButton()">
              <i class="fas fa-plus-square"></i> &nbsp; Ajouter un établissement
            </button>
          </div>
        </div>
      </div>

      <div class="row mt-1 mb-3">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 table-responsive">

          <div class="card mb-3 d-lg-none"
            *ngFor="let item of branches | paginate: { itemsPerPage: 10, currentPage: page }">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-6 card-table-header">
                  Nom de l'établissement
                </div>
                <div class="col-6">
                  {{item.branch_name}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6 card-table-header">
                  Numéro de téléphone
                </div>
                <div class="col-6">
                  {{item.branch_cell}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6 card-table-header">
                  Email
                </div>
                <div class="col-6">
                  {{item.branch_email}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6 card-table-header">
                  Devise
                </div>
                <div class="col-6">
                  {{item.branch_currency}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6 card-table-header">
                  Adresse
                </div>
                <div class="col-6">
                  {{item.branch_address}}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12 text-center">
                  <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                    (click)="editBranch1(item)"><i class="bi bi-pen"></i></button> &nbsp;
                  <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                    (click)="tempDelete(item)"><i class="bi bi-trash"></i></button>
                </div>
              </div>
            </div>
          </div>
          <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-lg-none"
            (pageChange)="page = $event" previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>


          <mat-table [dataSource]="dataSourceBranch" class="d-none d-lg-block" matSort>
            <ng-container matColumnDef="Name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Nom de l'établissement
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.branch_name}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Cellphone">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Numéro de téléphone
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.branch_cell}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Email">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Email
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.branch_email}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Currency">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Devise
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.branch_currency}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Address">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Adresse
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.branch_address}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Actions">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                Actions
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <!--                    <button class="btn btn-primary" data-toggle="modal" data-target="#viewProduct"-->
                <!--                            (click)="viewBranch(row)"><i class="fas fa-eye"></i></button> &nbsp;-->
                <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                  (click)="editBranch1(row)"><i class="bi bi-pen"></i></button> &nbsp;
                <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                  (click)="tempDelete(row)"><i class="bi bi-trash"></i></button>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsBranch">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsBranch">
            </mat-row>
          </mat-table>
          <div class="mt-3" *ngIf="tableHasData === false">Aucun établissement trouvé
          </div>
          <mat-paginator class="d-none d-lg-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        </div>
      </div>
      <!-- </div> -->








      <!---Add Modal-->
      <ng-template #createNewBranch let-modal>
        <div class="modal-header">
          <h5 class="modal-title">Ajouter un nouvel établissement</h5>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="branchForm">
            <div class="form-group">
              <small class="form-text text-muted">Nom de l'établissement</small>
              <input type="text" class="form-control" formControlName="branch_name" placeholder="Nom de l'établissement"
                required>
              <div *ngIf="branchForm.controls['branch_name'].invalid &&
                                                                    (branchForm.controls['branch_name'].dirty ||
                                                                    branchForm.controls['branch_name'].touched)"
                class="alert alert-light pt-0">
                <div *ngIf="branchForm.controls['branch_name'].errors.required">
                  Ne devrait pas être vide
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <small class="form-text text-muted">Numéro de téléphone de l'établissement</small>
                  <input type="text" class="form-control" formControlName="branch_cell"
                    placeholder="Numéro de téléphone" required>
                  <div *ngIf="branchForm.controls['branch_cell'].invalid &&
                                                                      (branchForm.controls['branch_cell'].dirty ||
                                                                      branchForm.controls['branch_cell'].touched)"
                    class="alert alert-light">
                    <div *ngIf="branchForm.controls['branch_cell'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <small class="form-text text-muted">Adresse email de l'établissement</small>
                  <input type="text" class="form-control" formControlName="branch_email" placeholder="Adresse email">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <small class="form-text text-muted">Type d'établissement</small>
                  <select class="form-control" formControlName="branch_type">
                    <option value="1">Bar/Restaurant/Lounge/Boite</option>
                    <option value="2">Shop/Boutique/Magasin</option>
                    <option value="3">Bus/Taxi</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <small class="form-text text-muted">Devise de l'établissement (FC, USD, etc.)</small>
                  <input type="text" class="form-control" formControlName="branch_currency"
                    placeholder="ZAR, FC, KSH, USD, etc." required>
                  <!--                <div *ngIf="branchForm.controls['branch_email'].invalid &&-->
                  <div *ngIf="branchForm.controls['branch_currency'].invalid &&
                                                                    (branchForm.controls['branch_currency'].dirty ||
                                                                    branchForm.controls['branch_currency'].touched)"
                    class="alert alert-light pt-0">
                    <div *ngIf="branchForm.controls['branch_currency'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <small class="form-text text-muted">Taux d'echange de l'etablisement</small>
              <input type="number" class="form-control" formControlName="dailyRate" placeholder="Taux" required>
              <div class="alert alert-light pt-0"
                *ngIf="branchForm.controls['dailyRate'].invalid && (branchForm.controls['dailyRate'].dirty || branchForm.controls['dailyRate'].touched)">
                Ne devrait pas être vide
              </div>
            </div>

            <div class="form-group">
              <small class="form-text text-muted">Adresse de l'établissement</small>
              <input type="text" class="form-control" formControlName="branch_address"
                placeholder="Adresse de l'établissement" required>
              <div *ngIf="branchForm.controls['branch_address'].invalid &&
                                                                    (branchForm.controls['branch_address'].dirty ||
                                                                    branchForm.controls['branch_address'].touched)"
                class="alert alert-light pt-0">
                <div *ngIf="branchForm.controls['branch_address'].errors.required">
                  Ne devrait pas être vide
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
          </button>
          <button type="button" [disabled]="!branchForm.valid" class="btn btn-primary btn-lg"
            (click)="saveNewBranch()">Sauvegarder
          </button>
        </div>
      </ng-template>

      <!---Edit Modal-->
      <ng-template #editBranch let-modal>
        <div class="modal-header">
          <h5 class="modal-title">Modification des détails de l'établissement</h5>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="editBranchForm">
            <div class="form-group">

              <small class="form-text text-muted">Nom de l'établissement</small>
              <input type="text" class="form-control" formControlName="branch_name" placeholder="Nom de l'établissement"
                required>
              <div *ngIf="editBranchForm.controls['branch_name'].invalid &&
                                                                    (editBranchForm.controls['branch_name'].dirty ||
                                                                    editBranchForm.controls['branch_name'].touched)"
                class="alert alert-light pt-0">
                <div *ngIf="editBranchForm.controls['branch_name'].errors.required">
                  Ne devrait pas être vide
                </div>
              </div>
            </div>
            <div class="form-group">

              <small class="form-text text-muted">Adresse de l'établissement</small>
              <input type="text" class="form-control" formControlName="branch_address" placeholder="Addresse" required>
              <div *ngIf="editBranchForm.controls['branch_address'].invalid &&
                                                        (editBranchForm.controls['branch_address'].dirty ||
                                                        editBranchForm.controls['branch_address'].touched)"
                class="alert alert-light pt-0">
                <div *ngIf="editBranchForm.controls['branch_address'].errors.required">
                  Ne devrait pas être vide
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <small class="form-text text-muted">Numéro de téléphone de l'établissement</small>
                  <input type="text" class="form-control" formControlName="branch_cell"
                    placeholder="Numéro de téléphone" required>
                  <div *ngIf="editBranchForm.controls['branch_cell'].invalid &&
                                                            (editBranchForm.controls['branch_cell'].dirty ||
                                                            editBranchForm.controls['branch_cell'].touched)"
                    class="alert alert-light pt-0">
                    <div *ngIf="editBranchForm.controls['branch_cell'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <small class="form-text text-muted">Adresse email de l'établissement</small>
                  <input type="text" class="form-control" formControlName="branch_email" placeholder="Email">
                  <!-- <div *ngIf="editBranchForm.controls['branch_email'].invalid &&
                  (editBranchForm.controls['branch_email'].dirty ||
                  editBranchForm.controls['branch_email'].touched)" class="alert alert-light">
                  <div
                      *ngIf="editBranchForm.controls['branch_email'].errors.required">
                      Ne devrait pas être vide
                  </div>
                  </div> -->
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group">

                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <small class="form-text text-muted">Type d'établissement</small>
                  <select class="form-control" formControlName="branch_type">
                    <option value="1">Bar/Restaurant/Lounge/Boite</option>
                    <option value="2">Shop/Boutique/Magasin</option>
                    <option value="3">Bus/Taxi</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <small class="form-text text-muted">Devise de l'établissement (FC, USD, etc.)</small>
                  <input type="text" class="form-control" formControlName="branch_currency"
                    placeholder="ZAR, FC, KSH, USD, etc." required>
                  <!--                <div *ngIf="branchForm.controls['branch_email'].invalid &&-->
                  <div
                    *ngIf="branchForm.controls['branch_currency'].invalid &&
                                                                        (branchForm.controls['branch_currency'].dirty ||
                                                                        branchForm.controls['branch_currency'].touched)"
                    class="alert alert-light pt-0">
                    <div *ngIf="branchForm.controls['branch_currency'].errors.required">
                      Ne devrait pas être vide
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="form-group">
              <small class="form-text text-muted">Taux d'echange de l'etablisement</small>
              <input type="number" class="form-control" formControlName="dailyRate" placeholder="Taux" required>
              <div class="alert alert-light pt-0"
                *ngIf="editBranchForm.controls['dailyRate'].invalid && (editBranchForm.controls['dailyRate'].dirty || editBranchForm.controls['dailyRate'].touched)">
                Ne devrait pas être vide
              </div>
            </div>

            <div class="form-group">
              <small class="form-text text-muted">Adresse de l'établissement</small>
              <input type="text" class="form-control" formControlName="branch_address"
                placeholder="Adresse de l'établissement" required>
              <div *ngIf="branchForm.controls['branch_address'].invalid &&
                                                                    (branchForm.controls['branch_address'].dirty ||
                                                                    branchForm.controls['branch_address'].touched)"
                class="alert alert-light pt-0">
                <div *ngIf="branchForm.controls['branch_address'].errors.required">
                  Ne devrait pas être vide
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
          </button>
          <button type="button" [disabled]="!editBranchForm.valid" class="btn btn-primary btn-lg"
            (click)='editBranchName()'>Sauvegarder
          </button>
        </div>
      </ng-template>

      <!--Delete Modal -->
      <ng-template #deleteBranch let-modal>
        <div class="modal-header text-center">
          <h5 class="modal-title" id="exampleModalLabel">Confirmation de suppression</h5>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div class="form-group">
            <label>Êtes-vous sûr de vouloir supprimer cet établissement ?</label>
            <h5>{{branchToDelete}}</h5>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler</button>
          <button type="button" class="btn btn-primary" (click)="deleteBranch1()">Confirmer
          </button>
        </div>
      </ng-template>


      <!----success product modal -->
      <ng-template #successModal let-modal>
        <div class="modal-header text-center border-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body py-0 text-center">
          <div class="row">
            <div class="col-12">
              <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
            </div>
          </div>
          <label class="mt-3"> {{successModalMessage}} </label>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
        </div>
      </ng-template>


      <!----failure product modal -->
      <ng-template #failureModal let-modal>
        <div class="modal-header text-center border-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body py-0 text-center">
          <div class="row">
            <div class="col-12">
              <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
            </div>
          </div>
          <label class="mt-3"> {{failureModalMessage}} </label>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
        </div>
      </ng-template>
      <!-- </div> -->
    </div>
  </div>
</div>

<div class="spinnerBox" *ngIf="spin">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-success loader" role="status">
      <span class="sr-only ">Loading...</span>
    </div>
  </div>
</div>