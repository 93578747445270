<!-- Printing div -->
<div style="display: none;" id="print-section-past-order">
    <div class="container">
        <div class="row">
            <div class="offset-lg-2 col-lg-6">
                <div class="border card px-2">
                    <div class="card-body">

                        <div class="container justify-content-between">
                            <div class="row">
                                <div style="display: inline-block; margin-right: 52%;">
                                    <h3 class="mt-5 mb-2"><b>{{printCompanyName}} </b></h3>
                                    <p>{{printCompanyAddress}},
                                        <br>{{printCompanyCity}},
                                        <br>{{printCompanyCountry}}.
                                    </p>
                                </div>
                                <div *ngIf="company?.company_id === 4" style="display: inline-block;">
                                    <img src="assets/logo/prestige_logo.jpeg" width="190px">
                                </div>
                            </div>
                        </div>
                        <div class="container d-flex justify-content-between">
                            <div class="row">
                                <div class="col-lg-12 pl-0">
                                    <p class="mb-0 my-3">Date d'impression : {{printDate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container justify-content-center w-100 table-print mt-3">
                        <div class="row">
                            <div class="col-md-12">
                                <h6>Commande #{{selectedOrderID_PastOrders}}</h6>
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                Serveuse: {{selectedOrderWaiterName_PastOrders}}
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-12">
                                Date de la commande: {{selectedOrderStartTime_PastOrders}}
                            </div>
                        </div>
                       
                        <div class="row" *ngFor="let item of orderItems">
                            <div class="col-md-1">
                                <p>{{item.quantity}} x</p>
                            </div>
                            <div class="col-md-3">
                                <p>{{item.product_name}}</p>
                            </div>
                            <div class="offset-md-1 col-md-4">
                                <p>{{currencyDefault}}
                                    {{item.cost_to_pay | number}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p> ----------------------------------------------------</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <p class="mb-0">TOTAL EN FC</p>
                            </div>
                            <div class="offset-md-2 col-md-4">
                                <h6 class="mb-0">{{currencyDefault}} {{selectedOrderTotalAmount_PastOrders | number}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <p class="mb-0">TOTAL EN USD</p>
                            </div>
                            <div class="offset-md-2 col-md-4">
                                <h6 class="mb-0">USD {{selectedOrderAmountToBePaidInUSD_PastOrders | number}}</h6>
                            </div>
                        </div>

                        <div class="row mt-2 mb-5">
                            <div class="col-md-3">
                                <p class="mb-0">ARGENT RECU</p>
                            </div>
                            <div class="offset-md-2 col-md-4">
                                <h6 class="mb-0">{{currencyDefault}} {{selectedOrderAmountToBePaid_PastOrders | number}}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">

        <div class="container-fluid">
            <div class="panel mt-5">

                <div class="row mt-4">
                    <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">Nombre total de commandes</h6>
                          <p class="card-text">{{totalNumberOfOrders}}</p>
                        </div>
                      </div>
                    </div> -->

                </div>

                <form [formGroup]="filterFormPastOrders">

                    <div class="row">
                        <div class="col-lg-4 col-md-12 col-sm-12 col-12 order-md-1 order-2">
                            <form [formGroup]="searchOrderForm">
                                <div class="form-group mt-4">
                                    <small class="form-text text-muted">Rechercher une commande</small>
                                    <div class="input-group mb-3">
                                        <input type="search" class="form-control" formControlName="searchOrderID"
                                            placeholder="Rechercher..." aria-label="Search..."
                                            aria-describedby="basic-addon2">
                                        <div *ngIf="searchOrderForm.controls['searchOrderID'].invalid &&
                                    (searchOrderForm.controls['searchOrderID'].dirty || 
                                    searchOrderForm.controls['searchOrderID'].touched)" class="alert alert-light pt-0">
                                            <div *ngIf="searchOrderForm.controls['searchOrderID'].errors.required">
                                                Ne devrait pas être vide
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-primary"
                                                (click)="searchOrder(selectedBranch.value)" type="button"><i
                                                    class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">

                        </div>
                    </div>

                    <div class="row mt-1">
                        <div
                            class="col-lg-9 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 table-responsive">

                            <div class="card mb-3 d-md-none"
                                *ngFor="let item of pastOrderReports | paginate: { itemsPerPage: 10, currentPage: pageOrderReport, id: 'orderReport' }">
                                <div class="card-body">
                                    <div class="row mb-2 text-center">
                                        <div class="col-md-12">
                                            <!-- <div class="mr-2" *ngIf="item.discount !== 0"> -->
                                            <a class="mr-2 tn-black-color" *ngIf="item.discount !== 0"> <i
                                                    class="fas fa-angle-double-down"></i></a>
                                            <!-- </div>
                                            <div *ngIf="item.flag === 1"> -->
                                            <a *ngIf="item.flag === 1" class="tn-red-color"> <i
                                                    class="fas fa-flag"></i></a>
                                            <!-- </div>&nbsp; -->
                                        </div>

                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            OrderID
                                        </div>
                                        <div class="col-6">
                                            {{item.order_id}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Date et heure
                                        </div>
                                        <div class="col-6">
                                            {{item.order_start_time}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Serveur(se)/Employé
                                            <!-- <p *ngIf="branch_type === 0">Serveur(se)/Employé</p>
                                            <p *ngIf="branch_type === 1">Serveur(se)</p>
                                            <p *ngIf="branch_type === 2">Employé</p>
                                            <p *ngIf="branch_type === 3">Employé</p> -->
                                        </div>
                                        <div class="col-6">
                                            {{item.employee_fullname}}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6 card-table-header">
                                            Montant payé
                                        </div>
                                        <div class="col-6">
                                            {{workingCurrency}}
                                            <!-- {{item.currency_one}} -->
                                            {{item.total_amount_brought_in | number}}
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary"
                                                (click)="loadOrderItemsByOrderID(item.order_id,
                                                item.discount, item.total_amount_brought_in, item.currency_one, item.comment, item.order_start_time, item.employee_fullname, item.order_rate)"><i
                                                    class="fas fa-eye"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <pagination-controls *ngIf="tableHasDataOrderReport === true"
                                class="mb-3 text-center d-md-none" (pageChange)="onMobileTableChangePage($event)"
                                id="orderReport" previousLabel="Précédent" nextLabel="Suivant">
                            </pagination-controls>


                            <mat-table [dataSource]="dataSourcePastOrders" class="d-none d-md-block" matSort>
                                <ng-container matColumnDef="OrderID">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        No de commande
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <div class="mr-2" *ngIf="row.discount !== 0">
                                            <a data-toggle="modal" data-target="#deleteProduct"> <i
                                                    class="fas fa-angle-double-down"></i></a>
                                        </div>&nbsp;
                                        <div class="mr-2" *ngIf="row.flag === 1">
                                            <a data-toggle="modal" class="tn-red-color" data-target="#deleteProduct"> <i
                                                    class="fas fa-flag"></i></a>
                                        </div>&nbsp;
                                        <div>
                                            {{row.order_id}}
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Date & Time">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Date et heure
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.order_start_time}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Waiter">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Serveur(se)/Employé
                                        <!-- <p *ngIf="branch_type === 0">Serveur(se)/Employé</p>
                                        <p *ngIf="branch_type === 1">Serveur(se)</p>
                                        <p *ngIf="branch_type === 2">Employé</p>
                                        <p *ngIf="branch_type === 3">Employé</p> -->
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.employee_fullname}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Amount paid">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Montant payé
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{workingCurrency}}
                                        <!-- {{row.currency_one}} -->
                                        {{row.total_amount_brought_in | number}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Action">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Action
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <!-- <div class="mr-2" *ngIf="row.flag === 1">
                                            <button class="btn btn-danger" data-toggle="modal"
                                                data-target="#deleteProduct">
                                                <i class="fas fa-flag"></i></button>
                                        </div>&nbsp; -->
                                        <div>
                                            <button
                                                (click)="loadOrderItemsByOrderID(row.order_id, row.discount, row.total_amount_brought_in, row.currency_one, row.comment, 
                                                row.order_start_time, row.employee_fullname, row.order_rate)"
                                                class="btn btn-primary mr-2">
                                                <i class="fas fa-eye"></i></button>

                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumnsPastOrderReport">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsPastOrderReport">
                                </mat-row>
                            </mat-table>
                            <div class="mt-3" *ngIf="tableHasDataOrderReport === false">Aucune vente trouvé
                            </div>
                            <mat-paginator #paginatorOrderReport class="d-none d-md-block mb-4" [pageSize]="10"
                                [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                        </div>

                        <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <select #selectedBranch class="form-control" formControlName="dpBranch"
                                            (change)="loadPastOrders($event.target.value)">
                                            <option value="0">
                                                Tous les établissement
                                            </option>
                                            <option *ngFor="let branch of branches" value={{branch.branch_id}}>
                                                {{branch.branch_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <select #selectedWaiters class="form-control" formControlName="dpWaiter"
                                            (change)="loadPastOrdersPerWaiter($event.target.value)">
                                            <option value="0">
                                                Tous les Employés
                                                <!-- <p *ngIf="branch_type === 0">Tous les Serveur(se)/Employé</p>
                                                <p *ngIf="branch_type === 1"> Tous les Serveur(ses)</p>
                                                <p *ngIf="branch_type === 2">Tous les Employés</p>
                                                <p *ngIf="branch_type === 3">Tous les Employé</p> -->
                                            </option>
                                            <option *ngFor="let waiter of employeeWaiters" value={{waiter.employee_id}}>
                                                {{waiter.employee_firstname}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <small class="form-text text-muted">Datant de</small>
                                    <input class="form-control" [readonly]="true"
                                        [owlDateTimeTrigger]="dtPastOrdersFrom" [owlDateTime]="dtPastOrdersFrom"
                                        name="dtPastOrdersFrom" formControlName="dtPastOrdersFrom">
                                    <owl-date-time #dtPastOrdersFrom></owl-date-time>
                                </div>

                                <div class="col-12">
                                    <small class="form-text text-muted">à</small>
                                    <input class="form-control" [readonly]="true" [owlDateTimeTrigger]="dtPastOrdersTo"
                                        [owlDateTime]="dtPastOrdersTo" name="dtPastOrdersTo"
                                        formControlName="dtPastOrdersTo">
                                    <owl-date-time #dtPastOrdersTo></owl-date-time>

                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-primary w-100"
                                        (click)="filterPastOrders(selectedBranch.value)">
                                        <i class="fas fa-filter"></i> &nbsp;Filtre
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <ng-template #viewOrderItem let-modal>
                    <div class="modal-header text-center">
                        <h5 class="modal-title">Commande #{{selectedOrderID_PastOrders}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <div class="row mb-1"
                            *ngFor="let item of orderItems | paginate: { itemsPerPage: 5, currentPage: viewOrderItemPage, id: 'viewOrderItemPage' }">
                            <div class="col-6">
                                {{item.quantity}} x
                                {{item.product_name}}
                            </div>
                            <div class="col-6">
                                {{currencyDefault}}
                                {{item.cost_to_pay | number}}
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                Total
                            </div>
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                {{currencyDefault}}
                                {{selectedOrderTotalAmount_PastOrders | number}}
                            </div>
                        </div>
                        <div class="row tn-failure-color">
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                Réduction
                            </div>
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                - {{currencyDefault}} {{selectedOrderDiscount_PastOrders | number}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                Total à payer
                            </div>
                            <div class="col-lg-6 col-md-6 col-6 font-weight-500">
                                {{currencyDefault}} {{selectedOrderAmountToBePaid_PastOrders | number}}
                            </div>
                        </div>
                        <pagination-controls *ngIf="tableHasDataOrderReport === true" class="mb-3 text-center"
                            (pageChange)="viewOrderItemPage = $event" id="viewOrderItemPage" previousLabel="Précédent"
                            nextLabel="Suivant"></pagination-controls>

                        <hr>

                        <div class="row tn-blue-color">
                            <div class="col-lg-12 col-md-12 col-12 font-weight-500">
                                <h6 class="text-left text-underline margin-left-10px">Comment:</h6>
                                <p class="text-left font-weight-normal margin-left-10px">
                                    {{selectedOrderComment_PastOrders}}</p>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer text-center">
                        <button printTitle="{{printCompanyName}}_Receipt" [useExistingCss]="true"
                        styleSheetFile="styles.scss"
                            printSectionId="print-section-past-order" class="btn btn-primary" ngxPrint type="button">
                            Imprimer <i class="fas fa-print"></i></button>

                        <button type="button" class="btn btn-primary" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>

                <!----success product modal -->
                <ng-template #successModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{successModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>


                <!----failure product modal -->
                <ng-template #failureModal let-modal>
                    <div class="modal-header text-center border-0">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0 text-center">
                        <div class="row">
                            <div class="col-12">
                                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
                            </div>
                        </div>
                        <label class="mt-3"> {{failureModalMessage}} </label>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>


<!-- Spinner -->
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>