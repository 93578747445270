import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Branch } from 'src/app/interface/branch';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BranchService } from 'src/app/services/branch.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Constants } from '../../common/Constants';
import * as $ from 'jquery';
import * as moment from 'moment';
import { UserLoggedIn } from 'src/app/models/user/user-logged-in';
import { OrderService } from 'src/app/services/order/order.service';
import { SaleAndBottleProfitLoss } from 'src/app/models/order/SaleAndBottleProfitLoss';
import { SaleAndProfitAndLossReportToDisplay } from 'src/app/models/order/SaleAndProfitAndLossReportToDisplay';
import { Expense } from 'src/app/models/Expense';
import { Summary } from '@angular/compiler';
import { SummaryItem } from 'src/app/models/order/SummaryItem';
import { ExpenseSummaryReport } from 'src/app/models/ExpenseSummaryReport';
import { CompanyService } from 'src/app/services/company.service';
import {Company} from 'src/app/models/company/company';

@Component({
  selector: 'app-report-profit',
  templateUrl: './report-profit.component.html',
  styleUrls: ['./report-profit.component.scss']
})
export class ReportProfitComponent implements OnInit {


  // totalDrinkLossWithProfit: number;
  // totalDrinkLossWithoutProfit: number;

  // Summary report
  totalDrinkRevenueWithoutProfit: number;
  totalDrinkRevenuWithProfit: number;
  currencyDefault: string;
  totalDrinkProfit: number;
  totalLossOnDefectedBottles: number;
  totalLossOnGiftBottles: number;
  totalFoodRevenue: number;
  totalFoodProfit: number;
  totalFoodRevenueOnBuyingPrice: number;
  totalOtherItemRevenue: number;
  totalOtherItemRevenueOnBuyingPrice: number;
  totalOtherItemProfit: number;
  expenseSummary: ExpenseSummaryReport;
  totalExpenses: number;
  totalExpenseLinkedToDrinkRevenue: number;
  totalExpenseLinkedToFoodRevenue: number;
  totalExpenseLinkedToHouseProductRevenue: number
  totalProfitForEverything: number;
  grandTotalRevenue: number;


  tableHasDataSaleReport: boolean;

  @ViewChild('successModal', { static: true }) successModal: TemplateRef<any>;
  @ViewChild('failureModal', { static: true }) failureModal: TemplateRef<any>;

  closeResult: string;
  successModalMessage: string;
  failureModalMessage: string;

  branchIdToBeUsed: number;

  displayedColumnsSaleReport = [
    'Product',
    'QuantitySold',
    'TotalRevenue',
    'LossOnRevenueBecauseOfGift',
    'LossOnRevenue',
    'ProfitOnRevenue',
  ];

  dataSourceSaleAndProfitReport: MatTableDataSource<SaleAndBottleProfitLoss>;

  @ViewChild(MatPaginator) paginatorSaleReport: MatPaginator;
  @ViewChild(MatSort) sortSaleReport: MatSort;

  @ViewChild(MatPaginator) paginatorSaleProfitDetailReport: MatPaginator;
  @ViewChild(MatSort) sortSaleProfitDetailReport: MatSort;

  filterFormSaleReport: FormGroup;

  userLoggedIn: UserLoggedIn;
  spin: boolean;

  saleDrinkReports: SaleAndBottleProfitLoss[];
  saleDrinkLossReports: SaleAndBottleProfitLoss[];
  saleDrinkLossDueToGiftToStaffReports: SaleAndBottleProfitLoss[];
  saleSummaryReports: SummaryItem[];
  // saleFoodReports: SaleAndBottleProfitLoss[];
  // saleFoodLossReports: SaleAndBottleProfitLoss[];
  // saleOtherItemReports: SaleAndBottleProfitLoss[];
  // saleOtherItemLossReports: SaleAndBottleProfitLoss[];
  saleReportToBeDisplayed: SaleAndProfitAndLossReportToDisplay[];
  // saleExpenseReports: Expense[];
  // totalExpense: number;

  branches: Branch[];

  startDateSaleReport: string;
  endDateSaleReport: string;
  startDate: string;
  endDate: string;

  pageSaleReport1 = 1;

  saletoDisplay: SaleAndProfitAndLossReportToDisplay;

  printDate: string;
  company: Company;
  printCompanyName: string;
  printCompanyAddress: string;
  printCompanyCity: string;
  printCompanyCountry: string;
  printEstablishment: string;
  printDateFrom: string;
  printDateTo: string;

  workingCurrency: string;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal,
    private orderService: OrderService, private branchService: BranchService, private formBuilder: FormBuilder,
    private companyService: CompanyService) {
    this.saleSummaryReports = [];
    this.saleDrinkReports = [];
    this.saleReportToBeDisplayed = [];
    this.saleDrinkLossReports = [];
    // this.saleExpenseReports = [];
    this.branches = [];
  }

  ngOnInit(): void {
    this.spin = true;

    this.createForm();
    this.userLoggedIn = this.authenticationService.currentUserValue;
    this.getWorkingCurrency(this.authenticationService.currentUserValue.branchID);

    if ((this.userLoggedIn.role === Constants.ROLE_ADMINISTRATOR) || (this.userLoggedIn.role === Constants.ROLE_DEPUTY_ADMINISTRATOR) ||
      (this.userLoggedIn.role === Constants.ROLE_MANAGER_FULL) || (this.userLoggedIn.role === Constants.ROLE_MANAGER_NORMAL)) {

      this.loadSaleReportBranches();

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {
        this.loadSaleAndProfitReport(0);
      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.loadSaleAndProfitReport(this.authenticationService.currentUserValue.branchID);
      }

      var date = new Date();

      this.filterFormSaleReport.controls['dtSaleReportFrom'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1));
      this.filterFormSaleReport.controls['dtSaleReportTo'].setValue(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

    } else {
      // console.log('role [' + this.userLoggedIn.role);
    }

    this.getCompanyDetails(this.authenticationService.currentUserValue.companyID);
  }

  getWorkingCurrency(branchID: number) {
    this.branchService.getOneBranchByBranchId(branchID,
      this.authenticationService.currentUserValue.companyID).subscribe(res => {

        this.workingCurrency = res.branch_currency;

        this.spin = false;
      }, error => {
        this.spin = false;
      });
  }

  createForm(): void {
    this.filterFormSaleReport = this.formBuilder.group({
      dtSaleReportFrom: new FormControl(null, Validators.required),
      dtSaleReportTo: new FormControl(null, Validators.required),
      selectBranch: ['']
    });
  }

  getCompanyDetails(companyId: number): void {
    this.companyService.getCompanyByID(companyId).subscribe(res => {
      this.company = res;
      this.printCompanyName = this.company.company_name;
      this.printCompanyAddress = this.company.company_address + ', ' + this.company.company_address_suburb;
      this.printCompanyCity = this.company.company_address_city + ', ' + this.company.company_address_province;
      this.printCompanyCountry = this.company.company_address_country;
    }, err => {


      // this.showTable = false;
      // this.spin = false;
    });
  }

  toggleNow(): void {
    $('#wrapper').toggleClass('toggled');
  }

  searchSaleReport(branchIdSaleReport) {
    this.spin = true;

    var date = new Date();

    if (this.filterFormSaleReport.controls['dtSaleReportFrom'].value !== null) {
      this.startDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadCompanySaleAndProfitReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  loadSaleReportBranches(): void {
    this.branchService.getBranchByCompany(this.authenticationService.currentUserValue.companyID).subscribe(res => {
      this.branches = res;

      if ((this.authenticationService.currentUserValue.role === Constants.ROLE_ADMINISTRATOR) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_DEPUTY_ADMINISTRATOR)) {

        this.filterFormSaleReport.controls.selectBranch.enable();
        this.branchIdToBeUsed = this.branches[0].branch_id;

      } else if ((this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_FULL) ||
        (this.authenticationService.currentUserValue.role === Constants.ROLE_MANAGER_NORMAL)) {
        this.filterFormSaleReport.controls.selectBranch.disable();
        this.filterFormSaleReport.controls.selectBranch.setValue(this.authenticationService.currentUserValue.branchID);

        this.branchIdToBeUsed = this.authenticationService.currentUserValue.branchID;
      }

      this.printEstablishment = this.branches.find(br => br.branch_id === this.branchIdToBeUsed).branch_name;


      this.spin = false;
    }, error => {
      this.spin = false;
    });
  }

  filterSaleReport(branchIdSaleReport): void {
    this.startDateSaleReport = moment(this.filterFormSaleReport.controls.dtSaleReportFrom.value).format('YYYY-MM-DD HH:mm:ss').toString();
    this.endDateSaleReport = moment(this.filterFormSaleReport.controls.dtSaleReportTo.value).format('YYYY-MM-DD HH:mm:ss').toString();

    this.loadCompanySaleAndProfitReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  resetSummaryInfo(): void {
    this.totalDrinkRevenueWithoutProfit = 0;
    this.totalDrinkRevenuWithProfit = 0;
    this.totalFoodRevenue = 0;
    this.totalFoodRevenueOnBuyingPrice = 0;
    this.totalFoodProfit = 0;
    this.totalOtherItemRevenue = 0;
    this.totalOtherItemRevenueOnBuyingPrice = 0;
    this.totalOtherItemProfit = 0;
    // this.totalDrinkLossWithProfit = 0;
    this.grandTotalRevenue = 0;
    this.totalExpenses = 0;
    this.totalExpenseLinkedToDrinkRevenue = 0;
    this.totalExpenseLinkedToFoodRevenue = 0
    this.totalExpenseLinkedToHouseProductRevenue = 0
    this.totalProfitForEverything = 0;
    // this.totalDrinkLossWithoutProfit = 0;
    this.totalDrinkProfit = 0;
    this.totalLossOnDefectedBottles = 0;
    this.totalLossOnGiftBottles = 0;
    this.saleReportToBeDisplayed = [];
  }

  loadCompanySaleAndProfitReport(branchId: number, fromDate: string, toDate: string) {
    this.spin = true;

    this.printDateFrom = moment(fromDate).format('DD-MM-YYYY HH:mm:ss').toString();
    this.printDateTo = moment(toDate).format('DD-MM-YYYY HH:mm:ss').toString();

    var date = new Date();
    this.printDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(),
      date.getHours(), date.getMinutes(), date.getSeconds())).format('DD-MM-YYYY HH:mm:ss').toString();

    this.resetSummaryInfo();

    this.orderService.getSaleAndProfitReport(this.authenticationService.currentUserValue.companyID,
      branchId, fromDate, toDate).subscribe(res => {

        this.saleDrinkReports = res.saleAndProfitReportList;
        this.saleDrinkLossReports = res.saleAndLossReportList;
        this.saleDrinkLossDueToGiftToStaffReports = res.staffGiftOrderReportList;
        this.expenseSummary = res.expenseSummaryReport;
        this.saleSummaryReports = res.summaryItems;

        this.totalExpenses = this.expenseSummary.totalOtherExpense;
        this.totalExpenseLinkedToDrinkRevenue = this.expenseSummary.totalDrinkExpense;
        this.totalExpenseLinkedToFoodRevenue = this.expenseSummary.totalFoodExpense;
        this.totalExpenseLinkedToHouseProductRevenue = this.expenseSummary.totalHouseProductExpense;

        this.currencyDefault = 'FC'; //this.saleDrinkReports[0].currency_one;

        if (this.saleSummaryReports !== undefined) {
          if (this.saleSummaryReports.length > 0) {

            let foodSummaryInfo = this.saleSummaryReports.find(x => x.categoryName === Constants.CATEGORY_FOOD);
            if (foodSummaryInfo !== undefined) {
              this.totalFoodRevenue = foodSummaryInfo.totalRevenu;
              this.totalFoodProfit = foodSummaryInfo.profit;
              this.totalFoodRevenueOnBuyingPrice = this.totalFoodRevenue - this.totalFoodProfit;
            } else {
              this.totalFoodRevenue = 0;
              this.totalFoodProfit = 0;
              this.totalFoodRevenueOnBuyingPrice = 0;
            }

            let otherItemSummaryInfo = this.saleSummaryReports.find(x => x.categoryName === Constants.CATEGORY_OTHER_ITEM);
            if (otherItemSummaryInfo !== undefined) {
              this.totalOtherItemRevenue = otherItemSummaryInfo.totalRevenu;
              this.totalOtherItemProfit = otherItemSummaryInfo.profit;
              this.totalOtherItemRevenueOnBuyingPrice = this.totalOtherItemRevenue - this.totalOtherItemProfit;
            } else {
              this.totalOtherItemRevenue = 0;
              this.totalOtherItemRevenueOnBuyingPrice = 0;
              this.totalOtherItemProfit = 0;
            }

            if (this.saleDrinkLossDueToGiftToStaffReports.length > 0) {
              this.saleDrinkLossDueToGiftToStaffReports.forEach(a => this.totalLossOnGiftBottles += (a.quantity_sold * a.profit_on_product));
            } else {
              this.totalLossOnGiftBottles = 0;
            }

            let drinkItemSummaryInfo = this.saleSummaryReports.find(x => x.categoryName === Constants.CATEGORY_DRINK);
            if (drinkItemSummaryInfo !== undefined) {
              this.totalDrinkRevenuWithProfit = drinkItemSummaryInfo.totalRevenu;
              this.totalDrinkProfit = drinkItemSummaryInfo.profit;
              this.totalDrinkRevenueWithoutProfit = drinkItemSummaryInfo.totalRevenu - (drinkItemSummaryInfo.profit + this.totalLossOnGiftBottles);
            } else {
              this.totalDrinkRevenuWithProfit = 0;
              this.totalDrinkRevenueWithoutProfit = 0;
              this.totalDrinkProfit = 0;
            }

          } else {
            this.totalFoodRevenue = 0;
            this.totalFoodRevenueOnBuyingPrice = 0;
            this.totalFoodProfit = 0;
            this.totalOtherItemRevenue = 0;
            this.totalOtherItemRevenueOnBuyingPrice = 0;
            this.totalOtherItemProfit = 0;
          }
        } else {
          this.totalFoodRevenue = 0;
          this.totalFoodRevenueOnBuyingPrice = 0;
          this.totalFoodProfit = 0;
          this.totalOtherItemRevenue = 0;
          this.totalOtherItemRevenueOnBuyingPrice = 0;
          this.totalOtherItemProfit = 0;
        }

        if (this.saleDrinkLossReports.length > 0) {

          this.saleDrinkLossReports.forEach(a => this.totalLossOnDefectedBottles += (a.quantity_sold * a.profit_on_product));
        } else {
          this.totalLossOnDefectedBottles = 0;
        }

        if (this.saleDrinkReports.length > 0) {
          this.tableHasDataSaleReport = true;

          this.saleDrinkReports.forEach(product => {

            this.saletoDisplay = new SaleAndProfitAndLossReportToDisplay();

            let lossProductFound = this.saleDrinkLossReports.find(p => p.product_name === product.product_name);

            let giftedProductFound = this.saleDrinkLossDueToGiftToStaffReports.find(p => p.product_name === product.product_name);

            if ((lossProductFound !== null) && (lossProductFound !== undefined)) {
              this.saletoDisplay.quantity_loss = lossProductFound.quantity_sold;
              this.saletoDisplay.profit_on_product = 0;
            } else {
              this.saletoDisplay.quantity_loss = 0;
              this.saletoDisplay.profit_on_product = product.profit_on_product;
            }

            if ((giftedProductFound !== null) && (giftedProductFound !== undefined)) {
              this.saletoDisplay.quantity_gifted = giftedProductFound.quantity_sold;
              this.saletoDisplay.profit_on_product = product.profit_on_product
              
            } else {
              this.saletoDisplay.quantity_gifted = 0;
              this.saletoDisplay.profit_on_product = product.profit_on_product;
            }

            this.saletoDisplay.date_sale_placed = product.date_sale_placed;
            this.saletoDisplay.product_name = product.product_name;
            this.saletoDisplay.branch_id = product.branch_id;
            this.saletoDisplay.branch_name = product.branch_name;
            this.saletoDisplay.quantity_sold = product.quantity_sold;
            this.saletoDisplay.buying_cost = product.buying_cost;
            this.saletoDisplay.selling_cost = product.selling_cost;
            // this.saletoDisplay.profit_on_product = product.profit_on_product;
            this.saletoDisplay.company_id = product.company_id;
            this.saletoDisplay.category_name = product.category_name;
            this.saletoDisplay.subcategory_name = product.subcategory_name
            
            this.saleReportToBeDisplayed.push(this.saletoDisplay);
          });

          this.dataSourceSaleAndProfitReport = new MatTableDataSource(this.saleReportToBeDisplayed);
          this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
          this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;

        } else {
          this.tableHasDataSaleReport = false;
          this.resetSummaryInfo();
        }

        this.totalProfitForEverything = Number(this.totalFoodProfit) + Number(this.totalOtherItemProfit) + Number(this.totalDrinkProfit) - this.totalExpenses;
        this.grandTotalRevenue = Number(this.totalFoodRevenue) + Number(this.totalOtherItemRevenue) + Number(this.totalDrinkRevenuWithProfit);

        this.spin = false;

      }, error => {
        this.tableHasDataSaleReport = false;

        this.resetSummaryInfo();

        this.dataSourceSaleAndProfitReport = new MatTableDataSource([]);
        this.dataSourceSaleAndProfitReport.paginator = this.paginatorSaleReport;
        this.dataSourceSaleAndProfitReport.sort = this.sortSaleReport;
        this.spin = false;
      });
  }

  loadSaleAndProfitReport(branchIdSaleReport) {
    var date = new Date();

    if (this.filterFormSaleReport.controls['dtSaleReportFrom'].value !== null) {
      this.startDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportFrom'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(this.filterFormSaleReport.controls['dtSaleReportTo'].value).format('YYYY-MM-DD HH:mm:ss').toString();
    } else {
      this.startDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)).format('YYYY-MM-DD HH:mm:ss').toString();
      this.endDateSaleReport = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss').toString();
    }

    this.loadCompanySaleAndProfitReport(branchIdSaleReport, this.startDateSaleReport, this.endDateSaleReport);
  }

  showSuccessModal(message: string): void {
    this.open(this.successModal);
    this.successModalMessage = message;
  }

  showFailureModal(message: string): void {
    this.open(this.failureModal);
    this.failureModalMessage = message;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onMobileTableChangePage(event) {
    this.pageSaleReport1 = event;
    window.scroll(0,0);
    }
}
