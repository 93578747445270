<div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12 col-12 order-md-1 order-2">
        <form [formGroup]="warehouseSearchForm">
            <div class="form-group mt-4">
                <small class="form-text text-muted">Rechercher un dépôt</small>
                <div class="input-group mb-3">
                    <input type="search" class="form-control" formControlName="searchWarehouseName"
                        placeholder="Rechercher..." aria-label="Search..." aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <button class="btn btn-outline-primary" (click)="searchWarehouse()" type="button"><i
                                class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-lg-5 col-md-6 col-sm-12 col-12 add-button-box text-right order-md-2 order-1">
        <form>
            <div class="form-group mt-4">
                <small class="form-text text-muted mt-5"></small>
                <button type="button" class="btn btn-primary" (click)="openCreateNewWarehouseModal()">
                    <i class="fas fa-plus-square"></i> &nbsp;Ajouter un dépôt
                </button>
            </div>
        </form>
    </div>
</div>

<div class="row mt-1 mb-3">
    <div class="col-lg-9 col-md-12 col-sm-12 col-12 table-responsive">

        <div class="card mb-3 d-lg-none"
            *ngFor="let item of wareHouseLists | paginate: { itemsPerPage: 10, currentPage: pager }">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-6 card-table-header">
                        Nom du dépôt
                    </div>
                    <div class="col-6">
                        {{item.warehouse_name}}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6 card-table-header">
                        Gérant du dépôt
                    </div>
                    <div class="col-6">
                        {{item.employee_manager_name}}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6 card-table-header">
                        Numéro de téléphone
                    </div>
                    <div class="col-6">
                        {{item.warehouse_cell}}
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6 card-table-header">
                        Adresse
                    </div>
                    <div class="col-6">
                        {{item.warehouse_address}}
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                            (click)="editWarehouse1(item)"><i class="bi bi-pen"></i></button> &nbsp;
                        <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                            (click)="deleteWarehouse1(item)"><i class="bi bi-trash"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <pagination-controls *ngIf="tableHasData === true" class="mb-3 text-center d-lg-none"
            (pageChange)="pager = $event" previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>


        <mat-table [dataSource]="dataSourceWarehouse" matSort class="d-none d-lg-block">
            <ng-container matColumnDef="Name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Nom du dépôt
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{row.warehouse_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Manager">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Gérant du dépôt
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{row.employee_manager_name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Cellphone">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Numéro de téléphone
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{row.warehouse_cell}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Address">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Adresse
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{row.warehouse_address}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Actions
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <button class="btn btn-primary" data-toggle="modal" data-target="#editProduct"
                        (click)="editWarehouse1(row)"><i class="bi bi-pen"></i></button> &nbsp;
                    <button class="btn btn-danger" data-toggle="modal" data-target="#deleteProduct"
                        (click)="deleteWarehouse1(row)"><i class="bi bi-trash"></i></button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsWarehouse">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsWarehouse">
            </mat-row>
        </mat-table>
        <div class="mt-3" *ngIf="tableHasData === false">Aucun dépôt trouvé
        </div>
        <mat-paginator class="d-none d-lg-block" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    </div>
    <div class="col-lg-3 col-md-3 col-sm-12 col-12">

    </div>
</div>


<!-- Add new warehouse -->
<ng-template #createNewWarehouse let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Ajouter un nouveau dépôt</h5>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="warehouseForm">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <small class="form-text text-muted">Nom du dépôt</small>
                            <input type="text" class="form-control" formControlName="warehouse_name"
                                placeholder="Nom du dépôt" required>
                            <div *ngIf="warehouseForm.controls['warehouse_name'].invalid &&
                                  (warehouseForm.controls['warehouse_name'].dirty ||
                                  warehouseForm.controls['warehouse_name'].touched)" class="alert alert-light pt-0">
                                <div *ngIf="warehouseForm.controls['warehouse_name'].errors.required">
                                    Ne devrait pas être vide
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <small class="form-text text-muted">Gérant du dépôt</small>
                            <select class="form-control" formControlName="manager" required>
                                <option value="" disabled selected>Selectionnez un gérant</option>
                                <option *ngFor="let employee of employeeListToShow" value={{employee.employee_id}}>
                                    {{employee.employee_surname}} {{employee.employee_firstname}} </option>
                            </select>
                            <div *ngIf="warehouseForm.controls['manager'].invalid &&
                                    (warehouseForm.controls['manager'].dirty ||
                                    warehouseForm.controls['manager'].touched)" class="pt-0 alert alert-light">
                                <div *ngIf="warehouseForm.controls['manager'].errors.required">
                                    Ne devrait pas être vide
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <small class="form-text text-muted">Numéro de téléphone du dépôt</small>
                            <input type="text" class="form-control" formControlName="warehouse_cell"
                                placeholder="Numéro de téléphone" required>
                            <div *ngIf="warehouseForm.controls['warehouse_cell'].invalid &&
                      (warehouseForm.controls['warehouse_cell'].dirty || warehouseForm.controls['warehouse_cell'].touched)"
                                class="alert alert-light">
                                <div *ngIf="warehouseForm.controls['warehouse_cell'].errors.required">
                                    Ne devrait pas être vide
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <small class="form-text text-muted">Email du dépôt</small>
                            <input type="text" class="form-control" formControlName="warehouse_email"
                                placeholder="Email du dépôt">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!-- <div class="form-group">
                      <small class="form-text text-muted">Devise du dépôt (FC, ZAR, KSH, USD, etc.)</small>
                      <input type="text" class="form-control"
                             formControlName="warehouse_currency" placeholder="ZAR, FC, KSH, USD, etc." required>
                      <div *ngIf="warehouseForm.controls['warehouse_currency'].invalid &&
                            (warehouseForm.controls['warehouse_currency'].dirty ||
                           warehouseForm.controls['warehouse_currency'].touched)"
                           class="alert alert-light pt-0">
                        <div
                          *ngIf="warehouseForm.controls['warehouse_currency'].errors.required">
                          Ne devrait pas être vide
                        </div>
                      </div>
                    </div> -->
                        <div class="form-group">
                            <small class="form-text text-muted">Adresse du dépôt</small>
                            <input type="text" class="form-control" formControlName="warehouse_address"
                                placeholder="Adresse du dépôt" required>
                            <div *ngIf="warehouseForm.controls['warehouse_address'].invalid &&
                                 (warehouseForm.controls['warehouse_address'].dirty ||
                                 warehouseForm.controls['warehouse_address'].touched)" class="alert alert-light pt-0">
                                <div *ngIf="warehouseForm.controls['warehouse_address'].errors.required">
                                    Ne devrait pas être vide
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
            </button>
            <button type="button" [disabled]="!warehouseForm.valid" class="btn btn-primary btn-lg"
                (click)="saveNewWarehouse()">Sauvegarder
            </button>
        </div>
    </div>

</ng-template>


<!-- EDIT WAREHOUSE MODAL -->
<ng-template #editWarehouse let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Modification des détails du dépôt</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editWarehouseForm">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <small class="form-text text-muted">Nom du dépôt</small>
                        <input type="text" class="form-control" formControlName="warehouse_name"
                            placeholder="Nom du dépôt" required>
                        <div *ngIf="editWarehouseForm.controls['warehouse_name'].invalid &&
                              (editWarehouseForm.controls['warehouse_name'].dirty ||
                              editWarehouseForm.controls['warehouse_name'].touched)" class="alert alert-light pt-0">
                            <div *ngIf="editWarehouseForm.controls['warehouse_name'].errors.required">
                                Ne devrait pas être vide
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <small class="form-text text-muted">Gérant du dépôt</small>
                        <select class="form-control" formControlName="manager" required>
                            <option value="" disabled selected>Selectionnez un gérant</option>
                            <option *ngFor="let employee of employeeListToShow" value={{employee.employee_id}}>
                                {{employee.employee_surname}} {{employee.employee_firstname}} </option>
                        </select>
                        <div *ngIf="editWarehouseForm.controls['manager'].invalid &&
                                (editWarehouseForm.controls['manager'].dirty ||
                                editWarehouseForm.controls['manager'].touched)" class="pt-0 alert alert-light">
                            <div *ngIf="editWarehouseForm.controls['manager'].errors.required">
                                Ne devrait pas être vide
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <small class="form-text text-muted">Numéro de téléphone du dépôt</small>
                        <input type="text" class="form-control" formControlName="warehouse_cell"
                            placeholder="Numéro de téléphone" required>
                        <div *ngIf="editWarehouseForm.controls['warehouse_cell'].invalid &&
                  (editWarehouseForm.controls['warehouse_cell'].dirty || editWarehouseForm.controls['warehouse_cell'].touched)"
                            class="alert alert-light">
                            <div *ngIf="editWarehouseForm.controls['warehouse_cell'].errors.required">
                                Ne devrait pas être vide
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <small class="form-text text-muted">Email du dépôt</small>
                        <input type="text" class="form-control" formControlName="warehouse_email"
                            placeholder="Email du dépôt">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <small class="form-text text-muted">Adresse du dépôt</small>
                        <input type="text" class="form-control" formControlName="warehouse_address"
                            placeholder="Adresse du dépôt" required>
                        <div *ngIf="editWarehouseForm.controls['warehouse_address'].invalid &&
                             (editWarehouseForm.controls['warehouse_address'].dirty ||
                             editWarehouseForm.controls['warehouse_address'].touched)" class="alert alert-light pt-0">
                            <div *ngIf="editWarehouseForm.controls['warehouse_address'].errors.required">
                                Ne devrait pas être vide
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
        </button>
        <button type="button" [disabled]="!editWarehouseForm.valid" class="btn btn-primary btn-lg"
            (click)='confirmEditWarehouse()'>Sauvegarder
        </button>
    </div>
</ng-template>

<!----delete warehouse modal -->
<ng-template #deleteWarehouse let-modal>
    <div class="modal-header text-center">
        <h5 class="modal-title">Confirmation de suppression</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center">
        <div class="form-group">
            <label>Êtes-vous sûr de vouloir supprimer ce dépôt ?</label>
            <h5>{{tempWarehouseName}}</h5>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-lg" (click)="modal.close()">Annuler
        </button>
        <button type="button" class="btn btn-primary btn-lg" (click)='confirmDelete()'>Confirmer
        </button>
    </div>
</ng-template>

<!----success product modal -->
<ng-template #successModal let-modal>
    <div class="modal-header text-center border-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body py-0 text-center">
        <div class="row">
            <div class="col-12">
                <i class="fas fa-check-circle tn-success-color tn-font-size-50"></i>
            </div>
        </div>
        <label class="mt-3"> {{successModalMessage}} </label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
    </div>
</ng-template>


<!----failure product modal -->
<ng-template #failureModal let-modal>
    <div class="modal-header text-center border-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body py-0 text-center">
        <div class="row">
            <div class="col-12">
                <i class="fas fa-exclamation-circle tn-failure-color tn-font-size-50"></i>
            </div>
        </div>
        <label class="mt-3"> {{failureModalMessage}} </label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" (click)="modal.close()">Fermer</button>
    </div>
</ng-template>
  
  <div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-success loader" role="status">
        <span class="sr-only ">Loading...</span>
      </div>
    </div>
  </div>
  