<div class="d-flex" id="wrapper">
    <app-side-navigation class="side-bar"></app-side-navigation>
    <div id="page-content-wrapper">
        <nav class="tn-toggle-menu navbar navbar-expand-lg navbar-light">
            <button class="btn btn-primary" (click)="toggleNow()" id="menu-toggle"><i class="fas fa-bars"></i></button>
        </nav>

        <div class="container-fluid">
            <div class="panel mt-5">

                    <ul class="nav nav-tabs">
                        <!-- <li class="nav-item">
                            <a class="nav-link " id="cat">Company</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link active" id="prod" (click)="showBranch()">Établissement</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="prod" (click)="showTables()">Tables</a>
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col-lg-6">
                            <label for="exampleFormControlInput1"></label>
                            <form [formGroup]="searchCompanyName">
                                <div class="input-group mb-3">
                                    <input type="search" class="form-control" formControlName="company_name"
                                        placeholder="Chercher..." aria-label="Search..." aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-primary" (click)="searchCompany()"
                                            type="button"><i class="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-3 add-button-box">
                            <label for=""></label>
                            <div class="form-group">
                                <button type="button" class="btn btn-primary" data-toggle="modal"
                                    data-target="#createNewCompany" ><i
                                        class="fas fa-plus-square"></i> &nbsp; Nouvelle société</button>
                            </div>
                        </div>
                    <!---Table-->
                    <div class="col-12 table-responsive" >
                    <table class="table table-striped text-center table-bordered table-sm row-border hover"
                        id="example">
                        <!-- <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table"> -->
                        <thead class="thead-light">
                            <tr>
                                <th scope="col-3" class="col1">Nom de la société</th>
                                <th scope="col-7" class="col2">Addresse</th>
                                <th scope="col-2" class="col3">Téléphone portable</th>
                                <th scope="col-2" class="col3">Email</th>
                                <th scope="col-2" class="col3" class="action-buttons-col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let company of companies">
                                <td> {{company.company_name}}</td>
                                <td>{{company.company_address}}</td>
                                <td>{{company.company_phone_number}}</td>
                                <td>{{company.company_email}}</td>
                                <td class="action-buttons-col-td">
                                    <button class="btn btn-primary" data-toggle="modal" data-target="#viewCompany"
                                        (click)="viewCompany(company)"><i class="fas fa-eye"></i></button> &nbsp;
                                    <button class="btn btn-primary" data-toggle="modal" data-target="#editCompany"
                                        (click)="editCompany(company)"><i class="fas fa-pen"></i></button> &nbsp;
                                    <button class="btn btn-danger" data-toggle="modal" data-target="#deleteCompany"
                                        (click)="tempDelete(company)"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>

                <!---Add Modal-->

                <div class="modal fade" id="createNewCompany" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Ajouter une nouvelle société</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form [formGroup]="companyForm">
                                    <div class="row">
                                        <div class="col-12">

                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_name" placeholder="Nom de la société" required>
                                                        <div *ngIf="companyForm.controls['company_name'].invalid &&
                                                                (companyForm.controls['company_name'].dirty ||
                                                                companyForm.controls['company_name'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="companyForm.controls['company_name'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_registration"
                                                        placeholder="Numéro d'enregistrement" required>
                                                        <div *ngIf="companyForm.controls['company_registration'].invalid &&
                                                        (companyForm.controls['company_registration'].dirty ||
                                                        companyForm.controls['company_registration'].touched)"
                                                class="alert alert-light">
                                                <div
                                                    *ngIf="companyForm.controls['company_registration'].errors.required">
                                                    Ne devrait pas être vide
                                                </div>
                                            </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_tax_number" placeholder="Identification fiscale" required>
                                                        <div *ngIf="companyForm.controls['company_tax_number'].invalid &&
                                                                (companyForm.controls['company_tax_number'].dirty ||
                                                                companyForm.controls['company_tax_number'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="companyForm.controls['company_tax_number'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_phone_number" placeholder="Numero de telephone" required>
                                                        <div *ngIf="companyForm.controls['company_phone_number'].invalid &&
                                                                (companyForm.controls['company_phone_number'].dirty ||
                                                                companyForm.controls['company_phone_number'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="companyForm.controls['company_phone_number'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="email" class="form-control"
                                                        formControlName="company_email" placeholder="Email" required>
                                                        <div *ngIf="companyForm.controls['company_email'].invalid &&
                                                                (companyForm.controls['company_email'].dirty ||
                                                                companyForm.controls['company_email'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="companyForm.controls['company_email'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_address" placeholder="Addresse" required>
                                                        <div *ngIf="companyForm.controls['company_address'].invalid &&
                                                                (companyForm.controls['company_address'].dirty ||
                                                                companyForm.controls['company_address'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="companyForm.controls['company_address'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger btn-lg"
                                        data-dismiss="modal">Annuler</button>
                                    <button type="button" [disabled]="!companyForm.valid" class="btn btn-primary btn-lg"
                                        (click)="saveNewCompany()">Sauvegarder</button>
                                </div>
                            </div>
                        </div>
                    </div>

                <!---View Modal-->

                <div class="modal fade" id="viewCompany" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Voir la société</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form [formGroup]="viewCompanyForm">
                                    <div class="row">
                                        <div class="col-12">

                                            <div class="form-group">
                                                <input type="text"
                                                    [attr.disabled]="disableEditField == true ? true : null"
                                                    class="form-control" formControlName="company_name"
                                                    placeholder="Nom de société">
                                            </div>
                                            <div class="form-group">
                                                <input type="text"
                                                    [attr.disabled]="disableEditField == true ? true : null"
                                                    class="form-control" formControlName="company_registration"
                                                    placeholder="Numéro d'enregistrement">
                                            </div>
                                            <div class="form-group">
                                                <input type="text"
                                                    [attr.disabled]="disableEditField == true ? true : null"
                                                    class="form-control" formControlName="company_tax_number"
                                                    placeholder="Identification fiscale">
                                            </div>
                                            <div class="form-group">
                                                <input type="text"
                                                    [attr.disabled]="disableEditField == true ? true : null"
                                                    class="form-control" formControlName="company_phone_number"
                                                    placeholder="Téléphone portable">
                                            </div>
                                            <div class="form-group">
                                                <input type="text"
                                                    [attr.disabled]="disableEditField == true ? true : null"
                                                    class="form-control" formControlName="company_email"
                                                    placeholder="Email">
                                            </div>
                                            <div class="form-group">
                                                <input type="text"
                                                    [attr.disabled]="disableEditField == true ? true : null"
                                                    class="form-control" formControlName="company_address"
                                                    placeholder="Addresse">
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger btn-lg"
                                    data-dismiss="modal">Annuler</button>
                                <button type="button" class="btn btn-primary btn-lg"
                                    (click)='viewCompanyName()'>Sauvergarder</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!---Edit Modal-->

                <div class="modal fade" id="editCompany" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Modifier la societe</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form [formGroup]="editCompanyForm">
                                    <div class="row">
                                        <div class="col-12">

                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_name" placeholder="Nom de société" required>
                                                        <div *ngIf="editCompanyForm.controls['company_name'].invalid &&
                                                                (editCompanyForm.controls['company_name'].dirty ||
                                                                editCompanyForm.controls['company_name'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="editCompanyForm.controls['company_name'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_registration"
                                                        placeholder="Numéro d'enregistrement" required>
                                                        <div *ngIf="editCompanyForm.controls['company_registration'].invalid &&
                                                                (editCompanyForm.controls['company_registration'].dirty ||
                                                                editCompanyForm.controls['company_registration'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="editCompanyForm.controls['company_registration'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_tax_number" placeholder="Identification fiscale" required>
                                                        <div *ngIf="editCompanyForm.controls['company_tax_number'].invalid &&
                                                                (editCompanyForm.controls['company_tax_number'].dirty ||
                                                                editCompanyForm.controls['company_tax_number'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="editCompanyForm.controls['company_tax_number'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_phone_number" placeholder="Téléphone portable" required>
                                                        <div *ngIf="editCompanyForm.controls['company_phone_number'].invalid &&
                                                                (editCompanyForm.controls['company_phone_number'].dirty ||
                                                                editCompanyForm.controls['company_phone_number'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="editCompanyForm.controls['company_phone_number'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_email" placeholder="Email" required>
                                                        <div *ngIf="editCompanyForm.controls['company_email'].invalid &&
                                                                (editCompanyForm.controls['company_email'].dirty ||
                                                                editCompanyForm.controls['company_email'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="editCompanyForm.controls['company_email'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        formControlName="company_address" placeholder="Addresse" required>
                                                        <div *ngIf="editCompanyForm.controls['company_address'].invalid &&
                                                                (editCompanyForm.controls['company_address'].dirty ||
                                                                editCompanyForm.controls['company_address'].touched)"
                                                        class="alert alert-light">
                                                        <div
                                                            *ngIf="editCompanyForm.controls['company_address'].errors.required">
                                                            Ne devrait pas être vide
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger btn-lg"
                                        data-dismiss="modal">Annuler</button>
                                    <button type="button" [disabled]="!editCompanyForm.valid" class="btn btn-primary btn-lg"
                                        (click)='editCompanyName()'>Sauvergarder</button>
                                </div>
                            </div>
                        </div>
                    </div>

                <!--Delete Modal -->
                <div class="modal fade text-center" id="deleteCompany" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header text-center">
                                <h5 class="modal-title" id="exampleModalLabel">Confirmer</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body text-center">
                                <form [formGroup]="companyFormDelete">
                                    <div class="form-group">
                                        <label for="exampleFormControlInput1">Voulez-vous supprimer ?</label>
                                        <h5>{{companyToDelete}}</h5>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer text-center">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Annuler</button>
                                <button type="button" class="btn btn-primary"
                                    (click)="deleteCompany()">Confirmer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="spinnerBox" *ngIf="spin">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-success loader" role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
</div>
