import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CategoryComponent } from './components/category/category.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CategoryMenuComponent } from './components/category-menu/category-menu.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsComponent } from './components/products/products.component';
import { EmployeesComponent } from './components/employees/employees.component';
import { OrdersComponent } from './components/orders/orders.component';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { DataTablesModule } from 'angular-datatables';
import { EmployeeLandingComponent } from './components/employee-landing/employee-landing.component';
import { EmployeeChangePasswordComponent } from './components/employee-change-password/employee-change-password.component';
import { BranchesComponent } from './components/branches/branches.component';
import { CompanyComponent } from './components/company/company.component';
import { ReportComponent } from './components/report/report.component';
import { BasicAuthHtppInterceptorService } from './services/interceptor/basic-auth-htpp-interceptor.service';
import { ErrorInterceptorService } from './services/interceptor/error-interceptor.service';
import { TablesComponent } from './components/tables/tables.component';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { TestComponent } from './components/test/test.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';
import { FirstLoginComponent } from './components/first-login/first-login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { ReportProfitComponent } from './components/report-profit/report-profit.component';
import { ReportLowStockComponent } from './components/report-low-stock/report-low-stock.component';
import { ReportEmployeePerformanceComponent } from './components/report-employee-performance/report-employee-performance.component';
import { ReportStockUpdateComponent } from './components/report-stock-update/report-stock-update.component';
import { ReportPastOrderComponent } from './components/report-past-order/report-past-order.component';
import { ExpenseComponent } from './components/expense/expense.component';
import { ReportExtraComponent } from './components/report-extra/report-extra.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { WarehouseSaleHistoryComponent } from './components/warehouse-sale-history/warehouse-sale-history.component';
import { WarehouseSetupComponent } from './components/warehouse-setup/warehouse-setup.component';
import { WarehouseTransactionHistoryComponent } from './components/warehouse-transaction-history/warehouse-transaction-history.component';
import { SupplierHistoryComponent } from './components/supplier-history/supplier-history.component';
import { NgxPrintModule } from 'ngx-print';
import { GiftOrdersComponent } from './components/gift-orders/gift-orders.component';
import { WarehouseRefillComponent } from './components/warehouse-refill/warehouse-refill.component';
import { ThermalPrintModule } from 'ng-thermal-print';
import { ReportWithIssuesComponent } from './components/report-with-issues/report-with-issues.component';
import { BranchMenuComponent } from './components/branch-menu/branch-menu.component';
import { QrCodeGenerateComponent } from './components/qr-code-generate/qr-code-generate.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ReportDeductedStockComponent } from './components/report-deducted-stock/report-deducted-stock.component';
import { ReportDebtsOrderComponent } from './components/report-debts-order/report-debts-order.component';

// import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CategoryComponent,
    CategoryMenuComponent,
    ProductsComponent,
    EmployeesComponent,
    OrdersComponent,
    SuppliersComponent,
    EmployeeLandingComponent,
    EmployeeChangePasswordComponent,
    BranchesComponent,
    CompanyComponent,
    ReportComponent,
    TablesComponent,
    SideNavigationComponent,
    TestComponent,
    ViewProfileComponent,
    FirstLoginComponent,
    ForgotPasswordComponent,
    ReportProfitComponent,
    ReportLowStockComponent,
    ReportEmployeePerformanceComponent,
    ReportStockUpdateComponent,
    ReportPastOrderComponent,
    ExpenseComponent,
    ReportExtraComponent,
    WarehouseComponent,
    WarehouseSaleHistoryComponent,
    WarehouseSetupComponent,
    WarehouseTransactionHistoryComponent,
    SupplierHistoryComponent,
    GiftOrdersComponent,
    WarehouseRefillComponent,
    ReportWithIssuesComponent,
    BranchMenuComponent,
    QrCodeGenerateComponent,
    ReportDeductedStockComponent,
    ReportDebtsOrderComponent

    // HeaderComponent
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    ChartsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    NgbModule,
    NgxPaginationModule,
    NgbModule,
    ThermalPrintModule,
    NgxPaginationModule,
    NgxPrintModule,
    QRCodeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthHtppInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
    // { provide: LOCALE_ID, useValue: 'fr-FR'},
    // { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
