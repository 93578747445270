<div class="nav flex-column" id="sidebar-wrapper">
  <div class="brand-logo mb-5">
    <img src="assets/images/PagadoLogo.png" alt="logo">
  </div>
<!--  <div class="sidebar-heading pb-1 text-center"> Pagado</div>-->
<!--  <div class="mb-5 text-center"> Bar & Restaurant</div>-->

  <ul class="list-group list-group-flush">
    <li class="nav-item" [ngClass]="{active:currentState === '/dashboard'}">
      <a class="nav-link pl-5" (click)="goToDashboard()">
        <i class="fas fa-tachometer-alt"></i>&nbsp;
        <span class="menu-title">Tableau de bord</span>
      </a>
    </li>
    <li *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator' 
    || roleLoggedIn == 'Manager Full' || roleLoggedIn == 'Manager Normal' || roleLoggedIn == 'Manager Stock'" 
    class="nav-item" id="productTab" [ngClass]="{active:currentState === '/products'}">
      <a class="nav-link pl-5" (click)="goToManageProducts()"><i class="fas fa-barcode"></i>&nbsp;
        <span class="menu-title">Stock &nbsp;</span> &nbsp;</a>
    </li>
    <li *ngIf="roleLoggedIn !== 'Bar Person' && roleLoggedIn !== 'Waiter'"  class="nav-item" 
    [ngClass]="{active:currentState === '/employees'}">
      <a class="nav-link pl-5" (click)="goToEmployees()"><i class="bi bi-people-fill"></i>&nbsp;
        <span class="menu-title">Employés</span></a>
    </li>
    <li *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator' 
    || roleLoggedIn == 'Manager Full' || roleLoggedIn == 'Manager Normal'" 
    class="nav-item" [ngClass]="{active:currentState === '/report'}">
      <a class="nav-link pl-5" (click)="goToReport()"> <i class="fas fa-chart-line"></i>&nbsp;
        <span class="menu-title">Rapports</span></a>
    </li>
    <li *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator' 
    || roleLoggedIn == 'Manager Full' || roleLoggedIn == 'Manager Normal'" 
    class="nav-item" [ngClass]="{active:currentState === '/report-with-issues'}">
      <a class="nav-link pl-5" (click)="goToOtherReport()"> <i class="fas fa-chart-line"></i>&nbsp;
        <span class="menu-title">Autre Rapports</span></a>
    </li>
    <li *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator' 
    || roleLoggedIn == 'Manager Full' || roleLoggedIn == 'Manager Normal'" 
    class="nav-item" [ngClass]="{active:currentState === '/expense'}">
      <a class="nav-link pl-5" (click)="goToExpense()"> <i class="fas fa-money-check-alt"></i>&nbsp;
        <span class="menu-title">Dépenses</span></a>
    </li>
    <li *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator' 
    || roleLoggedIn == 'Manager Full' || roleLoggedIn == 'Manager Normal' || roleLoggedIn == 'Manager Stock' || roleLoggedIn == 'Manager Warehouse'"  
    class="nav-item" [ngClass]="{active:currentState === '/suppliers' || currentState.includes('/supplier-transactions') }">
      <a class="nav-link pl-5" (click)="goToSupplier()"><i class="fas fa-truck"></i>&nbsp;
        <span class="menu-title">Fournisseurs</span></a>
    </li>
    <li *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator' 
    || roleLoggedIn == 'Manager Full' || roleLoggedIn == 'Manager Warehouse'" 
    class="nav-item" [ngClass]="{active:currentState === '/warehouse'}">
      <a class="nav-link pl-5" (click)="goToWarehouse()"><i class="fas fa-warehouse"></i>&nbsp;
        <span class="menu-title">Dépôt</span></a>
    </li>
    <li *ngIf="roleLoggedIn == 'Administrator' || roleLoggedIn == 'Deputy Administrator' 
    || roleLoggedIn == 'Manager Full' || roleLoggedIn == 'Manager Normal'"  
    class="nav-item" [ngClass]="{active:currentState === '/company'}">
      <a class="nav-link pl-5" (click)="goToCompany()"><i class="bi bi-building"></i>&nbsp;
        <span class="menu-title">Société</span></a>
    </li>
    <li class="nav-item" [ngClass]="{active:currentState === '/view-profile'}">
      <a class="nav-link pl-5" (click)="goToProfile()"><i class="fas fa-user"></i>&nbsp;
        <span class="menu-title">Mon profil</span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link pl-5" (click)="logout()"><i class="fas fa-sign-out-alt"></i>&nbsp;
        <span class="menu-title">Se déconnecter</span></a>
    </li>
  </ul>
</div>
